import { get, post, put, patch } from "../Config"

export function SearchHelpCenterList({ params = {} }) {
  return get({ url: `/v1/admin/helpCenterList`, params })
}
export function GetHelpCenterListById({ id }) {
  return get({ url: `/v1/admin/helpCenterList/${id}` })
}
export function CreateHelpCenterList({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/helpCenterList`, params, data })
}
export function UpdateHelpCenterList({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/helpCenterList/${id}`, params, data })
}
export function PatchHelpCenterList({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/helpCenterList/${id}`, params, data })
}
export function DeleteHelpCenterList({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/helpCenterList/mutiDelete`, params, data })
}
