import React from "react"
import { useParams } from "react-router-dom";

export const ChatwootWidget = ({questionnaireUUID}) => {

    // Add Chatwoot Settings
    window.chatwootSettings = {
        hideMessageBubble: false,
        position: "right", // This can be left or right
        locale: "en", // Language to be set
        type: "standard", // [standard, expanded_bubble]
    }

    // Paste the script from inbox settings except the <script> tag
    ;(function (d, t) {
        var BASE_URL="https://chatwoot-main.scapp.work";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
            window.chatwootSDK.run({
            websiteToken: 'hwzRWLks3mxif2aXiTEYRZ4F',
            baseUrl: BASE_URL
        })}
    })(document, "script")

    window.addEventListener("chatwoot:ready", function () {
        window.$chatwoot.setCustomAttributes({
            questionaireId: questionnaireUUID,
        });
        console.log('questionnaireUUID', questionnaireUUID)
    });
}
