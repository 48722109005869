import { get, post, put, patch } from "../Config"

export function SearchJobType({ params = {} }) {
  return get({ url: `/v1/admin/jobType`, params })
}
export function GetJobTypeById({ id }) {
  return get({ url: `/v1/admin/jobType/${id}` })
}
export function CreateJobType({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/jobType`, params, data })
}
export function UpdateJobType({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/jobType/${id}`, params, data })
}
export function PatchJobType({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/jobType/${id}`, params, data })
}
export function DeleteJobType({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/jobType/mutiDelete`, params, data })
}
