import { jwtDecode } from "jwt-decode";

function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
}

function checkScope(_scope) {
    const cmsMenuStr = localStorage.getItem("cmsMenu");
    const decoded = JSON.parse(cmsMenuStr);
    const scope = decoded.scope;

    return scope.indexOf(_scope) !== -1;
}

function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
}

const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

export {
    getCookie,
    checkScope,
    inArray,
    capitalize
}