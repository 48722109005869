import React, { useEffect, useState, useMemo, useContext, useRef } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
  CardHeader,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import Select from "react-select"

import ModalContext from "../../contexts/ModalContext"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import fn_helper from "helpers/fn_helper"

import Sortable from "sortablejs"

import { detailCustomer, updateCustomer } from "../../services/api/module/Customer"

import moment from "moment"

const GENDER_OPTION = [
  { id: "m", name: "ผู้ชาย" },
  { id: "f", name: "ผู้หญิง" },
  { id: "x", name: "ไม่ระบุเพศ" },
]

const USERTYPE_OPTION = [
  { id: "0", name: "บุคคลธรรมดา" },
  { id: "1", name: "นิติบุคคล" },
]

const STATUS_OPTION = [
  { id: "inactive", name: "Inactive" },
  { id: "active", name: "Active" },
]

const CustomerFormTemplatePage = ({ pageView = "create" }) => {
  /* Popup */
  const { mAlert } = useContext(ModalContext)
  /* End Popup */

  /* Navigate */
  const navigate = useNavigate()
  /* End Navigate */

  /* Api */
  const API = {
    // createContent: async payload => {
    //   let _return = {
    //     status: false,
    //     result: null,
    //   }

    //   try {
    //     const response = await createCustomer({ data: payload })
    //     const resData = response?.data ?? []
    //     // console.log("resData >>", resData)

    //     if (resData.status === 201) {
    //       mAlert.info({
    //         title: "Alert",
    //         subTitle: "Created Data Success",
    //         content: "",
    //         onClose: () => {
    //           navigate("/customer")
    //         },
    //       })
    //     }
    //   } catch (error) {
    //     console.log("error", error)

    //     mAlert.info({
    //       title: "Alert",
    //       subTitle: "Something went wrong",
    //       content: "",
    //       onClose: () => {
    //         // alert("onClose")
    //       },
    //     })
    //   }

    //   return _return
    // },
    detailContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await detailCustomer({
          params: payload.params,
          id: payload.id,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 202) {
          _return.status = true
          _return.result = resData.data
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    updateContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await updateCustomer({
          data: payload,
          id: payload.id,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/customer")
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
  }
  /* End Api */

  /* Form Validation */
  const [contentData, setContentData] = useState(null)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerId: contentData !== null && contentData.id ? contentData.id : "",
      firstname:
        contentData !== null && contentData.firstname
          ? contentData.firstname
          : "",
      lastname:
        contentData !== null && contentData.lastname
          ? contentData.lastname
          : "",
      email: contentData !== null && contentData.email ? contentData.email : "",
      mobileNo:
        contentData !== null && contentData.mobileNo
          ? contentData.mobileNo
          : "",
      gender:
        contentData !== null && contentData.gender ? contentData.gender : "",
      birthDate:
        contentData !== null && contentData.birthDate
          ? moment(contentData.birthDate).format("YYYY-MM-DD")
          : "",
      userType: contentData !== null ? contentData.userType.toString() : "",
      currentPoint:
        contentData !== null && contentData.currentPoint
          ? contentData.currentPoint
          : "0",
      isPdpa:
        contentData !== null ? (contentData.isPdpa ? true : false) : false,
      isNewsAndPro:
        contentData !== null
          ? contentData.isNewsAndPro === true
            ? true
            : false
          : false,
      isEmailconfirmed:
        contentData !== null
          ? contentData.isEmailconfirmed === true
            ? true
            : false
          : false,
      isLineVerified:
        contentData !== null
          ? contentData.isLineVerified === true
            ? true
            : false
          : false,
      isSurvey:
        contentData !== null
          ? contentData.isSurvey === true
            ? true
            : false
          : false,
      isDeleteAccount:
        contentData !== null
          ? contentData.isDeleteAccount === true
            ? true
            : false
          : false,
      status: contentData !== null ? contentData.status : "active",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Firstname"),
      lastname: Yup.string().required("Please Enter Lastname"),
      email: Yup.string().required("Please Enter Email"),
      mobileNo: Yup.string().required("Please Enter Mobile Number"),
      gender: Yup.string().required("Please Enter Gender"),
      birthDate: Yup.string().required("Please Enter Birthdate"),
      userType: Yup.string().required("Please Enter User Type"),
      status: Yup.string().required("Please Select Status"),
    }),
    onSubmit: values => {
      console.log("🚀 ~ Survey ~ values:", values)
      let payload = {
        id:  values.customerId ? values.customerId : undefined,
        birthDate: values.birthDate,
        // currentPoint:values.currentPoint,
        // customerId: values.customerId,
        // email: values.email,
        // firstname: values.firstname,
        // gender: values.gender,
        isDeleteAccount: parseInt(values.isDeleteAccount),
        // isEmailconfirmed: values.isEmailconfirmed,
        // isLineVerified: values.isLineVerified,
        // isNewsAndPro: values.isNewsAndPro,
        // isPdpa: values.isPdpa,
        // isSurvey: values.isSurvey,
        // lastname: values.lastname,
        // mobileNo: values.mobileNo,
        // status: values.status,
        // userType: values.userType
      }
      console.log("🚀 ~ onSave ~ payload:", payload)
      if (pageView === "update") {
        API.updateContent(payload)
      } 
      // else {
      //   API.createContent(payload)
      // }
    },
  })
  /* End Form Validation */

  /* First event render */
  const qParams = useParams()
  const qContentId = qParams.id

  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      API.detailContent({ params: {}, id: qContentId }).then(_rs => {
        // console.log(_rs)

        if (_rs.status === true) {
          setContentData(_rs.result)
        } else {
          navigate("/customer")
        }
      })
    }
  }, [])
  /* End First event render */

  //meta title
  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `Customer | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={`${PAGE_VIEW} Customer`}
            breadcrumbItems={[
              { title: "Customer", link: "customer" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row>
                      <div className="card-title">Customer Infomation</div>
                      <p className="card-title-desc mb4">
                        Fill all information below
                      </p>
                    </Row>
                    <Row>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="firstname"
                          className="col-md-12 col-form-label"
                        >
                          Firstname
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="firstname"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="firstname"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstname}
                            invalid={
                              validation.touched.firstname &&
                              validation.errors.firstname
                                ? true
                                : false
                            }
                            disabled={true}
                          />
                          {validation.touched.firstname &&
                            validation.errors.firstname && (
                              <FormFeedback type="invalid">
                                {validation.errors.firstname}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="lastname"
                          className="col-md-12 col-form-label"
                        >
                          Lastname
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="lastname"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="lastname"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastname}
                            invalid={
                              validation.touched.lastname &&
                              validation.errors.lastname
                                ? true
                                : false
                            }
                            disabled={true}
                          />
                          {validation.touched.lastname &&
                            validation.errors.lastname && (
                              <FormFeedback type="invalid">
                                {validation.errors.lastname}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="email"
                          className="col-md-12 col-form-label"
                        >
                          Email
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="email"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                            disabled={true}
                          />
                          {validation.touched.email &&
                            validation.errors.email && (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="mobileNo"
                          className="col-md-12 col-form-label"
                        >
                          Mobile Number
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="mobileNo"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="mobileNo"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mobileNo}
                            invalid={
                              validation.touched.mobileNo &&
                              validation.errors.mobileNo
                                ? true
                                : false
                            }
                            disabled={true}
                          />
                          {validation.touched.mobileNo &&
                            validation.errors.mobileNo && (
                              <FormFeedback type="invalid">
                                {validation.errors.mobileNo}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="gender"
                          className="col-md-12 col-form-label"
                        >
                          Gender
                        </label>
                        <div className="col-md-12">
                          <Select
                            name="gender"
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(
                              GENDER_OPTION,
                              `${validation.values.gender}`
                            )}
                            onChange={selectedOption => {
                              validation.setFieldValue(
                                `gender`,
                                selectedOption.id
                              )
                            }}
                            onBlur={() =>
                              validation.setFieldTouched(`gender`, true)
                            }
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            options={GENDER_OPTION}
                            className={`select2-selection ${
                              validation.touched.gender &&
                              validation.errors.gender
                                ? "input-err"
                                : ""
                            }`}
                            isDisabled={true}
                          />
                          {validation.touched.gender &&
                            validation.errors.gender && (
                              <FormFeedback type="invalid">
                                {validation.errors.gender}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="birthDate"
                          className="col-md-12 col-form-label"
                        >
                          Birthdate
                        </label>
                        <div className="col-md-12">
                        <Input
                          id="birthDate"
                          className="form-control"
                          type="date"
                          placeholder=""
                          name="birthDate"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.birthDate}
                          invalid={
                            validation.touched.birthDate &&
                            validation.errors.birthDate
                              ? true
                              : false
                          }
                          disabled={pageView === "view"}
                        />
                        {validation.touched.birthDate &&
                          validation.errors.birthDate && (
                            <FormFeedback type="invalid">
                              {validation.errors.birthDate}
                            </FormFeedback>
                          )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="userType"
                          className="col-md-12 col-form-label"
                        >
                          User Type
                        </label>
                        <div className="col-md-12">
                          <Select
                            name="userType"
                            placeholder="Select User Type"
                            value={fn_helper.FNFORM.getObjectValue(
                              USERTYPE_OPTION,
                              `${validation.values.userType}`
                            )}
                            onChange={selectedOption => {
                              validation.setFieldValue(
                                `userType`,
                                selectedOption.id
                              )
                            }}
                            onBlur={() =>
                              validation.setFieldTouched(`userType`, true)
                            }
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            options={USERTYPE_OPTION}
                            className={`select2-selection ${
                              validation.touched.userType &&
                              validation.errors.userType
                                ? "input-err"
                                : ""
                            }`}
                            isDisabled={true}
                          />
                          {validation.touched.userType &&
                            validation.errors.userType && (
                              <FormFeedback type="invalid">
                                {validation.errors.userType}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="currentPoint"
                          className="col-md-12 col-form-label"
                        >
                          Current Point
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="currentPoint"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="currentPoint"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.currentPoint}
                            invalid={
                              validation.touched.currentPoint &&
                              validation.errors.currentPoint
                                ? true
                                : false
                            }
                            disabled={true}
                          />
                          {validation.touched.currentPoint &&
                            validation.errors.currentPoint && (
                              <FormFeedback type="invalid">
                                {validation.errors.currentPoint}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="isPdpa"
                          className="col-md-12 col-form-label"
                        >
                          Is Pdpa
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="isPdpa"
                            className="form-control"
                            type="checkbox"
                            placeholder=""
                            name="isPdpa"
                            checked={validation.values.isPdpa}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.isPdpa &&
                              validation.errors.isPdpa
                                ? true
                                : false
                            }
                            disabled={true}
                          />
                          {validation.touched.isPdpa &&
                            validation.errors.isPdpa && (
                              <FormFeedback type="invalid">
                                {validation.errors.isPdpa}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="isNewsAndPro"
                          className="col-md-12 col-form-label"
                        >
                          Is News And Pro
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="isNewsAndPro"
                            className="form-control"
                            type="checkbox"
                            placeholder=""
                            name="isNewsAndPro"
                            checked={validation.values.isNewsAndPro}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.isNewsAndPro &&
                              validation.errors.isNewsAndPro
                                ? true
                                : false
                            }
                            disabled={true}
                          />
                          {validation.touched.isNewsAndPro &&
                            validation.errors.isNewsAndPro && (
                              <FormFeedback type="invalid">
                                {validation.errors.isNewsAndPro}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      {/* <Col className="col-6 mb-3">
                        <label
                          htmlFor="isEmailconfirmed"
                          className="col-md-12 col-form-label"
                        >
                          Is Email Confirmed
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="isEmailconfirmed"
                            className="form-control"
                            type="checkbox"
                            placeholder=""
                            name="isEmailconfirmed"
                            checked={validation.values.isEmailconfirmed}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.isEmailconfirmed &&
                              validation.errors.isEmailconfirmed
                                ? true
                                : false
                            }
                            disabled={true}
                          />
                          {validation.touched.isEmailconfirmed &&
                            validation.errors.isEmailconfirmed && (
                              <FormFeedback type="invalid">
                                {validation.errors.isEmailconfirmed}
                              </FormFeedback>
                            )}
                        </div>
                      </Col> */}
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="isLineVerified"
                          className="col-md-12 col-form-label"
                        >
                          Is Verified
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="isLineVerified"
                            className="form-control"
                            type="checkbox"
                            placeholder=""
                            name="isLineVerified"
                            checked={validation.values.isLineVerified}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.isLineVerified &&
                              validation.errors.isLineVerified
                                ? true
                                : false
                            }
                            disabled={true}
                          />
                          {validation.touched.isLineVerified &&
                            validation.errors.isLineVerified && (
                              <FormFeedback type="invalid">
                                {validation.errors.isLineVerified}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="isSurvey"
                          className="col-md-12 col-form-label"
                        >
                          Is Survey
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="isSurvey"
                            className="form-control"
                            type="checkbox"
                            placeholder=""
                            name="isSurvey"
                            checked={validation.values.isSurvey}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.isSurvey &&
                              validation.errors.isSurvey
                                ? true
                                : false
                            }
                            disabled={true}
                          />
                          {validation.touched.isSurvey &&
                            validation.errors.isSurvey && (
                              <FormFeedback type="invalid">
                                {validation.errors.isSurvey}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="isDeleteAccount"
                          className="col-md-12 col-form-label"
                        >
                          Is Delete Account
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="isDeleteAccount"
                            className="form-control"
                            type="checkbox"
                            placeholder=""
                            name="isDeleteAccount"
                            checked={validation.values.isDeleteAccount == '1'}
                            onClick={(value)=> {
                              const updateValue = validation.values.isDeleteAccount !== '1' ? '1' : '0'
                              validation.setFieldValue("isDeleteAccount", updateValue)
                            }}
                            readOnly

                            // onChange={validation.handleChange}
                            // onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.isDeleteAccount &&
                              validation.errors.isDeleteAccount
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.isDeleteAccount &&
                            validation.errors.isDeleteAccount && (
                              <FormFeedback type="invalid">
                                {validation.errors.isDeleteAccount}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div
                  className="mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "8px",
                  }}
                >
                  {pageView == "view" ? (
                    <>
                      <button
                        className="btn btn-primary w-md"
                        onClick={() => navigate("/customer")}
                      >
                        BACK
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="submit" className="btn btn-primary w-md">
                        SAVE
                      </button>
                      <button
                        className="btn btn-secondary w-md"
                        onClick={() => navigate("/customer")}
                      >
                        CANCEL
                      </button>
                    </>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

CustomerFormTemplatePage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CustomerFormTemplatePage)
