import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateHelpCenterList,
  GetHelpCenterListById,
  UpdateHelpCenterList,
} from "services/api/module/HelpCenterList"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import { DropdownHelpCenterCategory } from "services/api/module/Dropdown"
import MyEditor from "components/MyEditor"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const HelpCenterListTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qHelpCenterListId = qParams.id
  const navigate = useNavigate()

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    categoryId: Yup.string().max(100).required("Please Select Category"),
    titleTh: Yup.string().max(100).required("Please Enter title TH"),
    titleEn: Yup.string().max(100).required("Please Enter title EN"),
    sortOrder: Yup.string().max(100).required("Please Enter sort-order"),
    status: Yup.string().max(100).required("Please Enter Status"),
  })
  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)
  const [ddCategory, setDDCategory] = useState([])

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] =
    useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryId: "",
      titleTh: "",
      titleEn: "",
      sortOrder: "",
      status: "",
      detailTh: "",
      detailEn: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ HelpCenterListCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        categoryId: Number(values.categoryId),
        titleTh: values.titleTh,
        titleEn: values.titleEn,
        sortOrder: values.sortOrder,
        status: Number(values.status),
        detailTh: values.detailTh,
        detailEn: values.detailEn,
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.fetchUpdateHelpCenterList(payload)
      } else {
        API.fetchCreateHelpCenterList(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("categoryId", resData.helpCenterCategoryId)
    validation.setFieldValue("titleTh", resData.titleTh)
    validation.setFieldValue("titleEn", resData.titleEn)
    validation.setFieldValue("sortOrder", resData.sortOrder)
    validation.setFieldValue("status", resData.status)
    validation.setFieldValue("detailTh", resData.detailTh)
    validation.setFieldValue("detailEn", resData.detailEn)
  }

  const API = {
    fetchDDCategory: async () => {
      try {
        const response = await DropdownHelpCenterCategory({})
        const resData = response?.data ?? []
        const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
        console.log("🚀 ~ fetchDDBrand: ~ tmpDD:", tmpDD)
        setDDCategory(tmpDD)
        setDDLoaded0(true)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchGetHelpCenterListById: async payload => {
      try {
        const response = await GetHelpCenterListById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateHelpCenterList: async payload => {
      try {
        const response = await CreateHelpCenterList({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/help-center/list")
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateHelpCenterList: async payload => {
      try {
        const response = await UpdateHelpCenterList({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/help-center/list")
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qHelpCenterListId }
      API.fetchGetHelpCenterListById(payload)
    }
  }, [])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDCategory()
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `HelpCenterList | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} HelpCenterList`}
            breadcrumbItems={[
              { title: "HelpCenterList", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12">
                                <h5 className="mb-3">Help Center Infomation</h5>
                              </Col>
                              <Col className="col-6 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Select Category
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Category"
                                    value={fn_helper.FNFORM.getObjectValue(
                                      ddCategory,
                                      `${validation.values.categoryId}`
                                    )}
                                    onChange={value =>
                                      validation.setFieldValue(
                                        "categoryId",
                                        value.id
                                      )
                                    }
                                    getOptionValue={option => option.id}
                                    getOptionLabel={option => option.name}
                                    options={ddCategory}
                                    className={`select2-selection ${
                                      validation.touched.categoryId &&
                                      validation.errors.categoryId
                                        ? "input-err"
                                        : ""
                                    }`}
                                    isDisabled={["update", "view"].includes(
                                      pageView
                                    )}
                                  />
                                  {validation.touched.categoryId &&
                                    validation.errors.categoryId && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {validation.errors.categoryId}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="title_th"
                                  className="col-md-12 col-form-label"
                                >
                                  Title TH
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="titleTh"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="titleTh"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.titleTh}
                                    invalid={
                                      validation.touched.titleTh &&
                                      validation.errors.titleTh
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                  />
                                  {validation.touched.titleTh &&
                                    validation.errors.titleTh && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.titleTh}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Title En
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="titleEn"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="titleEn"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.titleEn}
                                    invalid={
                                      validation.touched.titleEn &&
                                      validation.errors.titleEn
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                  />
                                  {validation.touched.titleEn &&
                                    validation.errors.titleEn && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.titleEn}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="sort_order"
                                  className="col-md-12 col-form-label"
                                >
                                  Sort Order
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="sortOrder"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="sortOrder"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.sortOrder}
                                    invalid={
                                      validation.touched.sortOrder &&
                                      validation.errors.sortOrder
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                  />
                                  {validation.touched.sortOrder &&
                                    validation.errors.sortOrder && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sortOrder}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="status"
                                  className="col-md-12 col-form-label"
                                >
                                  Status (Active/Inactive)
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Status"
                                    value={fn_helper.FNFORM.getObjectValue(
                                      STATUS_OPTION,
                                      `${validation.values.status}`
                                    )}
                                    onChange={value =>
                                      validation.setFieldValue(
                                        "status",
                                        value.id
                                      )
                                    }
                                    getOptionValue={option => option.id}
                                    getOptionLabel={option => option.name}
                                    options={STATUS_OPTION}
                                    className={`select2-selection ${
                                      validation.touched.status &&
                                      validation.errors.status
                                        ? "input-err"
                                        : ""
                                    }`}
                                    isDisabled={pageView === "view"}
                                  />
                                  {validation.touched.status &&
                                    validation.errors.status && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {validation.errors.status}
                                      </div>
                                    )}
                                  {validation.touched.status &&
                                    validation.errors.status && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.status}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="detail_th"
                          className="col-md-12 col-form-label"
                        >
                          Content Thai
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <div
                            className={
                              validation.touched.detailTh &&
                              validation.errors.detailTh
                                ? "invalid-editor"
                                : ""
                            }
                          >
                            <MyEditor
                              value={validation.values.detailTh}
                              onChange={(event, editor) => {
                                const data = editor.getData()
                                validation.setFieldValue("detailTh", data)
                                console.log({ event, editor, data })
                              }}
                              disabled={pageView === "view"}
                            />
                          </div>

                          {validation.touched.detailTh &&
                            validation.errors.detailTh && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.detailTh}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="detail_en"
                          className="col-md-12 col-form-label"
                        >
                          Content En
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <div
                            className={
                              validation.touched.detailEn &&
                              validation.errors.detailEn
                                ? "invalid-editor"
                                : ""
                            }
                          >
                            <MyEditor
                              value={validation.values.detailEn}
                              onChange={(event, editor) => {
                                const data = editor.getData()
                                validation.setFieldValue("detailEn", data)
                                console.log({ event, editor, data })
                              }}
                              disabled={pageView === "view"}
                            />
                          </div>

                          {validation.touched.detailEn &&
                            validation.errors.detailEn && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.detailEn}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      {pageView == "view" ? (
                        <>
                          <button
                            className="btn btn-primary w-md"
                            onClick={() => navigate("/help-center/list")}
                          >
                            BACK
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-secondary w-md"
                            onClick={() => navigate("/help-center/list")}
                          >
                            CANCEL
                          </button>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(HelpCenterListTemplate)
