import React, { useState, useEffect, useRef } from "react";
import Switch from "react-switch";
// import { Dialog } from 'primereact/dialog';
import { Modal } from "reactstrap";

function InputSwitch({
  labelOFF = "No",
  labelON = "Yes",
  value = false,
  onChange = () => console.log('onChange'),
  disabled = false,
  onColor = "#626ed4",
  offColor = "#888",
  width = 56
}) {

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        {labelOFF}
      </div>
    );
  };
  
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        {labelON}
      </div>
    );
  };


  return (
    <div>
      <Switch
        uncheckedIcon={<Offsymbol />}
        checkedIcon={<OnSymbol />}
        className="me-1 mb-sm-8 mb-2"
        onColor={onColor}
        offColor={offColor}
        disabled={disabled}
        onChange={() => {
          onChange(!value);
        }}
        checked={value}
        width={width}
      />
    </div>
  )
}

export default InputSwitch;
