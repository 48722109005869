import { get, post, put } from "../Config"

export function listSurvey({ params = {} }) {
  return get({ url: `/v1/admin/survey/`, params })
}
export function detailSurvey({ params = {}, id }) {
  return get({ url: `/v1/admin/survey/detail/${id}`, params })
}
export function createSurvey({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/survey/create`, params, data })
}
export function updateSurvey({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/survey/update/${id}`, params, data })
}
export function deleteSurvey({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/survey/delete`, params, data })
}
