import React, { useEffect, useState, useContext, useRef } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CheckDuplicateArticleKeyword, CreateArticleKeyword, GetArticleKeywordById, UpdateArticleKeyword } from "services/api/module/ArticleKeyword"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"
import moment from "moment"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const ArticleKeyword = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qArticleKeywordId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    nameTh: Yup.string().max(100).required("Please Enter Keyword Name TH"),
    nameEn: Yup.string().max(100).required("Please Enter Keyword Name EN"),
    status: Yup.string().max(100).required("Please Enter Status"),

  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)
  
  // CheckDUP STATE
  const [errDupField, setErrDupField] = useState({ nameTh: false, nameEn: false })
  const timeoutInputRef = useRef(null);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameTh: "",
      nameEn: "",
      status: "",
    },
    validationSchema: Yup.object({
      nameTh: Yup.string().max(100).required("Please Enter Keyword Name TH"),
      nameEn: Yup.string().max(100).required("Please Enter Keyword Name EN"),
      status: Yup.string().max(100).required("Please Enter Status"),
  
    }),
    onSubmit: values => {
      const isPass = Object.values(errDupField).every(item => item === false) // เช็คว่าทุกตัว ไม่มีค่า true
      console.log("🚀 ~ isPass:", isPass)

      if (isPass) {
        console.log("🚀 ~ ArticleKeywordCreate ~ values:", values)
        const payload = {
          id: values.id ? values.id : undefined,
          nameTh: values.nameTh,
          nameEn: values.nameEn,
          status: Number(values.status),
        };
        console.log("🚀 ~ onSave ~ payload:", payload);

        if (pageView === "update") {
          API.fetchUpdateArticleKeyword(payload)
        } else {
          API.fetchCreateArticleKeyword(payload)
        }
      }
    }
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetArticleKeywordById: async (payload) => {
      try {
          const response = await GetArticleKeywordById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateArticleKeyword: async (payload) => {
      try {
          const response = await CreateArticleKeyword({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/article/keyword')
                },
            })
          } else if (resData.status === 403) {
            const updateErrDupField = _.cloneDeep(errDupField)
            updateErrDupField[resData.data] = true
            setErrDupField(updateErrDupField)
            
            mAlert.info({
              title: "Alert",
              subTitle: "Data Existing",
              content: "",
              onClose: () => {
                
              },
            })
          } else {
            throw resData
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateArticleKeyword: async (payload) => {
      try {
          const response = await UpdateArticleKeyword({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/article/keyword')
                  },
              })
          } else if (resData.status === 403) {
            const updateErrDupField = _.cloneDeep(errDupField)
            updateErrDupField[resData.data] = true
            setErrDupField(updateErrDupField)
            
            mAlert.info({
              title: "Alert",
              subTitle: "Data Existing",
              content: "",
              onClose: () => {
                
              },
            })
          } else {
            throw resData
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchCheckFieldDup: async (payload) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await CheckDuplicateArticleKeyword({ data: payload })
          const resData = response.data
          resolve(resData?.data ?? [])
        }
        catch (err) {
          console.log('err ', err)
          resolve([])
        }
      });
    }
  }

  const checkFieldDup = async(fieldName = '', value = '') => {
    if (timeoutInputRef.current) {
      clearTimeout(timeoutInputRef.current);
    }
    timeoutInputRef.current = setTimeout( async () => {
      const payload = { fieldName: fieldName, value: value }
      const rowsDup = await API.fetchCheckFieldDup(payload)

      let editId = qArticleKeywordId ?? 0
      let isDupField = fn_helper.FNFORM.checkDupField(fieldName, value, rowsDup, editId)
      const updateErrDupField = _.cloneDeep(errDupField)
      updateErrDupField[fieldName] = isDupField
      setErrDupField(updateErrDupField)
    }, 500)
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id : qArticleKeywordId }
      API.fetchGetArticleKeywordById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Keyword | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Keyword`}
            breadcrumbItems={[
              { title: "Keyword", link: "article/category" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Keyword Infomation</h5>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Keyword Thai
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="nameTh"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="nameTh"
                            onBlur={validation.handleBlur}
                            onChange={(e) => {
                              validation.setFieldValue("nameTh", e.target.value)
                              checkFieldDup("nameTh", e.target.value)
                            }}
                            value={validation.values.nameTh}
                            invalid={( validation.touched.nameTh && validation.errors.nameTh ? true : false ) || errDupField.nameTh}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.nameTh && validation.errors.nameTh && (
                              <FormFeedback type="invalid">
                                {validation.errors.nameTh}
                              </FormFeedback>
                            )
                          }
                          { 
                            errDupField.nameTh && (
                              <div type="invalid" className="invalid-feedback d-block">Field Category Name TH exist</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Keyword Eng
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="nameEn"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="nameEn"
                            onBlur={validation.handleBlur}
                            onChange={(e) => {
                              validation.setFieldValue("nameEn", e.target.value)
                              checkFieldDup("nameEn", e.target.value)
                            }}
                            value={validation.values.nameEn}
                            invalid={(validation.touched.nameEn && validation.errors.nameEn ? true : false ) || errDupField.nameEn}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.nameEn && validation.errors.nameEn && (
                              <FormFeedback type="invalid">
                                {validation.errors.nameEn}
                              </FormFeedback>
                            )
                          }
                          { 
                            errDupField.nameEn && (
                              <div type="invalid" className="invalid-feedback d-block">Field Category Name EN exist</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                            onChange={(value) => validation.setFieldValue("status", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.status && validation.errors.status) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                            )
                          }
                          { 
                            validation.touched.status && validation.errors.status && (
                              <FormFeedback type="invalid">
                                {validation.errors.status}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/article/keyword')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/article/keyword')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// ArticleKeyword.propTypes = {
//     t: PropTypes.any,
// };
export default withTranslation()(ArticleKeyword)
