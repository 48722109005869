import { get, post, put, patch } from "../Config"

export function SearchJobApplication({ params = {} }) {
  return get({ url: `/v1/admin/jobApplication`, params })
}
export function GetJobApplicationById({ id }) {
  return get({ url: `/v1/admin/jobApplication/${id}` })
}
export function CreateJobApplication({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/jobApplication`, params, data })
}
export function UpdateJobApplication({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/jobApplication/${id}`, params, data })
}
export function PatchJobApplication({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/jobApplication/${id}`, params, data })
}
export function DeleteJobApplication({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/jobApplication/mutiDelete`, params, data })
}
