import React, { useEffect, useState, useContext, useRef  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CheckDuplicateArticleCategory, CreateArticleCategory, GetArticleCategoryById, SearchArticleCategory, UpdateArticleCategory } from "services/api/module/ArticleCategory"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"
import moment from "moment"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const ArticleCategory = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qArticleCategoryId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    nameTh: Yup.string().max(25).required("Please Enter Category Name TH"),
    nameEn: Yup.string().max(25).required("Please Enter Category Name EN"),
    // description: Yup.string().max(100).required("Please Enter Description"),
    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string().max(100).required("Please Enter Meta Description"),
    status: Yup.string().max(100).required("Please Enter Status"),
    icon: Yup.string().max(100).required("Please Enter Logo"),

  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // CheckDUP STATE
  const [errDupField, setErrDupField] = useState({ nameTh: false, nameEn: false })
  const timeoutInputRef = useRef(null);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameTh: "",
      nameEn: "",
      icon: "",
      description: "",
      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      const isPass = Object.values(errDupField).every(item => item === false) // เช็คว่าทุกตัว ไม่มีค่า true
      console.log("🚀 ~ isPass:", isPass)

      if (isPass) {
        console.log("🚀 ~ ArticleCategoryCreate ~ values:", values)
        const payload = {
          id: values.id ? values.id : undefined,
          nameTh: values.nameTh,
          nameEn: values.nameEn,
          description: values.description,
          seoUrlKey: values.seoUrlKey,
          seoMetaTitle: values.seoMetaTitle,
          seoMetaKeyword: values.seoMetaKeyword,
          seoMetaDescription: values.seoMetaDescription,
          status: Number(values.status),
          icon: values.icon,
        };
        console.log("🚀 ~ onSave ~ payload:", payload);

        if (pageView === "update") {
          API.fetchUpdateArticleCategory(payload)
        } else {
          API.fetchCreateArticleCategory(payload)
        }
      }
    }
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("icon", resData.icon)
    validation.setFieldValue("description", resData.description)
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetArticleCategoryById: async (payload) => {
      try {
          const response = await GetArticleCategoryById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateArticleCategory: async (payload) => {
      try {
          const response = await CreateArticleCategory({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/article/category')
                },
            })
          } else if (resData.status === 403) {
            const updateErrDupField = _.cloneDeep(errDupField)
            updateErrDupField[resData.data] = true
            setErrDupField(updateErrDupField)
            
            mAlert.info({
              title: "Alert",
              subTitle: "Data Existing",
              content: "",
              onClose: () => {
                
              },
            })
          } else {
            throw resData
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateArticleCategory: async (payload) => {
      try {
          const response = await UpdateArticleCategory({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/article/category')
                  },
              })
          } else if (resData.status === 403) {
            const updateErrDupField = _.cloneDeep(errDupField)
            updateErrDupField[resData.data] = true
            setErrDupField(updateErrDupField)

            mAlert.info({
              title: "Alert",
              subTitle: "Data Existing",
              content: "",
              onClose: () => {
                
              },
            })
          } else {
            throw resData
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchCheckFieldDup: async (payload) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await CheckDuplicateArticleCategory({ data: payload })
          const resData = response.data
          resolve(resData?.data ?? [])
        }
        catch (err) {
          console.log('err ', err)
          resolve([])
        }
      });
    }
  }

  const checkFieldDup = async(fieldName = '', value = '') => {
    if (timeoutInputRef.current) {
      clearTimeout(timeoutInputRef.current);
    }
    timeoutInputRef.current = setTimeout( async () => {
      const payload = { fieldName: fieldName, value: value }
      const rowsDup = await API.fetchCheckFieldDup(payload)

      let editId = qArticleCategoryId ?? 0
      let isDupField = fn_helper.FNFORM.checkDupField(fieldName, value, rowsDup, editId)
      const updateErrDupField = _.cloneDeep(errDupField)
      updateErrDupField[fieldName] = isDupField
      setErrDupField(updateErrDupField)
    }, 500)
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id : qArticleCategoryId }
      API.fetchGetArticleCategoryById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Category | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Category`}
            breadcrumbItems={[
              { title: "Category", link: "article/category" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      
                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12">
                                <h5 className="mb-3">Category Infomation</h5>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Category Name TH
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="nameTh"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="nameTh"
                                    maxLength={25}
                                    onBlur={validation.handleBlur}
                                    onChange={(e) => {
                                      validation.setFieldValue("nameTh", e.target.value)
                                      checkFieldDup("nameTh", e.target.value)
                                    }}
                                    value={validation.values.nameTh}
                                    invalid={( validation.touched.nameTh && validation.errors.nameTh ? true : false ) || errDupField.nameTh}
                                    disabled={pageView === "view"}
                                  />
                                  { 
                                    validation.touched.nameTh && validation.errors.nameTh && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.nameTh}
                                      </FormFeedback>
                                    )
                                  }
                                  { 
                                    errDupField.nameTh && (
                                      <div type="invalid" className="invalid-feedback d-block">Field Category Name TH exist</div>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Category Name EN
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="nameEn"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="nameEn"
                                    maxLength={25}
                                    onBlur={validation.handleBlur}
                                    onChange={(e) => {
                                      validation.setFieldValue("nameEn", e.target.value)
                                      checkFieldDup("nameEn", e.target.value)
                                    }}
                                    value={validation.values.nameEn}
                                    invalid={(validation.touched.nameEn && validation.errors.nameEn ? true : false ) || errDupField.nameEn}
                                    disabled={pageView === "view"}
                                  />
                                  { 
                                    validation.touched.nameEn && validation.errors.nameEn && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.nameEn}
                                      </FormFeedback>
                                    )
                                  }
                                  { 
                                    errDupField.nameEn && (
                                      <div type="invalid" className="invalid-feedback d-block">Field Category Name EN exist</div>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Description
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="description"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="description"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.description}
                                    invalid={validation.touched.description && validation.errors.description ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  { 
                                    validation.touched.description && validation.errors.description && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.description}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Status (Active/Inactive)
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Status"
                                    value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                                    onChange={(value) => validation.setFieldValue("status", value.id)}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    options={STATUS_OPTION}
                                    className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                                    isDisabled={pageView === "view"}
                                  />
                                  {
                                    (validation.touched.status && validation.errors.status) && (
                                      <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                                    )
                                  }
                                  { 
                                    validation.touched.status && validation.errors.status && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.status}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          
                          <Col className="col-6">
                            <Col className="col-12">
                              <h5 className="mb-3">SEO Setting</h5>
                            </Col>
                            <Row className="">
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  URL Key
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoUrlKey"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoUrlKey"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoUrlKey}
                                    invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  { 
                                    validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoUrlKey}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta Title
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaTitle"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaTitle"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaTitle}
                                    invalid={validation.touched.seoMetaTitle && validation.errors.seoMetaTitle ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  { 
                                    validation.touched.seoMetaTitle && validation.errors.seoMetaTitle && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaTitle}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta keyword
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaKeyword"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaKeyword"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaKeyword}
                                    invalid={validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  { 
                                    validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaKeyword}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta Description
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaDescription"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaDescription"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaDescription}
                                    invalid={validation.touched.seoMetaDescription && validation.errors.seoMetaDescription ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  { 
                                    validation.touched.seoMetaDescription && validation.errors.seoMetaDescription && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaDescription}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>

                        </Row>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div className="col-md-12">
                          <UploadFileAPI
                            bucketName="article-category"
                            upload="Image"
                            typeUpload="ImageMap"
                            prefixName="brand-image"
                            label="Upload Category Icon"
                            required={true}
                            widthSize={20}
                            heightSize={20}
                            description="description"
                            value={validation.values.icon}
                            onChange={([ imageURL = '', imageSize = '']) => {
                                validation.setFieldValue("icon", imageURL)
                            }}
                          />
                          <Input
                            id="icon"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="icon"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.icon}
                            invalid={validation.touched.icon && validation.errors.icon ? true : false}
                            readOnly={true}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.icon && validation.errors.icon && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.icon}</div>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/article/category')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/article/category')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };
export default withTranslation()(ArticleCategory)
