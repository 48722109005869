// import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"

import {
    Col,
    Row,
    Card,
    CardBody,
    // CardTitle,
    // CardSubtitle,
    // CardImg,
    // CardText,
    CardHeader,
    // CardImgOverlay,
    // CardFooter,
    // CardDeck,
    Container,
    Label,
    Input,
    Form,
    Nav, NavItem, NavLink, TabContent, TabPane,
    Button,
    InputGroup,
    Spinner,
    Table,
} from "reactstrap";

import { ReactSortable, Sortable, Swap } from "react-sortablejs";

import Breadcrumbs from '../../components/Common/Breadcrumb2';
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import moment from "moment";

import { get, post } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { createQuestionaireQuestion, createQuestionaireQuestionReset, apiError } from "../../store/actions";
import { createQuestionaireCategory, createQuestionaireCategoryReset } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import Select from "react-select"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Spinners from "components/Common/Spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NoQuestionModal from "components/Common/NoQuestionModal";

import { isEmpty } from "lodash";
import { inArray } from "helpers/utility_helper";

const AddQuestionComponent = props => {
    const { setQuestionSet, questionSet, questionType, setQuestionType, topicData, keywordData } = props
    
    return(
        <>
            <h5 className="mb-3">Type of Question</h5>
            <Row className="mb-3">
                <div className="col-md-12">
                    <select
                        className="form-control form-control"
                        onChange={(newValue) => {
                            setQuestionType(newValue.target.value)
                        }}
                        defaultValue="multi"
                        name="question_type"
                    >
                        <option value="multi">Multiple Choice</option>
                        <option value="single">Single Answer</option>
                        <option value="openend">Open-end</option>
                        <option value="image">Image Upload</option>
                    </select>
                </div>
            </Row>
            {questionType && questionType == 'multi' &&   <TypeMultipleChoiceCreate myProp={questionType} setQuestionSet={setQuestionSet} questionSet={questionSet} topicData={topicData} keywordData={keywordData} />}
            {questionType && questionType == 'single' &&  <TypeSingleChoiceCreate   myProp={questionType} setQuestionSet={setQuestionSet} questionSet={questionSet} topicData={topicData} keywordData={keywordData} />}
            {questionType && questionType == 'openend' && <TypeOnlyQuestionWithCriteriaCreate   myProp={questionType} setQuestionSet={setQuestionSet} questionSet={questionSet} topicData={topicData} keywordData={keywordData} />}
            {questionType && questionType == 'image' &&   <TypeOnlyQuestionWithCriteriaCreate   myProp={questionType} setQuestionSet={setQuestionSet} questionSet={questionSet} topicData={topicData} keywordData={keywordData} />}
        </>
    )
}

const TypeMultipleChoiceCreate = props => {
    const { setQuestionSet, questionSet, topicData, keywordData } = props

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            question_text: '',
            choice: '',
            choice_multi: [],
            choice_text_1: '',
            choice_text_2: '',
            choice_text_3: '',
            choice_text_4: '',
            choice_text_5: '',
            choice_text_6: '',
            choice_text_7: '',
            ai_addon_questions: '',
            ask_number_question: '',
        },
        validationSchema: Yup.object({
            question_text: Yup.string(),
            choice: Yup.string(),
            choice_multi: Yup.array().min(1).of(Yup.number()),
            choice_text_1: Yup.string(),
            choice_text_2: Yup.string(),
            choice_text_3: Yup.string(),
            choice_text_4: Yup.string(),
            choice_text_5: Yup.string(),
            choice_text_6: Yup.string(),
            choice_text_7: Yup.string(),
            ai_addon_questions: Yup.string(),
            ask_number_question: Yup.string(),
        }),
        onSubmit: (values) => {
            values.question_type = 'multi'
            values.topic = topicData;
            values.keyword = keywordData;
            values.goto = ''

            if(isDisableAskNumberQuestion) {
                values.ai_addon_questions = false
                values.ask_number_question = 0
            }

            console.log(JSON.stringify(values, null, 2))
            if(questionSet.length == 0) {
                setQuestionSet([values])
            }
            else {
                setQuestionSet([...questionSet, values])
            }
        }
    })

    const [ askNumberQuestion, setAskNumberQuestion ] = useState('')
    const [ isDisableAskNumberQuestion, setIsDisableAskNumberQuestion ] = useState(true)
    const handleAddonQuestionCheckbox = (checked) => {
        if(checked) {
            setIsDisableAskNumberQuestion(false)
        }
        else {
            setIsDisableAskNumberQuestion(true)
        }
    }

    return(
        <>
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                {/* Question */}
                <Row className="mb-3">
                    <Col>
                        <Label
                            htmlFor="example-search-input"
                            className="col-form-label"
                            >
                            <h5>Question:</h5>
                        </Label>
                        <div>
                            <Input
                                className="form-control mw-100"
                                type="text"
                                name="question_text"
                                
                                placeholder="eg. What do you do?"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                                value={validation.values.question_text}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice_multi"
                                    type="checkbox"
                                    id="choice_1"
                                    value="1"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_1"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_1}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice_multi"
                                    type="checkbox"
                                    id="choice_2"
                                    value="2"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_2"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_2}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice_multi"
                                    type="checkbox"
                                    id="choice_3"
                                    value="3"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_3"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_3}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice_multi"
                                    type="checkbox"
                                    id="choice_4"
                                    value="4"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_4"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_4}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice_multi"
                                    type="checkbox"
                                    id="choice_5"
                                    value="5"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_5"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_5}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice_multi"
                                    type="checkbox"
                                    id="choice_6"
                                    value="6"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_6"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_6}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice_multi"
                                    type="checkbox"
                                    id="choice_7"
                                    value="7"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_7"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_7}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                {/* Question */}
                {/* AI add-on Questions */}
                <Row className="mb-3">
                    <Col className="col-md-12">
                        <div className="form-check form-switch mb-3" >
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id="ai_addon_questions"
                                defaultChecked={validation.values.ai_addon_questions == true}
                                name="ai_addon_questions"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                onClick={(el) => {
                                    handleAddonQuestionCheckbox(el.target.checked)
                                }}
                                value="true"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="ai_addon_questions"
                            >
                                <h5>AI add-on Questions</h5>
                            </label>
                        </div>
                    </Col>
                    <Col className="col-mb-12">
                        <Card outline color="secondary" className="border bg-secondary-subtle">
                            {/* <CardHeader className="bg-transparent">
                            
                            </CardHeader> */}
                            <CardBody>
                                <Row className="row-cols-md-auto g-3 align-items-center">
                                    <Col md={9}>
                                        <div className="align-middle">
                                            <Label className="my-0 text-secondary">
                                                <i className="mdi mdi-bullseye-arrow me-3" />How Many Questions would you like the AI to suggest for the survey?
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="align-middle">
                                        <Select
                                            id="ask_number_question"
                                            name="ask_number_question"
                                            className="select2-selection is-invalid"
                                            options={[
                                                {label: 0, value: 0},
                                                {label: 1, value: 1},
                                                {label: 2, value: 2},
                                                {label: 3, value: 3},
                                                {label: 4, value: 4},
                                                {label: 5, value: 5},
                                            ]}
                                            onChange={(selectedOption) => {
                                                setAskNumberQuestion(selectedOption)
                                                validation.setFieldValue("ask_number_question", selectedOption.value)
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={askNumberQuestion}
                                            isDisabled={isDisableAskNumberQuestion}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* AI add-on Questions */}
                <Row className="mb-3">
                    <Col style={{display: "flex", justifyContent: "right"}}>
                        <Button type="submit" color="primary" className="btn btn-primary">Save Question</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const TypeMultipleChoiceDisplay = props => {
    const {
        question_text,
        choice_multi, 
        choice_text_1,
        choice_text_2,
        choice_text_3,
        choice_text_4,
        choice_text_5,
        choice_text_6,
        choice_text_7,
        ai_addon_questions,
        ask_number_question,
        goto,
    } = props.elementData;

    let new_choice_multi = [];
    if(!isEmpty(choice_multi)) {
        if(typeof choice_multi == 'string') {
            new_choice_multi = JSON.parse(choice_multi)
        }
        else if(typeof choice_multi == 'object') {
            new_choice_multi = choice_multi
        }
    }

    const newIdx = parseInt(props.idx) + 1;

    const { questionSet, setQuestionSet } = props;

    const handleCloseClick = idx => {
        const newQuestionSet = questionSet.filter((item, index) => index !== idx);
        setQuestionSet([...newQuestionSet])
    }

    const handleInputText = (idx, fieldName, inputText) => {
        let _newQuestionSetRow = questionSet

        _newQuestionSetRow[idx][fieldName] = inputText

        // console.log(_newQuestionSetRow)

        setQuestionSet(_newQuestionSetRow)
    }

    const handleInputMultiCheckbox = (idx, fieldName, checked, value) => {
        let _newQuestionSetRow = questionSet

        let _jsonValue
        if(typeof _newQuestionSetRow[idx][fieldName] !== 'object') {
            _jsonValue = JSON.parse(_newQuestionSetRow[idx][fieldName])
        }
        else {
            _jsonValue = _newQuestionSetRow[idx][fieldName]
        }
        
        let newValue = []
        switch (checked) {
            case true:
                newValue = [
                    ..._jsonValue, 
                    value
                ]
                break;
            case false:
                newValue = _jsonValue.filter((item) => item !== value);
                break;
        
            default:
                break;
        }

        newValue = newValue.sort()
        _newQuestionSetRow[idx][fieldName] = JSON.stringify(newValue)

        setQuestionSet(_newQuestionSetRow)
    }

    const handleInputCheckbox = (idx, fieldName, checked) => {
        let _newQuestionSetRow = questionSet

        _newQuestionSetRow[idx][fieldName] = checked

        setQuestionSet(_newQuestionSetRow)
    }

    const [ askNumberQuestion, setAskNumberQuestion ] = useState('')
    const [ isDisableAskNumberQuestion, setIsDisableAskNumberQuestion ] = useState(true)
    const handleAddonQuestionCheckbox = (idx, checked) => {
        if(checked) {
            setIsDisableAskNumberQuestion(false)
        }
        else {
            setIsDisableAskNumberQuestion(true)
            handleInputText(idx, 'ask_number_question', '')
            setAskNumberQuestion('')
        }
    }

    const [ gotoData, setGotoData ] = useState(goto)
    const handleGoto = (idx, value) => {
        const _inputGoto = parseInt(value)
        let _goto = 0;
        if(_inputGoto == (parseInt(newIdx) - 1)) {
            _goto = newIdx
        }
        else if(_inputGoto > questionSet.length) {
            _goto = questionSet.length
        }
        else {
            _goto = _inputGoto
        }

        let _newQuestionSetRow = questionSet

        _newQuestionSetRow[idx]["goto"] = _goto

        console.log('_inputGoto', _inputGoto)
        console.log('newIdx', newIdx)
        console.log('_goto', _goto)

        console.log('_newQuestionSetRow', _newQuestionSetRow)
        
        setGotoData(_goto)
        setQuestionSet(_newQuestionSetRow)
    }

    return(
        <Row key={props.idx} className="sortable">
            <Col>
                <Card className="bg-secondary-subtle">
                    <CardHeader className="bg-transparent fs-6 text">
                        <Row>
                            <Col>
                                <i className='bx bx-grid-vertical align-middle handle' style={{cursor: "grab"}}></i> 
                                <span className="align-middle">Multiple Choice</span>
                            </Col>
                            <Col>
                                <button
                                    key={newIdx}
                                    type="button"
                                    className="btn-close align-middle"
                                    style={{position: 'absolute', right: 0}}
                                    aria-label="Close"
                                    onClick={() => {
                                        handleCloseClick(props.idx)
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {/* Question */}
                        <Row className="mb-3">
                            <Col>
                                <Label
                                    htmlFor="example-search-input"
                                    className="col-form-label"
                                    >
                                    Question {newIdx}
                                </Label>
                                <div>
                                    <Input
                                        
                                        className="form-control mw-100"
                                        type="text"
                                        name="question_text"
                                        
                                        placeholder="eg. What do you do?"
                                        value={question_text}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'question_text', el.target.value)
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup >
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name="choice_multi"
                                            type="checkbox"
                                            id={"choice_1_" + newIdx}
                                            value="1"
                                            defaultChecked={inArray(1, new_choice_multi)}
                                            onClick={(el) => {
                                                handleInputMultiCheckbox(props.idx, 'choice_multi', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input 
                                        className="form-control"
                                        type="text"
                                        name="choice_text_1"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_1}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_1', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup >
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name="choice_multi"
                                            type="checkbox"
                                            id={"choice_2_" + newIdx}
                                            value="2"
                                            defaultChecked={inArray(2, new_choice_multi)}
                                            onClick={(el) => {
                                                handleInputMultiCheckbox(props.idx, 'choice_multi', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input 
                                        className="form-control"
                                        type="text"
                                        name="choice_text_2"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_2}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_2', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup >
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name="choice_multi"
                                            type="checkbox"
                                            id={"choice_3_" + newIdx}
                                            value="3"
                                            defaultChecked={inArray(3, new_choice_multi)}
                                            onClick={(el) => {
                                                handleInputMultiCheckbox(props.idx, 'choice_multi', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input 
                                        className="form-control"
                                        type="text"
                                        name="choice_text_3"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_3}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_3', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup>
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name="choice_multi"
                                            type="checkbox"
                                            id={"choice_4_" + newIdx}
                                            value="4"
                                            defaultChecked={inArray(4, new_choice_multi)}
                                            onClick={(el) => {
                                                handleInputMultiCheckbox(props.idx, 'choice_multi', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name="choice_text_4"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_4}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_4', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup>
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name="choice_multi"
                                            type="checkbox"
                                            id={"choice_5_" + newIdx}
                                            value="5"
                                            defaultChecked={inArray(5, new_choice_multi)}
                                            onClick={(el) => {
                                                handleInputMultiCheckbox(props.idx, 'choice_multi', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name="choice_text_5"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_5}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_5', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup>
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name="choice_multi"
                                            type="checkbox"
                                            id={"choice_6_" + newIdx}
                                            value="6"
                                            defaultChecked={inArray(6, new_choice_multi)}
                                            onClick={(el) => {
                                                handleInputMultiCheckbox(props.idx, 'choice_multi', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name="choice_text_6"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_6}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_6', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup>
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name="choice_multi"
                                            type="checkbox"
                                            id={"choice_7_" + newIdx}
                                            value="7"
                                            defaultChecked={inArray(7, new_choice_multi)}
                                            onClick={(el) => {
                                                handleInputMultiCheckbox(props.idx, 'choice_multi', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name="choice_text_7"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_7}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_7', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <Row className="row-cols-md-auto g-3 align-items-center">
                                    <Col>
                                        <div className="align-middle">
                                            <div className="form-check form-switch mb-3" >
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`ai_addon_questions_` + newIdx}
                                                    defaultChecked={ai_addon_questions == true}
                                                    value="true"
                                                    onClick={(el) => {
                                                        handleAddonQuestionCheckbox(props.idx, el.target.checked)
                                                        handleInputCheckbox(props.idx, 'ai_addon_questions', el.target.checked)
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor={`ai_addon_questions_` + newIdx}
                                                >
                                                    AI add-on Questions
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="align-middle">
                                        <div className="">
                                            <select
                                                id={`ask_number_question_` + newIdx}
                                                name={`ask_number_question_` + newIdx}
                                                className="form-control mb-3"
                                                placeholder=""
                                                onChange={(el) => {
                                                    // console.log(el)
                                                    setAskNumberQuestion(el.target.value)
                                                    handleInputText(props.idx, 'ask_number_question', el.target.value)
                                                }}
                                                disabled={!ai_addon_questions && isDisableAskNumberQuestion ? true : false}
                                            >
                                                <option value="0" selected={ask_number_question == 0}>0</option>
                                                <option value="1" selected={ask_number_question == 1}>1</option>
                                                <option value="2" selected={ask_number_question == 2}>2</option>
                                                <option value="3" selected={ask_number_question == 3}>3</option>
                                                <option value="4" selected={ask_number_question == 4}>4</option>
                                                <option value="5" selected={ask_number_question == 5}>5</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="row-cols-md-auto g-3 align-items-center d-flex justify-content-end">
                                    <Col>
                                        <span className="align-middle float-right">
                                            <Label htmlFor={`goto_` + newIdx} className="col-form-label">Goto</Label>
                                        </span>
                                    </Col>
                                    <Col md={2}>
                                        <div className="align-middle">
                                            <Input
                                                className="form-control mw-100"
                                                type="text"
                                                name="goto"
                                                
                                                placeholder="What's page?"
                                                value={gotoData}
                                                onChange={(el) => {
                                                    // handleInputText(props.idx, 'goto', el.target.value)
                                                    handleGoto(props.idx, el.target.value)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

const TypeSingleChoiceCreate = props => {
    const { setQuestionSet, questionSet, topicData, keywordData } = props

    const [ askNumberQuestion, setAskNumberQuestion ] = useState('')
    const [ isDisableAskNumberQuestion, setIsDisableAskNumberQuestion ] = useState(true)
    const handleAddonQuestionCheckbox = (checked) => {
        if(checked) {
            setIsDisableAskNumberQuestion(false)
        }
        else {
            setIsDisableAskNumberQuestion(true)
        }
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            question_text: '',
            choice: '',
            choice_text_1: '',
            choice_text_2: '',
            choice_text_3: '',
            choice_text_4: '',
            choice_text_5: '',
            choice_text_6: '',
            choice_text_7: '',
            ai_addon_questions: '',
            ask_number_question: '',
        },
        validationSchema: Yup.object({
            question_text: Yup.string(),
            choice: Yup.string(),
            choice_text_1: Yup.string(),
            choice_text_2: Yup.string(),
            choice_text_3: Yup.string(),
            choice_text_4: Yup.string(),
            choice_text_5: Yup.string(),
            choice_text_6: Yup.string(),
            choice_text_7: Yup.string(),
            ai_addon_questions: Yup.string(),
            ask_number_question: Yup.string(),
        }),
        onSubmit: (values) => {
            values.question_type = 'single'
            values.topic = topicData;
            values.keyword = keywordData;
            values.goto = ''

            if(isDisableAskNumberQuestion) {
                values.ai_addon_questions = false
                values.ask_number_question = 0
            }

            console.log(JSON.stringify(values, null, 2))
            if(questionSet.length == 0) {
                setQuestionSet([values])
            }
            else {
                setQuestionSet([...questionSet, values])
            }
        }
    })

    return(
        <>
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row className="mb-3">
                    <Label
                        htmlFor="example-search-input"
                        className="col-form-label"
                        >
                        <h5>Question:</h5>
                    </Label>
                    <div>
                        <Input
                            className="form-control mw-100"
                            type="text"
                            name="question_text"
                            
                            placeholder="eg. What do you do?"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            
                            value={validation.values.question_text}
                        />
                    </div>
                </Row>
                
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice"
                                    type="radio"
                                    id="choice_1"
                                    value="1"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_1"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_1}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice"
                                    type="radio"
                                    id="choice_2"
                                    value="2"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_2"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_2}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                    
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice"
                                    type="radio"
                                    id="choice_3"
                                    value="3"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_3"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_3}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice"
                                    type="radio"
                                    id="choice_4"
                                    value="4"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_4"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_4}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>


                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice"
                                    type="radio"
                                    id="choice_5"
                                    value="5"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_5"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_5}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice"
                                    type="radio"
                                    id="choice_6"
                                    value="6"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_6"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_6}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <Input
                                    className="form-check-input"
                                    name="choice"
                                    type="radio"
                                    id="choice_7"
                                    value="7"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                            </span>
                            <Input
                                className="form-control"
                                type="text"
                                name="choice_text_7"
                                
                                placeholder="eg. Eating"
                                value={validation.values.choice_text_7}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                
                            />
                        </InputGroup>
                    </Col>
                </Row>
                {/* AI add-on Questions */}
                <Row className="mb-3">
                    <Col className="col-md-12">
                        <div className="form-check form-switch mb-3" >
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id="ai_addon_questions"
                                defaultChecked={validation.values.ai_addon_questions == true}
                                name="ai_addon_questions"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                onClick={(el) => {
                                    handleAddonQuestionCheckbox(el.target.checked)
                                }}
                                value="true"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="ai_addon_questions"
                            >
                                <h5>AI add-on Questions</h5>
                            </label>
                        </div>
                    </Col>
                    <Col className="col-mb-12">
                        <Card outline color="secondary" className="border bg-secondary-subtle">
                            {/* <CardHeader className="bg-transparent">
                            <h5 className="my-0 text-secondary">
                                <i className="mdi mdi-bullseye-arrow me-3" />How Many Questions would you like the AI to suggest for the survey?
                            </h5>
                            </CardHeader> */}
                            <CardBody>
                                {/* <Select
                                    id="ask_number_question"
                                    name="ask_number_question"
                                    className="select2-selection is-invalid"
                                    options={[
                                        {label: 0, value: 0},
                                        {label: 1, value: 1},
                                        {label: 2, value: 2},
                                        {label: 3, value: 3},
                                        {label: 4, value: 4},
                                        {label: 5, value: 5},
                                    ]}
                                    onChange={(selectedOption) => {
                                        setAskNumberQuestion(selectedOption)
                                        validation.setFieldValue("ask_number_question", selectedOption.value)
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={askNumberQuestion}
                                    isDisabled={isDisableAskNumberQuestion}
                                /> */}
                                <Row className="row-cols-md-auto g-3 align-items-center">
                                    <Col md={9}>
                                        <div className="align-middle">
                                            <Label className="my-0 text-secondary">
                                                <i className="mdi mdi-bullseye-arrow me-3" />How Many Questions would you like the AI to suggest for the survey?
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="align-middle">
                                        <Select
                                            id="ask_number_question"
                                            name="ask_number_question"
                                            className="select2-selection is-invalid"
                                            options={[
                                                {label: 0, value: 0},
                                                {label: 1, value: 1},
                                                {label: 2, value: 2},
                                                {label: 3, value: 3},
                                                {label: 4, value: 4},
                                                {label: 5, value: 5},
                                            ]}
                                            onChange={(selectedOption) => {
                                                setAskNumberQuestion(selectedOption)
                                                validation.setFieldValue("ask_number_question", selectedOption.value)
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={askNumberQuestion}
                                            isDisabled={isDisableAskNumberQuestion}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* AI add-on Questions */}
                <Row className="mb-3">
                    <Col style={{display: "flex", justifyContent: "right"}}>
                        <Button type="submit" color="primary" className="btn btn-primary">Save Question</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const TypeSingleChoiceDisplay = props => {
    const {
        question_text,
        choice, 
        choice_text_1,
        choice_text_2,
        choice_text_3,
        choice_text_4,
        choice_text_5,
        choice_text_6,
        choice_text_7,
        ai_addon_questions,
        ask_number_question,
        goto,
    } = props.elementData;

    // let new_choice_multi = [];
    // if(!isEmpty(choice_multi)) {
    //     if(typeof choice_multi == 'string') {
    //         new_choice_multi = JSON.parse(choice_multi)
    //     }
    //     else if(typeof choice_multi == 'object') {
    //         new_choice_multi = choice_multi
    //     }
    // }

    const newIdx = parseInt(props.idx) + 1;

    const { questionSet, setQuestionSet } = props;

    const handleCloseClick = idx => {
        const newQuestionSet = questionSet.filter((item, index) => index !== idx);
        setQuestionSet([...newQuestionSet])
    }

    const handleInputText = (idx, fieldName, inputText) => {
        let _newQuestionSetRow = questionSet

        _newQuestionSetRow[idx][fieldName] = inputText

        // console.log(_newQuestionSetRow)

        setQuestionSet(_newQuestionSetRow)
    }

    const handleInputMultiCheckbox = (idx, fieldName, checked, value) => {
        let _newQuestionSetRow = questionSet

        let _jsonValue
        if(typeof _newQuestionSetRow[idx][fieldName] !== 'object') {
            _jsonValue = JSON.parse(_newQuestionSetRow[idx][fieldName])
        }
        else {
            _jsonValue = _newQuestionSetRow[idx][fieldName]
        }
        
        let newValue = []
        switch (checked) {
            case true:
                newValue = [
                    ..._jsonValue, 
                    value
                ]
                break;
            case false:
                newValue = _jsonValue.filter((item) => item !== value);
                break;
        
            default:
                break;
        }

        newValue = newValue.sort()
        _newQuestionSetRow[idx][fieldName] = JSON.stringify(newValue)

        setQuestionSet(_newQuestionSetRow)
    }

    const handleInputCheckbox = (idx, fieldName, checked) => {
        let _newQuestionSetRow = questionSet

        _newQuestionSetRow[idx][fieldName] = checked

        setQuestionSet(_newQuestionSetRow)
    }

    const [ askNumberQuestion, setAskNumberQuestion ] = useState('')
    const [ isDisableAskNumberQuestion, setIsDisableAskNumberQuestion ] = useState(true)
    const handleAddonQuestionCheckbox = (idx, checked) => {
        if(checked) {
            setIsDisableAskNumberQuestion(false)
        }
        else {
            setIsDisableAskNumberQuestion(true)
            handleInputText(idx, 'ask_number_question', '')
            setAskNumberQuestion('')
        }
    }

    const [ gotoData, setGotoData ] = useState(goto)
    const handleGoto = (idx, value) => {
        const _inputGoto = parseInt(value)
        let _goto = 0;
        if(_inputGoto == (parseInt(newIdx) - 1)) {
            _goto = newIdx
        }
        else if(_inputGoto > questionSet.length) {
            _goto = questionSet.length
        }
        else {
            _goto = _inputGoto
        }

        let _newQuestionSetRow = questionSet

        _newQuestionSetRow[idx]["goto"] = _goto

        console.log('_inputGoto', _inputGoto)
        console.log('newIdx', newIdx)
        console.log('_goto', _goto)

        console.log('_newQuestionSetRow', _newQuestionSetRow)
        
        setGotoData(_goto)
        setQuestionSet(_newQuestionSetRow)
    }

    return(
        <Row key={props.idx} className="sortable">
            <Col>
                <Card className="bg-secondary-subtle">
                    <CardHeader className="bg-transparent fs-6 text">
                        <Row>
                            <Col>
                                <i className='bx bx-grid-vertical align-middle handle' style={{cursor: "grab"}}></i> 
                                <span className="align-middle">Single Answer</span>
                            </Col>
                            <Col>
                                <button
                                    key={newIdx}
                                    type="button"
                                    className="btn-close align-middle"
                                    style={{position: 'absolute', right: 0}}
                                    aria-label="Close"
                                    onClick={() => {
                                        handleCloseClick(props.idx)
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {/* Question */}
                        <Row className="mb-3">
                            <Col>
                                <Label
                                    htmlFor="example-search-input"
                                    className="col-form-label"
                                    >
                                    Question {newIdx}
                                </Label>
                                <div>
                                    <Input
                                        
                                        className="form-control mw-100"
                                        type="text"
                                        name="question_text"
                                        
                                        placeholder="eg. What do you do?"
                                        value={question_text}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'question_text', el.target.value)
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup >
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name={"choice_" + newIdx}
                                            type="radio"
                                            id={"choice_1_" + newIdx}
                                            value="1"
                                            defaultChecked={choice == 1}
                                            onClick={(el) => {
                                                handleInputCheckbox(props.idx, 'choice', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input 
                                        className="form-control"
                                        type="text"
                                        name="choice_text_1"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_1}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_1', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup >
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name={"choice_" + newIdx}
                                            type="radio"
                                            id={"choice_2_" + newIdx}
                                            value="2"
                                            defaultChecked={choice == 2}
                                            onClick={(el) => {
                                                handleInputCheckbox(props.idx, 'choice', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input 
                                        className="form-control"
                                        type="text"
                                        name="choice_text_2"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_2}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_2', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup >
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name={"choice_" + newIdx}
                                            type="radio"
                                            id={"choice_3_" + newIdx}
                                            value="3"
                                            defaultChecked={choice == 3}
                                            onClick={(el) => {
                                                handleInputCheckbox(props.idx, 'choice', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input 
                                        className="form-control"
                                        type="text"
                                        name="choice_text_3"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_3}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_3', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup>
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name={"choice_" + newIdx}
                                            type="radio"
                                            id={"choice_4_" + newIdx}
                                            value="4"
                                            defaultChecked={choice == 4}
                                            onClick={(el) => {
                                                handleInputCheckbox(props.idx, 'choice', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name="choice_text_4"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_4}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_4', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <InputGroup>
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name={"choice_" + newIdx}
                                            type="radio"
                                            id={"choice_5_" + newIdx}
                                            value="5"
                                            defaultChecked={choice == 5}
                                            onClick={(el) => {
                                                handleInputCheckbox(props.idx, 'choice', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name="choice_text_5"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_5}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_5', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup>
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name={"choice_" + newIdx}
                                            type="radio"
                                            id={"choice_6_" + newIdx}
                                            value="6"
                                            defaultChecked={choice == 6}
                                            onClick={(el) => {
                                                handleInputCheckbox(props.idx, 'choice', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name="choice_text_6"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_6}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_6', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup>
                                    <span className="input-group-text">
                                        <Input
                                            className=""
                                            name={"choice_" + newIdx}
                                            type="radio"
                                            id={"choice_7_" + newIdx}
                                            value="7"
                                            defaultChecked={choice == 7}
                                            onClick={(el) => {
                                                handleInputCheckbox(props.idx, 'choice', el.target.checked, el.target.value)
                                            }}
                                        />
                                    </span>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        name="choice_text_7"
                                        
                                        placeholder="eg. Eating"
                                        value={choice_text_7}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'choice_text_7', el.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <Row className="row-cols-md-auto g-3 align-items-center">
                                    <Col>
                                        <div className="align-middle">
                                            <div className="form-check form-switch mb-3" >
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`ai_addon_questions_` + newIdx}
                                                    defaultChecked={ai_addon_questions == true}
                                                    value="true"
                                                    onClick={(el) => {
                                                        handleAddonQuestionCheckbox(props.idx, el.target.checked)
                                                        handleInputCheckbox(props.idx, 'ai_addon_questions', el.target.checked)
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor={`ai_addon_questions_` + newIdx}
                                                >
                                                    AI add-on Questions
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="align-middle">
                                        <div className="">
                                            <select
                                                id={`ask_number_question_` + newIdx}
                                                name={`ask_number_question_` + newIdx}
                                                className="form-control mb-3"
                                                placeholder=""
                                                onChange={(el) => {
                                                    // console.log(el)
                                                    setAskNumberQuestion(el.target.value)
                                                    handleInputText(props.idx, 'ask_number_question', el.target.value)
                                                }}
                                                disabled={!ai_addon_questions && isDisableAskNumberQuestion ? true : false}
                                            >
                                                <option value="0" selected={ask_number_question == 0}>0</option>
                                                <option value="1" selected={ask_number_question == 1}>1</option>
                                                <option value="2" selected={ask_number_question == 2}>2</option>
                                                <option value="3" selected={ask_number_question == 3}>3</option>
                                                <option value="4" selected={ask_number_question == 4}>4</option>
                                                <option value="5" selected={ask_number_question == 5}>5</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="row-cols-md-auto g-3 align-items-center d-flex justify-content-end">
                                    <Col>
                                        <span className="align-middle float-right">
                                            <Label htmlFor={`goto_` + newIdx} className="col-form-label">Goto</Label>
                                        </span>
                                    </Col>
                                    <Col md={2}>
                                        <div className="align-middle">
                                            <Input
                                                className="form-control mw-100"
                                                type="text"
                                                name="goto"
                                                
                                                placeholder="What's page?"
                                                value={gotoData}
                                                onChange={(el) => {
                                                    // handleInputText(props.idx, 'goto', el.target.value)
                                                    handleGoto(props.idx, el.target.value)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

const TypeOnlyQuestionWithCriteriaCreate = props => {
    const { myProp, setQuestionSet, questionSet, topicData, keywordData } = props

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            question_text: '',
            ai_addon_questions: '',
            ask_number_question: '',
            evaluation_criteria: '',
            relevance_score: 0,
            detail_score: 0,
            average_score: 0,
        },
        validationSchema: Yup.object({
            question_text: Yup.string(),
            ai_addon_questions: Yup.string(),
            ask_number_question: Yup.number(),
            evaluation_criteria: Yup.string(),
            relevance_score: Yup.number(),
            detail_score: Yup.number(),
            average_score: Yup.number(),
        }),
        onSubmit: (values) => {
            console.log('values', values)
            values.question_type = myProp
            values.topic = topicData;
            values.keyword = keywordData;
            values.goto = '';
            
            if(isDisableAskNumberQuestion) {
                values.ai_addon_questions = false
                values.ask_number_question = 0
            }

            if(isDisableCriteria) {
                values.evaluation_criteria = false;
                values.relevance_score = 0
                values.detail_score = 0
                values.average_score = 0
            }
            else {
                values.evaluation_criteria = true;
            }

            if(questionSet.length == 0) {
                setQuestionSet([values])
            }
            else {
                setQuestionSet([...questionSet, values])
            }
        }
    })

    const [ askNumberQuestion, setAskNumberQuestion ] = useState('')
    const [ isDisableAskNumberQuestion, setIsDisableAskNumberQuestion ] = useState(true)
    const handleAddonQuestionCheckbox = (checked) => {
        if(checked) {
            setIsDisableAskNumberQuestion(false)
        }
        else {
            setIsDisableAskNumberQuestion(true)
        }
    }

    const [ isDisableCriteria, setIsDisableCriteria ] = useState(true)
    const handleCriteriaQuestionCheckbox = (checked) => {
        if(checked) {
            setIsDisableCriteria(false)
        }
        else {
            setIsDisableCriteria(true)
        }
    }

    const [ relevanceScore, setRelevanceScore ] = useState(0)
    const [ detailScore, setDetailScore ] = useState(0)
    const [ averageScore, setAverageScore ] = useState(0)

    const handleRelevanceScore = (value) => {
        if(parseInt(value) < 0) {
            setRelevanceScore(0)
            validation.setFieldValue("relevance_score", 0)

            validation.setFieldValue("detail_score", 100)
            setDetailScore(100)
        }
        else if(parseInt(value) > 100) {
            setRelevanceScore(100)
            validation.setFieldValue("relevance_score", 100)

            validation.setFieldValue("detail_score", 0)
            setDetailScore(0)
        }
        else if(!!value) {
            setRelevanceScore(parseInt(value))
            validation.setFieldValue("relevance_score", parseInt(value))

            const newDetailScore = 100 - parseInt(value);
            validation.setFieldValue("detail_score", parseInt(newDetailScore))
            setDetailScore(newDetailScore)
        }
    }

    const handleDetailScore = (value) => {
        if(parseInt(value) < 0) {
            setDetailScore(0)
            validation.setFieldValue("detail_score", 0)
            
            validation.setFieldValue("relevance_score", 100)
            setRelevanceScore(0)
        }
        else if(parseInt(value) > 100) {
            validation.setFieldValue("detail_score", 100)
            setDetailScore(100)

            validation.setFieldValue("relevance_score", 0)
            setRelevanceScore(0)
        }
        else {
            validation.setFieldValue("detail_score", parseInt(value))
            setDetailScore(parseInt(value))

            const newRelevanceScore = 100 - parseInt(value);
            validation.setFieldValue("relevance_score", parseInt(newRelevanceScore))
            setRelevanceScore(newRelevanceScore)
        }
    }

    const handleAverageScore = (value) => {
        if(parseInt(value) < 0) {
            setAverageScore(0)
            validation.setFieldValue("average_score", 0)
        }
        else if(parseInt(value) > 10) {
            validation.setFieldValue("average_score", 10)
            setAverageScore(10)
        }
        else {
            setAverageScore(parseInt(value))
            validation.setFieldValue("average_score", parseInt(value))
        }
    }
    
    return(
        <>
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row className="mb-3">
                    <Label
                        htmlFor="example-search-input"
                        className="col-form-label"
                        >
                        <h5>Question:</h5>
                    </Label>
                    <div>
                        <Input
                            className="form-control mw-100"
                            type="text"
                            name="question_text"
                            
                            placeholder="eg. What do you do?"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            
                            value={validation.values.question_text}
                        />
                    </div>
                </Row>

                <Row className="mb-3">
                    <Col className="col-md-12">
                        <div className="form-check form-switch mb-3" >
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id="ai_addon_questions"
                                defaultChecked={validation.values.ai_addon_questions == true}
                                name="ai_addon_questions"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                onClick={(el) => {
                                    handleAddonQuestionCheckbox(el.target.checked)
                                }}
                                value="true"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="ai_addon_questions"
                            >
                                <h5>AI add-on Questions</h5>
                            </label>
                        </div>
                    </Col>
                    <Col className="col-mb-12">
                        <Card outline color="secondary" className="border bg-secondary-subtle">
                            {/* <CardHeader className="bg-transparent">
                            <h5 className="my-0 text-secondary">
                                <i className="mdi mdi-bullseye-arrow me-3" />How Many Questions would you like the AI to suggest for the survey?
                            </h5>
                            </CardHeader> */}
                            <CardBody>
                                {/* <Select
                                    id="ask_number_question"
                                    name="ask_number_question"
                                    className="select2-selection is-invalid"
                                    options={[
                                        {label: 0, value: 0},
                                        {label: 1, value: 1},
                                        {label: 2, value: 2},
                                        {label: 3, value: 3},
                                        {label: 4, value: 4},
                                        {label: 5, value: 5},
                                    ]}
                                    onChange={(selectedOption) => {
                                        setAskNumberQuestion(selectedOption)
                                        validation.setFieldValue("ask_number_question", selectedOption.value)
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={askNumberQuestion}
                                    isDisabled={isDisableAskNumberQuestion}
                                /> */}
                                <Row className="row-cols-md-auto g-3 align-items-center">
                                    <Col md={9}>
                                        <div className="align-middle">
                                            <Label className="my-0 text-secondary">
                                                <i className="mdi mdi-bullseye-arrow me-3" />How Many Questions would you like the AI to suggest for the survey?
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col className="align-middle">
                                        <Select
                                            id="ask_number_question"
                                            name="ask_number_question"
                                            className="select2-selection is-invalid"
                                            options={[
                                                {label: 0, value: 0},
                                                {label: 1, value: 1},
                                                {label: 2, value: 2},
                                                {label: 3, value: 3},
                                                {label: 4, value: 4},
                                                {label: 5, value: 5},
                                            ]}
                                            onChange={(selectedOption) => {
                                                setAskNumberQuestion(selectedOption)
                                                validation.setFieldValue("ask_number_question", selectedOption.value)
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={askNumberQuestion}
                                            isDisabled={isDisableAskNumberQuestion}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col className="col-md-12">
                        <div className="form-check form-switch" >
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id="evaluation_criteria"
                                defaultChecked={validation.values.evaluation_criteria == true}
                                name="evaluation_criteria"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                onClick={(el) => {
                                    handleCriteriaQuestionCheckbox(el.target.checked)
                                }}
                                value="true"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="evaluation_criteria"
                            >
                                <h5>User’s Answer Evaluation Criteria</h5>
                        </label>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="col-md-1"></Col>
                    <Col className="col-md-11">
                        <Row>
                            <label
                                htmlFor="relevance_score"
                                className="col-md-5 col-form-label"
                                >
                                Relevance Score (1-10): <em className="fw-light">Scores how relevant the respondent's answer is to the question</em>
                            </label>
                            <div className="col-md-3">
                                <InputGroup>
                                    <Input
                                        className="form-control"
                                        type="number"
                                        name="relevance_score"
                                        // onChange={validation.handleChange}
                                        onChange={(value) => {
                                            // setRelevanceScore(value.target.value)
                                            handleRelevanceScore(value.target.value)
                                            // validation.setFieldValue(value.target.name, value.target.value)
                                            // validation.handleChange
                                        }}
                                        onBlur={validation.handleBlur}
                                        // value={validation.values.relevance_score}
                                        value={relevanceScore}
                                        // defaultValue={relevanceScore}
                                        disabled={isDisableCriteria}
                                        min={0}
                                        max={100}
                                    />
                                    <span className="input-group-append">
                                        <span className="input-group-text">%</span>
                                    </span>
                                </InputGroup>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="col-md-1"></Col>
                    <Col className="col-md-11">
                        <Row>
                            <label
                                htmlFor="detail_score"
                                className="col-md-5 col-form-label"
                                >
                                Detail Score (1-10): <em className="fw-light">Scores the level of detail in the respondent's answer</em>
                            </label>
                            <div className="col-md-3">
                                <InputGroup>
                                    <Input
                                        className="form-control"
                                        type="number"
                                        name="detail_score"
                                        // onChange={validation.handleChange}
                                        onChange={(value) => {
                                            // setDetailScore(value.target.value)
                                            handleDetailScore(value.target.value)
                                            // validation.setFieldValue(value.target.name, value.target.value)
                                            // validation.handleChange
                                        }}
                                        onBlur={validation.handleBlur}
                                        // value={validation.values.detail_score}
                                        value={detailScore}
                                        // defaultValue={detailScore}
                                        disabled={isDisableCriteria}
                                        min={0}
                                        max={100}
                                    />
                                    <span className="input-group-append">
                                        <span className="input-group-text">%</span>
                                    </span>
                                </InputGroup>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="col-md-1"></Col>
                    <Col className="col-md-11">
                        <Row>
                            <label
                                htmlFor="average_score"
                                className="col-md-5 col-form-label"
                                >
                                Average Score (1-10): <em className="fw-light">The minimum score needed to pass a question.</em>
                            </label>
                            <div className="col-md-3">
                                <Input
                                    className="form-control"
                                    type="number"
                                    // placeholder="Input number of question"
                                    name="average_score"
                                    // onChange={validation.handleChange}
                                    onChange={(value) => {
                                        // setDetailScore(value.target.value)
                                        handleAverageScore(value.target.value)
                                        // validation.setFieldValue(value.target.name, value.target.value)
                                        // validation.handleChange
                                    }}
                                    onBlur={validation.handleBlur}
                                    // defaultValue={averageScore}
                                    value={averageScore}
                                    disabled={isDisableCriteria}
                                    min={1}
                                    max={10}
                                />
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col style={{display: "flex", justifyContent: "right"}}>
                        <Button color="primary" className="btn btn-primary">Save Question</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const TypeOnlyQuestionWithCriteriaDisplay = props => {
    const {
        question_type, 
        question_text,
        ai_addon_questions,
        ask_number_question,
        evaluation_criteria,
        relevance_score,
        detail_score,
        average_score,
        goto,
    } = props.elementData;

    const newIdx = parseInt(props.idx) + 1;

    const { questionSet, setQuestionSet } = props;

    const handleCloseClick = idx => {
        const newQuestionSet = questionSet.filter((item, index) => index !== idx);
        setQuestionSet([...newQuestionSet])
    }

    let _question_type = '';
    switch (question_type) {
        case "single":
            _question_type = 'Single Answer'
            break;
        case "openend":
            _question_type = 'Open End'
            break;
        case "image":
            _question_type = 'Image Upload'
            break;
    }

    const handleInputText = (idx, fieldName, inputText) => {
        let _newQuestionSetRow = questionSet

        _newQuestionSetRow[idx][fieldName] = inputText

        // console.log(_newQuestionSetRow)

        setQuestionSet(_newQuestionSetRow)
    }

    const handleInputCheckbox = (idx, fieldName, checked) => {
        let _newQuestionSetRow = questionSet

        _newQuestionSetRow[idx][fieldName] = checked

        setQuestionSet(_newQuestionSetRow)
    }

    const [ askNumberQuestion, setAskNumberQuestion ] = useState('')
    const [ isDisableAskNumberQuestion, setIsDisableAskNumberQuestion ] = useState(true)
    const handleAddonQuestionCheckbox = (idx, checked) => {
        if(checked) {
            setIsDisableAskNumberQuestion(false)
        }
        else {
            setIsDisableAskNumberQuestion(true)
            handleInputText(idx, 'ask_number_question', '')
            setAskNumberQuestion('')
        }
    }

    const [ isDisableCriteria, setIsDisableCriteria ] = useState(true)
    const handleCriteriaQuestionCheckbox = (idx, checked) => {
        if(checked) {
            setIsDisableCriteria(false)
        }
        else {
            setIsDisableCriteria(true)
            handleInputText(idx, 'evaluation_criteria', '')
            handleInputText(idx, 'relevance_score', '')
            handleInputText(idx, 'detail_score', '')
            handleInputText(idx, 'average_score', '')
        }
    }

    const [ relevanceScore, setRelevanceScore ] = useState(relevance_score)
    const [ detailScore, setDetailScore ] = useState(detail_score)
    const [ averageScore, setAverageScore ] = useState(average_score)

    const handleRelevanceScore = (idx, value) => {
        let _newQuestionSetRow = questionSet

        if(_newQuestionSetRow[idx]['relevance_score'] < 0) {
            _newQuestionSetRow[idx]['relevance_score'] = 0
            _newQuestionSetRow[idx]['detail_score'] = 100
            setRelevanceScore(0)
            setDetailScore(100)
        }
        else if(_newQuestionSetRow[idx]['relevance_score'] > 100) {
            _newQuestionSetRow[idx]['relevance_score'] = 100
            _newQuestionSetRow[idx]['detail_score'] = 0
            setRelevanceScore(100)
            setDetailScore(0)
        }
        else {
            const currentValue = !!value ? value : 0
            const newScore = 100 - parseInt(currentValue);
            _newQuestionSetRow[idx]['relevance_score'] = parseInt(value)
            _newQuestionSetRow[idx]['detail_score'] = newScore
            setRelevanceScore(parseInt(value))
            setDetailScore(newScore)
        }
    }

    const handleDetailScore = (idx, value) => {
        let _newQuestionSetRow = questionSet

        if(_newQuestionSetRow[idx]['detail_score'] < 0) {
            _newQuestionSetRow[idx]['detail_score'] = 0
            _newQuestionSetRow[idx]['relevance_score'] = 100
            setDetailScore(100)
            setRelevanceScore(0)
        }
        else if(_newQuestionSetRow[idx]['detail_score'] > 100) {
            _newQuestionSetRow[idx]['detail_score'] = 100
            _newQuestionSetRow[idx]['relevance_score'] = 0
            setDetailScore(0)
            setRelevanceScore(100)
        }
        else {
            const currentValue = !!value ? value : 0
            const newScore = 100 - parseInt(currentValue);
            
            _newQuestionSetRow[idx]['detail_score'] = parseInt(value)
            _newQuestionSetRow[idx]['relevance_score'] = newScore
            setDetailScore(parseInt(value))
            setRelevanceScore(newScore)
        }
    }

    const handleAverageScore = (idx, value) => {
        let _newQuestionSetRow = questionSet

        if(parseInt(value) < 0) {
            _newQuestionSetRow[idx]['average_score'] = 0
            setAverageScore(0)
        }
        else if(parseInt(value) > 10) {
            _newQuestionSetRow[idx]['average_score'] = 10
            setAverageScore(10)
        }
        else {
            const currentValue = !!value ? value : 0
            _newQuestionSetRow[idx]['average_score'] = parseInt(currentValue)
            setAverageScore(parseInt(currentValue))
        }
    }

    const [ gotoData, setGotoData ] = useState(goto)
    const handleGoto = (idx, value) => {
        const _inputGoto = parseInt(value)
        let _goto = 0;
        if(_inputGoto == (parseInt(newIdx) - 1)) {
            _goto = newIdx
        }
        else if(_inputGoto > questionSet.length) {
            _goto = questionSet.length
        }
        else {
            _goto = _inputGoto
        }

        let _newQuestionSetRow = questionSet

        _newQuestionSetRow[idx]["goto"] = _goto

        console.log('_inputGoto', _inputGoto)
        console.log('newIdx', newIdx)
        console.log('_goto', _goto)

        console.log('_newQuestionSetRow', _newQuestionSetRow)
        
        setGotoData(_goto)
        setQuestionSet(_newQuestionSetRow)
    }

    return(
        <Row key={props.idx} className="sortable">
            <Col>
                <Card className="bg-secondary-subtle">
                    <CardHeader className="bg-transparent fs-6 text">
                        <Row>
                            <Col>
                                <i className='bx bx-grid-vertical align-middle handle' style={{cursor: "grab"}}></i> 
                                <span className="align-middle">{_question_type}</span>
                            </Col>
                            <Col>
                                <button
                                    key={newIdx}
                                    type="button"
                                    className="btn-close align-middle"
                                    style={{position: 'absolute', right: 0}}
                                    aria-label="Close"
                                    onClick={() => {
                                        handleCloseClick(props.idx)
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className="mb-3">
                            <Col>
                                <Label
                                    htmlFor="example-search-input"
                                    className="col-form-label"
                                    >
                                    Question {newIdx}
                                </Label>
                                <div>
                                    <Input
                                        
                                        className="form-control mw-100"
                                        type="text"
                                        name="question_text"
                                        
                                        placeholder="eg. What do you do?"
                                        value={question_text}
                                        onChange={(el) => {
                                            handleInputText(props.idx, 'question_text', el.target.value)
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <Row className="row-cols-md-auto g-3 align-items-center">
                                    <Col>
                                        <div className="align-middle">
                                            <div className="form-check form-switch mb-3" >
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`ai_addon_questions_` + newIdx}
                                                    defaultChecked={ai_addon_questions == true}
                                                    value="true"
                                                    onClick={(el) => {
                                                        handleAddonQuestionCheckbox(props.idx, el.target.checked)
                                                        handleInputCheckbox(props.idx, 'ai_addon_questions', el.target.checked)
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor={`ai_addon_questions_` + newIdx}
                                                >
                                                    AI add-on Questions
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="align-middle">
                                        <div className="">
                                            <select
                                                id={`ask_number_question_` + newIdx}
                                                name={`ask_number_question_` + newIdx}
                                                className="form-control mb-3"
                                                placeholder=""
                                                onChange={(el) => {
                                                    // console.log(el)
                                                    setAskNumberQuestion(el.target.value)
                                                    handleInputText(props.idx, 'ask_number_question', el.target.value)
                                                }}
                                                disabled={!ai_addon_questions && isDisableAskNumberQuestion ? true : false}
                                            >
                                                <option value="0" selected={ask_number_question == 0}>0</option>
                                                <option value="1" selected={ask_number_question == 1}>1</option>
                                                <option value="2" selected={ask_number_question == 2}>2</option>
                                                <option value="3" selected={ask_number_question == 3}>3</option>
                                                <option value="4" selected={ask_number_question == 4}>4</option>
                                                <option value="5" selected={ask_number_question == 5}>5</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="row-cols-md-auto g-3 align-items-center d-flex justify-content-end">
                                    <Col>
                                        <span className="align-middle float-right">
                                            <Label htmlFor={`goto_` + newIdx} className="col-form-label">Goto</Label>
                                        </span>
                                    </Col>
                                    <Col md={2}>
                                        <div className="align-middle">
                                            <Input
                                                className="form-control mw-100"
                                                type="text"
                                                name="goto"
                                                
                                                placeholder="What's page?"
                                                value={gotoData}
                                                onChange={(el) => {
                                                    // handleInputText(props.idx, 'goto', el.target.value)
                                                    handleGoto(props.idx, el.target.value)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Row className="mb-3">
                                    <Col className="col-md-12">
                                        <div className="form-check form-switch" >
                                            <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={"evaluation_criteria_" + newIdx}
                                                defaultChecked={evaluation_criteria == true}
                                                name="evaluation_criteria"
                                                onClick={(el) => {
                                                    handleCriteriaQuestionCheckbox(props.idx, el.target.checked)
                                                    handleInputCheckbox(props.idx, 'evaluation_criteria', el.target.checked)
                                                }}
                                                value="true"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="evaluation_criteria"
                                            >
                                                User’s Answer Evaluation Criteria
                                        </label>
                                        </div>
                                    </Col>
                                </Row>
                                { evaluation_criteria && <>
                                    <Row className="mb-3">
                                        <Col className="col-md-1"></Col>
                                        <Col className="col-md-11">
                                            <Row>
                                                <label
                                                    htmlFor="relevance_score"
                                                    className="col-md-5 col-form-label"
                                                    >
                                                    Relevance Score (1-10): <em className="fw-light">Scores how relevant the respondent's answer is to the question</em>
                                                </label>
                                                <div className="col-md-3">
                                                    <InputGroup>
                                                        <Input
                                                            className="form-control"
                                                            type="number"
                                                            name={"relevance_score" + newIdx}
                                                            onChange={(el) => {
                                                                handleRelevanceScore(props.idx, el.target.value)
                                                                // handleInputText(props.idx, 'relevance_score', el.target.value)
                                                            }}
                                                            value={relevanceScore}
                                                            disabled={!evaluation_criteria && isDisableCriteria ? true : false}
                                                            min={0}
                                                            max={100}
                                                        />
                                                        <span className="input-group-append">
                                                            <span className="input-group-text">%</span>
                                                        </span>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col className="col-md-1"></Col>
                                        <Col className="col-md-11">
                                            <Row>
                                                <label
                                                    htmlFor="detail_score"
                                                    className="col-md-5 col-form-label"
                                                    >
                                                    Detail Score (1-10): <em className="fw-light">Scores the level of detail in the respondent's answer</em>
                                                </label>
                                                <div className="col-md-3">
                                                    <InputGroup>
                                                        <Input
                                                            className="form-control"
                                                            type="number"
                                                            name={"detail_score" + newIdx}
                                                            onChange={(el) => {
                                                                handleDetailScore(props.idx, el.target.value)
                                                                // handleInputText(props.idx, 'detail_score', el.target.value)
                                                            }}
                                                            value={detailScore}
                                                            disabled={!evaluation_criteria && isDisableCriteria ? true : false}
                                                            min={0}
                                                            max={100}
                                                        />
                                                        <span className="input-group-append">
                                                            <span className="input-group-text">%</span>
                                                        </span>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col className="col-md-1"></Col>
                                        <Col className="col-md-11">
                                            <Row>
                                                <label
                                                    htmlFor="average_score"
                                                    className="col-md-5 col-form-label"
                                                    >
                                                    Average Score (1-10): <em className="fw-light">The minimum score needed to pass a question.</em>
                                                </label>
                                                <div className="col-md-3">
                                                    <Input
                                                        className="form-control"
                                                        type="number"
                                                        // placeholder="Input number of question"
                                                        name={"average_score" + newIdx}
                                                        onChange={(el) => {
                                                            handleAverageScore(props.idx, el.target.value)
                                                            // handleInputText(props.idx, 'average_score', el.target.value)
                                                        }}
                                                        value={averageScore}
                                                        disabled={!evaluation_criteria && isDisableCriteria ? true : false}
                                                        min={1}
                                                        max={10}
                                                    />
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

const QuestionaireCreate = props => {

    const location = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categoryId } = useParams();
    const [, rootMenu, subMenu, action] = location.pathname.split('/')

    document.title = "Questionnaire | Create Questionnaire | " + process.env.REACT_APP_CMS_TITLE

    // useEffect(() => {
    //     const fetchCategory = async () => {
    //         const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/category/' + categoryId)

    //         const _category = {
    //             category_id: result.data.category_id !== null ? result.data.category_id : '',
    //             category_name: result.data.category_name !== null ? result.data.category_name : '',
    //             purpose: result.data.purpose !== null ? result.data.purpose : '',
    //             age_range_start: result.data.age_range_start !== null ? result.data.age_range_start : '',
    //             age_range_end: result.data.age_range_end !== null ? result.data.age_range_end : '',
    //             gender: result.data.gender !== null ? result.data.gender : '' ,
    //             nationality: result.data.nationality !== null ? result.data.nationality : '',
    //             martial_status: result.data.martial_status !== null ? result.data.martial_status : '',
    //             remarks: result.data.remarks !== null ? result.data.remarks : '',
    //             rules: result.data.rules !== null ? result.data.rules : '',
    //         }

    //         setCategoryDetail(_category);
    //     };
    
    //     fetchCategory();
    // }, [])
    // ========== Questionaire Category ========== //
    const [categoryNameData, setCategoryNameData] = useState();
    const [purposeData, setPurposeData] = useState();
    const [categoryDetail, setCategoryDetail] = useState({
        category_id: '',
        category_name: '',
        purpose: '',
        age_range_start: '',
        age_range_end: '',
        gender: '',
        nationality: '',
        martial_status: '',
        remarks: '',
        rules: '',
    });
    const [categoryDetailLoading, setCategoryDetailLoading] = useState(true);

    useEffect(() => {
        setCategoryDetailLoading(true)
        setCategoryDetail(JSON.parse(localStorage.getItem('categoryData')))
    }, [])

    useEffect(() => {
        setCategoryNameData(categoryDetail.category_name)
        setPurposeData(categoryDetail.purpose)
    }, [categoryDetail])

    useEffect(() => {
        !!categoryNameData && !!purposeData && setCategoryDetailLoading(false)
    }, [categoryNameData, purposeData])
    
    const QuestionaireCategoryProperties = createSelector(
        (state) => state.QuestionaireCategoryCreate,
        (questionaireCategory) => ({
            categoryData: questionaireCategory.categoryData,
            createCateError: questionaireCategory.createError,
            createCateSuccess: questionaireCategory.createSuccess,
            cateLoading: questionaireCategory.loading,
        })
    );

    const {
        categoryData, createCateError, createCateSuccess, cateLoading
    } = useSelector(QuestionaireCategoryProperties);
    // ========== Questionaire Category ========== //

    const [topicData, setTopicData] = useState();
    const [keywordData, setKeywordData] = useState();
    const [questionType, setQuestionType] = useState('multi');
    const [questionSet, setQuestionSet] = useState([]);
    
    const renderSortable = data => {
        return data.map((item, i) => {
            switch (item.question_type) {
                case "multi":
                    return (
                        <TypeMultipleChoiceDisplay key={i} elementData={item} idx={i} questionSet={questionSet} setQuestionSet={setQuestionSet} />
                    )
                    break;
            
                case "single":
                    return (
                        <TypeSingleChoiceDisplay key={i} elementData={item} idx={i} questionSet={questionSet} setQuestionSet={setQuestionSet} />
                    )
                    break;

                case "openend":
                case "image":
                    return (
                        <TypeOnlyQuestionWithCriteriaDisplay key={i} elementData={item} idx={i} questionSet={questionSet} setQuestionSet={setQuestionSet} />
                    )
                    break;
            }
        })
    }

    // ========== Questionaire Create ========== //
    const QuestionaireQuestionProperties = createSelector(
        (state) => state.QuestionaireQuestionCreate,
        (questionaireQuestion) => ({
            questionData: questionaireQuestion.questionData,
            createError: questionaireQuestion.createError,
            createSuccess: questionaireQuestion.createSuccess,
            loading: questionaireQuestion.loading,
        })
    );

    const {
        questionData, createError, createSuccess, loading
    } = useSelector(QuestionaireQuestionProperties);
    // ========== Questionaire Create ========== //
    
    useEffect(() => {
        dispatch(apiError(""));
    }, []);

    // useEffect(() => {
    //     createSuccess && setTimeout(() => {
    //         dispatch(createQuestionaireQuestionReset())
    //         navigate(`/questionnaire`)
    //     }, 2000)
    // }, [createSuccess, questionData])

    const [ sendQuestion, setSendQuestion ] = useState(false)
    const [ noQuestionAlertModal, setNoQuestionAlertModal ] = useState(false);
    useEffect(() => {
        console.log('createCateSuccess', createCateSuccess)
        console.log('createCateError', createCateError)
        console.log('categoryData', categoryData)
        
        if(createCateSuccess) {
            let _questionaire = {
                category_id: categoryData.id,
                purpose: purposeData,
                topic: topicData,
                keyword: keywordData,
                question_set: questionSet
            }

            console.log('_questionaire', _questionaire)

            dispatch(createQuestionaireQuestion(_questionaire));
        }
    }, [createCateSuccess, categoryData])

    useEffect(() => {
        console.log('createSuccess', createSuccess)
        console.log('createError', createError)
        console.log('questionData', questionData)
        
        if(createSuccess) {
            dispatch(createQuestionaireCategoryReset());
            dispatch(createQuestionaireQuestionReset());

            localStorage.removeItem('categoryData')

            navigate(`/questionnaire`)

            // setSendQuestion(false)
        }
    }, [createSuccess, questionData])

    const handleSaveQuestionaire = () => {
        const cardElement = document.getElementsByClassName('sortable')
        if(cardElement.length > 0) {
            setSendQuestion(true)
            dispatch(createQuestionaireCategory(categoryDetail));
        }
        else {
            setNoQuestionAlertModal(true);
        }
    }

    const handleToggleAll = (e) => {
        const newQuestionSet = questionSet
        setQuestionSet([]);
        setQuestionSet(newQuestionSet.map((item, i) => {
            if(e.target.checked) {
                item.ai_addon_questions = e.target.checked
            }
            else {
                item.ai_addon_questions = ""
            }

            return item;
        }))

        const cardElement = document.getElementsByClassName('sortable')
        for (var i = 0; i < cardElement.length; i++) {
            cardElement[i].querySelector('[id^=ai_addon_questions]').checked = e.target.checked;

            if(e.target.checked) {
                cardElement[i].querySelector('[id^=ask_number_question]').removeAttribute('disabled')
            }
            else {
                cardElement[i].querySelector('[id^=ask_number_question]').setAttribute('disabled', '')
            }
        }
    }

    // ========== Suggest QUestion ========== //
    const [ previousQuestion, setPreviousQuestion ] = useState([])
    const [ previousQuestionLoading, setPreviousQuestionLoading ] = useState(false)
    const [ aiQuestion, setAiQuestion ] = useState('')
    const [ aiQuestionLoading, setAiQuestionLoading ] = useState(false)

    const handleCallPreviousQuestion = () => {
        setAiQuestion('')
        setPreviousQuestionLoading(true)
        const fetchPreviousQuestion = async () => {
            try {
                const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/previous/' + categoryNameData + '/' + purposeData + '/' + topicData + '/' + keywordData)
                setPreviousQuestion(result.data);
            } catch (error) {
                setPreviousQuestionLoading(false)
            }
        };
    
        fetchPreviousQuestion();
    }

    const handleCallAiSuggestQuestion = () => {
        // console.log('categoryNameData', categoryNameData)
        // console.log('categoryNameData', !!categoryNameData)
        // console.log('purposeData', purposeData)
        // console.log('purposeData', !!purposeData)
        // console.log('topicData', topicData)
        // console.log('topicData', !!topicData)
        // console.log('keywordData', keywordData)
        // console.log('keywordData', !!keywordData)
        if(!!categoryNameData && !!purposeData && !!topicData && !!keywordData) {
            setPreviousQuestion([])
            setAiQuestionLoading(true)
            const fetchAiSuggestQuestion = async () => {
                try {
                    const result = await post(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/aiSuggestQuestion', {
                        "question_type": questionType,
                        "category_name": categoryNameData,
                        "purpose": purposeData,
                        "topic": topicData,
                        "keyword": keywordData
                    })

                    setAiQuestion(result.data);
                } catch (error) {
                    setAiQuestionLoading(false)
                }
            };
        
            fetchAiSuggestQuestion();
        }
    }

    const renderPreviousQuestion = () => {
        if(previousQuestion.length == 0) {
            return <></>
        }

        return(
            <>
                <Col
                    className="table-responsive"
                >
                    <Table className="table-striped-columns">
                        <thead>
                            <tr>
                                <th style={{whiteSpace: 'nowrap'}}>Question Type</th>
                                <th style={{whiteSpace: 'nowrap'}}>Question Text</th>
                                <th style={{whiteSpace: 'nowrap'}}>Choice 1</th>
                                <th style={{whiteSpace: 'nowrap'}}>Choice 2</th>
                                <th style={{whiteSpace: 'nowrap'}}>Choice 3</th>
                                <th style={{whiteSpace: 'nowrap'}}>Choice 4</th>
                                <th style={{whiteSpace: 'nowrap'}}>Choice 5</th>
                                <th style={{whiteSpace: 'nowrap'}}>Choice 6</th>
                                <th style={{whiteSpace: 'nowrap'}}>Choice 7</th>
                                <th style={{whiteSpace: 'nowrap'}}>Selected Choice</th>
                                <th style={{whiteSpace: 'nowrap'}}>AI add-on Questions</th>
                                <th style={{whiteSpace: 'nowrap'}}>No. of Add-on</th>
                                <th style={{whiteSpace: 'nowrap'}}>Evaluation Criteria</th>
                                <th style={{whiteSpace: 'nowrap'}}>Relevance Score</th>
                                <th style={{whiteSpace: 'nowrap'}}>Detail Score</th>
                                <th style={{whiteSpace: 'nowrap'}}>Average Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {previousQuestion.map((item, i) => {
                                return(
                                    <tr key={i}>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.question_type}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.question_text}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_1}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_2}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_3}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_4}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_5}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_6}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choce_text_7}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.choice}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.ai_addon_questions && 'Checked'}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.ask_number_question}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.evaluation_criteria && 'Checked'}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.relevance_score}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.detail_score}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{item.average_score}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </>
        )
    }

    const renderSuggestQuestion = () => {
        if(aiQuestion.length == 0) {
            return <></>
        }

        const _data_json = JSON.parse(aiQuestion)
        // console.log('_data_json',_data_json)

        if(aiQuestionLoading) {
            return <Spinners setLoading={setAiQuestionLoading} />
        }
        
        return(
            <Col style={{whiteSpace: "pre-wrap"}}>
                <div className="mb-3">
                    <span className="fw-bold fs-6 text">Question: </span>
                    <span className="fs-6 text">{_data_json.question_text}</span>
                </div>
                <div className="mb-3">
                {
                    _data_json.question_choice && _data_json.question_choice.map((item, i) => {
                        return(
                            <div key={i} className="mb-1">
                                <span className="fw-bold fs-6 text">{(i + 1)}: </span>
                                <span className="fs-6 text">{item}</span>
                            </div>
                        )
                    })
                }
                </div>
                <div className="mb-3">
                {_data_json.question_choice_answer && <>
                    <div>
                        <span className="fw-bold fs-6 text">Answer is: </span>
                        <span className="fs-6 text">{_data_json.question_choice_answer}</span>
                    </div>
                </>}
                </div>
            </Col>
        )
    }

    useEffect(() => {
        setPreviousQuestionLoading(false)
    }, [previousQuestion]);

    useEffect(() => {
        setAiQuestionLoading(false)
    }, [aiQuestion]);
    // ========== Suggest QUestion ========== //

    useEffect(() => {
        const cardElement = document.getElementsByClassName('sortable')
        for (var i = 0; i < cardElement.length; i++) {
            // console.log('questionSet[i].evaluation_criteria', questionSet[i].ai_addon_questions)
            cardElement[i].querySelector('[id^=ai_addon_questions]').checked = questionSet[i].ai_addon_questions ? true : false;
            // cardElement[i].querySelector('select option[value="' + (i + 1) + '"]').selected = questionSet[i].ask_number_question == (i + 1);
            
            // console.log('evaluation_criteria', cardElement[i].querySelector('[id^=evaluation_criteria]'))
            if(cardElement[i].querySelector('[id^=evaluation_criteria]') !== null) {
                cardElement[i].querySelector('[id^=evaluation_criteria]').checked = questionSet[i].evaluation_criteria ? true : false;
            }

            if(cardElement[i].querySelector('[id^=relevance_score]') !== null) {
                cardElement[i].querySelector('[id^=relevance_score]').checked = questionSet[i].evaluation_criteria ? false : true;
            }

            if(cardElement[i].querySelector('[id^=detail_score]') !== null) {
                cardElement[i].querySelector('[id^=detail_score]').checked = questionSet[i].evaluation_criteria ? false : true;
            }

            if(cardElement[i].querySelector('[id^=average_score]') !== null) {
                cardElement[i].querySelector('[id^=average_score]').checked = questionSet[i].evaluation_criteria ? false : true;
            }
        }
    }, [questionSet]);

    if (categoryDetailLoading) {
        return <Spinners setLoading={setCategoryDetailLoading} />
    }

    return (
        <div className="page-content">
            <NoQuestionModal
                show={noQuestionAlertModal}
                onCloseClick={() => setNoQuestionAlertModal(false)}
            />
            <Container fluid={false}>
                <Breadcrumbs 
                    title={props.t("Questionnaire")} 
                    breadcrumbItems={[
                        {title: "Questionnaire", link: "questionnaire"}, 
                        {title: "Create Questionnaire", link: "create"},
                    ]}
                />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <h5 className="mb-3">Create Questionnaire</h5>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-search-input"
                                                className="col-md-2 col-form-label"
                                                >
                                                Category:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    
                                                    defaultValue={categoryDetail && categoryDetail.category_name}
                                                    placeholder="eg: Travel in Japan / Skincare / Car"
                                                    disabled="disabled"
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="purpose"
                                                className="col-md-2 col-form-label"
                                                >
                                                Purpose:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="purpose"
                                                    name="purpose"
                                                    
                                                    defaultValue={categoryDetail && categoryDetail.purpose}
                                                    placeholder="eg: The purpose of this interview is to understand the travel experiences of foreign tourists in Japan, to gain a deep understanding of their travel motivations, cultural adaptation and overall satisfaction in Japan"
                                                    // onChange={(value) => {
                                                    //     setPurposeData(value.target.value)
                                                    // }}
                                                    disabled="disabled"
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="topic"
                                                className="col-md-2 col-form-label"
                                                >
                                                Topic:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    
                                                    defaultValue=""
                                                    placeholder="eg: Demographic / Before Trip / During Trip / After Trip"
                                                    id="topic"
                                                    name="topic"
                                                    onChange={(value) => {
                                                        setTopicData(value.target.value)
                                                    }}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-4">
                                            <label
                                                htmlFor="keyword"
                                                className="col-md-2 col-form-label"
                                                >
                                                Keyword:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    
                                                    defaultValue=""
                                                    placeholder="eg: Career / Attractive / Activity / Culture / Accommodation / Food"
                                                    id="keyword"
                                                    name="keyword"
                                                    onChange={(value) => {
                                                        setKeywordData(value.target.value)
                                                    }}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col col={6} style={{display: "flex", justifyContent: "left"}}>
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="btn btn-primary btn-block"
                                                    onClick={handleCallAiSuggestQuestion}
                                                >
                                                    <i className='bx bxs-bolt'></i> AI Suggest Question List {aiQuestionLoading && <Spinner size="sm" className="ms-2" color="light" />}
                                                </Button>
                                            </Col>
                                            <Col col={6} style={{display: "flex", justifyContent: "right"}}>
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="btn btn-primary btn-block"
                                                    onClick={handleCallPreviousQuestion}
                                                >
                                                    Previous Question List {previousQuestionLoading && <Spinner size="sm" className="ms-2" color="light" />}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            {renderPreviousQuestion()}
                                        </Row>
                                        <Row className="mb-3">
                                            {renderSuggestQuestion()}
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <AddQuestionComponent
                                            setQuestionSet={setQuestionSet}
                                            questionSet={questionSet}
                                            questionType={questionType}
                                            setQuestionType={setQuestionType}
                                            topicData={topicData}
                                            keywordData={keywordData}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <h5 className="mb-3">Your Question Set</h5>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-check form-switch mb-3" style={{display: "flex", justifyContent: "right"}}>
                                            <Row>
                                                <Col>
                                                    <input 
                                                        type="checkbox" 
                                                        className="form-check-input" 
                                                        id="toggleAllAddOns"
                                                        onChange={handleToggleAll}
                                                    />
                                                    <label className="form-check-label" htmlFor="toggleAllAddOns">Make All Question to Add Ons</label>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <div id="questionListZone">
                                    <ReactSortable 
                                        list={questionSet} 
                                        setList={setQuestionSet} 
                                        swap={true} 
                                        animation={150} 
                                        handle=".handle"
                                    >
                                        {renderSortable(questionSet)}
                                    </ReactSortable>
                                    <Row className="mb-3">
                                        <Col>
                                            <div className="form-check mb-3" style={{display: "flex", justifyContent: "center"}}>
                                                {/* <button className="btn btn-outline-primary">Preview Questionaire</button>&nbsp; */}
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleSaveQuestionaire}
                                                >
                                                    Save Questionnaire {sendQuestion && <Spinner size="sm" className="ms-2" color="light" />}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

// QuestionaireCreate.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(QuestionaireCreate)
