import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"
import { apiError } from "store/actions"

import * as backend from "helpers/backend"

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(backend.createUser, user);
    yield put(registerUserSuccessful(response))
  } catch (error) {
    if(typeof error.response.data !== "undefined") {
      yield put(registerUserFailed(error.data.message))
      yield put(apiError(error.response.data.message));
    }
    else {
      yield put(registerUserFailed(error.response))
      yield put(apiError(error.response));
    }
  }


  // console.log("using the following url for registration: ")
  // try {
  //   console.log("Trying to register user (within try block)")
  //   if (process.env.DEFAULTAUTH === "firebase") {
  //     const response = yield call(
  //       fireBaseBackend.registerUser,
  //       user.email,
  //       user.password
  //     )
  //     yield put(registerUserSuccessful(response))
  //   } else if (process.env.DEFAULTAUTH === "jwt") {
  //     const response = yield call(postJwtRegister, "/post-jwt-register", user)
  //     yield put(registerUserSuccessful(response))
  //   } else if (process.env.DEFAULTAUTH === "fake") {
  //     const response = yield call(postFakeRegister, user)
  //     yield put(registerUserSuccessful(response))
  //   }
  // } catch (error) {
  //   console.log("There was an error registering: ", error)
  //   yield put(registerUserFailed(error))
  // }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
