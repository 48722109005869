import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Badge,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import TableContainer from '../../components/Common/TableContainer2';
import { withTranslation } from "react-i18next";

import { get, del } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";
import "../../components/Common/datatables.scss"
import Spinners from "components/Common/Spinner";

const RowStatus = ({ status }) => {
    switch (status) {
        // case "inactive":
        //     return <Badge className="bg-warning"> Inactive </Badge>;
    
        // case "deleted":
        //     return <Badge className="bg-danger"> Deleted </Badge>;
    
        case true:
            return <Badge className="bg-success"> Active </Badge>;
    
        default:
            return <Badge className="bg-success"> {status} </Badge>;
    }
};

const QuestionType = ({ question_type }) => {
    switch (question_type) {
        case "multi":
            return <span>Multiple Choice</span>;
    
        case "single":
            return <span>Single Answer</span>;
    
        case "openend":
            return <span>Open-end</span>;
        
        case "image":
            return <span>Image Upload</span>;
    
        default:
            return <span></span>;
    }
};

const CategoryList = props => {
    const navigate = useNavigate();

    const deleteRow = useCallback(
        (id) => () => {
            if (confirm("Are you sure?") == true) {
                console.log("ID: ", id)

                const delContent = async () => {
                    const result = await del(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/delete/' + id)
                    console.log(result)
                    if(result.status === 201) {
                        setContentListLoading(true)
                        window.location.reload()
                    }
                };
            
                delContent();
            }
        },
        []
    )

    const columns = useMemo(
        () => [
            // {
            //     id: "colcheckbox",
            //     header: () => {
            //         return (
            //             <div style={{ display: "flex", justifyContent: "center" }}>
            //                 <Input type={`checkbox`} />
            //             </div>
            //     )},
            //     enableColumnFilter: false,
            //     enableSorting: false,
            //     cell: (cellProps) => {
            //         return (
            //             <>
            //                 <div id={cellProps.row.id} style={{ display: "flex", justifyContent: "center" }}>
            //                     <Input type={`checkbox`} value={cellProps.row.original.user_id} />
            //                 </div>
            //             </>
            //         )
            //     }
            // },
            {
                header: '#',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                { (parseInt(cellProps.row.id) + 1) }
                            </div>
                        </>
                    )
                }
            },
            {
                header: 'Category Name',
                accessorKey: 'category_name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Tools',
                accessorKey: 'user_id',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    let _questionaireId = cellProps.row.original.id;
                    return (
                        <>
                            {checkScope('questionnaire:view') && <Link className={`btn btn-success`} to={`/questionnaire/list/${cellProps.row.original.category_name}`}><i className={`bx bx-show-alt`}></i></Link>}
                            &nbsp;
                            {/* {checkScope('questionnaire:update') && <Link className={`btn btn-warning`} to={`/questionnaire/editCategory/${_categoryId}`}><i className={`bx bx-edit-alt`}></i></Link>}
                            &nbsp; */}
                            {/* {checkScope('questionnaire:delete') && <Link className={`btn btn-danger`} onClick={deleteRow(_categoryId)}><i className={`bx bx-trash`}></i></Link>} */}
                        </>
                    )
                }
            },
        ],
        []
    );

    const [contentList, setContentList] = useState([])
    const [contentListLoading, setContentListLoading] = useState(false)

    useEffect(() => {
        setContentListLoading(true)
        const fetchContentList = async () => {
            const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire')
            setContentList(result.data);
        };
    
        fetchContentList();
    }, []);

    useEffect(() => {
        setContentListLoading(false)
    }, [contentList])

    //meta title
    document.title = "Questionnaire | " + process.env.REACT_APP_CMS_TITLE;

    return (
        <div className="page-content">
            <div className="container-fluid">
                {/* <Breadcrumbs title="Questionnaire" breadcrumbItem="Category" /> */}
                <Breadcrumbs 
                    title={props.t("Questionnaire")} 
                    breadcrumbItems={[
                        {title: "Questionnaire", link: "questionnaire"}, 
                        {title: "Category", link: "category"}, 
                        {title: "List", link: "list"},
                    ]}
                />

                {
                    contentListLoading ? <Spinners setLoading={setContentListLoading} />
                    : 
                        <TableContainer
                            isAddButton={true}
                            addRowLink={`/category/create`}
                            isExportButton={true}
                            exportLink={`/export`}
                            columns={columns}
                            data={contentList || []}
                            isGlobalFilter={true}
                            isPagination={true}
                            SearchPlaceholder="Search"
                            pagination="pagination"
                            paginationWrapper='dataTables_paginate paging_simple_numbers'
                            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                            rootMenu="questionnaire"
                        />
                }
            </div>
        </div>
    );
}

CategoryList.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(CategoryList)
