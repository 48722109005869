import React, { useEffect, useState, useContext, useRef  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"


//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateArticle, GetArticleById, UpdateArticle } from "services/api/module/Article"
import ModalContext from "../../contexts/ModalContext";
import { DropdownArticleCategory, DropdownArticleKeyword } from "services/api/module/Dropdown"
import Flatpickr from "react-flatpickr";
import moment from "moment"
import UploadFileAPI from "components/UploadFileAPI"
import InputRadio from "components/Input/InputRadio"
import InputSwitch from "components/Input/InputSwitch"
import MyEditor from "../../components/MyEditor"


const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const VIDEO_TYPE_OPTION = [
  { id: 'youtube', name: 'Youtube' },
  { id: 'upload', name: 'Upload' }
]

const YEAR_OPTION = [
  ...fn_helper.GEN.YEAR_OPTION(2000, 5)
]

const ArticleTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qArticleId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    nameTh: Yup.string().max(100).required("Please Enter Article Name TH"),
    nameEn: Yup.string().max(100).required("Please Enter Article Name EN"),
    articleCategoryId: Yup.string().max(100).required("Please Select Article Category"),
    articleKeywordIds: Yup.array().min(1, "Please Select 1 Keyword").required("Please Select Keyword"),
    year: Yup.string().max(200).required("Please Enter Year"),
    coverImg: Yup.string().max(200).required("Please Enter Article Cover"),
    
    // hightlightVideoType: Yup.string().max(10).required("Please Enter Hightlight Video Type"),
    // hightlightVideo: Yup.string().max(200).required("Please Enter Video"),
    hightlightVideo: Yup.string().max(200)
      .when('articleCategoryId', {
          is: (val) => val == "1",
          then: (schema) => schema.required("Please Enter Hightlight Video"),
          otherwise: (schema) => schema.notRequired(),
      }),

    detailTh: Yup.string().required("Please Enter Article Detail Thai"),
    detailEn: Yup.string().required("Please Enter Article Detail Eng"),

    status: Yup.string().max(100).required("Please Select Status"),

    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string().max(100).required("Please Enter Meta Description"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddArticleKeyword, setDDArticleKeyword] = useState([])
  const [ddArticleCategory, setDDArticleCategory] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)
  const [ddLoaded1, setDDLoaded1] = useState(DEF_LOADED)
  const prevSelectDDArticleCategoryRef = useRef();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      articleCategoryId: "",
      articleKeywordIds: [],
      year: "",
      nameTh: "",
      nameEn: "",
      coverImg: "",
      hightlightVideoType: "youtube",
      hightlightVideo: "",
      detailTh: "",
      detailEn: "",
      status: "",
      isHightlight: "1",

      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ ArticleCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        articleCategoryId: Number(values.articleCategoryId),
        articleKeywordIds: values.articleKeywordIds,
        year: Number(values.year),
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        coverImg: values.coverImg,
        hightlightVideoType: values.hightlightVideoType ? values.hightlightVideoType : null,
        hightlightVideo: values.hightlightVideo,
        detailTh: values.detailTh,
        detailEn: values.detailEn,
        status: Number(values.status),
        isHightlight: Number(values.isHightlight),

        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateArticle(payload)
      } else {
        API.fetchCreateArticle(payload)
      }
    },
  })

  // SELECT DATA
  const selectedDDArticleCategory = ddArticleCategory.find(item => item.id == validation.values.articleKeywordIds)

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("articleCategoryId", resData.articleCategoryId)
    validation.setFieldValue("articleKeywordIds", resData.articleKeywordIds)
    validation.setFieldValue("year", resData.year)

    validation.setFieldValue("coverImg", resData.coverImg)

    validation.setFieldValue("hightlightVideoType", resData.hightlightVideoType)
    validation.setFieldValue("hightlightVideo", resData.hightlightVideo)

    validation.setFieldValue("status", resData.status)
    validation.setFieldValue("isHightlight", String(resData.isHightlight))

    validation.setFieldValue("detailTh", resData.detailTh)
    validation.setFieldValue("detailEn", resData.detailEn)

    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)

    // ติด Effect Clear State
    // setTimeout(() => validation.setFieldValue("articleKeywordIds", resData.articleKeywordIds), 100)
  }

  const API = {
    fetchDDArticleKeyword: async () => {
      try {
          const response = await DropdownArticleKeyword({})
          const resData = response?.data ?? []
          const tmpDD = resData.data
          console.log("🚀 ~ fetchDDArticleKeyword: ~ tmpDD:", tmpDD)
          setDDArticleKeyword(tmpDD);
          setDDLoaded0(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchDDArticleCategory: async () => {
      try {
          const response = await DropdownArticleCategory({})
          const resData = response?.data ?? []
          const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          setDDArticleCategory(tmpDD);
          setDDLoaded1(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchGetArticleById: async (payload) => {
      try {
          const response = await GetArticleById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateArticle: async (payload) => {
      try {
          const response = await CreateArticle({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/article/article')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateArticle: async (payload) => {
      try {
          const response = await UpdateArticle({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/article/article')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    if (ddLoaded0 && ddLoaded1) {
      if (["update", "view"].includes(pageView)) {
        const payload = { id : qArticleId }
        API.fetchGetArticleById(payload)
      }
    }
  }, [ddLoaded0, ddLoaded1])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDArticleCategory();
    API.fetchDDArticleKeyword();
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Article | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Article`}
            breadcrumbItems={[
              { title: "Article", link: "article/article" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Article Infomation</h5>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Article Name TH
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="nameTh"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="nameTh"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.nameTh}
                            invalid={validation.touched.nameTh && validation.errors.nameTh ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.nameTh && validation.errors.nameTh && (
                              <FormFeedback type="invalid">
                                {validation.errors.nameTh}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Article Name EN
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="nameEn"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="nameEn"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.nameEn}
                            invalid={validation.touched.nameEn && validation.errors.nameEn ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.nameEn && validation.errors.nameEn && (
                              <FormFeedback type="invalid">
                                {validation.errors.nameEn}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Category
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Category"
                            value={fn_helper.FNFORM.getObjectValue(ddArticleCategory, `${validation.values.articleCategoryId}`)}
                            onChange={(value) => {
                              validation.setFieldValue("articleCategoryId", value.id)
                              if (value.id == 1) {
                                setTimeout(() => { 
                                  validation.setFieldValue("hightlightVideoType", 'youtube') 
                                  validation.setFieldValue("hightlightVideo", null) 
                                }, 200)
                              } else {
                                setTimeout(() => { 
                                  validation.setFieldValue("hightlightVideoType", null) 
                                  validation.setFieldValue("hightlightVideo", null) 
                                }, 200)

                              }
                            }}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddArticleCategory}
                            className={`select2-selection ${validation.touched.articleCategoryId && validation.errors.articleCategoryId ? "input-err" : ""}` }
                            isDisabled={["update", "view"].includes(pageView)}
                          />
                          {
                            (validation.touched.articleCategoryId && validation.errors.articleCategoryId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.articleCategoryId}</div>
                            )
                          }
                        </div>
                      </Col>
                      
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Keyword
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Keyword"
                            isMulti={true}
                            // value={
                            //   fn_helper.FNFORM.getArrObjectValue(ddArticleKeyword, `${validation.values.articleKeywordIds}`)
                            // }
                            value={fn_helper.FNFORM.getArrObjectValue(ddArticleKeyword, validation.values.articleKeywordIds)}
                            onChange={(values) => {
                                console.log("🚀 ~ values:", values)
                                const valueIds = fn_helper.FNFORM.arrObjToId(_.cloneDeep(values))
                                validation.setFieldValue("articleKeywordIds", valueIds)
                            }}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddArticleKeyword}
                            className={`select2-selection ${validation.touched.articleKeywordIds && validation.errors.articleKeywordIds ? "input-err" : ""}` }
                            isDisabled={["update", "view"].includes(pageView)}
                          />
                          {
                            (validation.touched.articleKeywordIds && validation.errors.articleKeywordIds) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.articleKeywordIds}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Year
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Year"
                            value={fn_helper.FNFORM.getObjectValue(YEAR_OPTION, `${validation.values.year}`)}
                            onChange={(value) => validation.setFieldValue("year", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={YEAR_OPTION}
                            className={`select2-selection ${(validation.touched.year && validation.errors.year) ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.year && validation.errors.year) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.year}</div>
                            )
                          }
                          { 
                            validation.touched.year && validation.errors.year && (
                              <FormFeedback type="invalid">
                                {validation.errors.year}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      
                      <Col className="col-12 mb-3">
                        <div className="col-md-12">
                          <UploadFileAPI 
                            bucketName="banner"
                            upload="Image"
                            typeUpload="ImageMap"
                            prefixName="article-image"
                            label="Article Cover Img"
                            required={true}
                            widthSize={288}
                            heightSize={200}
                            description="description"
                            value={validation.values.coverImg}
                            onChange={([ imageURL = '', imageSize = '']) => {
                                validation.setFieldValue("coverImg", imageURL)
                            }}
                          />
                          <Input
                            id="coverImg"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="coverImg"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.coverImg}
                            invalid={validation.touched.coverImg && validation.errors.coverImg ? true : false}
                            readOnly={true}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.coverImg && validation.errors.coverImg && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.coverImg}</div>
                            )
                          }
                        </div>
                      </Col>
                      {
                        // 1 คือ id ของ Social JIB
                        validation.values.articleCategoryId == 1 && (
                          <Col className="col-12 mb-3">
                            <InputRadio 
                              label="Video Type"
                              name="hightlightVideoType"
                              value={validation.values.hightlightVideoType}
                              option={VIDEO_TYPE_OPTION}
                              onChange={(value)=> {
                                console.log("🚀 ~ value:", value)
                                validation.setFieldValue("hightlightVideoType", value)
                              }}
                              disabled={pageView === "view"}
                            />
                          </Col>
                        )
                      }

                      {
                        validation.values.articleCategoryId == 1 && validation.values.hightlightVideoType === 'youtube'
                        ? (
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              YouTube Link
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="hightlightVideo"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="hightlightVideo"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.hightlightVideo}
                                invalid={validation.touched.hightlightVideo && validation.errors.hightlightVideo ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.hightlightVideo && validation.errors.hightlightVideo && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.hightlightVideo}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        )
                        : 
                        validation.values.hightlightVideoType === 'upload' ? (
                          <Col className="col-12 mb-3">
                            <div className="col-md-12">
                              <UploadFileAPI
                                bucketName="banner"
                                upload="Video"
                                typeUpload="Video"
                                prefixName="article-video"
                                label="Upload Video"
                                required={true}
                                widthSize={1024}
                                heightSize={1024}
                                description="description"
                                value={validation.values.hightlightVideo}
                                onChange={([ imageURL = '', imageSize = '']) => {
                                    validation.setFieldValue("hightlightVideo", imageURL)
                                }}
                              />
                              <Input
                                id="hightlightVideo"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="hightlightVideo"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.hightlightVideo}
                                invalid={validation.touched.hightlightVideo && validation.errors.hightlightVideo ? true : false}
                                readOnly={true}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.hightlightVideo && validation.errors.hightlightVideo && (
                                  <div type="invalid" className="invalid-feedback d-block">{validation.errors.hightlightVideo}</div>
                                )
                              }
                            </div>
                          </Col>
                        )
                        : <></>
                      }
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                            onChange={(value) => validation.setFieldValue("status", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.status && validation.errors.status) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Is HightLight on HomePage
                        </label>
                        <InputSwitch
                          labelTrue="Yes"
                          labelFalse="No"
                          value={validation.values.isHightlight == "1"}
                          onChange={(value)=> {
                            const updateValue = value ? '1' : '0'
                            validation.setFieldValue("isHightlight", updateValue)
                          }}
                          disabled={pageView === "view"}
                        />
                      </Col>

                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Article Detail Thai
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <div className={validation.touched.detailTh && validation.errors.detailTh ? 'invalid-editor' : ''}>
                            {/* <CKEditor
                              editor={ClassicEditor}
                              data={validation.values.detailTh}
                              onChange={(event, editor) => {
                                  const data = editor.getData();
                                  validation.setFieldValue("detailTh", data)
                                  console.log({ event, editor, data });
                              }}
                              onReady={(editor) => {
                                  console.log('Editor is ready to use!', editor);
                              }}
                              onBlur={(event, editor) => {
                                  console.log('Blur.', editor);
                              }}
                              onFocus={(event, editor) => {
                                  console.log('Focus.', editor);
                              }}
                              disabled={pageView === "view"}
                            /> */}
                            <MyEditor
                              value={validation.values.detailTh}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                validation.setFieldValue("detailTh", data)
                                console.log({ event, editor, data });
                              }}
                              disabled={pageView === "view"}
                            />
                          </div>
                          
                          {
                            (validation.touched.detailTh && validation.errors.detailTh) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.detailTh}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Article Detail Eng
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <div className={validation.touched.detailEn && validation.errors.detailEn ? 'invalid-editor' : ''}>
                            <MyEditor
                              value={validation.values.detailEn}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                validation.setFieldValue("detailEn", data)
                                console.log({ event, editor, data });
                              }}
                              disabled={pageView === "view"}
                            />
                            {/* <CKEditor
                              editor={ClassicEditor}
                              data={validation.values.detailEn}
                              onChange={(event, editor) => {
                                  const data = editor.getData();
                                  validation.setFieldValue("detailEn", data)
                                  console.log({ event, editor, data });
                              }}
                              onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                
                                // กำหนด custom upload adapter ของเรา
                                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                                  return new CustomUploadAdapterPlugin(loader);  // สร้าง Adapter เมื่อทำการอัปโหลดไฟล์
                                };
                              }}
                              
                              onBlur={(event, editor) => {
                                  console.log('Blur.', editor);
                              }}
                              onFocus={(event, editor) => {
                                  console.log('Focus.', editor);
                              }}
                              disabled={pageView === "view"}
                            /> */}
                            {
                              (validation.touched.detailEn && validation.errors.detailEn) && (
                                <div type="invalid" className="invalid-feedback d-block">{validation.errors.detailEn}</div>
                              )
                            }
                          </div>
                        </div>
                      </Col>

                    </Row>

                    <Row>
                      <Col className="col-6">
                        <Col className="col-12">
                          <h5 className="mb-3">SEO Setting</h5>
                        </Col>
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              URL Key
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoUrlKey"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoUrlKey"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoUrlKey}
                                invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoUrlKey}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Meta Title
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaTitle"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaTitle"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaTitle}
                                invalid={validation.touched.seoMetaTitle && validation.errors.seoMetaTitle ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.seoMetaTitle && validation.errors.seoMetaTitle && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaTitle}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Meta keyword
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaKeyword"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaKeyword"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaKeyword}
                                invalid={validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaKeyword}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Meta Description
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaDescription"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaDescription"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaDescription}
                                invalid={validation.touched.seoMetaDescription && validation.errors.seoMetaDescription ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.seoMetaDescription && validation.errors.seoMetaDescription && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaDescription}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/article/article')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/article/article')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };
export default withTranslation()(ArticleTemplate)
