import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

const Stepper = ({
  value = 0,
  componentSteps = [],
  onChange = () => console.log('onChange!')
}) => {
  // const [activeTab, setactiveTab] = useState(0)
  const [passedSteps, setPassedSteps] = useState([0])
  const activeTab = value

 

  return (
    <div>
      {/* activeTab: {JSON.stringify(activeTab)}<br/>
      <p>-----------------------------</p>
      passedSteps: {JSON.stringify(passedSteps)}<br/> */}

      <div className="wizard clearfix">
        <div className="steps clearfix">
          <ul>
            {
              componentSteps.map((item, i) => {
                return (
                  <NavItem 
                    key={i}
                    className={classnames({ current: activeTab === i })} 
                  >
                    <NavLink
                      className={classnames({ current: activeTab === i })}
                      onClick={() => {
                        setactiveTab(1)
                      }}
                      disabled={true}
                    >
                      <span className="number">{i + 1}.</span> {item.stepLabel}
                    </NavLink>
                  </NavItem>
                )
              })
            }

          
          </ul>
        </div>

        <div className="content clearfix">
          <TabContent activeTab={activeTab} className="body">
            {
              componentSteps.map((item, i) => {
                return (
                  <TabPane tabId={i} key={i}>
                    {item.components}
                  </TabPane>
                )
              })
            }
          </TabContent>
        </div>

        {/* <div className="actions clearfix">
          <ul>
            <li className={activeTab === 0 ? "previous disabled" : "previous"}>
              <button
                className="btn btn-primary w-md" 
                onClick={() => {
                  toggleTab(activeTab - 1)
                }}
                disabled={activeTab === 0}
              >
                Previous
              </button>
            </li>
            <li className={activeTab === (componentSteps.length - 1) ? "next disabled" : "next"}>
              <button
                className="btn btn-primary w-md"
                onClick={() => {
                  toggleTab(activeTab + 1)
                }}
                disabled={activeTab === (componentSteps.length - 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </div> */}

      </div>
    
    </div>
    
  )
}
export default Stepper
