import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
// import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import dashboardSaga from "./dashboard/saga";

import questionaireCateSaga from "./questionaire/category/create/saga"
import questionaireUpdateSaga from "./questionaire/category/update/saga"

import questionaireQuestionSaga from "./questionaire/question/create/saga"
import questionaireQuestionUpdateSaga from "./questionaire/question/update/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    // fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(questionaireCateSaga),
    fork(questionaireUpdateSaga),
    fork(questionaireQuestionSaga),
    fork(questionaireQuestionUpdateSaga),
  ]);
}
