import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"
import { apiError } from "store/actions"

import * as backend from "helpers/backend"

function* editProfile({ payload: { user } }) {
  console.log(user)
  try {
    const response = yield call(backend.updateUser, user);
    yield put(profileSuccess("User Updated Successfully"))
  } catch (error) {
    if(typeof error.response.data !== "undefined") {
      yield put(profileError(error.data.message))
      yield put(apiError(error.data.message));
    }
    else {
      yield put(profileError(error.response.message))
      yield put(apiError(error.response));
    }
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
