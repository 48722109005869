import React, { useState, useEffect } from 'react';

const VisualSwatchForm = ({ disabled, values, onChange }) => {
  const [formRows, setFormRows] = useState([
    { 
      id: 1, 
      swatchType: '', 
      swatchValue: '', 
      labelAdmin: '', 
      labelTh: '', 
      labelEn: '' 
    }
  ]);

  const SWATCH_TYPES = [
    { value: 'color', label: 'Color Picker' },
    { value: 'upload', label: 'Upload Image' }
  ];

  const onAddFormRow = () => {
    const newRow = {
      id: Math.floor(Math.random() * (30 - 20)) + 20,
      swatchType: '',
      swatchValue: '', 
      labelAdmin: '',
      labelTh: '',
      labelEn: ''
    };
    const newRows = [...formRows, newRow];
    setFormRows(newRows);
    if (onChange) {
      onChange(newRows);
    }
  };

  const onDeleteFormRow = (id) => {
    const updatedRows = formRows.filter(row => row.id !== id);
    setFormRows(updatedRows);
    if (onChange) {
      onChange(updatedRows);
    }
  };

  const handleInputChange = (id, field, value) => {
    const updatedRows = formRows.map(row => {
      if (row.id === id) {
        const updatedRow = { ...row, [field]: value };
        if (field === 'swatchType') {
          updatedRow.swatchValue = '';
        }
        return updatedRow;
      }
      return row;
    });
    
    setFormRows(updatedRows);
    if (onChange) {
      onChange(updatedRows);
    }
  };

  useEffect(() => {
    if (values.length > 0) {
      setFormRows(values);
    }
  }, [values]);
  

  return (
    <div className="visual-swatch-form mt-4">
      <h6 className="mb-3">Visual Swatch Options</h6>
      <div className="swatch-rows">
        {formRows.map((row, index) => (
          <div key={row.id} className="mb-4 p-3 border rounded">
            <div className="row">
              <div className="col-md-2 mb-3">
                <label className="form-label">Swatch Type</label>
                <select 
                  className="form-select"
                  value={row.swatchType}
                  onChange={(e) => handleInputChange(row.id, 'swatchType', e.target.value)}
                  disabled={disabled}
                >
                  <option value="">Select Type</option>
                  {SWATCH_TYPES.map(type => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-2 mb-3">
                {row.swatchType === 'color' ? (
                  <div>
                    <label className="form-label">Pick Color</label>
                    <input
                      type="color"
                      className="form-control form-control-color w-100"
                      value={row.swatchValue || '#000000'}
                      onChange={(e) => handleInputChange(row.id, 'swatchValue', e.target.value)}
                      disabled={disabled}
                    />
                  </div>
                ) : row.swatchType === 'upload' ? (
                  <div>
                    <label className="form-label">Upload Image</label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={(e) => handleInputChange(row.id, 'swatchValue', e.target.files[0])}
                      disabled={disabled}
                    />
                  </div>
                ) : null}
              </div>

              <div className="col-md-2 mb-3">
                <label className="form-label">Admin Label</label>
                <input
                  type="text"
                  className="form-control"
                  value={row.labelAdmin}
                  onChange={(e) => handleInputChange(row.id, 'labelAdmin', e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="col-md-2 mb-3">
                <label className="form-label">Thai Label</label>
                <input
                  type="text"
                  className="form-control"
                  value={row.labelTh}
                  onChange={(e) => handleInputChange(row.id, 'labelTh', e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="col-md-2 mb-3">
                <label className="form-label">English Label</label>
                <input
                  type="text"
                  className="form-control"
                  value={row.labelEn}
                  onChange={(e) => handleInputChange(row.id, 'labelEn', e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="col-md-1 mb-3 d-flex align-items-end">
                <button
                  type="button"
                  className="btn btn-danger w-100"
                  onClick={() => onDeleteFormRow(row.id)}
                  disabled={disabled}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <button
        type="button"
        className="btn btn-success mt-2"
        onClick={onAddFormRow}
        disabled={disabled}
      >
        Add Swatch Option
      </button>
    </div>
  );
};

export default VisualSwatchForm;
