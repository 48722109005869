import {
    CREATE_QUESTIONAIRE_QUESTION,
    CREATE_QUESTIONAIRE_QUESTION_SUCCESSFUL,
    CREATE_QUESTIONAIRE_QUESTION_FAILED,
    CREATE_QUESTIONAIRE_QUESTION_RESET,
} from "./actionsType"

const initialState = {
    message: null,
    loading: false,
    questionData: null,
    createError: null,
    createSuccess: false
}

const questionaireQuestion = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_QUESTIONAIRE_QUESTION:
            state = {
                ...state,
                loading: true,
                createSuccess: false,
                createError: null,
            }
            break
        case CREATE_QUESTIONAIRE_QUESTION_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                questionData: action.payload,
                createSuccess: true,
                createError: null,
            }
            break
        case CREATE_QUESTIONAIRE_QUESTION_FAILED:
            state = {
                ...state,
                questionData: null,
                loading: false,
                createSuccess: false,
                createError: action.payload,
            }
            break
        case CREATE_QUESTIONAIRE_QUESTION_RESET:
            state = {
                ...state,
                message: null,
                loading: false,
                createError: null,
                createSuccess: false
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export default questionaireQuestion