import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { GetOrderRewardById } from "services/api/module/OrderReward"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"
import Timeline from "components/Common/Timeline"
import moment from "moment"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const OrderRewardTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qOrderRewardId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({})

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderHistoryId: "",
      rewardId: "",
      usedPoint: "",
      quantity: "",
      status: "",
      orderHistoryOrderType: "",
      orderHistoryTrackingNumber: "",
      orderHistoryFirstName: "",
      orderHistoryLastName: "",
      orderHistoryMobileNo: "",
      orderHistoryEmail: "",
      orderHistoryAddress: "",
      orderHistoryNote: "",
      orderHistoryOrderStatus: "",
      orderHistoryUserId: "",
      orderHistoryTombonId: "",
      orderHistoryOrderNumber: "",
      orderHistoryProductSubtotal: "",
      orderHistoryRewardSubtotal: "",
      orderHistoryAmphureId: "",
      orderHistoryProvinceId: "",
      orderHistoryUserFirstname: "",
      orderHistoryUserLastname: "",
      orderHistoryProvinceNameTh: "",
      orderHistoryAmphureNameTh: "",
      orderHistoryTombonNameTh: "",
      orderHistoryTombonPostcode: "",
      rewardProductCode: "",
      rewardNameTh: "",
      createdAt: "",
      rewardImage: "",
      orderShipment: []
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ OrderRewardCreate ~ values:", values)

    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("orderHistoryId", resData.orderHistoryId)
    validation.setFieldValue("rewardId", resData.rewardId)
    validation.setFieldValue("usedPoint", resData.usedPoint)
    validation.setFieldValue("quantity", resData.quantity)
    validation.setFieldValue("status", resData.status)
    validation.setFieldValue("orderHistoryOrderType", resData.orderHistoryOrderType)
    validation.setFieldValue("orderHistoryTrackingNumber", resData.orderHistoryTrackingNumber)
    validation.setFieldValue("orderHistoryFirstName", resData.orderHistoryFirstName)
    validation.setFieldValue("orderHistoryLastName", resData.orderHistoryLastName)
    validation.setFieldValue("orderHistoryMobileNo", resData.orderHistoryMobileNo)
    validation.setFieldValue("orderHistoryEmail", resData.orderHistoryEmail)
    validation.setFieldValue("orderHistoryAddress", resData.orderHistoryAddress)
    validation.setFieldValue("orderHistoryNote", resData.orderHistoryNote)
    validation.setFieldValue("orderHistoryOrderStatus", resData.orderHistoryOrderStatus)
    validation.setFieldValue("orderHistoryUserId", resData.orderHistoryUserId)
    validation.setFieldValue("orderHistoryTombonId", resData.orderHistoryTombonId)
    validation.setFieldValue("orderHistoryOrderNumber", resData.orderHistoryOrderNumber)
    validation.setFieldValue("orderHistoryProductSubtotal", resData.orderHistoryProductSubtotal)
    validation.setFieldValue("orderHistoryRewardSubtotal", resData.orderHistoryRewardSubtotal)
    validation.setFieldValue("orderHistoryAmphureId", resData.orderHistoryAmphureId)
    validation.setFieldValue("orderHistoryProvinceId", resData.orderHistoryProvinceId)
    validation.setFieldValue("orderHistoryUserFirstname", resData.orderHistoryUserFirstname)
    validation.setFieldValue("orderHistoryUserLastname", resData.orderHistoryUserLastname)
    validation.setFieldValue("orderHistoryProvinceNameTh", resData.orderHistoryProvinceNameTh)
    validation.setFieldValue("orderHistoryAmphureNameTh", resData.orderHistoryAmphureNameTh)
    validation.setFieldValue("orderHistoryTombonNameTh", resData.orderHistoryTombonNameTh)
    validation.setFieldValue("orderHistoryTombonPostcode", resData.orderHistoryTombonPostcode)
    validation.setFieldValue("rewardProductCode", resData.rewardProductCode)
    validation.setFieldValue("rewardNameTh", resData.rewardNameTh)
    validation.setFieldValue("createdAt", resData.createdAt)
    validation.setFieldValue("rewardImage", resData.rewardImage)
    validation.setFieldValue("orderShipment", resData.orderShipment)

  }

  const API = {
    fetchGetOrderRewardById: async (payload) => {
      try {
        const response = await GetOrderRewardById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qOrderRewardId }
      API.fetchGetOrderRewardById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `OrderReward | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} REWARDS TRANSACTIONS DEATAIL`}
            breadcrumbItems={[
              { title: "REWARDS TRANSACTIONS DEATAIL", link: "reward/order-reward" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">

                      <Col className="col-12">

                        <Row className="">
                          <Col className="col-12">
                            <h5 className="mb-3">Order Information</h5>
                          </Col>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Created At
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="createdAt"
                                className="form-control"
                                type="datetime-local"
                                placeholder=""
                                name="createdAt"
                                onBlur={validation.handleBlur}
                                value={validation.values.createdAt}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Order Code
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryOrderNumber"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryOrderNumber"
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryOrderNumber}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Recipient Name
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryFirstName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryFirstName}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Recipient Lastname
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryLastName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryLastName"
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryLastName}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Email
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryEmail"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryEmail"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryEmail}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Mobile Number
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryMobileNo"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryMobileNo"
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryMobileNo}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Status
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryMobileNo"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryMobileNo"
                                onBlur={validation.handleBlur}
                                value={validation.values.status == 0 ? 'Pending' : validation.values.status == 1 ? 'Approve' : validation.values.status == 2 ? 'Reject' : 'No Data'}
                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row className="mb-3">

                      <Col className="col-12">

                        <Row className="">
                          <Col className="col-12">
                            <h5 className="mb-3">Reward Information</h5>
                          </Col>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Reward Code
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="rewardProductCode"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="rewardProductCode"
                                onBlur={validation.handleBlur}
                                value={validation.values.rewardProductCode}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Name
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="rewardNameTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                onBlur={validation.handleBlur}
                                value={validation.values.rewardNameTh}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Used Point
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="rewardNameTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                onBlur={validation.handleBlur}
                                value={validation.values.usedPoint}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Image
                            </label>
                            <div className="col-md-10">
                              <UploadFileAPI
                                bucketName="promotion-category"
                                upload="Image"
                                typeUpload="ImageMap"
                                prefixName="promotion-category-image"
                                label="Reward Image"
                                required={true}
                                widthSize={600}
                                heightSize={600}
                                description="description"
                                value={validation.values.rewardImage}
                                onChange={([
                                  imageURL = "",
                                  imageSize = "",
                                ]) => {
                                  validation.setFieldValue(
                                    "image",
                                    imageURL
                                  )
                                }}
                              />
                              <Input
                                id="rewardImage"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="rewardImage"

                                onBlur={validation.handleBlur}
                                value={validation.values.rewardImage}

                                readOnly={true}
                                disabled={pageView === "view" || pageView === "update"}
                              />

                            </div>
                          </Row>
                        </Row>
                      </Col>

                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row className="mb-3">

                      <Col className="col-12">

                        <Row className="">
                          <Col className="col-12">
                            <h5 className="mb-3">Delivery Address</h5>
                          </Col>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Recipient Name
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryFirstName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryFirstName}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Recipient Lastname
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryLastName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryLastName"
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryLastName}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Mobile Number
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryMobileNo"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryMobileNo"
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryMobileNo}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>


                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Email
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryEmail"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryEmail"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryEmail}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Address
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryAddress"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryAddress"
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryAddress}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Tombon
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryTombonNameTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryTombonNameTh"
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryTombonNameTh}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Amphure
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryAmphureNameTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryAmphureNameTh"
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryAmphureNameTh}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Province
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryProvinceNameTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryProvinceNameTh"
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryProvinceNameTh}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>


                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Postcode
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryTombonPostcode"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryTombonPostcode"
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryTombonPostcode}

                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Note
                            </label>
                            <div className="col-md-10">
                              <Input
                                id="orderHistoryNote"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="orderHistoryNote"
                                onBlur={validation.handleBlur}
                                value={validation.values.orderHistoryNote}
                                disabled={pageView === "view"}
                              />
                            </div>
                          </Row>

                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <h5 className="mb-3">Shipments Information</h5>
                    {/* {JSON.stringify(validation.values.orderShipment)} */}
                    <ul className="verti-timeline list-unstyled">
                      {(validation.values.orderShipment || []).map((item, index) => {
                        return (
                          <Timeline
                            key={index}
                            title={item.name}
                            subTitle={moment(item.timestamp).format("YYYY-MM-DD HH:mm:ss")}
                            description={''}
                          />)


                      })}

                    </ul>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                      {
                        pageView == "view"
                          ? <><button className="btn btn-primary w-md" onClick={() => navigate('/reward/order-reward')}>BACK</button></>
                          : <>
                            <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                            <button className="btn btn-secondary w-md" onClick={() => navigate('/reward/order-reward')}>CANCEL</button>
                          </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(OrderRewardTemplate)
