import { get, post, put, patch } from "../Config"

export function SearchBranchType({ params = {} }) {
  return get({ url: `/v1/admin/branchType`, params })
}
export function GetBranchTypeById({ id }) {
  return get({ url: `/v1/admin/branchType/${id}` })
}
export function CreateBranchType({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/branchType`, params, data })
}
export function UpdateBranchType({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/branchType/${id}`, params, data })
}
export function PatchBranchType({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/branchType/${id}`, params, data })
}
export function DeleteBranchType({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/branchType/mutiDelete`, params, data })
}
