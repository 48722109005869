import axios from "axios";
import helper from "helpers/fn_helper";

export const baseUrl = process.env.REACT_APP_BACKEND_URL;
export const headers = {
};

axios.defaults.withCredentials = true
axios.interceptors.request.use(function (request) {
  // console.log("🚀 ~ request MiddleWare >>", request)
  return request;
}, function (error) {
  return Promise.reject(error);
});

// // Add a response interceptor
axios.interceptors.response.use(function (response) {
  // console.log("🚀 ~ response MiddleWare >>", response)
  return response;
}, function (error) {
  console.log('errrrrrrrrrrrrrrrrrrrrrrrrr >>', error)
  const resData = error.response.data
  return Promise.reject(error);
});

export function get({ url, baseURL = baseUrl, params, timeOut = 20000 }) {
  return axios({
    url,
    method: "get",
    baseURL: baseURL,
    headers: {
      ...headers,
      // authorization: helper.Auth.getToken(),
      lang: helper.Auth.getLang(),
    },
    params,
    timeout: timeOut,
  })
    .then((res) => {
      // console.log('GET api main success >>', res)
      return res;
    })
    .catch((e) => {
      // const resData = e?.response?.data ?? {};
      console.log("GET api main err >>", e.response.data);
      return e.response;
    });
}

export function post({
  url,
  baseURL = baseUrl,
  params,
  data,
  timeOut = 20000,
}) {
  return axios({
    url,
    method: "post",
    baseURL: baseURL,
    headers: {
      ...headers,
      // authorization: helper.Auth.getToken(),
      lang: helper.Auth.getLang(),
    },
    params,
    data,
    timeout: timeOut,
  })
    .then((res) => {
      // console.log('POST api main success >>', res)
      return res;
    })
    .catch((e) => {
      // const resData = e?.response?.data ?? {};
      console.log("POST api main err >>", e.response.data);
      return e.response;
    });
}

export function put({
  url,
  baseURL = baseUrl,
  params,
  data,
  timeOut = 20000,
}) {
  return axios({
    url,
    method: "put",
    baseURL: baseURL,
    headers: {
      ...headers,
      // authorization: helper.Auth.getToken(),
      lang: helper.Auth.getLang(),
    },
    params,
    data,
    timeout: timeOut,
  })
    .then((res) => {
      // console.log('POST api main success >>', res)
      return res;
    })
    .catch((e) => {
      // const resData = e?.response?.data ?? {};
      console.log("PUT api main err >>", e.response.data);
      return e.response;
    });
}

export function patch({
  url,
  baseURL = baseUrl,
  params,
  data,
  timeOut = 20000,
}) {
  return axios({
    url,
    method: "patch",
    baseURL: baseURL,
    headers: {
      ...headers,
      // authorization: helper.Auth.getToken(),
      lang: helper.Auth.getLang(),
    },
    params,
    data,
    timeout: timeOut,
  })
    .then((res) => {
      // console.log('POST api main success >>', res)
      return res;
    })
    .catch((e) => {
      // const resData = e?.response?.data ?? {};
      console.log("PUT api main err >>", e.response.data);
      return e.response;
    });
}
