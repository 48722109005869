import React, { useEffect, useState, useContext, useRef } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CheckDuplicatePromotionCategory,
  CreatePromotionCategory,
  GetPromotionCategoryById,
  SearchPromotionCategory,
  UpdatePromotionCategory,
} from "services/api/module/PromotionCategory"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import moment from "moment"
import InputSwitch from "components/Input/InputSwitch"
import { DropdownPromotionCategoryByType } from "services/api/module/Dropdown"
import InputRadio from "components/Input/InputRadio"
import Stepper from "pages/BannerImageProduct/Stepper"
import images from "assets/images"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const CATE_TYPE_OPTION = [
  { id: 1, name: "Normal" },
  { id: 2, name: "Parent" },
  { id: 3, name: "Child" },
]

const PromotionCategoryTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qPromotionCategoryId = qParams.id
  const navigate = useNavigate()

  // STEPPER STATE
  const [activeTab, setActiveTab] = useState(0)

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    nameTh: Yup.string().max(25).required("Please Enter Category Name TH"),
    nameEn: Yup.string().max(25).required("Please Enter Category Name EN"),
    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string().required("Please Enter Meta Description"),
    status: Yup.string().max(100).required("Please Enter Status"),
    cateType: Yup.string().max(100).required("Please Enter Category Type"),

  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddPromotionCategory, setDDPromotionCategory] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)

  // CheckDUP STATE
  const [errDupField, setErrDupField] = useState({
    nameTh: false,
    nameEn: false,
  })
  const timeoutInputRef = useRef(null)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameTh: "",
      nameEn: "",
      image: "",
      description: "",
      sortOrder: "",
      parentId: "",
      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: "",
      isRelateBank: "",
      isMegamenu: "",
      embedLink: "",
      imageBanner: "",
      title: "",
      shortDescription: "",
      shortSelection: "",
      cateType: 1,
    },
    validationSchema: updatedValidationSchema,

    onSubmit: values => {

      if (values.cateType === 2) {
        const isEmpty =
          values.embedLink === "" ||
            values.imageBanner === "" ||
            values.title === "" ||
            values.shortDescription === "" ||
            values.shortSelection === ""
            ? true : false

        if (isEmpty) {
          validation.setFieldError("embedLink", "Please Enter Embed Link");
          validation.setFieldError("imageBanner", "Please Enter Image Banner")
          validation.setFieldError("title", "Please Enter Title")
          validation.setFieldError("shortDescription", "Please Enter Short Description")
          validation.setFieldError("shortSelection", "Please Enter Short Selection")
          return false
        }

      }

      if (values.cateType === 3) {

        const isEmpty = values.parentId === "" ? true : false
        if (isEmpty) {
          validation.setFieldError("parentId", "Please Enter Parent Category");
          return false
        }

      }

      const isPass = Object.values(errDupField).every(item => item === false) // เช็คว่าทุกตัว ไม่มีค่า true
      console.log("🚀 ~ isPass:", isPass)

      if (isPass) {
        console.log("🚀 ~ PromotionCategoryCreate ~ values:", values)
        const payload = {
          id: values.id ? values.id : undefined,
          nameTh: values.nameTh,
          nameEn: values.nameEn,
          description: values.description,
          sortOrder: values.sortOrder,
          parentId: Number(values.parentId) ?? 0,
          seoUrlKey: values.seoUrlKey,
          seoMetaTitle: values.seoMetaTitle,
          seoMetaKeyword: values.seoMetaKeyword,
          seoMetaDescription: values.seoMetaDescription,
          status: Number(values.status),
          image: values.image,
          isRelateBank: Number(values.isRelateBank),
          isMegamenu: Number(values.isMegamenu),
          embedLink: values.embedLink ? values.embedLink : "",
          imageBanner: values.imageBanner,
          title: values.title,
          shortDescription: values.shortDescription,
          shortSelection: values.shortSelection,
          cateType: Number(values.cateType),
        }

        console.log("🚀 ~ onSave ~ payload:", payload)

        if (pageView === "update") {
          API.fetchUpdatePromotionCategory(payload)
        } else {
          API.fetchCreatePromotionCategory(payload)
        }
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("image", resData.image)
    validation.setFieldValue("description", resData.description)
    validation.setFieldValue("sortOrder", resData.sortOrder)
    validation.setFieldValue("parentId", resData.parentId)
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)
    validation.setFieldValue("isRelateBank", resData.isRelateBank)
    validation.setFieldValue("isMegamenu", resData.isMegamenu)
    validation.setFieldValue("embedLink", resData.embedLink)
    validation.setFieldValue("imageBanner", resData.imageBanner)
    validation.setFieldValue("title", resData.title)
    validation.setFieldValue("shortDescription", resData.shortDescription)
    validation.setFieldValue("shortSelection", resData.shortSelection)
    validation.setFieldValue("cateType", resData.cateType)
  }

  const onStep1 = () => {
    console.log("🚀 ~ onStep1 ~ validation:", validation.values)

    // validate field ตาม step
    const isEmpty =
      validation.values.nameTh === "" ||
        validation.values.nameEn === "" ||
        validation.values.seoUrlKey === "" ||
        validation.values.seoMetaTitle === "" ||
        validation.values.seoMetaKeyword === "" ||
        validation.values.seoMetaDescription === "" ||
        validation.values.status === ""
        ? true : false

    if (isEmpty) {
      validation.setFieldTouched("nameTh", true)
      validation.setFieldTouched("nameEn", true)
      validation.setFieldTouched("seoUrlKey", true)
      validation.setFieldTouched("seoMetaTitle", true)
      validation.setFieldTouched("seoMetaKeyword", true)
      validation.setFieldTouched("seoMetaDescription", true)
      validation.setFieldTouched("status", true)
    } else {
      setActiveTab(activeTab + 1)
    }
  }

  const API = {
    fetchGetPromotionCategoryById: async payload => {
      try {
        const response = await GetPromotionCategoryById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreatePromotionCategory: async payload => {
      try {
        const response = await CreatePromotionCategory({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/promotion/category")
            },
          })
        } else if (resData.status === 403) {
          const updateErrDupField = _.cloneDeep(errDupField)
          updateErrDupField[resData.data] = true
          setErrDupField(updateErrDupField)

          mAlert.info({
            title: "Alert",
            subTitle: "Data Existing",
            content: "",
            onClose: () => { },
          })
        } else {
          throw resData
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdatePromotionCategory: async payload => {
      try {
        const response = await UpdatePromotionCategory({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/promotion/category")
            },
          })
        } else if (resData.status === 403) {
          const updateErrDupField = _.cloneDeep(errDupField)
          updateErrDupField[resData.data] = true
          setErrDupField(updateErrDupField)

          mAlert.info({
            title: "Alert",
            subTitle: "Data Existing",
            content: "",
            onClose: () => { },
          })
        } else {
          throw resData
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCheckFieldDup: async payload => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await CheckDuplicatePromotionCategory({
            data: payload,
          })
          const resData = response.data
          resolve(resData?.data ?? [])
        } catch (err) {
          console.log("err ", err)
          resolve([])
        }
      })
    },
    fetchDDPromotionCategory: async () => {
      try {
        const response = await DropdownPromotionCategoryByType({})
        const resData = response?.data ?? []
        const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
        setDDPromotionCategory(tmpDD)
        setDDLoaded0(true)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  const checkFieldDup = async (fieldName = "", value = "") => {
    if (timeoutInputRef.current) {
      clearTimeout(timeoutInputRef.current)
    }
    timeoutInputRef.current = setTimeout(async () => {
      const payload = { fieldName: fieldName, value: value }
      const rowsDup = await API.fetchCheckFieldDup(payload)

      let editId = qPromotionCategoryId ?? 0
      let isDupField = fn_helper.FNFORM.checkDupField(
        fieldName,
        value,
        rowsDup,
        editId
      )
      const updateErrDupField = _.cloneDeep(errDupField)
      updateErrDupField[fieldName] = isDupField
      setErrDupField(updateErrDupField)
    }, 500)
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qPromotionCategoryId }
      API.fetchGetPromotionCategoryById(payload)
    }
  }, [])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDPromotionCategory()
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Promotion Category | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  // RENDER
  const STEP_CONTENT = [
    {
      stepLabel: 'Category Infomation',
      components: <>
        <Col className="col-12">
          <Row className="">
            <Col className="col-6">
              <Row className="">
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Category Name TH
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="nameTh"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="nameTh"
                      onBlur={validation.handleBlur}
                      onChange={e => {
                        validation.setFieldValue(
                          "nameTh",
                          e.target.value
                        )
                        checkFieldDup("nameTh", e.target.value)
                      }}
                      value={validation.values.nameTh}
                      invalid={
                        (validation.touched.nameTh &&
                          validation.errors.nameTh
                          ? true
                          : false) || errDupField.nameTh
                      }
                      disabled={pageView === "view"}
                    />
                    {validation.touched.nameTh &&
                      validation.errors.nameTh && (
                        <FormFeedback type="invalid">
                          {validation.errors.nameTh}
                        </FormFeedback>
                      )}
                    {errDupField.nameTh && (
                      <div
                        type="invalid"
                        className="invalid-feedback d-block"
                      >
                        Field Category Name TH exist
                      </div>
                    )}
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Category Name EN
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="nameEn"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="nameEn"
                      onBlur={validation.handleBlur}
                      onChange={e => {
                        validation.setFieldValue(
                          "nameEn",
                          e.target.value
                        )
                        checkFieldDup("nameEn", e.target.value)
                      }}
                      value={validation.values.nameEn}
                      invalid={
                        (validation.touched.nameEn &&
                          validation.errors.nameEn
                          ? true
                          : false) || errDupField.nameEn
                      }
                      disabled={pageView === "view"}
                    />
                    {validation.touched.nameEn &&
                      validation.errors.nameEn && (
                        <FormFeedback type="invalid">
                          {validation.errors.nameEn}
                        </FormFeedback>
                      )}
                    {errDupField.nameEn && (
                      <div
                        type="invalid"
                        className="invalid-feedback d-block"
                      >
                        Field Category Name EN exist
                      </div>
                    )}
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Description
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="description"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="description"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.description}
                      invalid={
                        validation.touched.description &&
                          validation.errors.description
                          ? true
                          : false
                      }
                      disabled={pageView === "view"}
                    />
                    {validation.touched.description &&
                      validation.errors.description && (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      )}
                  </div>
                </Col>

                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Status (Active/Inactive)
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Select
                      placeholder="Select Status"
                      value={fn_helper.FNFORM.getObjectValue(
                        STATUS_OPTION,
                        `${validation.values.status}`
                      )}
                      onChange={value =>
                        validation.setFieldValue(
                          "status",
                          value.id
                        )
                      }
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.name}
                      options={STATUS_OPTION}
                      className={`select2-selection ${validation.touched.status &&
                        validation.errors.status
                        ? "input-err"
                        : ""
                        }`}
                      isDisabled={pageView === "view"}
                    />
                    {validation.touched.status &&
                      validation.errors.status && (
                        <div
                          type="invalid"
                          className="invalid-feedback d-block"
                        >
                          {validation.errors.status}
                        </div>
                      )}
                    {validation.touched.status &&
                      validation.errors.status && (
                        <FormFeedback type="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col className="col-6">
              <Row className="">
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    URL Key
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoUrlKey"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoUrlKey"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoUrlKey}
                      invalid={
                        validation.touched.seoUrlKey &&
                          validation.errors.seoUrlKey
                          ? true
                          : false
                      }
                      disabled={pageView === "view"}
                    />
                    {validation.touched.seoUrlKey &&
                      validation.errors.seoUrlKey && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoUrlKey}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta Title
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaTitle"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaTitle"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaTitle}
                      invalid={
                        validation.touched.seoMetaTitle &&
                          validation.errors.seoMetaTitle
                          ? true
                          : false
                      }
                      disabled={pageView === "view"}
                    />
                    {validation.touched.seoMetaTitle &&
                      validation.errors.seoMetaTitle && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaTitle}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta keyword
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaKeyword"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaKeyword"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaKeyword}
                      invalid={
                        validation.touched.seoMetaKeyword &&
                          validation.errors.seoMetaKeyword
                          ? true
                          : false
                      }
                      disabled={pageView === "view"}
                    />
                    {validation.touched.seoMetaKeyword &&
                      validation.errors.seoMetaKeyword && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaKeyword}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta Description
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaDescription"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaDescription"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaDescription}
                      invalid={
                        validation.touched.seoMetaDescription &&
                          validation.errors.seoMetaDescription
                          ? true
                          : false
                      }
                      disabled={pageView === "view"}
                    />
                    {validation.touched.seoMetaDescription &&
                      validation.errors.seoMetaDescription && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaDescription}
                        </FormFeedback>
                      )}
                  </div>
                </Col>

              </Row>
            </Col>

          </Row>

        </Col>

      </>,
    },
    {
      stepLabel: 'Category Type',
      components: <>
        <Col className="col-12">
          <Row className="">
            <Col className="col-12">
              <Col className="col-12 mb-3">
                <InputRadio
                  label="Category Type"
                  name="cateType"
                  value={validation.values.cateType}
                  option={CATE_TYPE_OPTION}
                  onChange={value => {
                    if (pageView !== "update") {
                      validation.setFieldValue("cateType", value)
                    }

                  }}
                  disabled={pageView === "view" || pageView === "update"}
                />
              </Col>
            </Col>
          </Row>

          <Row className="">
            {validation.values.cateType === 1 && (
              <Col className="col-12 mb-3">
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  Display on Mega Menu
                </label>
                <InputSwitch
                  labelTrue="Yes"
                  labelFalse="No"
                  value={validation.values.isMegamenu == "1"}
                  onChange={value => {
                    const updateValue = value ? "1" : "0"
                    validation.setFieldValue(
                      "isMegamenu",
                      updateValue
                    )
                  }}
                  disabled={pageView === "view"}
                />
              </Col>

            )}
            {validation.values.cateType === 2 && (
              <Row className="">
                <Col className="col-6">
                  <Col className="col-12 mb-3">
                    <label
                      htmlFor="category_name"
                      className="col-md-12 col-form-label"
                    >
                      Is Relate Bank
                    </label>
                    <InputSwitch
                      labelTrue="Yes"
                      labelFalse="No"
                      value={
                        validation.values.isRelateBank == "1"
                      }
                      onChange={value => {
                        const updateValue = value ? "1" : "0"
                        validation.setFieldValue(
                          "isRelateBank",
                          updateValue
                        )
                      }}
                      disabled={pageView === "view"}
                    />
                  </Col>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Promotion Banner Embed Link
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="embedLink"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="embedLink"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.embedLink}
                      invalid={
                        validation.touched.embedLink &&
                          validation.errors.embedLink
                          ? true
                          : false
                      }
                      disabled={pageView === "view"}
                    />
                    {validation.touched.embedLink &&
                      validation.errors.embedLink && (
                        <FormFeedback type="invalid">
                          {validation.errors.embedLink}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
                <Col className="col-12">
                  <Col className="col-12 mb-3">
                    <div className="col-md-12">
                      <UploadFileAPI
                        bucketName="promotion-category"
                        upload="Image"
                        typeUpload="ImageMap"
                        prefixName="promotion-category-image"
                        label="Banner Category Image"
                        required={true}
                        widthSize={1224}
                        heightSize={300}
                        description="description"
                        value={validation.values.imageBanner}
                        isDisabled={pageView === "view"}
                        onChange={([
                          imageURL = "",
                          imageSize = "",
                        ]) => {
                          validation.setFieldValue(
                            "imageBanner",
                            imageURL
                          )
                        }}
                      />
                      <Input
                        id="imageBanner"
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="imageBanner"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.imageBanner}
                        invalid={
                          validation.touched.imageBanner &&
                            validation.errors.imageBanner
                            ? true
                            : false
                        }
                        readOnly={true}
                        disabled={pageView === "view"}
                      />
                      {validation.touched.imageBanner &&
                        validation.errors.imageBanner && (
                          <div
                            type="invalid"
                            className="invalid-feedback d-block"
                          >
                            {validation.errors.imageBanner}
                          </div>
                        )}
                    </div>
                  </Col>
                </Col>
                <Col className="col-6">
                  <Col className="col-12 mb-3">
                    <label
                      htmlFor="category_name"
                      className="col-md-12 col-form-label"
                    >
                      Title
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="title"
                        className="form-control"
                        type="text"
                        placeholder="Enter Your Title"
                        name="title"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title}
                        invalid={
                          validation.touched.title &&
                            validation.errors.title
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.title &&
                        validation.errors.title && (
                          <FormFeedback type="invalid">
                            {validation.errors.title}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <label
                      htmlFor="category_name"
                      className="col-md-12 col-form-label"
                    >
                      Short Description
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="shortDescription"
                        className="form-control"
                        type="text"
                        placeholder="Enter Your Short Description"
                        name="shortDescription"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.shortDescription
                        }
                        invalid={
                          validation.touched
                            .shortDescription &&
                            validation.errors.shortDescription
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.shortDescription &&
                        validation.errors
                          .shortDescription && (
                          <FormFeedback type="invalid">
                            {
                              validation.errors
                                .shortDescription
                            }
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col className="col-12 mb-3">
                    <label
                      htmlFor="category_name"
                      className="col-md-12 col-form-label"
                    >
                      Short Selection
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="shortSelection"
                        className="form-control"
                        type="text"
                        placeholder="Enter Your Short Description"
                        name="shortSelection"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.shortSelection
                        }
                        invalid={
                          validation.touched.shortSelection &&
                            validation.errors.shortSelection
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.shortSelection &&
                        validation.errors.shortSelection && (
                          <FormFeedback type="invalid">
                            {validation.errors.shortSelection}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Col>
              </Row>
            )}
            {validation.values.cateType === 3 && (
              <Row className="">
                <Col className="col-12">
                  <Row className="">
                    <Col className="col-6">
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Parent Category
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Parent Category"
                            value={fn_helper.FNFORM.getObjectValue(
                              ddPromotionCategory,
                              `${validation.values.parentId}`
                            )}
                            onChange={value => {
                              validation.setFieldValue(
                                "parentId",
                                value.id
                              )
                            }}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            options={ddPromotionCategory}
                            className={`select2-selection ${validation.touched.parentId &&
                              validation.errors.parentId
                              ? "input-err"
                              : ""
                              }`}
                            isDisabled={["view"].includes(pageView)}
                          />
                          {validation.touched.parentId &&
                            validation.errors.parentId && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.parentId}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Col>

                    <Col className="col-12">
                      <Col className="col-12 mb-3">
                        <div className="col-md-12">
                          <UploadFileAPI
                            bucketName="promotion-category"
                            upload="Image"
                            typeUpload="ImageMap"
                            prefixName="promotion-category-image"
                            label="Logo Image"
                            required={true}
                            widthSize={42}
                            heightSize={24}
                            description="description"
                            value={validation.values.image}
                            onChange={([
                              imageURL = "",
                              imageSize = "",
                            ]) => {
                              validation.setFieldValue(
                                "image",
                                imageURL
                              )
                            }}
                          />
                          <Input
                            id="image"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="image"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.image}
                            invalid={
                              validation.touched.image &&
                                validation.errors.image
                                ? true
                                : false
                            }
                            readOnly={true}
                            disabled={pageView === "view"}
                          />
                          {validation.touched.image &&
                            validation.errors.image && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.image}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Col>

                    {/* {validation.values.isRelateBank == '1' ? (

                    ) : (<></>)} */}
                  </Row>
                </Col>
              </Row>

            )}
          </Row>

        </Col>
      </>
    }
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Promotion Category`}
            breadcrumbItems={[
              { title: "Promotion Category", link: "article/category" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>


                    <Row className="mb-3">
                      <h5 className="mb-3">Promotion Category</h5>
                      <Stepper
                        value={activeTab}
                        componentSteps={STEP_CONTENT}
                      />
                    </Row>

                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                      <button type="button" className="btn btn-primary w-md"
                        onClick={() => { setActiveTab(activeTab - 1) }}
                        disabled={activeTab === 0}
                      >
                        Previous
                      </button>
                      {
                        activeTab === 0 && (
                          <>
                            {
                              pageView == "view"
                                ? <><button className="btn btn-primary w-md" onClick={() => navigate("/promotion/category")}>BACK</button></>
                                : <>
                                  <button type="button" className="btn btn-primary w-md" onClick={() => onStep1()} >Next</button>
                                  <button className="btn btn-secondary w-md" onClick={() => navigate("/promotion/category")}>CANCEL</button>
                                </>
                            }
                          </>

                        )
                      }
                      {
                        activeTab === 1 && (
                          <>
                            {
                              pageView == "view"
                                ? <><button className="btn btn-primary w-md" onClick={() => navigate("/promotion/category")}>BACK</button></>
                                : <>
                                  <button type="submit" className="btn btn-primary w-md">SAVE</button>
                                  <button className="btn btn-secondary w-md" onClick={() => navigate("/promotion/category")}>CANCEL</button>
                                </>
                            }
                          </>
                        )
                      }

                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// PromotionCategoryTemplate.propTypes = {
//     t: PropTypes.any,
// };
export default withTranslation()(PromotionCategoryTemplate)
