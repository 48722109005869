import React from "react"
import _ from "lodash"
import Template1 from "./Template1"
import Template2 from "./Template2"
import Template3 from "./Template3"
import Template4 from "./Template4"
import Template5 from "./Template5"
import Template6 from "./Template6"
import Template7 from "./Template7"
import Template8 from "./Template8"

const TemplateView = ({
  useTemplateName = '',
  mini = false
}) => {

  return (
    <section id="pPartnerTemplate" style={mini ? { zoom: 0.1, width: '60%' } : {} }>
      <Template1 useTemplateName={useTemplateName} viewmode={true}/>
      <Template2 useTemplateName={useTemplateName} viewmode={true}/>
      <Template3 useTemplateName={useTemplateName} viewmode={true}/>
      <Template4 useTemplateName={useTemplateName} viewmode={true}/>
      <Template5 useTemplateName={useTemplateName} viewmode={true}/>
      <Template6 useTemplateName={useTemplateName} viewmode={true}/>
      <Template7 useTemplateName={useTemplateName} viewmode={true}/>
      <Template8 useTemplateName={useTemplateName} viewmode={true}/>
    </section>
  )
}

export default TemplateView
