import { get, post, put, patch } from "../Config"

export function SearchEMagazine({ params = {} }) {
  return get({ url: `/v1/admin/magazine`, params })
}
export function GetEMagazineById({ id }) {
  return get({ url: `/v1/admin/magazine/${id}` })
}
export function CreateEMagazine({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/magazine`, params, data })
}
export function UpdateEMagazine({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/magazine/${id}`, params, data })
}
export function PatchEMagazine({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/magazine/${id}`, params, data })
}
export function DeleteEMagazine({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/magazine/mutiDelete`, params, data })
}
