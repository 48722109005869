import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback, useContext } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Badge,
} from "reactstrap"

import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { withTranslation } from "react-i18next";

import { get } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";
import "../../components/Common/datatables.scss"
import Spinners from "components/Common/Spinner";
import { DeleteOrderReward, PatchOrderReward, SearchOrderReward } from "services/api/module/OrderReward";

import ModalContext from "../../contexts/ModalContext";
import moment from "moment";
import InputSwitch from "components/Input/InputSwitch";

const RowStatus = ({ status }) => {
    switch (String(status)) {
        case "0":
            return <Badge className="bg-danger">InActive</Badge>;
        case "1":
            return <Badge className="bg-success">Active</Badge>;
        default:
            return <Badge className="bg-success">{status}</Badge>;
    }
};

const OrderRewardList = props => {
    // POPUP
    const { mAlert } = useContext(ModalContext);

    // OTHER STATE
    const { categoryName } = useParams();
    const navigate = useNavigate();

    // LOCAL STATE
    const [contentList, setContentList] = useState([])
    const [contentListLoading, setContentListLoading] = useState(false)
    const [selectedContentId, setSelectedContentId] = useState([])

    // OPTION STATE
    const REWARD_OPTION = [
        {
            id: null,
            name: ''
        },
        {
            id: 0,
            name: 'Pending'
        },
        {
            id: 1,
            name: 'Approve'
        },
        {
            id: 2,
            name: 'Reject'
        }
    ]
    const ORDER_OPTION = [
        {
            id: null,
            name: ''
        },
        {
            id: 0,
            name: 'รอชำระเงิน'
        },
        {
            id: 1,
            name: 'ที่ต้องจัดส่ง'
        },
        {
            id: 2,
            name: 'ที่ต้องได้รับ'
        },
        {
            id: 3,
            name: 'สำเร็จ'
        },
        {
            id: 4,
            name: 'ยกเลิกคำสั่ง'
        }
    ]


    const onDeleteRow = (ids = [], name = '') => {
        // alert('onDeleteRow'+ JSON.stringify(ids))
        mAlert.info({
            type: "confrim",
            title: "Alert Confrim",
            subTitle: `Are you sure Delete ${name} ?`,
            mode: "red",
            content: "",
            onClose: () => { },
            onYes: () => {
                const payload = { orderRewardIds: ids };
                API.fetchDeleteContentList(payload)
            },
            onNo: () => { console.log("Cancel") },
        })
    }
    const onPatchRow = (id = 0, fieldName = '', value = null) => {
        const payload = { id: id, fieldName: fieldName, value: value };
        API.fetchPatchContent(payload)
    }

    const onSelectionChange = (selectedValue = {}, oldValues = [], option = [], mode = "") => {
        const selectedId = selectedValue?.id
        const tmpOldValues = _.cloneDeep(oldValues)
        const findOldValue = tmpOldValues.filter(id => id === selectedId)

        let updateState = []
        if (findOldValue && findOldValue.length) {
            updateState = tmpOldValues.filter(id => id != selectedId)
        } else {
            updateState = tmpOldValues
            updateState.push(selectedId)
        }

        if (mode === "all") {
            if (tmpOldValues.length >= option.length) {
                updateState = []
            } else {
                updateState = option.map(item => item.id)
            }
        }
        console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
        return updateState
    };

    const columns = useMemo(
        () => [
            {
                header: 'Created At',
                accessorKey: 'createdAt',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') : ''
                }
            },
            {
                header: 'Order Number',
                accessorKey: 'orderHistoryOrderNumber',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Tracking Number',
                accessorKey: 'orderHistoryTrackingNumber',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Reward Status',
                accessorKey: 'status',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return REWARD_OPTION.find(({ id }) => id == row.status).name

                }
            },
            {
                header: 'Order Status',
                accessorKey: 'orderHistoryOrderStatus',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return ORDER_OPTION.find(({ id }) => id == row.orderHistoryOrderStatus).name

                }
            },
            {
                header: 'Shipment Status',
                accessorKey: '',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'First Name',
                accessorKey: 'orderHistoryUserFirstname',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Last Name',
                accessorKey: 'orderHistoryUserLastname',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Used Point',
                accessorKey: 'usedPoint',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Product Code',
                accessorKey: 'rewardProductCode',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Reward Name',
                accessorKey: 'rewardNameTh',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Quantity',
                accessorKey: 'quantity',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Recipient Name',
                accessorKey: 'orderHistoryFirstName',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Recipient Lastname',
                accessorKey: 'orderHistoryLastName',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Mobile Number',
                accessorKey: 'orderHistoryMobileNo',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Address',
                accessorKey: 'orderHistoryAddress',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Tombon',
                accessorKey: 'orderHistoryTombonNameTh',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Amphure',
                accessorKey: 'orderHistoryAmphureNameTh',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Province',
                accessorKey: 'orderHistoryProvinceNameTh',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Postcode',
                accessorKey: 'orderHistoryTombonPostcode',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Tools',
                accessorKey: 'user_id',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    let { id } = cellProps?.row?.original ?? {};
                    return (
                        <>
                            {checkScope('systemsettings:usersetting:view') && <Link className={`btn btn-info`} to={`/reward/order-reward/view/${id}`}><i className={`bx bx-show-alt`}></i></Link>}
                        </>
                    )
                }
            },
        ],
        [selectedContentId, contentList]
    );

    const API = {
        fetchContentList: async () => {
            try {
                const response = await SearchOrderReward({})
                const resData = response?.data ?? []
                setContentList(resData.data);
            } catch (e) {
                console.log(e)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                        //  alert("onClose")
                    },
                })
            }
        },
        fetchPatchContent: async (payload) => {
            try {
                const response = await PatchOrderReward({ data: payload, id: payload.id })
                const resData = response.data
                if (resData.status === 201) {
                    mAlert.info({
                        title: "Alert",
                        subTitle: "Update Data Success",
                        content: "",
                        onClose: () => {
                            fetchAPI()
                        },
                    })
                }
            }
            catch (err) {
                console.log('err ', err)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                        //  alert("onClose")
                    },
                })
            }
        },
        fetchDeleteContentList: async (payload) => {
            try {
                const response = await DeleteOrderReward({ data: payload })
                const resData = response.data
                if (resData.status === 201) {
                    mAlert.info({
                        title: "Alert",
                        subTitle: "Delete Data Success",
                        content: "",
                        onClose: () => {
                            fetchAPI()
                        },
                    })
                }
            }
            catch (err) {
                console.log('err ', err)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                        //  alert("onClose")
                    },
                })
            }
        }

    }

    const fetchAPI = () => {
        const payload = {}
        API.fetchContentList(payload)
    }

    useEffect(() => {
        setContentListLoading(true)
        fetchAPI()
    }, []);

    useEffect(() => {
        if (contentList.length) {
            setContentListLoading(false)
        }
    }, [contentList])

    //meta title
    document.title = "Reward | " + process.env.REACT_APP_CMS_TITLE;

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Reward" breadcrumbItem="Reward Transaction" />

                {
                    contentListLoading ? <Spinners setLoading={setContentListLoading} />
                        :
                        <TableContainer
                            isAddButton={false}
                            columns={columns}
                            data={contentList || []}
                            isGlobalFilter={true}
                            isPagination={true}
                            SearchPlaceholder="Search"
                            pagination="pagination"
                            paginationWrapper='dataTables_paginate paging_simple_numbers'
                            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                            rootMenu="systemsettings"
                            subMenu="usersetting"
                            isCustomPageSize={true}
                            isSelected={selectedContentId.length > 0}
                            onClearSelected={() => setSelectedContentId([])}
                            onDeleleSelected={() => onDeleteRow(_.cloneDeep(selectedContentId))}
                            isExportButton={true}
                            exportOption={{
                                filename: "orderReward-platform",
                                dateColumns: []
                            }}

                        />
                }
            </div>
        </div>
    );
}

OrderRewardList.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(OrderRewardList)
