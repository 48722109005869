import { get, post, put, patch } from "../Config"

export function SearchBankPaymentAccount({ params = {} }) {
  return get({ url: `/v1/admin/bankPaymentAccount`, params })
}
export function GetBankPaymentAccountById({ id }) {
  return get({ url: `/v1/admin/bankPaymentAccount/${id}` })
}
export function CreateBankPaymentAccount({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bankPaymentAccount`, params, data })
}
export function UpdateBankPaymentAccount({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/bankPaymentAccount/${id}`, params, data })
}
export function PatchBankPaymentAccount({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/bankPaymentAccount/${id}`, params, data })
}
export function DeleteBankPaymentAccount({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bankPaymentAccount/mutiDelete`, params, data })
}
