import React, { useEffect, useState, useContext, useRef  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../../store/actions"


//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import { useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import { CreateJobApplication, GetJobApplicationById, UpdateJobApplication } from "services/api/module/JobApplication"
import ModalContext from "../../../contexts/ModalContext";
import { DropdownJobType, DropdownJobPosition } from "services/api/module/Dropdown"
import Flatpickr from "react-flatpickr";
import moment from "moment"
import InputRadio from "components/Input/InputRadio"
import Spinners from "components/Common/Spinner";

const WORK_PATTERN_OPTION = fn_helper.OPTIONS.WORK_PATTERN_OPTION

const numComma = (value) => fn_helper.FN.toNumberWithCommas(`${value}`)
const toDateStr = (value) => moment(value).format('DD/MM/YYYY')

const CONSENT_OPTION = [
  { id: 'false', name: "รอตรวจสอบ" },
  { id: 'true', name: "ตรวจสอบแล้ว" },
]

const STEP_JOB_OPTION = [
  { id: 1, name: 'รอสัมภาษณ์' },
  { id: 2, name: 'ผ่าน' },
  { id: 3, name: 'ไม่ผ่าน' },
]

const JobApplicationPrint = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qJobApplicationId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    isConsent: Yup.boolean().required("Please Enter Consent Status"),
    firstInterview: Yup.string().max(100).required("Please Enter FirstInter View"),
    secondInterview: Yup.string().max(100).required("Please Enter SecondInter View"),
    thirdInterview: Yup.string().max(100).required("Please Enter ThirdInter View"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddJobPosition, setDDJobPosition] = useState([])
  const [ddJobType, setDDJobType] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)
  const [ddLoaded1, setDDLoaded1] = useState(DEF_LOADED)
  const [formLoaded, setFormLoaded] = useState(false)
  const prevSelectDDJobTypeRef = useRef();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      
      // ลักษณะงานที่ต้องการ
      FROM1_jobTypeName: "",
      FROM1_jobPositionName: "",
      FROM1_jobPositionWorkPattern: "",
      FROM1_expectSalary: "",
      FROM1_startDate: "",

      // ประวัติส่วนตัว
      FROM1_prefixName: "",
      FROM1_fullName: "",
      FROM1_idCard: "",
      FROM1_gender: "",
      FROM1_birthDate: "",
      FROM1_age: "",
      FROM1_ethnicity: "",
      FROM1_nationality: "",
      FROM1_religion: "",
      FROM1_provinceOfBirthName: "",

      // ที่อยู่ปัจจุบัน
      FROM2_address: "",
      FROM2_provinceName: "",
      FROM2_amphureName: "",
      FROM2_tombonName: "",
      FROM2_postcode: "",
      FROM2_contactMobileNo: "",
      FROM2_contactEmail: "",
      FROM2_contactLineId: "",

      // สถานภาพ
      FROM1_maritalStatus: "",
      FROM1_maritalName: "",
      FROM1_maritalJob: "",
      FROM1_maritalCompany: "",
      FROM1_numberOfChild: "",
      FROM1_numberOfChildStudy: "",
      FROM1_militaryStatus: "",

      // ข้อมูลผู้ติดต่อฉุกเฉิน
      FROM1_eContactName: "",
      FROM1_eContactRelation: "",
      FROM1_eContactMobileNo: "",
      FROM1_eContactAddress: "",
      FROM1_eContactProvince: "",
      FROM1_eContactAmphure: "",
      FROM1_eContactTombon: "",
      FROM1_eContactPostcode: "",

      // ประวัติการศึกษา
      FROM3_degreeHighest: "",
      FROM3_university: "",
      FROM3_department: "",
      FROM3_graduateYear: "",
      FROM3_gpax: "",

      // ประวัติการฝึกอบรม
      FROM3_trainingHistory: [],

      // ประวัติการทำงาน
      FROM3_workHistory: [],

      // ทักษะความสามารถพิเศษ

      // ทักษะด้านภาษา
      FROM4_thListeningSkill: "",
      FROM4_thSpeakingSkill: "",
      FROM4_thReadingSkill: "",
      FROM4_thWritingSkill: "",
      FROM4_enListeningSkill: "",
      FROM4_enSpeakingSkill: "",
      FROM4_enReadingSkill: "",
      FROM4_enWritingSkill: "",

      // ทักษะด้านคอมพิวเตอร์
      FROM4_computerSkillExcel: "",
      FROM4_computerSkillWord: "",
      FROM4_computerSkillWindows: "",
      FROM4_computerSkillOther: "",

      // ทักษะด้านการพิมพ์
      FROM4_typingThai: "",
      FROM4_typingEng: "",

      // ความสามารถในการขับรถ
      FROM4_canDriveCar: "",
      FROM4_carLicense: "",
      FROM4_canDriveMotercycle: "",
      FROM4_motercycleLicense: "",
      FROM4_otherSkill: "",

      // เกี่ยวกับ JIB
      FROM1_jibChannel: "",
      FROM1_jibPersonName: "",
      FROM1_jibPersonPosition: "",
      FROM1_jibPersonRelation: "",

      // การประเมินหลังสัมภาษณ์
      isConsent: 'false',
      firstInterview: "",
      secondInterview: "",
      thirdInterview: "",

    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ JobApplicationCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        isConsent: values.isConsent === 'true' ? true : false,
        firstInterview: values.firstInterview,
        secondInterview: values.secondInterview,
        thirdInterview: values.thirdInterview,
        
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateJobApplication(payload)
      } else {
        API.fetchCreateJobApplication(payload)
      }
    },
  })

  // SELECT DATA
  const selectedDDJobType = ddJobType.find(item => item.id == validation.values.articleKeywordIds)

  const preFilInput = (resData = {}) => {
    console.log("🚀 ~ preFilInput ~ resData:", resData)
    const workPatternName = WORK_PATTERN_OPTION.find(item => item.id === resData.application.jobPositionWorkPattern)?.name ?? ''

    // warpPUI
    validation.setFieldValue("id", resData.application.id)

    // ลักษณะงานที่ต้องการ
    validation.setFieldValue("FROM1_jobTypeName", numComma(resData.application.jobTypeName))
    validation.setFieldValue("FROM1_jobPositionName", numComma(resData.application.jobPositionName))
    validation.setFieldValue("FROM1_jobPositionWorkPattern", numComma(workPatternName))
    validation.setFieldValue("FROM1_expectSalary", numComma(resData.application.expectSalary))
    validation.setFieldValue("FROM1_startDate", toDateStr(resData.application.startDate))

    // ประวัติส่วนตัว
    validation.setFieldValue("FROM1_prefixName", resData.profile.prefixName)
    validation.setFieldValue("FROM1_fullName", resData.profile.firstname + ' ' + resData.profile.lastname)
    validation.setFieldValue("FROM1_idCard", resData.profile.idCard)
    validation.setFieldValue("FROM1_gender", resData.profile.gender)
    validation.setFieldValue("FROM1_birthDate", toDateStr(resData.profile.birthDate))
    validation.setFieldValue("FROM1_age", resData.profile.age)
    validation.setFieldValue("FROM1_ethnicity", resData.profile.ethnicity)
    validation.setFieldValue("FROM1_nationality", resData.profile.nationality)
    validation.setFieldValue("FROM1_religion", resData.profile.religion)
    validation.setFieldValue("FROM1_provinceOfBirthName", resData.profile.provinceOfBirth)

    // ที่อยู่ปัจจุบัน
    validation.setFieldValue("FROM2_address", resData.contactAddress.address)
    validation.setFieldValue("FROM2_provinceName", resData.contactAddress.province)
    validation.setFieldValue("FROM2_amphureName", resData.contactAddress.amphure)
    validation.setFieldValue("FROM2_tombonName", resData.contactAddress.tombon)
    validation.setFieldValue("FROM2_postcode", resData.contactAddress.postcode)
    validation.setFieldValue("FROM2_contactMobileNo", resData.contactAddress.contactMobileNo)
    validation.setFieldValue("FROM2_contactEmail", resData.contactAddress.contactEmail)
    validation.setFieldValue("FROM2_contactLineId", resData.contactAddress.contactLineId)

    // สถานภาพ
    validation.setFieldValue("FROM1_maritalStatus", resData.profile.maritalStatus)
    validation.setFieldValue("FROM1_maritalName", resData.profile.maritalName)
    validation.setFieldValue("FROM1_maritalJob", resData.profile.maritalJob)
    validation.setFieldValue("FROM1_maritalCompany", resData.profile.maritalCompany)
    validation.setFieldValue("FROM1_numberOfChild", resData.profile?.numberOfChild ?? '0')
    validation.setFieldValue("FROM1_numberOfChildStudy", resData.profile?.numberOfChildStudy ?? '0')
    validation.setFieldValue("FROM1_militaryStatus", resData.profile.militaryStatus)

    // ข้อมูลผู้ติดต่อฉุกเฉิน
    validation.setFieldValue("FROM1_eContactName", resData.contactAddress.eContactName)
    validation.setFieldValue("FROM1_eContactRelation", resData.contactAddress.eContactRelation)
    validation.setFieldValue("FROM1_eContactMobileNo", resData.contactAddress.eContactMobileNo)
    validation.setFieldValue("FROM1_eContactAddress", resData.contactAddress.eContactAddress)
    validation.setFieldValue("FROM1_eContactProvince", resData.contactAddress.eContactProvince)
    validation.setFieldValue("FROM1_eContactAmphure", resData.contactAddress.eContactAmphure)
    validation.setFieldValue("FROM1_eContactTombon", resData.contactAddress.eContactTombon)
    validation.setFieldValue("FROM1_eContactPostcode", resData.contactAddress.eContactPostcode)

    // ประวัติการศึกษา
    validation.setFieldValue("FROM3_degreeHighest", resData.educationHistory.degreeHighest)
    validation.setFieldValue("FROM3_university", resData.educationHistory.university)
    validation.setFieldValue("FROM3_department", resData.educationHistory.department)
    validation.setFieldValue("FROM3_graduateYear", resData.educationHistory.graduateYear)
    validation.setFieldValue("FROM3_gpax", resData.educationHistory.gpax ? Number(`${resData.educationHistory.gpax}`).toFixed(2) : 0)

    // ประวัติการฝึกอบรม
    validation.setFieldValue("FROM3_trainingHistory", resData.trainingHistory)

    // ประวัติการทำงาน
    validation.setFieldValue("FROM3_workHistory", resData.workHistory)

    // ทักษะความสามารถพิเศษ

    // ทักษะด้านภาษา
    validation.setFieldValue("FROM4_thListeningSkill", resData.skill.thListeningSkill)
    validation.setFieldValue("FROM4_thSpeakingSkill", resData.skill.thSpeakingSkill)
    validation.setFieldValue("FROM4_thReadingSkill", resData.skill.thReadingSkill)
    validation.setFieldValue("FROM4_thWritingSkill", resData.skill.thWritingSkill)
    validation.setFieldValue("FROM4_enListeningSkill", resData.skill.enListeningSkill)
    validation.setFieldValue("FROM4_enSpeakingSkill", resData.skill.enSpeakingSkill)
    validation.setFieldValue("FROM4_enReadingSkill", resData.skill.enReadingSkill)
    validation.setFieldValue("FROM4_enWritingSkill", resData.skill.enWritingSkill)

    // ทักษะด้านคอมพิวเตอร์
    validation.setFieldValue("FROM4_computerSkillExcel", resData.skill.computerSkillExcel)
    validation.setFieldValue("FROM4_computerSkillWord", resData.skill.computerSkillWord)
    validation.setFieldValue("FROM4_computerSkillWindows", resData.skill.computerSkillWindows)
    validation.setFieldValue("FROM4_computerSkillOther", resData.skill.computerSkillOther)

    // ทักษะด้านการพิมพ์
    validation.setFieldValue("FROM4_typingThai", resData.skill.typingThai)
    validation.setFieldValue("FROM4_typingEng", resData.skill.typingEng)

    // ความสามารถในการขับรถ
    validation.setFieldValue("FROM4_canDriveCar", resData.skill.canDriveCar)
    validation.setFieldValue("FROM4_carLicense", resData.skill.carLicense)
    validation.setFieldValue("FROM4_canDriveMotercycle", resData.skill.canDriveMotercycle)
    validation.setFieldValue("FROM4_motercycleLicense", resData.skill.motercycleLicense)
    validation.setFieldValue("FROM4_otherSkill", resData.skill.otherSkill)

    // เกี่ยวกับ JIB
    validation.setFieldValue("FROM1_jibChannel", resData.profile.jibChannel)
    validation.setFieldValue("FROM1_jibPersonName", resData.profile.jibPersonName)
    validation.setFieldValue("FROM1_jibPersonPosition", resData.profile.jibPersonPosition)
    validation.setFieldValue("FROM1_jibPersonRelation", resData.profile.jibPersonRelation)

        // เอกสารสมัครงาน
        validation.setFieldValue("FROM5_personalImage", resData.document.personalImage)
        validation.setFieldValue("FROM5_resume", resData.document.resume)

    // การประเมินหลังสัมภาษณ์
    validation.setFieldValue("isConsent", `${resData.application.isConsent}` === 'true' ? 'true' : 'false')
    validation.setFieldValue("firstInterview", resData.application.firstInterview)
    validation.setFieldValue("secondInterview", resData.application.secondInterview)
    validation.setFieldValue("thirdInterview", resData.application.thirdInterview)



    // ติด Effect Clear State
    // setTimeout(() => validation.setFieldValue("articleKeywordIds", resData.articleKeywordIds), 100)
  }

  const API = {
    fetchDDJobPosition: async () => {
      try {
          const response = await DropdownJobPosition({})
          const resData = response?.data ?? []
          const tmpDD = resData.data
          console.log("🚀 ~ fetchDDJobPosition: ~ tmpDD:", tmpDD)
          setDDJobPosition(tmpDD);
          setDDLoaded0(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchDDJobType: async () => {
      try {
          const response = await DropdownJobType({})
          const resData = response?.data ?? []
          const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          setDDJobType(tmpDD);
          setDDLoaded1(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchGetJobApplicationById: async (payload) => {
      try {
          const response = await GetJobApplicationById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
      finally {
        setFormLoaded(true)
      }
    },
    fetchCreateJobApplication: async (payload) => {
      try {
          const response = await CreateJobApplication({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/jobs-managment/job-application')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateJobApplication: async (payload) => {
      try {
          const response = await UpdateJobApplication({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/jobs-managment/job-application')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    const payload = { id : qJobApplicationId }
    API.fetchGetJobApplicationById(payload)
  }, [ddLoaded0, ddLoaded1])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    // API.fetchDDJobType();
    // API.fetchDDJobPosition();
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Job Application | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  if(formLoaded === false) {
    return <Spinners setLoading={setFormLoaded} />
  }
  return <div>print</div>
}

// JobApplicationPrint.propTypes = {
//     t: PropTypes.any,
// };
export default JobApplicationPrint
