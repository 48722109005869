import { get, post, put, patch } from "../Config"

export function SearchArticleKeyword({ params = {} }) {
  return get({ url: `/v1/admin/artlcleKeyword`, params })
}
export function GetArticleKeywordById({ id }) {
  return get({ url: `/v1/admin/artlcleKeyword/${id}` })
}
export function CreateArticleKeyword({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/artlcleKeyword`, params, data })
}
export function UpdateArticleKeyword({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/artlcleKeyword/${id}`, params, data })
}
export function PatchArticleKeyword({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/artlcleKeyword/${id}`, params, data })
}
export function DeleteArticleKeyword({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/artlcleKeyword/mutiDelete`, params, data })
}
export function CheckDuplicateArticleKeyword({ params = {}, data = {}}) {
  return post({ url: `/v1/admin/artlcleKeyword/duplicate`, params, data })
}
