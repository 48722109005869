import { get, post, put, patch } from "../Config"

export function SearchAttributes({ params = {} }) {
  return get({ url: `/v1/admin/attributes`, params })
}
export function GetAttributesById({ id }) {
  return get({ url: `/v1/admin/attributes/${id}` })
}
export function CreateAttributes({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/attributes`, params, data })
}
export function UpdateAttributes({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/attributes/${id}`, params, data })
}
export function PatchAttributes({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/attributes/${id}`, params, data })
}
export function DeleteAttributes({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/attributes/mutiDelete`, params, data })
}
