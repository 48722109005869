import React from "react";
import { Navigate } from "react-router-dom";

import * as url from 'helpers/url_helper'
import * as apiHelper from "helpers/api_helper"
import * as utility from "helpers/utility_helper"

const Authmiddleware = (props) => {

  if (!localStorage.getItem("cmsIsLogin")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
