import React, { useEffect, useState, useMemo, useContext, useRef } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
  CardHeader,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import Select from "react-select"

import ModalContext from "../../../contexts/ModalContext"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import fn_helper from "helpers/fn_helper"

import Sortable from "sortablejs"

import moment from "moment"

import {
  listPointType,
  createPointSpecialEventConfig,
  detailPointSpecialEventConfig,
  updatePointSpecialEventConfig,
} from "../../../services/api/module/Point"

const STATUS_OPTION = [
  { id: "inactive", name: "Inactive" },
  { id: "active", name: "Active" },
]

const PointSpecialEventConfigFormTemplatePage = ({ pageView = "create" }) => {
  /* Popup */
  const { mAlert } = useContext(ModalContext)
  /* End Popup */

  /* Navigate */
  const navigate = useNavigate()
  /* End Navigate */

  /* Api */
  const API = {
    createContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await createPointSpecialEventConfig({ data: payload })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/point/special-event-config")
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    detailContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await detailPointSpecialEventConfig({
          params: payload.params,
          id: payload.id,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 202) {
          _return.status = true
          _return.result = resData.data
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    updateContent: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await updatePointSpecialEventConfig({
          data: payload.data,
          id: payload.id,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/point/special-event-config")
            },
          })
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
    pointTypeData: async payload => {
      let _return = {
        status: false,
        result: null,
      }

      try {
        const response = await listPointType({
          params: payload,
        })
        const resData = response?.data ?? []
        // console.log("resData >>", resData)

        if (resData.status === 202) {
          _return.status = true
          _return.result = resData.data
        }
      } catch (error) {
        console.log("error", error)

        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            // alert("onClose")
          },
        })
      }

      return _return
    },
  }
  /* End Api */

  /* Form Validation */
  const [contentData, setContentData] = useState(null)
  const [selectPointType, setSelectPointType] = useState(null)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      pointSpecialEventConfigId:
        contentData !== null && contentData.id ? contentData.id : "",
      pointEventName:
        contentData !== null && contentData.pointEventName
          ? contentData.pointEventName
          : "",
      description:
        contentData !== null && contentData.description
          ? contentData.description
          : "",
      pointTypeId:
        contentData !== null && contentData.pointTypeId
          ? contentData.pointTypeId
          : null,
      startDate:
        contentData !== null && contentData.startDate !== null
          ? moment(contentData.startDate).format("YYYY-MM-DD HH:mm:ss")
          : "",
      endDate:
        contentData !== null && contentData.endDate !== null
          ? moment(contentData.endDate).format("YYYY-MM-DD HH:mm:ss")
          : "",
      pointExpiredAt:
        contentData !== null && contentData.pointExpiredAt !== null
          ? moment(contentData.pointExpiredAt).format("YYYY-MM-DD HH:mm:ss")
          : "",
      pointStartDay:
        contentData !== null && contentData.pointStartDay !== null
          ? contentData.pointStartDay
          : "",
      rateBaht:
        contentData !== null && contentData.rateBaht !== null
          ? contentData.rateBaht
          : "",
      ratePoint:
        contentData !== null && contentData.ratePoint !== null
          ? contentData.ratePoint
          : "",
      point:
        contentData !== null && contentData.point !== null
          ? contentData.point
          : "",
      status: contentData !== null ? contentData.status : "active",
    },
    validationSchema: Yup.object({
      pointEventName: Yup.string().required("Please Enter Event Name"),
      // description: Yup.string().required("Please Enter Description"),
      pointTypeId: Yup.string().required("Please Select Point Type"),
      rateBaht: Yup.number().when("pointTypeId", {
        is: value => value === "3",
        then: schema =>
          schema
            .required("Please Enter Rate Baht")
            .min(1, "Rate Baht must be at least 1"),
      }),
      ratePoint: Yup.number().when("pointTypeId", {
        is: value => value === "3",
        then: schema =>
          schema
            .required("Please Enter Rate Point")
            .min(1, "Rate Point must be at least 1"),
      }),
      point: Yup.number().when("pointTypeId", {
        is: value => value !== "3",
        then: schema =>
          schema
            .required("Please Enter Point")
            .min(1, "Point must be at least 1"),
      }),
      startDate: Yup.string().required("Please Enter Start Date"),
      endDate: Yup.string().required("Please Enter End Date"),
      pointExpiredAt: Yup.string().required("Please Enter Point Expired Date"),
      pointStartDay: Yup.number().required("Please Enter Point Start Day"),
      status: Yup.string().required("Please Select Status"),
    }),
    onSubmit: values => {
      console.log("🚀 ~ Survey ~ values:", values)
      let payload = {
        pointEventName: values.pointEventName,
        description: values.description,
        pointTypeId: values.pointTypeId,
        rateBaht: values.rateBaht !== "" ? values.rateBaht : null,
        ratePoint: values.ratePoint !== "" ? values.ratePoint : null,
        point: values.point !== "" ? values.point : null,
        startDate: values.startDate,
        endDate: values.endDate,
        pointExpiredAt: values.pointExpiredAt,
        pointStartDay: values.pointStartDay,
        status: values.status,
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.updateContent({
          data: payload,
          id: values.pointSpecialEventConfigId,
        })
      } else {
        API.createContent(payload)
      }
    },
  })

  useEffect(() => {
    if (contentData !== null) {
      setSelectPointType(`${contentData.pointTypeId}`)
    }
  }, [contentData])
  /* End Form Validation */

  /* First event render */
  const qParams = useParams()
  const qContentId = qParams.id

  const [pointTypeOption, setPointTypeOption] = useState(null)

  useEffect(() => {
    API.pointTypeData({}).then(_rs => {
      if (_rs.status === true) {
        if (_rs.result.length > 0) {
          setPointTypeOption(_rs.result)
        }
      }
    })

    if (["update", "view"].includes(pageView)) {
      API.detailContent({ params: {}, id: qContentId }).then(_rs => {
        // console.log(_rs)
        if (_rs.status === true) {
          setContentData(_rs.result)
        } else {
          navigate("/point/special-event-config")
        }
      })
    }
  }, [])

  // useEffect(() => {
  //   // console.log(
  //   //   "pointTypeOption >>",
  //   //   pointTypeOption,
  //   //   "selectPointType >>",
  //   //   selectPointType
  //   // )
  // }, [pointTypeOption, selectPointType])
  /* End First event render */

  /* Handle selcet point type */
  const handleSelectPointType = pointType => {
    // console.log(pointType)

    setSelectPointType(pointType.id)

    validation.setFieldValue("pointTypeId", pointType.id)
  }
  /* End Handle selcet point type */

  //meta title
  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `Point Special Event Config | ${PAGE_VIEW} | ` +
    process.env.REACT_APP_CMS_TITLE

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={`${PAGE_VIEW} Point Special Event Config`}
            breadcrumbItems={[
              {
                title: "Point Special Event Config",
                link: "point/special-event-config",
              },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row>
                      <div className="card-title">
                        Point Special Event Config Infomation
                      </div>
                      <p className="card-title-desc mb4">
                        Fill all information below
                      </p>
                    </Row>
                    <Row>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="pointEventName"
                          className="col-md-12 col-form-label"
                        >
                          Point Event Name
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="pointEventName"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="pointEventName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pointEventName}
                            invalid={
                              validation.touched.pointEventName &&
                              validation.errors.pointEventName
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.pointEventName &&
                            validation.errors.pointEventName && (
                              <FormFeedback type="invalid">
                                {validation.errors.pointEventName}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="description"
                          className="col-md-12 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="description"
                            className="form-control"
                            type="textarea"
                            rows="3"
                            placeholder=""
                            name="description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description}
                            invalid={
                              validation.touched.description &&
                              validation.errors.description
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.description &&
                            validation.errors.description && (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="pointTypeId"
                          className="col-md-12 col-form-label"
                        >
                          Point Type
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            name="pointTypeId"
                            placeholder="Select Point Type"
                            value={
                              pointTypeOption !== null &&
                              fn_helper.FNFORM.getObjectValue(
                                pointTypeOption,
                                validation.values.pointTypeId
                              )
                            }
                            onChange={handleSelectPointType}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.typeName}
                            options={pointTypeOption}
                            className={`select2-selection ${
                              validation.touched.pointTypeId &&
                              validation.errors.pointTypeId
                                ? "input-err"
                                : ""
                            }`}
                            isDisabled={pageView === "view"}
                          />
                          {validation.touched.pointTypeId &&
                            validation.errors.pointTypeId && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.pointTypeId}
                              </div>
                            )}
                          {validation.touched.pointTypeId &&
                            validation.errors.pointTypeId && (
                              <FormFeedback type="invalid">
                                {validation.errors.pointTypeId}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    {selectPointType && selectPointType !== 3 && (
                      <Row>
                        <Col className="col-6 mb-3">
                          <label
                            htmlFor="point"
                            className="col-md-12 col-form-label"
                          >
                            Point
                          </label>
                          <div className="col-md-12">
                            <Input
                              id="point"
                              className="form-control"
                              type="number"
                              placeholder=""
                              name="point"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.point}
                              invalid={
                                validation.touched.point &&
                                validation.errors.point
                                  ? true
                                  : false
                              }
                              disabled={pageView === "view"}
                              min="1"
                            />
                            {validation.touched.point &&
                              validation.errors.point && (
                                <FormFeedback type="invalid">
                                  {validation.errors.point}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      </Row>
                    )}
                    {selectPointType && selectPointType === 3 && (
                      <Row>
                        <Col className="col-6 mb-3">
                          <label
                            htmlFor="rateBaht"
                            className="col-md-12 col-form-label"
                          >
                            Rate Baht
                          </label>
                          <div className="col-md-12">
                            <Input
                              id="rateBaht"
                              className="form-control"
                              type="number"
                              placeholder=""
                              name="rateBaht"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.rateBaht}
                              invalid={
                                validation.touched.rateBaht &&
                                validation.errors.rateBaht
                                  ? true
                                  : false
                              }
                              disabled={pageView === "view"}
                              min="1"
                            />
                            {validation.touched.rateBaht &&
                              validation.errors.rateBaht && (
                                <FormFeedback type="invalid">
                                  {validation.errors.rateBaht}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                        <Col className="col-6 mb-3">
                          <label
                            htmlFor="ratePoint"
                            className="col-md-12 col-form-label"
                          >
                            Rate Point
                          </label>
                          <div className="col-md-12">
                            <Input
                              id="ratePoint"
                              className="form-control"
                              type="number"
                              placeholder=""
                              name="ratePoint"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.ratePoint}
                              invalid={
                                validation.touched.ratePoint &&
                                validation.errors.ratePoint
                                  ? true
                                  : false
                              }
                              disabled={pageView === "view"}
                              min="1"
                            />
                            {validation.touched.ratePoint &&
                              validation.errors.ratePoint && (
                                <FormFeedback type="invalid">
                                  {validation.errors.ratePoint}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="startDate"
                          className="col-md-12 col-form-label"
                        >
                          Start Date
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="startDate"
                            className="form-control"
                            type="datetime-local"
                            placeholder=""
                            name="startDate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.startDate}
                            invalid={
                              validation.touched.startDate &&
                              validation.errors.startDate
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.startDate &&
                            validation.errors.startDate && (
                              <FormFeedback type="invalid">
                                {validation.errors.startDate}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="endDate"
                          className="col-md-12 col-form-label"
                        >
                          End Date
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="endDate"
                            className="form-control"
                            type="datetime-local"
                            placeholder=""
                            name="endDate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.endDate}
                            invalid={
                              validation.touched.endDate &&
                              validation.errors.endDate
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.endDate &&
                            validation.errors.endDate && (
                              <FormFeedback type="invalid">
                                {validation.errors.endDate}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="pointExpiredAt"
                          className="col-md-12 col-form-label"
                        >
                          Point Expired Date
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="pointExpiredAt"
                            className="form-control"
                            type="datetime-local"
                            placeholder=""
                            name="pointExpiredAt"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pointExpiredAt}
                            invalid={
                              validation.touched.pointExpiredAt &&
                              validation.errors.pointExpiredAt
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.pointExpiredAt &&
                            validation.errors.pointExpiredAt && (
                              <FormFeedback type="invalid">
                                {validation.errors.pointExpiredAt}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="pointStartDay"
                          className="col-md-12 col-form-label"
                        >
                          Point Start Day
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="pointStartDay"
                            className="form-control"
                            type="number"
                            placeholder=""
                            name="pointStartDay"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pointStartDay}
                            invalid={
                              validation.touched.pointStartDay &&
                              validation.errors.pointStartDay
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                            min="0"
                          />
                          {validation.touched.pointStartDay &&
                            validation.errors.pointStartDay && (
                              <FormFeedback type="invalid">
                                {validation.errors.pointStartDay}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col className="col-3 mb-3">
                        <label
                          htmlFor="status"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            name="status"
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(
                              STATUS_OPTION,
                              `${validation.values.status}`
                            )}
                            onChange={value =>
                              validation.setFieldValue("status", value.id)
                            }
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${
                              validation.touched.status &&
                              validation.errors.status
                                ? "input-err"
                                : ""
                            }`}
                            isDisabled={pageView === "view"}
                          />
                          {validation.touched.status &&
                            validation.errors.status && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.status}
                              </div>
                            )}
                          {validation.touched.status &&
                            validation.errors.status && (
                              <FormFeedback type="invalid">
                                {validation.errors.status}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div
                  className="mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "8px",
                  }}
                >
                  {pageView == "view" ? (
                    <>
                      <button
                        className="btn btn-primary w-md"
                        onClick={() => navigate("/point/special-event-config")}
                      >
                        BACK
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="submit" className="btn btn-primary w-md">
                        SAVE
                      </button>
                      <button
                        className="btn btn-secondary w-md"
                        onClick={() => navigate("/point/special-event-config")}
                      >
                        CANCEL
                      </button>
                    </>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

PointSpecialEventConfigFormTemplatePage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(PointSpecialEventConfigFormTemplatePage)
