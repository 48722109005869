import React, { useState } from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx"
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { get } from 'helpers/api_helper';
import moment from 'moment';

export const ExportToExcel = ({ apiData, fileName }) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {

        let apiDataTZ = apiData.map(row => {
            return {
                ...row, create_date: moment(row.create_date).format("YYYY-MM-DD HH:mm:ss")
            }
        });

        const ws = XLSX.utils.json_to_sheet(apiDataTZ);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        setExportLoading(false)
    };

    const [ exportLoading, setExportLoading]  = useState()
    const handleExport = () => {
        setExportLoading(true)
        const exportExcel = async () => {
            const result = await get(`${process.env.REACT_APP_BACKEND_URL}/v1/admin/questionnaire/export`)
            console.log('result', result.data)
            exportToCSV(result.data, fileName)
        };
    
        exportExcel();
    } 

    return (
        <Link className={`btn btn-success`} onClick={handleExport}><i className='bx bx-export'></i> Export{exportLoading && <Spinner size="sm" className="ms-2" color="light" setLoading={setExportLoading} />}</Link>
    );
};