import { ChatwootWidget } from "components/ChatwootWidget";
import React, { useEffect, useState } from "react";
import withRouter from "components/Common/withRouter";
import { useParams } from "react-router-dom";

const QuestionnaireFrontweb = props => {
    const { questionnaireUUID } = useParams();
    return (
        <>
            <ChatwootWidget questionnaireUUID={questionnaireUUID} />
        </>
    );
}

export default withRouter(QuestionnaireFrontweb);