import {
    UPDATE_QUESTIONAIRE_CATEGORY,
    UPDATE_QUESTIONAIRE_CATEGORY_SUCCESSFUL,
    UPDATE_QUESTIONAIRE_CATEGORY_FAILED,
    UPDATE_QUESTIONAIRE_CATEGORY_RESET,
} from "./actionsType"

export const updateQuestionaireCategory = categoryData => {
    return {
        type: UPDATE_QUESTIONAIRE_CATEGORY,
        payload: { categoryData },
    }
}

export const updateQuestionaireCategorySuccessful = categoryData => {
    return {
        type: UPDATE_QUESTIONAIRE_CATEGORY_SUCCESSFUL,
        payload: categoryData,
    }
}

export const updateQuestionaireCategoryFailed = categoryData => {
    return {
        type: UPDATE_QUESTIONAIRE_CATEGORY_FAILED,
        payload: categoryData,
    }
}

export const updateQuestionaireCategoryReset = categoryData => {
    return {
        type: UPDATE_QUESTIONAIRE_CATEGORY_RESET,
        payload: { categoryData },
    }
}
