import {
    CREATE_QUESTIONAIRE_CATEGORY,
    CREATE_QUESTIONAIRE_CATEGORY_SUCCESSFUL,
    CREATE_QUESTIONAIRE_CATEGORY_FAILED,
    CREATE_QUESTIONAIRE_CATEGORY_RESET,
} from "./actionsType"

export const createQuestionaireCategory = categoryData => {
    return {
        type: CREATE_QUESTIONAIRE_CATEGORY,
        payload: { categoryData },
    }
}

export const createQuestionaireCategorySuccessful = categoryData => {
    return {
        type: CREATE_QUESTIONAIRE_CATEGORY_SUCCESSFUL,
        payload: categoryData,
    }
}

export const createQuestionaireCategoryFailed = categoryData => {
    return {
        type: CREATE_QUESTIONAIRE_CATEGORY_FAILED,
        payload: categoryData,
    }
}

export const createQuestionaireCategoryReset = categoryData => {
    return {
        type: CREATE_QUESTIONAIRE_CATEGORY_RESET,
        payload: { categoryData },
    }
}
