import {
    CREATE_QUESTIONAIRE_QUESTION,
    CREATE_QUESTIONAIRE_QUESTION_SUCCESSFUL,
    CREATE_QUESTIONAIRE_QUESTION_FAILED,
    CREATE_QUESTIONAIRE_QUESTION_RESET,
} from "./actionsType"

export const createQuestionaireQuestion = questionData => {
    return {
        type: CREATE_QUESTIONAIRE_QUESTION,
        payload: { questionData },
    }
}

export const createQuestionaireQuestionSuccessful = questionData => {
    return {
        type: CREATE_QUESTIONAIRE_QUESTION_SUCCESSFUL,
        payload: questionData,
    }
}

export const createQuestionaireQuestionFailed = questionData => {
    return {
        type: CREATE_QUESTIONAIRE_QUESTION_FAILED,
        payload: questionData,
    }
}

export const createQuestionaireQuestionReset = questionData => {
    return {
        type: CREATE_QUESTIONAIRE_QUESTION_RESET,
        payload: { questionData },
    }
}
