import React, { useState, useEffect, useRef } from "react";
// import { Dialog } from 'primereact/dialog';
import { Modal } from "reactstrap";

function DialogCustom({
  visible = false,
  style = { width: '342px' },
  onHide = () => console.log('onHide'),
  onClickOut = () => console.log('onClickOut'), 
  children
}) {


  return (
    <>
      <Modal
          isOpen={visible}
          scrollable
          size="lg"
          toggle={() => {
          }}
          backdrop={'static'}
          id="staticBackdrop"
      >
        {children}
      </Modal>
    </>
  )
  // return (
  //   <>
  //     {/* { open ? <div className="bOutPopup" onClick={() => alert("OUT")}></div> : <></> } */}
  //     <Dialog visible={visible} style={style} onHide={() => onHide()} onClickOut={() => onClickOut()} draggable={false} resizable={false} closable={false}>
  //       {children}
  //     </Dialog>
  //   </>
  // )
}

export default DialogCustom;
