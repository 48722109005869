import { get, post, put, patch } from "../Config"

export function SearchArticle({ params = {} }) {
  return get({ url: `/v1/admin/artlcleDetail`, params })
}
export function GetArticleById({ id }) {
  return get({ url: `/v1/admin/artlcleDetail/${id}` })
}
export function CreateArticle({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/artlcleDetail`, params, data })
}
export function UpdateArticle({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/artlcleDetail/${id}`, params, data })
}
export function PatchArticle({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/artlcleDetail/${id}`, params, data })
}
export function DeleteArticle({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/artlcleDetail/mutiDelete`, params, data })
}
