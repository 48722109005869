import React, { useEffect, useState, useContext, useRef  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"


//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateBranch, GetBranchById, UpdateBranch } from "services/api/module/Branch"
import ModalContext from "../../contexts/ModalContext";
import { DropdownAddressAll, DropdownBranchType } from "services/api/module/Dropdown"
import Flatpickr from "react-flatpickr";
import moment from "moment"
import UploadFileAPI from "components/UploadFileAPI"


const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const BranchTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qBranchId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    branchTypeId: Yup.string().max(255).required("Please Select Branch Type"),
    code: Yup.string().max(255).required("Please Enter Branch Code"),
    nameTh: Yup.string().max(255).required("Please Enter Branch Name Thai"),
    nameEn: Yup.string().max(255).required("Please Enter Branch Name Eng"),
    openTime: Yup.string().max(255).required("Please Enter Open Time"),
    closeTime: Yup.string().max(255).required("Please Enter Close Time"),
    facebook: Yup.string().max(255).required("Please Enter Facebook"),
    line: Yup.string().max(255).required("Please Enter Line"),
    latitude: Yup.string().max(255).required("Please Enter Latitude"),
    longitude: Yup.string().max(255).required("Please Enter Longitude"),
    address: Yup.string().max(255).required("Please Enter Address Detail"),

    provinceId: Yup.string().max(10).required("Please Select Province"),
    amphureId: Yup.string().max(10).required("Please Select Amphure"),
    tombonId: Yup.string().max(10).required("Please Select Tombon"),
    postcode: Yup.string().max(255).required("Please Enter Postcode"),

    mapLink: Yup.string().max(255).required("Please Enter Map URL"),
    // contactNo: Yup.string().max(255).required("Please Enter Contact No"),
    status: Yup.string().max(255).required("Please Enter Status"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddBranchType, setDDBranchType] = useState([])
  const [ddRegion, setDDRegion] = useState([])
  const [ddProvince, setDDProvince] = useState([])
  const [ddAmphure, setDDAmphure] = useState([])
  const [ddTombon, setDDTombon] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)
  const [ddLoaded1, setDDLoaded1] = useState(DEF_LOADED)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      branchTypeId: "",
      code: "",
      nameTh: "",
      nameEn: "",
      openTime: "",
      closeTime: "",
      facebook: "",
      line: "",
      latitude: "",
      longitude: "",
      address: "",
      regionId: "",
      provinceId: "",
      amphureId: "",
      tombonId : "",
      postcode: "",
      mapLink: "",
      contactNo: "",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ BranchCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        branchTypeId: Number(`${values.branchTypeId}`),
        code: values.code,
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        openTime: values.openTime,
        closeTime: values.closeTime,
        facebook: values.facebook,
        line: values.line,
        latitude: values.latitude,
        longitude: values.longitude,
        address: values.address,
        regionId: Number(`${values.regionId}`),
        provinceId: Number(`${values.provinceId}`),
        amphureId: Number(`${values.amphureId}`),
        tombonId : Number(`${values.tombonId}`),
        postcode: values.postcode,
        mapLink: values.mapLink,
        contactNo: values.contactNo,
        status: Number(values.status)
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateBranch(payload)
      } else {
        API.fetchCreateBranch(payload)
      }
    },
  })

  // SELECT DATA

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("branchTypeId", resData.branchTypeId)
    validation.setFieldValue("code", resData.code)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("openTime", resData.openTime)
    validation.setFieldValue("closeTime", resData.closeTime)
    validation.setFieldValue("facebook", resData.facebook)
    validation.setFieldValue("line", resData.line)
    validation.setFieldValue("latitude", resData.latitude)
    validation.setFieldValue("longitude", resData.longitude)
    validation.setFieldValue("address", resData.address)
    validation.setFieldValue("regionId", resData.regionId)
    validation.setFieldValue("provinceId", resData.provinceId)
    validation.setFieldValue("amphureId", resData.amphureId)
    validation.setFieldValue("tombonId", resData.tombonId)
    validation.setFieldValue("postcode", resData.postcode)
    validation.setFieldValue("mapLink", resData.mapLink)
    validation.setFieldValue("contactNo", resData.contactNo)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchDDBranchType: async () => {
      try {
          const response = await DropdownBranchType({})
          const resData = response?.data ?? []
          const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          console.log("🚀 ~ fetchDDBranchType: ~ tmpDD:", tmpDD)
          setDDBranchType(tmpDD);
          setDDLoaded0(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchDDBannerPage: async () => {
      try {
          const response = await DropdownAddressAll({})
          const resData = response?.data ?? []
          const addDEF = fn_helper.OPTIONS.ADD_SELECT_DEF
          setDDRegion(addDEF(resData.data.region))
          setDDProvince(addDEF(resData.data.province))
          setDDAmphure(addDEF(resData.data.amphure))
          setDDTombon(addDEF(resData.data.tombon))
          setDDLoaded1(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchGetBranchById: async (payload) => {
      try {
          const response = await GetBranchById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateBranch: async (payload) => {
      try {
          const response = await CreateBranch({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/branch/branch')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateBranch: async (payload) => {
      try {
          const response = await UpdateBranch({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/branch/branch')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  const clearImageFill = (prevState) => {
    if (validation.values.imageDesktop || validation.values.imageMobile) {
      mAlert.info({
        type: "confrim",
        title: "Alert Confrim",
        subTitle: `Are you sure Change Banner Page ?`,
        mode: "red",
        content: "",
        onClose: () => {},
        onYes: () => { 
          validation.setFieldValue("imageDesktop", "")
          validation.setFieldValue("imageMobile", "")
        },
        onNo: () => {
          validation.setFieldValue("bannerPageId", `${prevState}`)
        },
      })
    }
  }


  useEffect(() => {
    console.log("🚀 ~ useEffect :", validation.values.branchTypeId)
    validation.setFieldValue("bannerPageId", "")

  }, [validation.values.branchTypeId])

  // กรณี Edit
  useEffect(() => {
    if (ddLoaded0 && ddLoaded1) {
      if (["update", "view"].includes(pageView)) {
        const payload = { id : qBranchId }
        API.fetchGetBranchById(payload)
      }
    }
  }, [ddLoaded0, ddLoaded1])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDBranchType();
    API.fetchDDBannerPage();
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Branch | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  const ddProvinceFilter = ddProvince.filter(item => item.regionId === validation.values.regionId)
  const ddAmphureFilter = ddAmphure.filter(item => item.provinceId === validation.values.provinceId)
  const ddTombonFilter = ddTombon.filter(item => item.amphureId === validation.values.amphureId)

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Branch`}
            breadcrumbItems={[
              { title: "Branch", link: "banner/banner-list" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Branch Infomation</h5>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Select Branch Type
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Branch Type"
                            value={fn_helper.FNFORM.getObjectValue(ddBranchType, `${validation.values.branchTypeId}`)}
                            onChange={(value) => validation.setFieldValue("branchTypeId", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddBranchType}
                            className={`select2-selection ${validation.touched.branchTypeId && validation.errors.branchTypeId ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.branchTypeId && validation.errors.branchTypeId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.branchTypeId}</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Branch Code
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="code"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.code}
                            invalid={validation.touched.code && validation.errors.code ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.code && validation.errors.code && (
                              <FormFeedback type="invalid">
                                {validation.errors.code}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Branch Name Thai
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="nameTh"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="nameTh"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.nameTh}
                            invalid={validation.touched.nameTh && validation.errors.nameTh ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.nameTh && validation.errors.nameTh && (
                              <FormFeedback type="invalid">
                                {validation.errors.nameTh}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Branch Name Eng
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="nameEn"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="nameEn"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.nameEn}
                            invalid={validation.touched.nameEn && validation.errors.nameEn ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.nameEn && validation.errors.nameEn && (
                              <FormFeedback type="invalid">
                                {validation.errors.nameEn}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="startDate"
                          className="col-md-12 col-form-label"
                        >
                          Open Time
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="openTime"
                            className="form-control"
                            type="time"
                            placeholder=""
                            name="openTime"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.openTime}
                            invalid={
                              validation.touched.openTime &&
                              validation.errors.openTime
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.openTime &&
                            validation.errors.openTime && (
                              <FormFeedback type="invalid">
                                {validation.errors.openTime}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="endDate"
                          className="col-md-12 col-form-label"
                        >
                          Close Time
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="closeTime"
                            className="form-control"
                            type="time"
                            placeholder=""
                            name="closeTime"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.closeTime}
                            invalid={
                              validation.touched.closeTime &&
                              validation.errors.closeTime
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.closeTime && validation.errors.closeTime && (
                            <FormFeedback type="invalid">
                              {validation.errors.closeTime}
                            </FormFeedback>
                          )}
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Facebook
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="facebook"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="facebook"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.facebook}
                            invalid={validation.touched.facebook && validation.errors.facebook ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.facebook && validation.errors.facebook && (
                              <FormFeedback type="invalid">
                                {validation.errors.facebook}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Line
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="line"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="line"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.line}
                            invalid={validation.touched.line && validation.errors.line ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.line && validation.errors.line && (
                              <FormFeedback type="invalid">
                                {validation.errors.line}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Latitude
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="latitude"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="latitude"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.latitude}
                            invalid={validation.touched.latitude && validation.errors.latitude ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.latitude && validation.errors.latitude && (
                              <FormFeedback type="invalid">
                                {validation.errors.latitude}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Longitude
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="longitude"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="longitude"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.longitude}
                            invalid={validation.touched.longitude && validation.errors.longitude ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.longitude && validation.errors.longitude && (
                              <FormFeedback type="invalid">
                                {validation.errors.longitude}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Address Detail
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="address"
                            className="form-control"
                            type="textarea"
                            rows="3"
                            placeholder=""
                            name="address"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address}
                            invalid={ validation.touched.address && validation.errors.address ? true: false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.address && validation.errors.address && (
                              <FormFeedback type="invalid">
                                {validation.errors.address}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Select Region
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Region"
                            value={fn_helper.FNFORM.getObjectValue(ddRegion, `${validation.values.regionId}`)}
                            onChange={(value) => {
                              validation.setFieldValue("regionId", value.id)
                              validation.setFieldValue("provinceId", "")
                              validation.setFieldValue("amphureId", "")
                              validation.setFieldValue("tombonId", "")
                              validation.setFieldValue("postcode", "")
                            }}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddRegion}
                            className={`select2-selection ${validation.touched.regionId && validation.errors.regionId ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.regionId && validation.errors.regionId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.regionId}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Select Province
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Province"
                            value={fn_helper.FNFORM.getObjectValue(ddProvince, `${validation.values.provinceId}`)}
                            onChange={(value) => {
                              validation.setFieldValue("provinceId", value.id)
                              validation.setFieldValue("amphureId", "")
                              validation.setFieldValue("tombonId", "")
                              validation.setFieldValue("postcode", "")
                            }}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddProvinceFilter}
                            className={`select2-selection ${validation.touched.provinceId && validation.errors.provinceId ? "input-err" : ""}` }
                            isDisabled={pageView === "view" || !validation.values.regionId}
                          />
                          {
                            (validation.touched.provinceId && validation.errors.provinceId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.provinceId}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Select Amphure
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Amphure"
                            value={fn_helper.FNFORM.getObjectValue(ddAmphure, `${validation.values.amphureId}`)}
                            onChange={(value) => {
                              validation.setFieldValue("amphureId", value.id)
                              validation.setFieldValue("tombonId", "")
                              validation.setFieldValue("postcode", "")
                            }}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddAmphureFilter}
                            className={`select2-selection ${validation.touched.amphureId && validation.errors.amphureId ? "input-err" : ""}` }
                            isDisabled={pageView === "view" || !validation.values.provinceId}
                          />
                          {
                            (validation.touched.amphureId && validation.errors.amphureId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.amphureId}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Select Tombon
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Tombon"
                            value={fn_helper.FNFORM.getObjectValue(ddTombon, `${validation.values.tombonId}`)}
                            onChange={(value) => {
                              validation.setFieldValue("tombonId", value.id)
                              const postcode = ddTombonFilter.find(item => item.id === value.id).postcode
                              validation.setFieldValue("postcode", postcode)

                            }}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddTombonFilter}
                            className={`select2-selection ${validation.touched.tombonId && validation.errors.tombonId ? "input-err" : ""}` }
                            isDisabled={pageView === "view" || !validation.values.amphureId}
                          />
                          {
                            (validation.touched.tombonId && validation.errors.tombonId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.tombonId}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Postcode
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="postcode"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="postcode"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            maxLength={5}
                            value={validation.values.postcode}
                            invalid={validation.touched.postcode && validation.errors.postcode ? true : false}
                            disabled={pageView === "view" || true}
                          />
                          { 
                            validation.touched.postcode && validation.errors.postcode && (
                              <FormFeedback type="invalid">
                                {validation.errors.postcode}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Map URL
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="mapLink"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="mapLink"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mapLink}
                            invalid={validation.touched.mapLink && validation.errors.mapLink ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.mapLink && validation.errors.mapLink && (
                              <FormFeedback type="invalid">
                                {validation.errors.mapLink}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Contact No
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="contactNo"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="contactNo"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contactNo}
                            invalid={validation.touched.contactNo && validation.errors.contactNo ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.contactNo && validation.errors.contactNo && (
                              <FormFeedback type="invalid">
                                {validation.errors.contactNo}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                            onChange={(value) => validation.setFieldValue("status", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.status && validation.errors.status) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/branch/branch')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/branch/branch')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// BranchTemplate.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(BranchTemplate)
