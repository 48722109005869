import React, { useEffect, useState, useContext, useRef  } from "react"
import { Col, Row, Input, Form, FormFeedback } from "reactstrap"
import Select from "react-select"
import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import moment from "moment"
import UploadFileAPI from "components/UploadFileAPI"
import InputRadio from "components/Input/InputRadio"
import InputSwitch from "components/Input/InputSwitch"
import DialogCustom from "components/modal/DialogCustom"

const TARGET_OPTION = [
  { id: "1", name: "New Window" },
  { id: "2", name: "On Page" },
]

const BANNER_TYPE_OPTION = [
  { id: "1", name: "Product Group" },
  { id: "2", name: "Embed Link" },
]

const PopupForm = ({
  config = { order: 1, width: 390, height: 390 },
  index = -1,
  value = "",
  productGroupOption = [],
  onChange = () => console.log('onChange!'),
  onRemove = () => console.log('onRemove!'),
  pageView = 'create'
  
}) => {

  // STATE POPUP
  const [ isOpen, setIsOpen ] = useState(false)

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    name: Yup.string().max(100).required("Please Enter Banner title"),
    target: Yup.string().max(100).required("Please Enter Target"),
    
    bannerType: Yup.string().max(100).required("Please Enter Banner Type"),
    // bannerProductGroupId : Yup.string().max(100).required("Please Select Product Group"),
    // embedLink: Yup.string().max(100).required("Please Enter Embed Link"),

    bannerProductGroupId: Yup.string().max(100)
      .when('bannerType', {
          is: (val) => val === "1",
          then: (schema) => schema.required("Please Select Product Group"),
          otherwise: (schema) => schema.notRequired(),
      }),
      embedLink: Yup.string().max(100)
      .when('bannerType', {
          is: (val) => val === "2",
          then: (schema) => schema.required("Please Enter Embed Link"),
          otherwise: (schema) => schema.notRequired(),
      }),
    // bannerProductGroupId: Yup.string().max(100).required("Please Select Products Group"),
    img: Yup.string().max(512).required("Please Enter Banner Image"),

    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string().max(100).required("Please Enter Meta Description"),

  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      order: config.order,
      name: "",
      target: "1",
      bannerType: "1",
      embedLink: "",
      img: "",
      bannerProductGroupId: "",

      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ ArticleCategoryCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        order: config.order,
        name: values.name,
        target: values.target,
        bannerType: values.bannerType,
        embedLink: values.embedLink,
        bannerProductGroupId: values.bannerProductGroupId,
        img: values.img,

        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
        
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      onSave(payload, config, index)
    },
  })


  const onClose = () => {
    console.log('onClose')
    setIsOpen(false)
  }

  const onSave = (_values ={}, _config = {}, _index = -1) => {
    console.log('onSave')
    onChange(_values, _config, _index)
    setTimeout(() => onClose(), 200)
  }

  const onCancel = () => {
    console.log('onCancel')
    onClose()
  }

  const onDelete = () => {
    console.log('onClose')
    onRemove(config, config.order)
  }

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)

    validation.setFieldValue("id", resData?.id)
    validation.setFieldValue("name", resData.name)
    validation.setFieldValue("target", `${resData.target}`)
    validation.setFieldValue("embedLink", resData.embedLink)
    validation.setFieldValue("bannerProductGroupId", resData.bannerProductGroupId)
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)

    setTimeout(() => validation.setFieldValue("img", resData.img), 100)
  }

  useEffect(() => {
    if (isOpen) {
      if (value) {
        // console.log('preFilInput >>', value)
        preFilInput(value)
      }
    }

    return () => {
      validation.resetForm()
    }
  }, [isOpen, value])


  // if (value) {

  //   return (
  //     <img src={value.img} alt="image-banner" />
  //   )
  // }
  // return 'mock'
  return (
    <>
      

      {
        value 
        ? <img src={value.img} alt="image-banner" />
        : <img src={`https://dummyimage.com/${config.width}x${config.height}/2C3D92/FFF`} alt=""/> 
      }
      {
        value && (
          <div className="bnExit" onClick={() => onDelete()}>
            <div className="bx bx-x bx-md"></div>
          </div>
        )
      }
      
      <div className="bnTap" onClick={() => setIsOpen(true)}></div>

      <div>
        <DialogCustom visible={isOpen} onHide={() => onClose()} onClickOut = {() => onClose()}>
          <div className="modal-group">
          <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Banner Detail Infomation</h5>
            </div>
            {/* {JSON.stringify(validation.values)} */}
            <div className="modal-body" style={{ height: '65vh' }}>
              <Row className="mb-3">
                <Col className="col-12">
                  <Row className="">
                    <Col className="col-6">
                      <Row className="">
                        <Col className="col-12">
                          <h5 className="mb-3">Banner Detail</h5>
                        </Col>
                        <Col className="col-12 mb-3">
                          <label
                            htmlFor="category_name"
                            className="col-md-12 col-form-label"
                          >
                            Banner Title
                            <span className="t-require">*</span>
                          </label>
                          <div className="col-md-12">
                            <Input
                              id="name"
                              className="form-control"
                              type="text"
                              placeholder=""
                              name="name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name}
                              invalid={validation.touched.name && validation.errors.name ? true : false}
                              disabled={pageView === "view"}
                            />
                            { 
                              validation.touched.name && validation.errors.name && (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              )
                            }
                          </div>
                        </Col>

                        <Col 
                          className="col-12 mb-3" 
                          style={{
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                            minHeight: '74px'
                          }}
                        >
                          <InputRadio 
                            label="Target Page"
                            name="target"
                            value={validation.values.target}
                            option={TARGET_OPTION}
                            onChange={(value)=> {
                              console.log("🚀 ~ value:", value)
                              validation.setFieldValue("target", value)
                            }}
                            disabled={pageView === "view"}
                          />
                        </Col>
                        <Col 
                          className="col-12 mb-3" 
                          style={{
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                            minHeight: '72px'
                          }}
                        >
                          <InputRadio 
                            label="Banner Type"
                            name="bannerType"
                            value={validation.values.bannerType}
                            option={BANNER_TYPE_OPTION}
                            onChange={(value)=> {
                              console.log("🚀 ~ value:", value)
                              validation.setFieldValue("bannerType", value)
                              validation.setFieldValue("bannerProductGroupId", null)
                              validation.setFieldValue("embedLink", null)
                            }}
                            disabled={pageView === "view"}
                          />
                        </Col>
                        {
                          validation.values.bannerType == 1
                          ? (
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="category_name"
                                className="col-md-12 col-form-label"
                              >
                                Products Group
                                <span className="t-require">*</span>
                              </label>
                              <div className="col-md-12">
                              <Select
                                placeholder="Select Products Group"
                                value={fn_helper.FNFORM.getObjectValue(productGroupOption, `${validation.values.bannerProductGroupId}`)}
                                onChange={(value) => validation.setFieldValue("bannerProductGroupId", value.id)}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                options={productGroupOption}
                                className={`select2-selection ${validation.touched.bannerProductGroupId && validation.errors.bannerProductGroupId ? "input-err" : ""}` }
                                isDisabled={pageView === "view"}
                              />
                              {
                                (validation.touched.bannerProductGroupId && validation.errors.bannerProductGroupId) && (
                                  <div type="invalid" className="invalid-feedback d-block">{validation.errors.bannerProductGroupId}</div>
                                )
                              }
                              </div>
                            </Col>
                          )
                          : (
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="category_name"
                                className="col-md-12 col-form-label"
                              >
                                Embed Link
                                <span className="t-require">*</span>
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="embedLink"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="embedLink"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.embedLink}
                                  invalid={validation.touched.embedLink && validation.errors.embedLink ? true : false}
                                  disabled={pageView === "view"}
                                />
                                { 
                                  validation.touched.embedLink && validation.errors.embedLink && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.embedLink}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                          )
                        }
                        

                        
                      </Row>
                    </Col>
                    
                    <Col className="col-6">
                      <Col className="col-12">
                        <h5 className="mb-3">SEO Setting</h5>
                      </Col>
                      <Row className="">
                        <Col className="col-12 mb-3">
                          <label
                            htmlFor="category_name"
                            className="col-md-12 col-form-label"
                          >
                            URL Key
                            <span className="t-require">*</span>
                          </label>
                          <div className="col-md-12">
                            <Input
                              id="seoUrlKey"
                              className="form-control"
                              type="text"
                              placeholder=""
                              name="seoUrlKey"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.seoUrlKey}
                              invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                              disabled={pageView === "view"}
                            />
                            { 
                              validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                                <FormFeedback type="invalid">
                                  {validation.errors.seoUrlKey}
                                </FormFeedback>
                              )
                            }
                          </div>
                        </Col>
                        <Col className="col-12 mb-3">
                          <label
                            htmlFor="category_name"
                            className="col-md-12 col-form-label"
                          >
                            Meta Title
                            <span className="t-require">*</span>
                          </label>
                          <div className="col-md-12">
                            <Input
                              id="seoMetaTitle"
                              className="form-control"
                              type="text"
                              placeholder=""
                              name="seoMetaTitle"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.seoMetaTitle}
                              invalid={validation.touched.seoMetaTitle && validation.errors.seoMetaTitle ? true : false}
                              disabled={pageView === "view"}
                            />
                            { 
                              validation.touched.seoMetaTitle && validation.errors.seoMetaTitle && (
                                <FormFeedback type="invalid">
                                  {validation.errors.seoMetaTitle}
                                </FormFeedback>
                              )
                            }
                          </div>
                        </Col>
                        <Col className="col-12 mb-3">
                          <label
                            htmlFor="category_name"
                            className="col-md-12 col-form-label"
                          >
                            Meta keyword
                            <span className="t-require">*</span>
                          </label>
                          <div className="col-md-12">
                            <Input
                              id="seoMetaKeyword"
                              className="form-control"
                              type="text"
                              placeholder=""
                              name="seoMetaKeyword"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.seoMetaKeyword}
                              invalid={validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword ? true : false}
                              disabled={pageView === "view"}
                            />
                            { 
                              validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword && (
                                <FormFeedback type="invalid">
                                  {validation.errors.seoMetaKeyword}
                                </FormFeedback>
                              )
                            }
                          </div>
                        </Col>
                        <Col className="col-12 mb-3">
                          <label
                            htmlFor="category_name"
                            className="col-md-12 col-form-label"
                          >
                            Meta Description
                            <span className="t-require">*</span>
                          </label>
                          <div className="col-md-12">
                            <Input
                              id="seoMetaDescription"
                              className="form-control"
                              type="text"
                              placeholder=""
                              name="seoMetaDescription"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.seoMetaDescription}
                              invalid={validation.touched.seoMetaDescription && validation.errors.seoMetaDescription ? true : false}
                              disabled={pageView === "view"}
                            />
                            { 
                              validation.touched.seoMetaDescription && validation.errors.seoMetaDescription && (
                                <FormFeedback type="invalid">
                                  {validation.errors.seoMetaDescription}
                                </FormFeedback>
                              )
                            }
                          </div>
                        </Col>
                      </Row>
                    </Col>

                  </Row>
                </Col>

                <Col className="col-12 mb-3">
                  <div className="col-md-12">
                    <UploadFileAPI
                      bucketName="article-category"
                      upload="Image"
                      typeUpload="ImageMap"
                      prefixName="brand-image"
                      label="Upload Banner Image"
                      required={true}
                      widthSize={config.width}
                      heightSize={config.height}
                      description="description"
                      value={validation.values.img}
                      onChange={([ imageURL = '', imageSize = '']) => {
                          validation.setFieldValue("img", imageURL)
                      }}
                    />
                    
                    <Input
                      id="img"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="img"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.img}
                      invalid={validation.touched.img && validation.errors.img ? true : false}
                      readOnly={true}
                      disabled={pageView === "view"}
                    />
                    { 
                      validation.touched.img && validation.errors.img && (
                        <div type="invalid" className="invalid-feedback d-block">{validation.errors.img}</div>
                      )
                    }
                  </div>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                {
                  pageView == "view"
                  ? <> <button type="button" className="btn btn-primary w-md" onClick={() => onCancel()}>BACK</button></> 
                  : <>
                    <button type="button" className="btn btn-primary w-md" onClick={()=> validation.handleSubmit()}>SAVE</button>
                    <button type="button" className="btn btn-secondary w-md" onClick={() => onCancel()}>CANCEL</button>
                  </>
                }
              </div>
            </div>
            </Form>

          </div>

        </DialogCustom>
      </div>
    </>
  )
}
export default PopupForm
