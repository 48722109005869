import React from 'react';
import styled, { keyframes } from 'styled-components';

// Animation สำหรับวงกลมหมุน
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Animation สำหรับการขยับข้อความ Loading
const move = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
  }
`;

// Styled component สำหรับ LoadingOverlay
const LoadingOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px; /* กำหนดความสูง */
  background: rgba(255, 255, 255, 0.8);
`;

// วงกลมหมุนสำหรับแสดงการโหลด
const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #556ee6;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

// ข้อความ Loading พร้อม animation ขยับ
const LoadingText = styled.div`
  margin-top: 14px;
  font-size: 1.2em;
  color: #556ee6;
  animation: ${move} 1s ease-in-out infinite;
`;

// Icon หรือรูปแบบสำหรับแสดงในกรณี Table ว่าง
const EmptyIcon = styled.div`
  font-size: 3em;
  color: #cccccc;
  margin-bottom: 20px;
`;

// ข้อความแสดงสถานะ Table ว่าง
const EmptyText = styled.div`
  font-size: 1.5em;
  color: #556ee6;
`;

// Styled component สำหรับแถวของตาราง
const StyledTableRow = styled.tr`
  cursor: none;
  &:hover {
    pointer-events: none; /* ปิดการทำ hover */
  }
`;

// Styled component สำหรับ td
const StyledTableCell = styled.td`
  cursor: none;
  padding: 0 !important; /* กำหนด padding เป็น 0 */
`;

// คอมโพเนนต์หลักสำหรับการแสดงผลการโหลด
function TableLoading({ colSpan }) {
  return (
    <StyledTableRow>
      <StyledTableCell colSpan={colSpan}>
        <LoadingOverlay>
          <Spinner />
          <LoadingText>Loading...</LoadingText>
        </LoadingOverlay>
      </StyledTableCell>
    </StyledTableRow>
  );
}

// คอมโพเนนต์หลักสำหรับแสดงสถานะ Table ว่าง
function TableEmpty({ colSpan }) {
  return (
    <StyledTableRow>
      <StyledTableCell colSpan={colSpan}>
        <LoadingOverlay>
          <EmptyIcon>🚫</EmptyIcon> {/* สามารถเปลี่ยนเป็นไอคอนหรือรูปภาพที่ต้องการ */}
          <EmptyText>No Data Available</EmptyText>
        </LoadingOverlay>
      </StyledTableCell>
    </StyledTableRow>
  );
}

export { TableLoading, TableEmpty };
