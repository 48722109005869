import React, { useState, useEffect, useRef } from "react";
// import { Dialog } from 'primereact/dialog';
import { Modal } from "reactstrap";

function InputRadio({
  label = '',
  noLable = false,
  value = '',
  name = 'radio',
  option = [],
  onChange = () => console.log('onChange'),
  disabled = false,
  style = {}
}) {

  const handleOnChange = (selectValue) => {
    console.log("🚀 ~ handleOnChange ~ selectValue:", selectValue)
    onChange(selectValue)
  }


  return (
    <div className="" style={style}>
      {noLable === false && (<h5 className="font-size-14 mb-3">{label}</h5>)}
      
      <div  style={{ display: 'flex', alignItems: 'center'}}>
        {
          option.map(item => {
            return (
              <div className="form-check form-check-inline" key={item.id} onClick={() => handleOnChange(item.id)}>
                <input
                  className="form-check-input"
                  type="radio"
                  name={name}
                  value={item.id}
                  checked={value === item.id}
                  disabled={disabled}
                />
                <label
                  className="form-check-label"
                  htmlFor="exampleRadios1"
                >
                  {item.name}
                </label>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default InputRadio;
