import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CustomUploadAdapterPlugin from './CustomUploadAdapter';

const MyEditor = ({
    value = null,
    onChange = () => console.log('onChange!'),
    disabled = false
}) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            data={value}
            config={{
                extraPlugins: [CustomUploadAdapterPlugin], // เพิ่ม Custom Upload Adapter Plugin
            }}
            onReady={editor => {
                console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
                // const data = editor.getData();
                // console.log({ event, editor, data });
                onChange(event, editor)
            }}
            disabled={disabled}
        />
    );
};

export default MyEditor;