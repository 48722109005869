import React, { useEffect, useState, useContext  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _, { values } from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateEMagazine, GetEMagazineById, UpdateEMagazine } from "services/api/module/EMagazine"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"

const MONTH_OPTION = [
  { id: "1", name: "January" },
  { id: "2", name: "February" },
  { id: "3", name: "March" },
  { id: "4", name: "April" },
  { id: "5", name: "May" },
  { id: "6", name: "June" },
  { id: "7", name: "July" },
  { id: "8", name: "August" },
  { id: "9", name: "September" },
  { id: "10", name: "October" },
  { id: "11", name: "November" },
  { id: "12", name: "December" }
]

const YEAR_OPTION = [
  ...fn_helper.GEN.YEAR_OPTION(2000, 5)
]
const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const EMagazineTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qEMagazineId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    nameTh: Yup.string().max(100).required("Please Enter E-Magazine Name TH"),
    nameEn: Yup.string().max(100).required("Please Enter E-Magazine Name EN"),
    // description: Yup.string().max(100).required("Please Enter Description"),
    // sortOrder: Yup.string().max(100).required("Please Enter Sort Order"),
    month: Yup.string().max(100).required("Please Enter Month"),
    year: Yup.string().max(100).required("Please Enter Year"),
    status: Yup.string().max(100).required("Please Enter Status"),

    img: Yup.string().max(512).required("Please Enter Upload E-Magazine Cover"),
    imgHome: Yup.string().max(512).required("Please Enter Upload E-Magazine Cover for Homepage"),
    pdfFile: Yup.string().max(512).required("Please Enter Upload E-Magazine .PDF file"),

  })

  // LOCAL STATE FORM
  const [errYearMonth, setErrYearMonth] = useState(false)
  const [errYearMonthList, setErrYearMonthList] = useState([])
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameTh: "",
      nameEn: "",
      description: "",
      sortOrder: "",
      month: "",
      year: "",
      status: "",

      img: "",
      imgHome: "",
      pdfFile: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ EMagazineCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        description: values.description,
        sortOrder: values.sortOrder,

        month: Number(values.month),
        year: Number(values.year),
        status: Number(values.status),

        img: values.img,
        imgHome: values.imgHome,
        pdfFile: values.pdfFile,

      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateEMagazine(payload)
      } else {
        API.fetchCreateEMagazine(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("description", resData.description)
    validation.setFieldValue("sortOrder", resData.sortOrder)
    validation.setFieldValue("month", resData.month)
    validation.setFieldValue("year", resData.year)
    validation.setFieldValue("status", resData.status)

    validation.setFieldValue("img", resData.img)
    validation.setFieldValue("imgHome", resData.imgHome)
    validation.setFieldValue("pdfFile", resData.pdfFile)
  }

  const API = {
    fetchGetEMagazineById: async (payload) => {
      try {
          const response = await GetEMagazineById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateEMagazine: async (payload) => {
      try {
          const response = await CreateEMagazine({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/e-magazine')
                },
            })
          } else if (resData.status === 403) {
            setErrYearMonth(true)
            let tmpYearMonth = _.cloneDeep(errYearMonthList)
            tmpYearMonth.push({ year: validation.values.year, month: validation.values.month })
            setErrYearMonthList(tmpYearMonth)

            mAlert.info({
              title: "Alert",
              subTitle: "Data Existing",
              content: "",
              onClose: () => {
                
              },
            })
          } else {
            throw resData
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateEMagazine: async (payload) => {
      try {
          const response = await UpdateEMagazine({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/e-magazine')
                  },
              })
          } else if (resData.status === 403) {
            setErrYearMonth(true)
            let tmpYearMonth = _.cloneDeep(errYearMonthList)
            tmpYearMonth.push({ year: validation.values.year, month: validation.values.month })
            setErrYearMonthList(tmpYearMonth)

            mAlert.info({
              title: "Alert",
              subTitle: "Data Existing",
              content: "",
              onClose: () => {
                
              },
            })
          } else {
            throw resData
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id : qEMagazineId }
      API.fetchGetEMagazineById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `E-Magazine | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  const checkYearMonth = (year, month, errList = []) => {
    console.log("🚀 ~ checkYearMonth ~ errList:", errList)
    console.log("🚀 ~ checkYearMonth ~ year, month:", year, month)
    let errArr = errList.filter(item => item.year == year && item.month == month)
    console.log("🚀 ~ checkYearMonth ~ errArr:", errArr)
    console.log("🚀 ~ checkYearMonth ~ errArr aa:", errArr.length > 0)
    return errArr.length > 0
  }


  let isErrorYearMonth = checkYearMonth(validation.values.year, validation.values.month, errYearMonthList)


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} E-Magazine`}
            breadcrumbItems={[
              { title: "E-Magazine", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">E-Magazine Infomation</h5>
                      <Col className="col-12">
                        <Row className="">
                          {/* {JSON.stringify(errYearMonthList)} */}
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              E-Magazine Name TH
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="nameTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="nameTh"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nameTh}
                                invalid={validation.touched.nameTh && validation.errors.nameTh ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.nameTh && validation.errors.nameTh && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.nameTh}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Month
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Month"
                                value={fn_helper.FNFORM.getObjectValue(MONTH_OPTION, `${validation.values.month}`)}
                                onChange={(value) => validation.setFieldValue("month", value.id)}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                options={MONTH_OPTION}
                                className={`select2-selection ${(validation.touched.month && validation.errors.month) || (errYearMonth && isErrorYearMonth) ? "input-err" : ""}` }
                                isDisabled={pageView === "view"}
                              />
                              {
                                (validation.touched.month && validation.errors.month) && (
                                  <div type="invalid" className="invalid-feedback d-block">{validation.errors.month}</div>
                                )
                              }
                              { 
                                validation.touched.month && validation.errors.month && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.month}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              E-Magazine Name EN
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="nameEn"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="nameEn"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nameEn}
                                invalid={validation.touched.nameEn && validation.errors.nameEn ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.nameEn && validation.errors.nameEn && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.nameEn}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>

                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Year
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Year"
                                value={fn_helper.FNFORM.getObjectValue(YEAR_OPTION, `${validation.values.year}`)}
                                onChange={(value) => validation.setFieldValue("year", value.id)}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                options={YEAR_OPTION}
                                className={`select2-selection ${(validation.touched.year && validation.errors.year) || (errYearMonth && isErrorYearMonth) ? "input-err" : ""}` }
                                isDisabled={pageView === "view"}
                              />
                              {
                                (validation.touched.year && validation.errors.year) && (
                                  <div type="invalid" className="invalid-feedback d-block">{validation.errors.year}</div>
                                )
                              }
                              { 
                                validation.touched.year && validation.errors.year && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.year}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          
                          {/* <Col className="col-6 mb-3">
                            <label
                              htmlFor="order"
                              className="col-md-12 col-form-label"
                            >
                              Sort Order
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="sortOrder"
                                className="form-control"
                                type="number"
                                name="sortOrder"
                                width="100%"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sortOrder}
                                invalid={
                                  validation.touched.sortOrder &&
                                  validation.errors.sortOrder
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                                min="1"
                              />
                              {validation.touched.sortOrder &&
                                validation.errors.sortOrder && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.sortOrder}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col> */}

                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Description
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="description"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description}
                                invalid={validation.touched.description && validation.errors.description ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.description && validation.errors.description && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>

                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Status (Active/Inactive)
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Status"
                                value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                                onChange={(value) => validation.setFieldValue("status", value.id)}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                options={STATUS_OPTION}
                                className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                                isDisabled={pageView === "view"}
                              />
                              {
                                (validation.touched.status && validation.errors.status) && (
                                  <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                                )
                              }
                              { 
                                validation.touched.status && validation.errors.status && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.status}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>

                        </Row>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div className="col-md-12">
                          <UploadFileAPI
                            bucketName="e-magazine"
                            upload="Image"
                            typeUpload="ImageMap"
                            prefixName="e-magazine-image"
                            label="Upload E-Magazine Cover"
                            required={true}
                            widthSize={288}
                            heightSize={196}
                            description="description"
                            value={validation.values.img}
                            onChange={([ imageURL = '', imageSize = '']) => {
                                validation.setFieldValue("img", imageURL)
                            }}
                          />
                          <Input
                            id="img"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="img"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.img}
                            invalid={validation.touched.img && validation.errors.img ? true : false}
                            readOnly={true}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.img && validation.errors.img && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.img}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div className="col-md-12">
                          <UploadFileAPI
                            bucketName="e-magazine"
                            upload="Image"
                            typeUpload="ImageMap"
                            prefixName="e-magazine-image-home"
                            label="Upload E-Magazine Cover for Homepage"
                            required={true}
                            widthSize={353}
                            heightSize={499}
                            description="description"
                            value={validation.values.imgHome}
                            onChange={([ imageURL = '', imageSize = '']) => {
                                validation.setFieldValue("imgHome", imageURL)
                            }}
                          />
                          <Input
                            id="imgHome"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="imgHome"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.imgHome}
                            invalid={validation.touched.imgHome && validation.errors.imgHome ? true : false}
                            readOnly={true}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.imgHome && validation.errors.imgHome && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.imgHome}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div className="col-md-12">
                          <UploadFileAPI
                            bucketName="e-magazine"
                            upload="PDF"
                            typeUpload="PDF"
                            prefixName="e-magazine-pdf"
                            label="Upload E-Magazine .PDF file"
                            required={true}
                            widthSize={1024}
                            heightSize={1024}
                            description="description"
                            value={validation.values.pdfFile}
                            onChange={([ imageURL = '', imageSize = '']) => {
                                validation.setFieldValue("pdfFile", imageURL)
                            }}
                          />
                          <Input
                            id="pdfFile"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="pdfFile"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pdfFile}
                            invalid={validation.touched.pdfFile && validation.errors.pdfFile ? true : false}
                            readOnly={true}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.pdfFile && validation.errors.pdfFile && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.pdfFile}</div>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/e-magazine')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/e-magazine')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(EMagazineTemplate)
