import { get, post, put } from "../Config"

export function listCustomer({ params = {} }) {
  return get({ url: `/v1/admin/customer/`, params })
}
export function detailCustomer({ params = {}, id }) {
  return get({ url: `/v1/admin/customer/detail/${id}`, params })
}
export function createCustomer({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/customer/create`, params, data })
}
export function updateCustomer({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/customer/update/${id}`, params, data })
}
export function deleteCustomer({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/customer/delete`, params, data })
}
