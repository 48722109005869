import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateBankPaymentAccount, GetBankPaymentAccountById, UpdateBankPaymentAccount } from "services/api/module/BankPaymentAccount"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"
import { DropdownBank } from "services/api/module/Dropdown"
import moment from "moment"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const ACCOUNT_TYPE_OPTION = [
  { value: "ออมทรัพย์", name: "ออมทรัพย์" },
  { value: "กระแสรายวัน", name: "กระแสรายวัน" },
]

const BankPaymentAccountTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qBankPaymentAccountId = qParams.id
  const navigate = useNavigate();

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddBank, setDDBank] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    bankId: Yup.string().max(100).required("Please Select Bank"),
    accountName: Yup.string().max(100).required("Please Enter Account Name"),
    accountNumber: Yup.string().max(100).required("Please Enter Account Number"),
    accountType: Yup.string().max(100).required("Please Enter Account Type"),
    bankBranchName: Yup.string().max(100).required("Please Enter Bank Branch Name"),
    image: Yup.string().max(100).required("Please Enter Image"),
    tempCloseStartDate: Yup.string().required("Please Enter Temp Close Start Date"),
    tempCloseEndDate: Yup.string().required("Please Enter Temp Close End Date"),

    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string().max(100).required("Please Enter Meta Description"),
    status: Yup.string().max(100).required("Please Enter Status"),

  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      bankId: "",
      accountName: "",
      accountNumber: "",
      accountType: "",
      bankBranchName: "",
      image: "",
      tempCloseStartDate: "",
      tempCloseEndDate: "",

      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ BankPaymentAccountCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        bankId: values.bankId,
        accountName: values.accountName,
        accountNumber: values.accountNumber,
        accountType: values.accountType,
        bankBranchName: values.bankBranchName,
        image: values.image,
        tempCloseStartDate: moment(values.tempCloseStartDate, "YYYY-MM-DDTHH:mm").toISOString(),
        tempCloseEndDate: moment(values.tempCloseEndDate, "YYYY-MM-DDTHH:mm").toISOString(),
        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
        status: Number(values.status),
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateBankPaymentAccount(payload)
      } else {
        API.fetchCreateBankPaymentAccount(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("bankId", resData.bankId)
    validation.setFieldValue("accountName", resData.accountName)
    validation.setFieldValue("accountNumber", resData.accountNumber)
    validation.setFieldValue("accountType", resData.accountType)
    validation.setFieldValue("bankBranchName", resData.bankBranchName)
    validation.setFieldValue("image", resData.image)
    validation.setFieldValue("tempCloseStartDate", moment(resData.tempCloseStartDate).format("YYYY-MM-DDTHH:mm"))
    validation.setFieldValue("tempCloseEndDate", moment(resData.tempCloseEndtDate).format("YYYY-MM-DDTHH:mm"))

    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetBankPaymentAccountById: async (payload) => {
      try {
        const response = await GetBankPaymentAccountById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateBankPaymentAccount: async (payload) => {
      try {
        const response = await CreateBankPaymentAccount({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate('/bank/bank-payment-account')
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateBankPaymentAccount: async (payload) => {
      try {
        const response = await UpdateBankPaymentAccount({ data: payload, id: payload.id })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate('/bank/bank-payment-account')
            },
          })
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDBank: async () => {
      try {
        const response = await DropdownBank({})
        const resData = response?.data ?? []
        const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
        setDDBank(tmpDD)
        setDDLoaded0(true)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qBankPaymentAccountId }
      API.fetchGetBankPaymentAccountById(payload)
    }
  }, [])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDBank()
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `BankPaymentAccount | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} BankPaymentAccount`}
            breadcrumbItems={[
              { title: "BankPaymentAccount", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">

                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12">
                                <h5 className="mb-3">BankPaymentAccount Infomation</h5>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Bank
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Category"
                                    value={fn_helper.FNFORM.getObjectValue(
                                      ddBank,
                                      `${validation.values.bankId}`
                                    )}
                                    onChange={value => {
                                      validation.setFieldValue("bankId", value.id)
                                    }}
                                    getOptionValue={option => option.id}
                                    getOptionLabel={option => option.name}
                                    options={ddBank}
                                    className={`select2-selection ${validation.touched
                                      .bankId &&
                                      validation.errors
                                        .bankId
                                      ? "input-err"
                                      : ""
                                      }`}
                                    isDisabled={["view"].includes(pageView)}
                                  />
                                  {validation.touched
                                    .bankId &&
                                    validation.errors
                                      .bankId && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {
                                          validation.errors
                                            .bankId
                                        }
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Account Name
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="accountName"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="accountName"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.accountName}
                                    invalid={validation.touched.accountName && validation.errors.accountName ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.accountName && validation.errors.accountName && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.accountName}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Account Number
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="accountNumber"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="accountNumber"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.accountNumber}
                                    invalid={validation.touched.accountNumber && validation.errors.accountNumber ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.accountNumber && validation.errors.accountNumber && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.accountNumber}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>

                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Account Type
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Status"
                                    value={fn_helper.FNFORM.getObjectValue(ACCOUNT_TYPE_OPTION, `${validation.values.accountType}`)}
                                    onChange={(value) => validation.setFieldValue("accountType", value.value)}
                                    getOptionValue={(option) => option.value}
                                    getOptionLabel={(option) => option.name}
                                    options={ACCOUNT_TYPE_OPTION}
                                    className={`select2-selection ${validation.touched.accountType && validation.errors.accountType ? "input-err" : ""}`}
                                    isDisabled={pageView === "view"}
                                  />
                                  {
                                    (validation.touched.accountType && validation.errors.accountType) && (
                                      <div type="invalid" className="invalid-feedback d-block">{validation.errors.accountType}</div>
                                    )
                                  }
                                  {
                                    validation.touched.accountType && validation.errors.accountType && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.accountType}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>

                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Bank Branch Name
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="bankBranchName"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="bankBranchName"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.bankBranchName}
                                    invalid={validation.touched.bankBranchName && validation.errors.bankBranchName ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.bankBranchName && validation.errors.bankBranchName && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.bankBranchName}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>

                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Status (Active/Inactive)
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Status"
                                    value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                                    onChange={(value) => validation.setFieldValue("status", value.id)}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    options={STATUS_OPTION}
                                    className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}`}
                                    isDisabled={pageView === "view"}
                                  />
                                  {
                                    (validation.touched.status && validation.errors.status) && (
                                      <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                                    )
                                  }
                                  {
                                    validation.touched.status && validation.errors.status && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.status}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col className="col-6">
                            <Col className="col-12">
                              <h5 className="mb-3">SEO Setting</h5>
                            </Col>
                            <Row className="">
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  URL Key
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoUrlKey"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoUrlKey"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoUrlKey}
                                    invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoUrlKey}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta Title
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaTitle"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaTitle"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaTitle}
                                    invalid={validation.touched.seoMetaTitle && validation.errors.seoMetaTitle ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.seoMetaTitle && validation.errors.seoMetaTitle && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaTitle}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta keyword
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaKeyword"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaKeyword"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaKeyword}
                                    invalid={validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaKeyword}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta Description
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaDescription"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaDescription"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaDescription}
                                    invalid={validation.touched.seoMetaDescription && validation.errors.seoMetaDescription ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.seoMetaDescription && validation.errors.seoMetaDescription && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaDescription}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>

                        </Row>
                      </Col>

                      <Col className="col-6">
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="tempCloseStartDate"
                              className="col-md-12 col-form-label"
                            >
                              Temporary Start Date
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="tempCloseStartDate"
                                className="form-control"
                                type="datetime-local"
                                placeholder=""
                                name="tempCloseStartDate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tempCloseStartDate}
                                invalid={
                                  validation.touched.tempCloseStartDate &&
                                    validation.errors.tempCloseStartDate
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.tempCloseStartDate &&
                                validation.errors.tempCloseStartDate && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.tempCloseStartDate}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-6">
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="tempCloseEndDate"
                              className="col-md-12 col-form-label"
                            >
                              Temporary End Date
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="tempCloseEndDate"
                                className="form-control"
                                type="datetime-local"
                                placeholder=""
                                name="tempCloseEndDate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tempCloseEndDate}
                                invalid={
                                  validation.touched.tempCloseEndDate &&
                                    validation.errors.tempCloseEndDate
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.tempCloseEndDate &&
                                validation.errors.tempCloseEndDate && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.tempCloseEndDate}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-12 mb-3">
                        <div className="col-md-12">
                          <UploadFileAPI
                            bucketName="bank-payment-account"
                            upload="Image"
                            typeUpload="ImageMap"
                            prefixName="bank-payment-account-image"
                            label="Upload Image"
                            required={true}
                            widthSize={56}
                            heightSize={32}
                            description="description"
                            value={validation.values.image}
                            onChange={([imageURL = '', imageSize = '']) => {
                              validation.setFieldValue("image", imageURL)
                            }}
                          />
                          <Input
                            id="image"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="image"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.image}
                            invalid={validation.touched.image && validation.errors.image ? true : false}
                            readOnly={true}
                            disabled={pageView === "view"}
                          />
                          {
                            validation.touched.image && validation.errors.image && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.image}</div>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                      {
                        pageView == "view"
                          ? <><button className="btn btn-primary w-md" onClick={() => navigate('/bank/bank-payment-account')}>BACK</button></>
                          : <>
                            <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                            <button className="btn btn-secondary w-md" onClick={() => navigate('/bank/bank-payment-account')}>CANCEL</button>
                          </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(BankPaymentAccountTemplate)
