import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { CREATE_QUESTIONAIRE_CATEGORY } from "./actionsType"
import { createQuestionaireCategorySuccessful, createQuestionaireCategoryFailed } from "./actions"
import { apiError } from "store/actions"

import * as backend from "helpers/backend"

function* createQuestionaireCategory({ payload: { categoryData } }) {
    try {
        const response = yield call(backend.createQCate, categoryData);
        console.log(response);
        const insertData = response.data
        yield put(createQuestionaireCategorySuccessful(insertData))
    } catch (error) {
        if(typeof error.response.data !== "undefined") {
            yield put(createQuestionaireCategoryFailed(error.response.data.message))
            yield put(apiError(error.response.data.message));
        }
        else {
            yield put(createQuestionaireCategoryFailed(error.response))
            yield put(apiError(error.response));
        }
    }
}

export function* watchCreateQuestionaireCategory() {
    yield takeEvery(CREATE_QUESTIONAIRE_CATEGORY, createQuestionaireCategory)
}

function* questionaireCateSaga() {
    yield all([fork(watchCreateQuestionaireCategory)])
}

export default questionaireCateSaga