import React, { useEffect, useState, useContext  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireTemplate,
  createQuestionaireTemplateReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateTemplate, GetTemplateById, UpdateTemplate } from "services/api/module/Template"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"
import TemplateView from "pages/BannerImageProduct/BannerBox/TemplateView"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const TemplateTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qTemplateId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    name: Yup.string().max(100).required("Please Enter Template Name"),
    img: Yup.string().max(512).required("Please Enter Logo"),
    status: Yup.string().max(100).required("Please Enter Status"),

  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      img: "https://img5.pic.in.th/file/secure-sv1/20190308164109_tem.jpg",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ TemplateCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        name: values.name,
        img: values.img,
        status: Number(values.status)
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateTemplate(payload)
      } else {
        API.fetchCreateTemplate(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("name", resData.name)
    validation.setFieldValue("img", resData.img)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetTemplateById: async (payload) => {
      try {
          const response = await GetTemplateById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateTemplate: async (payload) => {
      try {
          const response = await CreateTemplate({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/banner-supplier/template')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateTemplate: async (payload) => {
      try {
          const response = await UpdateTemplate({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/banner-supplier/template')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id : qTemplateId }
      API.fetchGetTemplateById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Template | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Template`}
            breadcrumbItems={[
              { title: "Template", link: "article/category" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Template Infomation</h5>
                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Template Name
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="name"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name}
                                    invalid={validation.touched.name && validation.errors.name ? true : false}
                                    disabled={true}
                                  />
                                  { 
                                    validation.touched.name && validation.errors.name && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.name}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Status (Active/Inactive)
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Status"
                                    value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                                    onChange={(value) => validation.setFieldValue("status", value.id)}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    options={STATUS_OPTION}
                                    className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                                    isDisabled={pageView === "view"}
                                  />
                                  {
                                    (validation.touched.status && validation.errors.status) && (
                                      <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                                    )
                                  }
                                  { 
                                    validation.touched.status && validation.errors.status && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.status}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Grid Example
                                </label>
                                <div className="col-md-12">
                                  {/* <img  className="rs1-1" src={validation.values.img} alt="template-img"/> */}
                                  <TemplateView useTemplateName={validation.values.name}/>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                        </Row>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/banner-supplier/template')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/banner-supplier/template')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// TemplateTemplate.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(TemplateTemplate)
