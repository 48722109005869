import { get, post, put, patch } from "../Config"

export function SearchAboutUsReward({ params = {} }) {
  return get({ url: `/v1/admin/aboutUsReward`, params })
}
export function GetAboutUsRewardById({ id }) {
  return get({ url: `/v1/admin/aboutUsReward/${id}` })
}
export function CreateAboutUsReward({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/aboutUsReward`, params, data })
}
export function UpdateAboutUsReward({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/aboutUsReward/${id}`, params, data })
}
export function PatchAboutUsReward({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/aboutUsReward/${id}`, params, data })
}
export function DeleteAboutUsReward({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/aboutUsReward/mutiDelete`, params, data })
}
