import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateProducts,
  GetProductsById,
  UpdateProducts,
} from "services/api/module/Products"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]
const BOOLEAN_OPTION = [
  { id: "0", name: "No" },
  { id: "1", name: "Yes" },
]

const ProductsTemplate = ({ pageView = "create" }) => {
  const [activeTab, setActiveTab] = useState("main-info")
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qProductsId = qParams.id
  const navigate = useNavigate()

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    product_code: Yup.string().required("Please Enter Product Code"),
    name_th: Yup.string().required("Please Enter Thai Name"),
    status: Yup.string().required("Please Select Status"),
    sort_order: Yup.number().required("Please Enter Sort Order"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] =
    useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      attribute_set_id: "",
      product_code: "",
      name_th: "",
      name_en: "",
      weight: "",
      weight_include_box: "",
      width: "",
      length: "",
      height: "",
      short_description: "",
      description: "",
      thumbnail: "",
      video_type: "",
      video_value: "",
      is_bestseller: "0",
      bestseller_number: "",
      is_new: "0",
      new_start: "",
      new_end: "",
      is_free_shipping: "0",
      is_installment_plan: "0",
      is_360_img: "0",
      is_in_stock: "1",
      is_pre_order: "0",
      is_variant: "0",
      sort_order: "",
      status: "0",
      seo_url_key: "",
      seo_meta_title: "",
      seo_meta_keyword: "",
      seo_meta_description: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ ProductsCreate ~ values:", values)
      const payload = {
        ...values,
        weight: values.weight ? parseFloat(values.weight) : null,
        weight_include_box: values.weight_include_box
          ? parseFloat(values.weight_include_box)
          : null,
        width: values.width ? parseFloat(values.width) : null,
        length: values.length ? parseFloat(values.length) : null,
        height: values.height ? parseFloat(values.height) : null,
        video_type: values.video_type ? parseInt(values.video_type) : null,
        is_bestseller: parseInt(values.is_bestseller),
        bestseller_number: values.bestseller_number
          ? parseInt(values.bestseller_number)
          : null,
        is_new: parseInt(values.is_new),
        is_free_shipping: parseInt(values.is_free_shipping),
        is_installment_plan: parseInt(values.is_installment_plan),
        is_360_img: parseInt(values.is_360_img),
        is_in_stock: parseInt(values.is_in_stock),
        is_pre_order: parseInt(values.is_pre_order),
        is_variant: parseInt(values.is_variant),
        sort_order: parseInt(values.sort_order),
        status: parseInt(values.status),
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.fetchUpdateProducts(payload)
      } else {
        API.fetchCreateProducts(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("attribute_set_id", resData.attribute_set_id)
    validation.setFieldValue("product_code", resData.product_code)
    validation.setFieldValue("name_th", resData.name_th)
    validation.setFieldValue("name_en", resData.name_en)
    validation.setFieldValue("weight", resData.weight)
    validation.setFieldValue("weight_include_box", resData.weight_include_box)
    validation.setFieldValue("width", resData.width)
    validation.setFieldValue("length", resData.length)
    validation.setFieldValue("height", resData.height)
    validation.setFieldValue("short_description", resData.short_description)
    validation.setFieldValue("description", resData.description)
    validation.setFieldValue("thumbnail", resData.thumbnail)
    validation.setFieldValue("video_type", resData.video_type)
    validation.setFieldValue("video_value", resData.video_value)
    validation.setFieldValue("is_bestseller", resData.is_bestseller?.toString())
    validation.setFieldValue("bestseller_number", resData.bestseller_number)
    validation.setFieldValue("is_new", resData.is_new?.toString())
    validation.setFieldValue("new_start", resData.new_start)
    validation.setFieldValue("new_end", resData.new_end)
    validation.setFieldValue(
      "is_free_shipping",
      resData.is_free_shipping?.toString()
    )
    validation.setFieldValue(
      "is_installment_plan",
      resData.is_installment_plan?.toString()
    )
    validation.setFieldValue("is_360_img", resData.is_360_img?.toString())
    validation.setFieldValue("is_in_stock", resData.is_in_stock?.toString())
    validation.setFieldValue("is_pre_order", resData.is_pre_order?.toString())
    validation.setFieldValue("is_variant", resData.is_variant?.toString())
    validation.setFieldValue("sort_order", resData.sort_order)
    validation.setFieldValue("status", resData.status?.toString())
    validation.setFieldValue("seo_url_key", resData.seo_url_key)
    validation.setFieldValue("seo_meta_title", resData.seo_meta_title)
    validation.setFieldValue("seo_meta_keyword", resData.seo_meta_keyword)
    validation.setFieldValue(
      "seo_meta_description",
      resData.seo_meta_description
    )
  }

  const API = {
    fetchGetProductsById: async payload => {
      try {
        const response = await GetProductsById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateProducts: async payload => {
      try {
        const response = await CreateProducts({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/products")
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateProducts: async payload => {
      try {
        const response = await UpdateProducts({ data: payload, id: payload.id })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/products")
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qProductsId }
      API.fetchGetProductsById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `Products | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Products`}
            breadcrumbItems={[
              { title: "Products", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom mb-4">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "main-info",
                        })}
                        onClick={() => setActiveTab("main-info")}
                      >
                        Main Info
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "detail",
                        })}
                        onClick={() => setActiveTab("detail")}
                      >
                        Detail
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "specification",
                        })}
                        onClick={() => setActiveTab("specification")}
                      >
                        Specification
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "image-gallery",
                        })}
                        onClick={() => setActiveTab("image-gallery")}
                      >
                        Image Gallery
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="main-info">
                        <Row className="mb-3">
                          <Col className="col-12">
                            <Row className="">
                              <Col className="col-12">
                                <h5 className="mb-3">Products Infomation</h5>
                              </Col>
                              <Col className="col-6">
                                <Row className="">
                                  <Col className="col-12 mb-3">
                                    <label
                                      htmlFor="category_name"
                                      className="col-md-12 col-form-label"
                                    >
                                      Status (Active/Inactive)
                                      <span className="t-require">*</span>
                                    </label>
                                    <div className="col-md-12">
                                      <Select
                                        placeholder="Select Status"
                                        value={fn_helper.FNFORM.getObjectValue(
                                          STATUS_OPTION,
                                          `${validation.values.status}`
                                        )}
                                        onChange={value =>
                                          validation.setFieldValue(
                                            "status",
                                            value.id
                                          )
                                        }
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.name}
                                        options={STATUS_OPTION}
                                        className={`select2-selection ${
                                          validation.touched.status &&
                                          validation.errors.status
                                            ? "input-err"
                                            : ""
                                        }`}
                                        isDisabled={pageView === "view"}
                                      />
                                      {validation.touched.status &&
                                        validation.errors.status && (
                                          <div
                                            type="invalid"
                                            className="invalid-feedback d-block"
                                          >
                                            {validation.errors.status}
                                          </div>
                                        )}
                                      {validation.touched.status &&
                                        validation.errors.status && (
                                          <FormFeedback type="invalid">
                                            {validation.errors.status}
                                          </FormFeedback>
                                        )}
                                    </div>
                                  </Col>
                                  <Col className="col-12 mb-3">
                                    <label className="col-md-12 col-form-label">
                                      Product Thai Name
                                      <span className="t-require">*</span>
                                    </label>
                                    <div className="col-md-12">
                                      <Input
                                        name="name_th"
                                        value={validation.values.name_th}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched.name_th &&
                                          validation.errors.name_th
                                        }
                                        disabled={pageView === "view"}
                                      />
                                      {validation.touched.name_th &&
                                        validation.errors.name_th && (
                                          <FormFeedback>
                                            {validation.errors.name_th}
                                          </FormFeedback>
                                        )}
                                    </div>
                                  </Col>
                                  <Col className="col-6 mb-3">
                                    <label
                                      htmlFor="category_name"
                                      className="col-md-12 col-form-label"
                                    >
                                      Select Category
                                      <span className="t-require">*</span>
                                    </label>
                                    <div className="col-md-12">
                                      <Select
                                        placeholder="Select Category"
                                        value={fn_helper.FNFORM.getObjectValue(
                                          ddCategory,
                                          `${validation.values.categoryId}`
                                        )}
                                        onChange={value =>
                                          validation.setFieldValue(
                                            "categoryId",
                                            value.id
                                          )
                                        }
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.name}
                                        options={ddCategory}
                                        className={`select2-selection ${
                                          validation.touched.categoryId &&
                                          validation.errors.categoryId
                                            ? "input-err"
                                            : ""
                                        }`}
                                        isDisabled={["update", "view"].includes(
                                          pageView
                                        )}
                                      />
                                      {validation.touched.categoryId &&
                                        validation.errors.categoryId && (
                                          <div
                                            type="invalid"
                                            className="invalid-feedback d-block"
                                          >
                                            {validation.errors.categoryId}
                                          </div>
                                        )}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col className="col-6">
                                <Row>
                                  <Col className="col-12 mb-3">
                                    <label
                                      htmlFor="product_code"
                                      className="col-md-12 col-form-label"
                                    >
                                      Product Code
                                      <span className="t-require">*</span>
                                    </label>
                                    <div className="col-md-12">
                                      <Input
                                        id="product_code"
                                        className="form-control"
                                        type="text"
                                        name="product_code"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.product_code}
                                        invalid={
                                          validation.touched.product_code &&
                                          validation.errors.product_code
                                        }
                                        disabled={pageView === "view"}
                                      />
                                      {validation.touched.product_code &&
                                        validation.errors.product_code && (
                                          <FormFeedback type="invalid">
                                            {validation.errors.product_code}
                                          </FormFeedback>
                                        )}
                                    </div>
                                  </Col>
                                  <Col className="col-12 mb-3">
                                    <label className="col-md-12 col-form-label">
                                      Product English Name
                                    </label>
                                    <div className="col-md-12">
                                      <Input
                                        name="name_en"
                                        value={validation.values.name_en}
                                        onChange={validation.handleChange}
                                        disabled={pageView === "view"}
                                      />
                                    </div>
                                  </Col>
                                  <Col className="col-12 mb-3">
                                    <label className="col-md-12 col-form-label">
                                      Sort Order
                                      <span className="t-require">*</span>
                                    </label>
                                    <div className="col-md-12">
                                      <Input
                                        type="number"
                                        name="sort_order"
                                        value={validation.values.sort_order}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched.sort_order &&
                                          validation.errors.sort_order
                                        }
                                        disabled={pageView === "view"}
                                      />
                                      {validation.touched.sort_order &&
                                        validation.errors.sort_order && (
                                          <FormFeedback>
                                            {validation.errors.sort_order}
                                          </FormFeedback>
                                        )}
                                    </div>
                                  </Col>
                                  <Col className="col-12 mb-3">
                                    <label className="col-md-12 col-form-label">
                                      Bestseller
                                    </label>
                                    <div className="col-md-12">
                                      <Select
                                        value={fn_helper.FNFORM.getObjectValue(
                                          BOOLEAN_OPTION,
                                          `${validation.values.is_bestseller}`
                                        )}
                                        onChange={value =>
                                          validation.setFieldValue(
                                            "is_bestseller",
                                            value.id
                                          )
                                        }
                                        options={BOOLEAN_OPTION}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.name}
                                        isDisabled={pageView === "view"}
                                      />
                                    </div>
                                  </Col>
                                  {validation.values.is_bestseller === "1" && (
                                    <Col className="col-12 mb-3">
                                      <label className="col-md-12 col-form-label">
                                        Bestseller Number
                                      </label>
                                      <div className="col-md-12">
                                        <Input
                                          type="number"
                                          name="bestseller_number"
                                          value={
                                            validation.values.bestseller_number
                                          }
                                          onChange={validation.handleChange}
                                          disabled={pageView === "view"}
                                        />
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mt-4">
                              <Col className="col-12">
                                <h5 className="mb-3">Product Dimensions</h5>
                              </Col>
                            </Row>
                          </Col>

                          <Col className="col-12 mb-3">
                            <div className="col-md-12">
                              <UploadFileAPI
                                bucketName="partners"
                                upload="Image"
                                typeUpload="ImageMap"
                                prefixName="partners-image"
                                label="Upload Image"
                                required={true}
                                widthSize={288}
                                heightSize={188}
                                description="description"
                                value={validation.values.image}
                                onChange={([imageURL = "", imageSize = ""]) => {
                                  validation.setFieldValue("image", imageURL)
                                }}
                              />
                              <Input
                                id="image"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="image"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.image}
                                invalid={
                                  validation.touched.image &&
                                  validation.errors.image
                                    ? true
                                    : false
                                }
                                readOnly={true}
                                disabled={pageView === "view"}
                              />
                              {validation.touched.image &&
                                validation.errors.image && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.image}
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      {pageView == "view" ? (
                        <>
                          <button
                            className="btn btn-primary w-md"
                            onClick={() => navigate("/partners")}
                          >
                            BACK
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-secondary w-md"
                            onClick={() => navigate("/partners")}
                          >
                            CANCEL
                          </button>
                        </>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(ProductsTemplate)
