import PropTypes from 'prop-types'
import React from "react"
import { Modal, ModalBody } from "reactstrap"

const NoQuestionModal = ({ show, onCloseClick }) => {
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-warning bg-opacity-10 font-size-20 rounded-3">
              <i className='bx bx-question-mark' ></i>
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">Not have any questions.</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Close</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

NoQuestionModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any
}

export default NoQuestionModal
