import {
    UPDATE_QUESTIONAIRE_CATEGORY,
    UPDATE_QUESTIONAIRE_CATEGORY_SUCCESSFUL,
    UPDATE_QUESTIONAIRE_CATEGORY_FAILED,
    UPDATE_QUESTIONAIRE_CATEGORY_RESET,
} from "./actionsType"

const initialState = {
    message: null,
    loading: false,
    categoryData: null,
    updateError: null,
    updateSuccess: false
}

const questionaireCategoryEdit = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_QUESTIONAIRE_CATEGORY:
            state = {
                ...state,
                loading: true,
                updateSuccess: false,
                updateError: null,
            }
            break
        case UPDATE_QUESTIONAIRE_CATEGORY_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                categoryData: action.payload,
                updateSuccess: true,
                updateError: null,
            }
            break
        case UPDATE_QUESTIONAIRE_CATEGORY_FAILED:
            state = {
                ...state,
                categoryData: null,
                loading: false,
                updateSuccess: false,
                updateError: action.payload,
            }
            break
        case UPDATE_QUESTIONAIRE_CATEGORY_RESET:
            state = {
                ...state,
                message: null,
                loading: false,
                updateError: null,
                updateSuccess: false
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export default questionaireCategoryEdit