import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { UPDATE_QUESTIONAIRE_CATEGORY } from "./actionsType"
import { updateQuestionaireCategorySuccessful, updateQuestionaireCategoryFailed } from "./actions"
import { apiError } from "store/actions"

import * as backend from "helpers/backend"

function* updateQuestionaireCategory({ payload: { categoryData } }) {
    try {
        const response = yield call(backend.updateQCate, categoryData);
        console.log(response);
        const updateData = response.data
        yield put(updateQuestionaireCategorySuccessful(updateData))
    } catch (error) {
        console.log(error)
        if(typeof error.response.data !== "undefined") {
            yield put(updateQuestionaireCategoryFailed(error.response.data.message))
            yield put(apiError(error.response.data.message));
        }
        else {
            yield put(updateQuestionaireCategoryFailed(error.response))
            yield put(apiError(error.response));
        }
    }
}

export function* watchUpdateQuestionaireCategory() {
    yield takeEvery(UPDATE_QUESTIONAIRE_CATEGORY, updateQuestionaireCategory)
}

function* questionaireUpdateSaga() {
    yield all([fork(watchUpdateQuestionaireCategory)])
}

export default questionaireUpdateSaga