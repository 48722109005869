import { get, post, put, patch } from "../Config"

export function SearchReward({ params = {} }) {
  return get({ url: `/v1/admin/reward`, params })
}
export function GetRewardById({ id }) {
  return get({ url: `/v1/admin/reward/${id}` })
}
export function CreateReward({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/reward`, params, data })
}
export function UpdateReward({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/reward/${id}`, params, data })
}
export function PatchReward({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/reward/${id}`, params, data })
}
export function DeleteReward({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/reward/mutiDelete`, params, data })
}
