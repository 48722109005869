import { get, post, put } from "../Config"

export function SearchBannerImageProduct({ params = {} }) {
  return get({ url: `/v1/admin/bannerSupplierMain/image`, params })
}
export function GetBannerImageProductById({ id }) {
  return get({ url: `/v1/admin/bannerSupplierImageProduct/${id}` })
}
export function CreateBannerImageProduct({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerSupplierImageProduct`, params, data })
}
export function UpdateBannerImageProduct({ params = {}, data = {} , id }) {
  return post({ url: `/v1/admin/bannerSupplierImageProduct/mutiUpdate`, params, data })
}
export function DeleteBannerImageProduct({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerSupplierImageProduct/mutiDelete`, params, data })
}
