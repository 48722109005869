import { get, post, put, patch } from "../Config"

export function SearchJobPosition({ params = {} }) {
  return get({ url: `/v1/admin/jobPosition`, params })
}
export function GetJobPositionById({ id }) {
  return get({ url: `/v1/admin/jobPosition/${id}` })
}
export function CreateJobPosition({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/jobPosition`, params, data })
}
export function UpdateJobPosition({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/jobPosition/${id}`, params, data })
}
export function PatchJobPosition({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/jobPosition/${id}`, params, data })
}
export function DeleteJobPosition({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/jobPosition/mutiDelete`, params, data })
}
