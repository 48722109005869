import { get, post, put, patch } from "../Config"

export function SearchPromotionCategory({ params = {} }) {
  return get({ url: `/v1/admin/promotionCategory`, params })
}
export function GetPromotionCategoryById({ id }) {
  return get({ url: `/v1/admin/promotionCategory/${id}` })
}
export function CreatePromotionCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/promotionCategory`, params, data })
}
export function UpdatePromotionCategory({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/promotionCategory/${id}`, params, data })
}
export function PatchPromotionCategory({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/promotionCategory/${id}`, params, data })
}
export function DeletePromotionCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/promotionCategory/mutiDelete`, params, data })
}
export function CheckDuplicatePromotionCategory({ params = {}, data = {}}) {
  return post({ url: `/v1/admin/promotionCategory/duplicate`, params, data })
}
