import { get, post, put, patch } from "../Config"

export function SearchOrderReward({ params = {} }) {
  return get({ url: `/v1/admin/orderReward`, params })
}
export function GetOrderRewardById({ id }) {
  return get({ url: `/v1/admin/orderReward/${id}` })
}
export function CreateOrderReward({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/orderReward`, params, data })
}
export function UpdateOrderReward({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/orderReward/${id}`, params, data })
}
export function PatchOrderReward({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/orderReward/${id}`, params, data })
}
export function DeleteOrderReward({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/orderReward/mutiDelete`, params, data })
}
