import { get, post, put, patch } from "../Config"

export function SearchBannerMain({ params = {} }) {
  return get({ url: `/v1/admin/bannerSupplierMain`, params })
}
export function GetBannerMainById({ id }) {
  return get({ url: `/v1/admin/bannerSupplierMain/${id}` })
}
export function CreateBannerMain({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerSupplierMain`, params, data })
}
export function UpdateBannerMain({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/bannerSupplierMain/${id}`, params, data })
}
export function PatchBannerMain({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/bannerSupplierMain/${id}`, params, data })
}
export function DeleteBannerMain({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerSupplierMain/mutiDelete`, params, data })
}
