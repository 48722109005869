import React, { useEffect, useState, useContext, useRef  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../../store/actions"


//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateJobApplication, GetJobApplicationById, UpdateJobApplication } from "services/api/module/JobApplication"
import ModalContext from "../../../contexts/ModalContext";
import { DropdownJobType, DropdownJobPosition } from "services/api/module/Dropdown"
import Flatpickr from "react-flatpickr";
import moment from "moment"
import UploadFileAPI from "components/UploadFileAPI"
import InputRadio from "components/Input/InputRadio"
import InputSwitch from "components/Input/InputSwitch"
import MyEditor from "../../../components/MyEditor"
import Spinners from "components/Common/Spinner";

const WORK_PATTERN_OPTION = fn_helper.OPTIONS.WORK_PATTERN_OPTION

const numComma = (value) => fn_helper.FN.toNumberWithCommas(`${value}`)
const toDateStr = (value) => moment(value).format('DD/MM/YYYY')

const CONSENT_OPTION = [
  { id: 'false', name: "รอตรวจสอบ" },
  { id: 'true', name: "ตรวจสอบแล้ว" },
]

const STEP_JOB_OPTION = [
  { id: 1, name: 'รอสัมภาษณ์' },
  { id: 2, name: 'ผ่าน' },
  { id: 3, name: 'ไม่ผ่าน' },
]

const JobApplicationTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qJobApplicationId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    isConsent: Yup.boolean().required("Please Enter Consent Status"),
    firstInterview: Yup.string().max(100).required("Please Enter FirstInter View"),
    secondInterview: Yup.string().max(100).required("Please Enter SecondInter View"),
    thirdInterview: Yup.string().max(100).required("Please Enter ThirdInter View"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddJobPosition, setDDJobPosition] = useState([])
  const [ddJobType, setDDJobType] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)
  const [ddLoaded1, setDDLoaded1] = useState(DEF_LOADED)
  const [formLoaded, setFormLoaded] = useState(DEF_LOADED)
  const prevSelectDDJobTypeRef = useRef();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      
      // ลักษณะงานที่ต้องการ
      FROM1_jobTypeName: "",
      FROM1_jobPositionName: "",
      FROM1_jobPositionWorkPattern: "",
      FROM1_expectSalary: "",
      FROM1_startDate: "",

      // ประวัติส่วนตัว
      FROM1_prefixName: "",
      FROM1_fullName: "",
      FROM1_idCard: "",
      FROM1_gender: "",
      FROM1_birthDate: "",
      FROM1_age: "",
      FROM1_ethnicity: "",
      FROM1_nationality: "",
      FROM1_religion: "",
      FROM1_provinceOfBirthName: "",

      // ที่อยู่ปัจจุบัน
      FROM2_address: "",
      FROM2_provinceName: "",
      FROM2_amphureName: "",
      FROM2_tombonName: "",
      FROM2_postcode: "",
      FROM2_contactMobileNo: "",
      FROM2_contactEmail: "",
      FROM2_contactLineId: "",

      // สถานภาพ
      FROM1_maritalStatus: "",
      FROM1_maritalName: "",
      FROM1_maritalJob: "",
      FROM1_maritalCompany: "",
      FROM1_numberOfChild: "",
      FROM1_numberOfChildStudy: "",
      FROM1_militaryStatus: "",

      // ข้อมูลผู้ติดต่อฉุกเฉิน
      FROM1_eContactName: "",
      FROM1_eContactRelation: "",
      FROM1_eContactMobileNo: "",
      FROM1_eContactAddress: "",
      FROM1_eContactProvince: "",
      FROM1_eContactAmphure: "",
      FROM1_eContactTombon: "",
      FROM1_eContactPostcode: "",

      // ประวัติการศึกษา
      FROM3_degreeHighest: "",
      FROM3_university: "",
      FROM3_department: "",
      FROM3_graduateYear: "",
      FROM3_gpax: "",

      // ประวัติการฝึกอบรม
      FROM3_trainingHistory: [],

      // ประวัติการทำงาน
      FROM3_workHistory: [],

      // ทักษะความสามารถพิเศษ

      // ทักษะด้านภาษา
      FROM4_thListeningSkill: "",
      FROM4_thSpeakingSkill: "",
      FROM4_thReadingSkill: "",
      FROM4_thWritingSkill: "",
      FROM4_enListeningSkill: "",
      FROM4_enSpeakingSkill: "",
      FROM4_enReadingSkill: "",
      FROM4_enWritingSkill: "",

      // ทักษะด้านคอมพิวเตอร์
      FROM4_computerSkillExcel: "",
      FROM4_computerSkillWord: "",
      FROM4_computerSkillWindows: "",
      FROM4_computerSkillOther: "",

      // ทักษะด้านการพิมพ์
      FROM4_typingThai: "",
      FROM4_typingEng: "",

      // ความสามารถในการขับรถ
      FROM4_canDriveCar: "",
      FROM4_carLicense: "",
      FROM4_canDriveMotercycle: "",
      FROM4_motercycleLicense: "",
      FROM4_otherSkill: "",

      // เกี่ยวกับ JIB
      FROM1_jibChannel: "",
      FROM1_jibPersonName: "",
      FROM1_jibPersonPosition: "",
      FROM1_jibPersonRelation: "",

      // การประเมินหลังสัมภาษณ์
      isConsent: 'false',
      firstInterview: "",
      secondInterview: "",
      thirdInterview: "",

    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ JobApplicationCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        isConsent: values.isConsent === 'true' ? true : false,
        firstInterview: values.firstInterview,
        secondInterview: values.secondInterview,
        thirdInterview: values.thirdInterview,
        
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateJobApplication(payload)
      } else {
        API.fetchCreateJobApplication(payload)
      }
    },
  })

  // SELECT DATA
  const selectedDDJobType = ddJobType.find(item => item.id == validation.values.articleKeywordIds)

  const preFilInput = (resData = {}) => {
    console.log("🚀 ~ preFilInput ~ resData:", resData)
    const workPatternName = WORK_PATTERN_OPTION.find(item => item.id === resData.application.jobPositionWorkPattern)?.name ?? ''

    // warpPUI
    validation.setFieldValue("id", resData.application.id)

    // ลักษณะงานที่ต้องการ
    validation.setFieldValue("FROM1_jobTypeName", numComma(resData.application.jobTypeName))
    validation.setFieldValue("FROM1_jobPositionName", numComma(resData.application.jobPositionName))
    validation.setFieldValue("FROM1_jobPositionWorkPattern", numComma(workPatternName))
    validation.setFieldValue("FROM1_expectSalary", numComma(resData.application.expectSalary))
    validation.setFieldValue("FROM1_startDate", toDateStr(resData.application.startDate))

    // ประวัติส่วนตัว
    validation.setFieldValue("FROM1_prefixName", resData.profile.prefixName)
    validation.setFieldValue("FROM1_fullName", resData.profile.firstname + ' ' + resData.profile.lastname)
    validation.setFieldValue("FROM1_idCard", resData.profile.idCard)
    validation.setFieldValue("FROM1_gender", resData.profile.gender)
    validation.setFieldValue("FROM1_birthDate", toDateStr(resData.profile.birthDate))
    validation.setFieldValue("FROM1_age", resData.profile.age)
    validation.setFieldValue("FROM1_ethnicity", resData.profile.ethnicity)
    validation.setFieldValue("FROM1_nationality", resData.profile.nationality)
    validation.setFieldValue("FROM1_religion", resData.profile.religion)
    validation.setFieldValue("FROM1_provinceOfBirthName", resData.profile.provinceOfBirth)

    // ที่อยู่ปัจจุบัน
    validation.setFieldValue("FROM2_address", resData.contactAddress.address)
    validation.setFieldValue("FROM2_provinceName", resData.contactAddress.province)
    validation.setFieldValue("FROM2_amphureName", resData.contactAddress.amphure)
    validation.setFieldValue("FROM2_tombonName", resData.contactAddress.tombon)
    validation.setFieldValue("FROM2_postcode", resData.contactAddress.postcode)
    validation.setFieldValue("FROM2_contactMobileNo", resData.contactAddress.contactMobileNo)
    validation.setFieldValue("FROM2_contactEmail", resData.contactAddress.contactEmail)
    validation.setFieldValue("FROM2_contactLineId", resData.contactAddress.contactLineId)

    // สถานภาพ
    validation.setFieldValue("FROM1_maritalStatus", resData.profile.maritalStatus)
    validation.setFieldValue("FROM1_maritalName", resData.profile.maritalName)
    validation.setFieldValue("FROM1_maritalJob", resData.profile.maritalJob)
    validation.setFieldValue("FROM1_maritalCompany", resData.profile.maritalCompany)
    validation.setFieldValue("FROM1_numberOfChild", resData.profile?.numberOfChild ?? '0')
    validation.setFieldValue("FROM1_numberOfChildStudy", resData.profile?.numberOfChildStudy ?? '0')
    validation.setFieldValue("FROM1_militaryStatus", resData.profile.militaryStatus)

    // ข้อมูลผู้ติดต่อฉุกเฉิน
    validation.setFieldValue("FROM1_eContactName", resData.contactAddress.eContactName)
    validation.setFieldValue("FROM1_eContactRelation", resData.contactAddress.eContactRelation)
    validation.setFieldValue("FROM1_eContactMobileNo", resData.contactAddress.eContactMobileNo)
    validation.setFieldValue("FROM1_eContactAddress", resData.contactAddress.eContactAddress)
    validation.setFieldValue("FROM1_eContactProvince", resData.contactAddress.eContactProvince)
    validation.setFieldValue("FROM1_eContactAmphure", resData.contactAddress.eContactAmphure)
    validation.setFieldValue("FROM1_eContactTombon", resData.contactAddress.eContactTombon)
    validation.setFieldValue("FROM1_eContactPostcode", resData.contactAddress.eContactPostcode)

    // ประวัติการศึกษา
    validation.setFieldValue("FROM3_degreeHighest", resData.educationHistory.degreeHighest)
    validation.setFieldValue("FROM3_university", resData.educationHistory.university)
    validation.setFieldValue("FROM3_department", resData.educationHistory.department)
    validation.setFieldValue("FROM3_graduateYear", resData.educationHistory.graduateYear)
    validation.setFieldValue("FROM3_gpax", resData.educationHistory.gpax ? Number(`${resData.educationHistory.gpax}`).toFixed(2) : 0)

    // ประวัติการฝึกอบรม
    validation.setFieldValue("FROM3_trainingHistory", resData.trainingHistory)

    // ประวัติการทำงาน
    validation.setFieldValue("FROM3_workHistory", resData.workHistory)

    // ทักษะความสามารถพิเศษ

    // ทักษะด้านภาษา
    validation.setFieldValue("FROM4_thListeningSkill", resData.skill.thListeningSkill)
    validation.setFieldValue("FROM4_thSpeakingSkill", resData.skill.thSpeakingSkill)
    validation.setFieldValue("FROM4_thReadingSkill", resData.skill.thReadingSkill)
    validation.setFieldValue("FROM4_thWritingSkill", resData.skill.thWritingSkill)
    validation.setFieldValue("FROM4_enListeningSkill", resData.skill.enListeningSkill)
    validation.setFieldValue("FROM4_enSpeakingSkill", resData.skill.enSpeakingSkill)
    validation.setFieldValue("FROM4_enReadingSkill", resData.skill.enReadingSkill)
    validation.setFieldValue("FROM4_enWritingSkill", resData.skill.enWritingSkill)

    // ทักษะด้านคอมพิวเตอร์
    validation.setFieldValue("FROM4_computerSkillExcel", resData.skill.computerSkillExcel)
    validation.setFieldValue("FROM4_computerSkillWord", resData.skill.computerSkillWord)
    validation.setFieldValue("FROM4_computerSkillWindows", resData.skill.computerSkillWindows)
    validation.setFieldValue("FROM4_computerSkillOther", resData.skill.computerSkillOther)

    // ทักษะด้านการพิมพ์
    validation.setFieldValue("FROM4_typingThai", resData.skill.typingThai)
    validation.setFieldValue("FROM4_typingEng", resData.skill.typingEng)

    // ความสามารถในการขับรถ
    validation.setFieldValue("FROM4_canDriveCar", resData.skill.canDriveCar)
    validation.setFieldValue("FROM4_carLicense", resData.skill.carLicense)
    validation.setFieldValue("FROM4_canDriveMotercycle", resData.skill.canDriveMotercycle)
    validation.setFieldValue("FROM4_motercycleLicense", resData.skill.motercycleLicense)
    validation.setFieldValue("FROM4_otherSkill", resData.skill.otherSkill)

    // เกี่ยวกับ JIB
    validation.setFieldValue("FROM1_jibChannel", resData.profile.jibChannel)
    validation.setFieldValue("FROM1_jibPersonName", resData.profile.jibPersonName)
    validation.setFieldValue("FROM1_jibPersonPosition", resData.profile.jibPersonPosition)
    validation.setFieldValue("FROM1_jibPersonRelation", resData.profile.jibPersonRelation)

        // เอกสารสมัครงาน
        validation.setFieldValue("FROM5_personalImage", resData.document.personalImage)
        validation.setFieldValue("FROM5_resume", resData.document.resume)

    // การประเมินหลังสัมภาษณ์
    validation.setFieldValue("isConsent", `${resData.application.isConsent}` === 'true' ? 'true' : 'false')
    validation.setFieldValue("firstInterview", resData.application.firstInterview)
    validation.setFieldValue("secondInterview", resData.application.secondInterview)
    validation.setFieldValue("thirdInterview", resData.application.thirdInterview)



    // ติด Effect Clear State
    // setTimeout(() => validation.setFieldValue("articleKeywordIds", resData.articleKeywordIds), 100)
  }

  const API = {
    fetchDDJobPosition: async () => {
      try {
          const response = await DropdownJobPosition({})
          const resData = response?.data ?? []
          const tmpDD = resData.data
          console.log("🚀 ~ fetchDDJobPosition: ~ tmpDD:", tmpDD)
          setDDJobPosition(tmpDD);
          setDDLoaded0(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchDDJobType: async () => {
      try {
          const response = await DropdownJobType({})
          const resData = response?.data ?? []
          const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          setDDJobType(tmpDD);
          setDDLoaded1(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchGetJobApplicationById: async (payload) => {
      try {
          setFormLoaded(false)
          const response = await GetJobApplicationById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
      finally {
        setFormLoaded(true)
      }
    },
    fetchCreateJobApplication: async (payload) => {
      try {
          const response = await CreateJobApplication({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/jobs-managment/job-application')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateJobApplication: async (payload) => {
      try {
          const response = await UpdateJobApplication({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/jobs-managment/job-application')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    // if (ddLoaded0 && ddLoaded1) {
    if (true) {
      if (["update", "view"].includes(pageView)) {
        const payload = { id : qJobApplicationId }
        API.fetchGetJobApplicationById(payload)
      }
    }
  // }, [ddLoaded0, ddLoaded1])
  }, [ddLoaded0, ddLoaded1])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    // API.fetchDDJobType();
    // API.fetchDDJobPosition();
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Job Application | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Job Application`}
            breadcrumbItems={[
              { title: "Job Application", link: "article/article" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="">
                      <h5 className="mb-3">Job Application Infomation</h5>
                    </Row>
                    {
                      formLoaded 
                      ? (
                      <>
                        <Row>
                          <div className="mt-3">
                            <h5 className="mb-3">ลักษณะงานที่ต้องการ</h5>
                          </div>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              แผนกงาน
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_jobTypeName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_jobTypeName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_jobTypeName}
                                invalid={validation.touched.FROM1_jobTypeName && validation.errors.FROM1_jobTypeName ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_jobTypeName && validation.errors.FROM1_jobTypeName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_jobTypeName}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ตำแหน่ง
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_jobPositionName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_jobPositionName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_jobPositionName}
                                invalid={validation.touched.FROM1_jobPositionName && validation.errors.FROM1_jobPositionName ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_jobPositionName && validation.errors.FROM1_jobPositionName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_jobPositionName}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ประเภทงาน
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_jobPositionWorkPattern"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_jobPositionWorkPattern"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_jobPositionWorkPattern}
                                invalid={validation.touched.FROM1_jobPositionWorkPattern && validation.errors.FROM1_jobPositionWorkPattern ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_jobPositionWorkPattern && validation.errors.FROM1_jobPositionWorkPattern && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_jobPositionWorkPattern}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              เงินเดือนที่ต้องการ
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_expectSalary"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_expectSalary"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_expectSalary}
                                invalid={validation.touched.FROM1_expectSalary && validation.errors.FROM1_expectSalary ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_expectSalary && validation.errors.FROM1_expectSalary && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_expectSalary}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              วันที่เริ่มงานได้
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_startDate"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_startDate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_startDate}
                                invalid={validation.touched.FROM1_startDate && validation.errors.FROM1_startDate ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_startDate && validation.errors.FROM1_startDate && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_startDate}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">ประวัติส่วนตัว</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              คำนำหน้า
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_prefixName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_prefixName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_prefixName}
                                invalid={validation.touched.FROM1_prefixName && validation.errors.FROM1_prefixName ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_prefixName && validation.errors.FROM1_prefixName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_prefixName}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ชื่อ - นามสกุล
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_fullName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_fullName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_fullName}
                                invalid={validation.touched.FROM1_fullName && validation.errors.FROM1_fullName ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_fullName && validation.errors.FROM1_fullName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_fullName}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              เลขที่บัตรประชาชน
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_idCard"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_idCard"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_idCard}
                                invalid={validation.touched.FROM1_idCard && validation.errors.FROM1_idCard ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_idCard && validation.errors.FROM1_idCard && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_idCard}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              เพศ
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_gender"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_gender"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_gender}
                                invalid={validation.touched.FROM1_gender && validation.errors.FROM1_gender ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_gender && validation.errors.FROM1_gender && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_gender}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              วันเกิด
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_birthDate"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_birthDate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_birthDate}
                                invalid={validation.touched.FROM1_birthDate && validation.errors.FROM1_birthDate ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_birthDate && validation.errors.FROM1_birthDate && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_birthDate}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              อายุ
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_age"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_age"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_age}
                                invalid={validation.touched.FROM1_age && validation.errors.FROM1_age ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_age && validation.errors.FROM1_age && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_age}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              สัญชาติ
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_ethnicity"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_ethnicity"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_ethnicity}
                                invalid={validation.touched.FROM1_ethnicity && validation.errors.FROM1_ethnicity ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_ethnicity && validation.errors.FROM1_ethnicity && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_ethnicity}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              เชื้อชาติ
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_nationality"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_nationality"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_nationality}
                                invalid={validation.touched.FROM1_nationality && validation.errors.FROM1_nationality ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_nationality && validation.errors.FROM1_nationality && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_nationality}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ศาสนา
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_religion"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_religion"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_religion}
                                invalid={validation.touched.FROM1_religion && validation.errors.FROM1_religion ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_religion && validation.errors.FROM1_religion && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_religion}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ภูมิลำเนาเกิด
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_provinceOfBirthName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_provinceOfBirthName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_provinceOfBirthName}
                                invalid={validation.touched.FROM1_provinceOfBirthName && validation.errors.FROM1_provinceOfBirthName ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_provinceOfBirthName && validation.errors.FROM1_provinceOfBirthName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_provinceOfBirthName}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>


                        </Row>
                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">ที่อยู่ปัจจุบัน</h5>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              รายละเอียดที่อยู่
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM2_address"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM2_address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM2_address}
                                invalid={validation.touched.FROM2_address && validation.errors.FROM2_address ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM2_address && validation.errors.FROM2_address && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM2_address}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              จังหวัด
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM2_provinceName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM2_provinceName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM2_provinceName}
                                invalid={validation.touched.FROM2_provinceName && validation.errors.FROM2_provinceName ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM2_provinceName && validation.errors.FROM2_provinceName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM2_provinceName}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              อำเภอ/เขต
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM2_amphureName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM2_amphureName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM2_amphureName}
                                invalid={validation.touched.FROM2_amphureName && validation.errors.FROM2_amphureName ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM2_amphureName && validation.errors.FROM2_amphureName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM2_amphureName}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ตำบล/แขวง
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM2_tombonName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM2_tombonName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM2_tombonName}
                                invalid={validation.touched.FROM2_tombonName && validation.errors.FROM2_tombonName ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM2_tombonName && validation.errors.FROM2_tombonName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM2_tombonName}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              รหัสไปรษณีย์
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM2_postcode"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM2_postcode"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM2_postcode}
                                invalid={validation.touched.FROM2_postcode && validation.errors.FROM2_postcode ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM2_postcode && validation.errors.FROM2_postcode && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM2_postcode}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">ข้อมูลติดต่อ</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-6 col-form-label"
                            >
                              เบอร์โทรศัพท์
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM2_contactMobileNo"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM2_contactMobileNo"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM2_contactMobileNo}
                                invalid={validation.touched.FROM2_contactMobileNo && validation.errors.FROM2_contactMobileNo ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM2_contactMobileNo && validation.errors.FROM2_contactMobileNo && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM2_contactMobileNo}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              อีเมล
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM2_contactEmail"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM2_contactEmail"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM2_contactEmail}
                                invalid={validation.touched.FROM2_contactEmail && validation.errors.FROM2_contactEmail ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM2_contactEmail && validation.errors.FROM2_contactEmail && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM2_contactEmail}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Line ID
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM2_contactLineId"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM2_contactLineId"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM2_contactLineId}
                                invalid={validation.touched.FROM2_contactLineId && validation.errors.FROM2_contactLineId ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM2_contactLineId && validation.errors.FROM2_contactLineId && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM2_contactLineId}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">สถานภาพ</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              สถานภาพทางครอบครัว
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_maritalStatus"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_maritalStatus"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_maritalStatus}
                                invalid={validation.touched.FROM1_maritalStatus && validation.errors.FROM1_maritalStatus ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_maritalStatus && validation.errors.FROM1_maritalStatus && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_maritalStatus}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ชื่อคู่สมรส
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_maritalName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_maritalName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_maritalName}
                                invalid={validation.touched.FROM1_maritalName && validation.errors.FROM1_maritalName ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_maritalName && validation.errors.FROM1_maritalName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_maritalName}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              อาชีพ
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_maritalJob"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_maritalJob"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_maritalJob}
                                invalid={validation.touched.FROM1_maritalJob && validation.errors.FROM1_maritalJob ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_maritalJob && validation.errors.FROM1_maritalJob && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_maritalJob}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              สถานที่ทำงาน
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_maritalCompany"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_maritalCompany"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_maritalCompany}
                                invalid={validation.touched.FROM1_maritalCompany && validation.errors.FROM1_maritalCompany ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_maritalCompany && validation.errors.FROM1_maritalCompany && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_maritalCompany}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              จำนวนบุตร
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_numberOfChild"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_numberOfChild"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_numberOfChild}
                                invalid={validation.touched.FROM1_numberOfChild && validation.errors.FROM1_numberOfChild ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_numberOfChild && validation.errors.FROM1_numberOfChild && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_numberOfChild}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              จำนวนบุตรที่กำลังศึกษา
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_numberOfChildStudy"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_numberOfChildStudy"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_numberOfChildStudy}
                                invalid={validation.touched.FROM1_numberOfChildStudy && validation.errors.FROM1_numberOfChildStudy ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_numberOfChildStudy && validation.errors.FROM1_numberOfChildStudy && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_numberOfChildStudy}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              สถานะทางทหาร
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_militaryStatus"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_militaryStatus"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_militaryStatus}
                                invalid={validation.touched.FROM1_militaryStatus && validation.errors.FROM1_militaryStatus ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_militaryStatus && validation.errors.FROM1_militaryStatus && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_militaryStatus}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                        
                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">ข้อมูลผู้ติดต่อฉุกเฉิน</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ชื่อ-นามสกุล
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_eContactName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_eContactName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_eContactName}
                                invalid={validation.touched.FROM1_eContactName && validation.errors.FROM1_eContactName ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_eContactName && validation.errors.FROM1_eContactName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_eContactName}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ความสัมพันธ์
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_eContactRelation"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_eContactRelation"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_eContactRelation}
                                invalid={validation.touched.FROM1_eContactRelation && validation.errors.FROM1_eContactRelation ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_eContactRelation && validation.errors.FROM1_eContactRelation && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_eContactRelation}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              เบอร์ศัพท์
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_eContactMobileNo"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_eContactMobileNo"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_eContactMobileNo}
                                invalid={validation.touched.FROM1_eContactMobileNo && validation.errors.FROM1_eContactMobileNo ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_eContactMobileNo && validation.errors.FROM1_eContactMobileNo && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_eContactMobileNo}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              รายละเอียดที่อยู่
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_eContactAddress"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_eContactAddress"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_eContactAddress}
                                invalid={validation.touched.FROM1_eContactAddress && validation.errors.FROM1_eContactAddress ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_eContactAddress && validation.errors.FROM1_eContactAddress && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_eContactAddress}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              จังหวัด
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_eContactProvince"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_eContactProvince"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_eContactProvince}
                                invalid={validation.touched.FROM1_eContactProvince && validation.errors.FROM1_eContactProvince ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_eContactProvince && validation.errors.FROM1_eContactProvince && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_eContactProvince}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              อำเภอ/เขต
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_eContactAmphure"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_eContactAmphure"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_eContactAmphure}
                                invalid={validation.touched.FROM1_eContactAmphure && validation.errors.FROM1_eContactAmphure ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_eContactAmphure && validation.errors.FROM1_eContactAmphure && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_eContactAmphure}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ตำบล/แขวง
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_eContactTombon"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_eContactTombon"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_eContactTombon}
                                invalid={validation.touched.FROM1_eContactTombon && validation.errors.FROM1_eContactTombon ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_eContactTombon && validation.errors.FROM1_eContactTombon && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_eContactTombon}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              รหัสไปรษณย์
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_eContactPostcode"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_eContactPostcode"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_eContactPostcode}
                                invalid={validation.touched.FROM1_eContactPostcode && validation.errors.FROM1_eContactPostcode ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_eContactPostcode && validation.errors.FROM1_eContactPostcode && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_eContactPostcode}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">ประวัติการศึกษา</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ระดับการศึกษา
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM3_degreeHighest"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM3_degreeHighest"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM3_degreeHighest}
                                invalid={validation.touched.FROM3_degreeHighest && validation.errors.FROM3_degreeHighest ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM3_degreeHighest && validation.errors.FROM3_degreeHighest && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM3_degreeHighest}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              สถาบันการศึกษา
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM3_university"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM3_university"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM3_university}
                                invalid={validation.touched.FROM3_university && validation.errors.FROM3_university ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM3_university && validation.errors.FROM3_university && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM3_university}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              สาขาวิชาเอก
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM3_department"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM3_department"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM3_department}
                                invalid={validation.touched.FROM3_department && validation.errors.FROM3_department ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM3_department && validation.errors.FROM3_department && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM3_department}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ปีที่จบการศึกษา
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM3_graduateYear"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM3_graduateYear"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM3_graduateYear}
                                invalid={validation.touched.FROM3_graduateYear && validation.errors.FROM3_graduateYear ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM3_graduateYear && validation.errors.FROM3_graduateYear && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM3_graduateYear}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              เกรดเฉลี่ย
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM3_gpax"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM3_gpax"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM3_gpax}
                                invalid={validation.touched.FROM3_gpax && validation.errors.FROM3_gpax ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM3_gpax && validation.errors.FROM3_gpax && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM3_gpax}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">ประวัติการฝึกอบรม</h5>
                          </Col>
                          <Col className="col-12 mb-3">
                            <table className="table table-bordered table-striped table-nowrap mb-0">
                              <thead>
                                <tr>
                                  <th className="text-center" style={{ width: '60px' }}>
                                  ลำดับ<br/>
                                  </th>
                                  <th className="text-center">
                                  ชื่อหน่วยงาน/สถานที่ฝึกงาน<br/>
                                  </th>
                                  <th className="text-center">
                                  หลักสูตร/กิจกรรม<br/>
                                  </th>
                                  <th className="text-center">
                                  ระยะเวลา (เดือน)<br/>
                                  </th>
                                  <th className="text-center">
                                  ปีที่อบรม/ฝึกงาน<br/>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  Array.isArray(validation.values?.FROM3_trainingHistory)
                                  && validation.values.FROM3_trainingHistory.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <th className="text-center" scope="row">{i + 1}</th>
                                        <td className="text-center">{ item?.placeName ?? '-'}</td>
                                        <td className="text-center">{ item?.curriculum ?? '-' }</td>
                                        <td className="text-center">{ item?.period ?? '-' }</td>
                                        <td className="text-center">{ item?.year ?? '-' }</td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">ประวัติการทำงาน</h5>
                          </Col>
                          <Col className="col-12 mb-3">
                            <table className="table table-bordered  table-nowrap mb-0">
                              <thead>
                                <tr>
                                  <th className="text-center" style={{ width: '60px' }}>
                                    ลำดับ<br/>
                                  </th>
                                  <th className="text-center">
                                    ชื่อบริษัท<br/>
                                  </th>
                                  <th className="text-center">
                                    ตำแหน่ง<br/>
                                  </th>
                                  <th className="text-center">
                                    หน้าที่รับผิดชอบ<br/>
                                  </th>
                                  <th className="text-center">
                                    เงินเดือน<br/>
                                  </th>
                                  <th className="text-center">
                                    จากวันที่<br/>
                                  </th>
                                  <th className="text-center">
                                    ถึงวันที่<br/>
                                  </th>
                                  <th className="text-center" style={{ width: '280px' }}>
                                    สาเหตุที่ลาออก<br/>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  Array.isArray(validation.values?.FROM3_workHistory)
                                  && validation.values.FROM3_workHistory.map((item, i) => {
                                    let lineColor = i % 2 === 0 ? { '--bs-table-bg-type': '#F8F9FA' } : {}
                                    return (
                                      <>
                                      <tr className="row-car" style={lineColor}>
                                        <th className="text-center text-middle" scope="row" rowSpan={4}>{i + 1}</th>
                                        <td className="text-center">{item.company}</td>
                                        <td className="text-center">{item.rolePosition}</td>
                                        <td className="text-center">{item.responsibility}</td>
                                        <td className="text-center">{numComma(item.salary)}</td>
                                        <td className="text-center">{toDateStr(item.startDate)}</td>
                                        <td className="text-center">{toDateStr(item.endDate)}</td>
                                        <td className="text-center">{item.reason}</td>
                                      </tr>
                                      <tr style={lineColor}>
                                        <td className="text-center">บุคคลอ้างอิง</td>
                                        <td className="text-left" colSpan={6}>{item.referPersonName}</td>
                                      </tr>
                                      <tr style={lineColor}>
                                        <td className="text-center">ตำแหน่ง</td>
                                        <td className="text-left" colSpan={6}>{item.referPersonPosition}</td>
                                      </tr>
                                      <tr style={lineColor}>
                                        <td className="text-center">เบอร์โทรศัพท์</td>
                                        <td className="text-left" colSpan={6}>{fn_helper.FORMAT.TEL(`${item.referPersonMobileNo}`)}</td>
                                      </tr>
                                    </>
                                    )
                                  })
                                }
                                {/* <tr>
                                  <th className="text-nowrap" scope="row">ภาษาอังกฤษ</th>
                                  <td className="text-center">{ validation.values?.FROM4_typingEng ?? '0' }</td>
                                </tr> */}
                              </tbody>
                            </table>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">ทักษะความสามารถพิเศษ</h5>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ทักษะด้านภาษา
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <table className="table table-bordered table-striped table-nowrap mb-0">
                                <thead>
                                  <tr>
                                    <th className="text-center" style={{ width: '250px' }}>
                                      ภาษา<br/>
                                    </th>
                                    <th className="text-center">
                                      การฟัง<br/>
                                    </th>
                                    <th className="text-center">
                                      การพูด<br/>
                                    </th>
                                    <th className="text-center">
                                      การอ่าน<br/>
                                    </th>
                                    <th className="text-center">
                                      การเขียน<br/>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th className="text-nowrap" scope="row">ภาษาไทย</th>
                                    <td className="text-center">{ validation.values?.FROM4_thListeningSkill ?? '' }</td>
                                    <td className="text-center">{ validation.values?.FROM4_thSpeakingSkill ?? '' }</td>
                                    <td className="text-center">{ validation.values?.FROM4_thReadingSkill ?? '' }</td>
                                    <td className="text-center">{ validation.values?.FROM4_thWritingSkill ?? '' }</td>
                                  </tr>
                                  <tr>
                                    <th className="text-nowrap" scope="row">ภาษาอังกฤษ</th>
                                    <td className="text-center">{ validation.values?.FROM4_enListeningSkill ?? '' }</td>
                                    <td className="text-center">{ validation.values?.FROM4_enSpeakingSkill ?? '' }</td>
                                    <td className="text-center">{ validation.values?.FROM4_enReadingSkill ?? '' }</td>
                                    <td className="text-center">{ validation.values?.FROM4_enWritingSkill ?? '' }</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>

                          <Col className="col-12 ">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ทักษะด้านคอมพิวเตอร์
                              <span className="t-require">*</span>
                            </label>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              MS Excel
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM4_computerSkillExcel"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM4_computerSkillExcel"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM4_computerSkillExcel}
                                invalid={validation.touched.FROM4_computerSkillExcel && validation.errors.FROM4_computerSkillExcel ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM4_computerSkillExcel && validation.errors.FROM4_computerSkillExcel && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM4_computerSkillExcel}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              MS Word
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM4_computerSkillWord"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM4_computerSkillWord"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM4_computerSkillWord}
                                invalid={validation.touched.FROM4_computerSkillWord && validation.errors.FROM4_computerSkillWord ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM4_computerSkillWord && validation.errors.FROM4_computerSkillWord && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM4_computerSkillWord}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              MS Windows
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM4_computerSkillWindows"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM4_computerSkillWindows"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM4_computerSkillWindows}
                                invalid={validation.touched.FROM4_computerSkillWindows && validation.errors.FROM4_computerSkillWindows ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM4_computerSkillWindows && validation.errors.FROM4_computerSkillWindows && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM4_computerSkillWindows}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              อื่นๆ
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM4_computerSkillOther"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM4_computerSkillOther"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM4_computerSkillOther}
                                invalid={validation.touched.FROM4_computerSkillOther && validation.errors.FROM4_computerSkillOther ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM4_computerSkillOther && validation.errors.FROM4_computerSkillOther && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM4_computerSkillOther}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>


                          <Col className="col-12">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ทักษะด้านการพิมพ์
                              <span className="t-require">*</span>
                            </label>
                          </Col>
                          <Col className="col-12 mb-3">
                            <table className="table table-bordered table-striped table-nowrap mb-0">
                              <thead>
                                <tr>
                                  <th className="text-center" style={{ width: '250px' }}>
                                  ภาษา<br/>
                                  </th>
                                  <th className="text-center">
                                    จำนวนคำ/นาที<br/>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th className="text-nowrap" scope="row">ภาษาไทย</th>
                                  <td className="text-center">{ validation.values?.FROM4_typingThai ?? '0' }</td>
                                </tr>
                                <tr>
                                  <th className="text-nowrap" scope="row">ภาษาอังกฤษ</th>
                                  <td className="text-center">{ validation.values?.FROM4_typingEng ?? '0' }</td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ความสามารถในการขับรถ
                              <span className="t-require">*</span>
                            </label>
                          </Col>
                          <Col className="col-12 mb-3">
                            <div className="table-responsive">
                              <table className="table table-bordered table-striped table-nowrap mb-0">
                                <thead>
                                  <tr>
                                    <th className="text-center" style={{ width: '250px' }}>
                                      ประเภทรถ<br/>
                                    </th>
                                    <th className="text-center">
                                      การขับ<br/>
                                    </th>
                                    <th className="text-center">
                                      ใบขับขี่<br/>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th className="text-nowrap" scope="row">รถยนต์</th>
                                    <td className="text-center">{ validation.values.FROM4_canDriveCar ? 'ได้': 'ไม่ได้' }</td>
                                    <td className="text-center">{ validation.values.FROM4_carLicense ? 'มี': 'ไม่มี' }</td>
                                  </tr>
                                  <tr>
                                    <th className="text-nowrap" scope="row">รถจักรยานยนต์</th>
                                    <td className="text-center">{ validation.values.FROM4_canDriveMotercycle ? 'ได้': 'ไม่ได้' }</td>
                                    <td className="text-center">{ validation.values.FROM4_motercycleLicense ? 'มี': 'ไม่มี' }</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ทักษะหรือความสามารถอื่นๆ
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM4_otherSkill"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM4_otherSkill"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM4_otherSkill}
                                invalid={validation.touched.FROM4_otherSkill && validation.errors.FROM4_otherSkill ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM4_otherSkill && validation.errors.FROM4_otherSkill && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM4_otherSkill}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">เกี่ยวกับ JIB</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              รู้จัก JIB จาก
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_jibChannel"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_jibChannel"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_jibChannel}
                                invalid={validation.touched.FROM1_jibChannel && validation.errors.FROM1_jibChannel ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_jibChannel && validation.errors.FROM1_jibChannel && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_jibChannel}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              บุคคลในบริษัท JIB ที่รู้จัก
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_jibPersonName"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_jibPersonName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_jibPersonName}
                                invalid={validation.touched.FROM1_jibPersonName && validation.errors.FROM1_jibPersonName ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_jibPersonName && validation.errors.FROM1_jibPersonName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_jibPersonName}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ตำแหน่ง
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_jibPersonPosition"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_jibPersonPosition"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_jibPersonPosition}
                                invalid={validation.touched.FROM1_jibPersonPosition && validation.errors.FROM1_jibPersonPosition ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_jibPersonPosition && validation.errors.FROM1_jibPersonPosition && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_jibPersonPosition}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              ความสัมพันธ์
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="FROM1_jibPersonRelation"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="FROM1_jibPersonRelation"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FROM1_jibPersonRelation}
                                invalid={validation.touched.FROM1_jibPersonRelation && validation.errors.FROM1_jibPersonRelation ? true : false}
                                disabled={pageView === "view"}
                                readOnly={true}
                              />
                              { 
                                validation.touched.FROM1_jibPersonRelation && validation.errors.FROM1_jibPersonRelation && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FROM1_jibPersonRelation}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">เอกสารสมัครงาน</h5>
                          </Col>
                          <Col className="col-12 mb-3">
                          <table className="table table-bordered table-striped table-nowrap mb-0">
                              <thead>
                                <tr>
                                  <th className="text-center" style={{ width: '450px' }}>
                                    รูป<br/>
                                  </th>
                                  <th className="text-center">
                                    Resume<br/>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr style={{ cursor: 'auto' }}>
                                  <th className="text-nowrap" scope="row">
                                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                                      {
                                        validation.values?.FROM5_personalImage
                                        ? <img src={validation.values?.FROM5_personalImage} style={{ height: 240 }} alt="personal-image"/>
                                        : <div></div>
                                      }
                                    </div>
                                  </th>
                                  <td className="text-nowrap" scope="row">
                                    <div>
                                      {
                                        validation.values?.FROM5_resume
                                        ? (
                                          <div style={{ paddingTop: '60px'}}>
                                            <a href={validation.values?.FROM5_resume} target="blank">
                                              <div style={{ display: 'flex',alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '8px' }}>
                                                  <div className="bx bxs-file-pdf bx-lg"></div>
                                                  <h4>PDF File</h4>
                                              </div>
                                            </a>
                                          </div>
                                        )
                                          
                                        : <div></div>
                                      }
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">การประเมินหลังสัมภาษณ์</h5>
                          </Col>
                          <Col className="col-12 mb-3">
                            <Row className="mb-2">
                              <label
                                htmlFor="example-search-input"
                                className="col-md-2 col-form-label"
                              >
                                ตรวจสอบข้อมูลแล้ว
                              </label>
                              <div className="col-md-10">
                                <Select
                                  placeholder="Select Consent Status"
                                  value={fn_helper.FNFORM.getObjectValue(CONSENT_OPTION, `${validation.values.isConsent}`)}
                                  onChange={(value) => validation.setFieldValue("isConsent", value.id)}
                                  getOptionValue={(option) => option.id}
                                  getOptionLabel={(option) => option.name}
                                  options={CONSENT_OPTION}
                                  className={`select2-selection ${validation.touched.isConsent && validation.errors.isConsent ? "input-err" : ""}` }
                                  isDisabled={pageView === "view"}
                                />
                                {
                                  (validation.touched.isConsent && validation.errors.isConsent) && (
                                    <div type="invalid" className="invalid-feedback d-block">{validation.errors.isConsent}</div>
                                  )
                                }
                              </div>
                            </Row>
                          </Col>
                          <Col className="col-12 mb-3">
                            <Row className="mb-2">
                              <label
                                htmlFor="example-search-input"
                                className="col-md-2 col-form-label"
                              >
                                รอบที่ 1 ฝ่ายบุคคล
                              </label>
                              <div className="col-md-10">
                                <InputRadio
                                  label=""
                                  noLable={true}
                                  name="firstInterview"
                                  value={validation.values.firstInterview}
                                  option={STEP_JOB_OPTION}
                                  style={{ height: '100%', display: 'flex' }}
                                  onChange={(value)=> {
                                    console.log("🚀 ~ value:", value)
                                    validation.setFieldValue("firstInterview", value)
                                  }}
                                  disabled={pageView === "view"}
                                />
                              </div>
                            </Row>
                          </Col>
                          <Col className="col-12 mb-3">
                            <Row className="mb-2">
                              <label
                              style={{ height: '100%' }}
                                htmlFor="example-search-input"
                                className="col-md-2 col-form-label"
                              >
                                รอบที่ 2 หัวหน้างาน
                              </label>
                              <div className="col-md-10">
                                <InputRadio
                                  label=""
                                  noLable={true}
                                  name="secondInterview"
                                  value={validation.values.secondInterview}
                                  option={STEP_JOB_OPTION}
                                  style={{ height: '100%', display: 'flex' }}
                                  onChange={(value)=> {
                                    console.log("🚀 ~ value:", value)
                                    validation.setFieldValue("secondInterview", value)
                                  }}
                                  disabled={pageView === "view"}
                                />
                              </div>
                            </Row>
                          </Col>
                          <Col className="col-12 mb-3">
                            <Row className="mb-2">
                              <label
                                htmlFor="example-search-input"
                                className="col-md-2 col-form-label"
                              >
                                รอบที่ 3 ผู้บริหาร
                              </label>
                              <div className="col-md-10">
                                <InputRadio
                                  label=""
                                  noLable={true}
                                  name="thirdInterview"
                                  value={validation.values.thirdInterview}
                                  option={STEP_JOB_OPTION}
                                  style={{ height: '100%', display: 'flex' }}
                                  onChange={(value)=> {
                                    console.log("🚀 ~ value:", value)
                                    validation.setFieldValue("thirdInterview", value)
                                  }}
                                  disabled={pageView === "view"}
                                />
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </>
                      ) 
                      :   (
                        <div style={{ height: '75vh' }}>
                          <Spinner color="primary" className='position-absolute top-50 start-50' />
                        </div>
                      )

                    }
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/jobs-managment/job-application')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/jobs-managment/job-application')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };
export default withTranslation()(JobApplicationTemplate)
