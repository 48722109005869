import { get, post, put, patch } from "../Config"

export function SearchPaymentMethodGroup({ params = {} }) {
  return get({ url: `/v1/admin/paymentMethodGroup`, params })
}
export function GetPaymentMethodGroupById({ id }) {
  return get({ url: `/v1/admin/paymentMethodGroup/${id}` })
}
export function CreatePaymentMethodGroup({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/paymentMethodGroup`, params, data })
}
export function UpdatePaymentMethodGroup({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/paymentMethodGroup/${id}`, params, data })
}
export function PatchPaymentMethodGroup({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/paymentMethodGroup/${id}`, params, data })
}
export function DeletePaymentMethodGroup({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/paymentMethodGroup/mutiDelete`, params, data })
}
