import { get, post, put, patch } from "../Config"

export function SearchHelpCenterCategory({ params = {} }) {
  return get({ url: `/v1/admin/helpCenterCategory`, params })
}
export function GetHelpCenterCategoryById({ id }) {
  return get({ url: `/v1/admin/helpCenterCategory/${id}` })
}
export function CreateHelpCenterCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/helpCenterCategory`, params, data })
}
export function UpdateHelpCenterCategory({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/helpCenterCategory/${id}`, params, data })
}
export function PatchHelpCenterCategory({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/helpCenterCategory/${id}`, params, data })
}
export function DeleteHelpCenterCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/helpCenterCategory/mutiDelete`, params, data })
}
