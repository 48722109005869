import { get, post, put, patch } from "../Config"

export function SearchProducts({ params = {} }) {
  return get({ url: `/v1/admin/products`, params })
}
export function GetProductsById({ id }) {
  return get({ url: `/v1/admin/products/${id}` })
}
export function CreateProducts({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/products`, params, data })
}
export function UpdateProducts({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/products/${id}`, params, data })
}
export function PatchProducts({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/products/${id}`, params, data })
}
export function DeleteProducts({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/products/mutiDelete`, params, data })
}
