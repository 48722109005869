import { get, post, put, patch } from "../Config"

export function SearchBrand({ params = {} }) {
  return get({ url: `/v1/admin/brand`, params })
}
export function GetBrandById({ id }) {
  return get({ url: `/v1/admin/brand/${id}` })
}
export function CreateBrand({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/brand`, params, data })
}
export function UpdateBrand({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/brand/${id}`, params, data })
}
export function PatchBrand({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/brand/${id}`, params, data })
}
export function DeleteBrand({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/brand/mutiDelete`, params, data })
}
