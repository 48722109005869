import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Card, CardBody } from "reactstrap"


const Timeline = ({
  title = '',
  subTitle = '',
  description = ''
}) => {

  return (
    <>
      <li className="event-list" >
        <div className="event-timeline-dot">
          <i className="bx bx-right-arrow-circle"></i>
        </div>
        <div className="d-flex">
          <div className="flex-grow-1">
            <div>
              <h6 className="font-size-14 mb-1">{title}</h6>
              <p className="text-muted">{subTitle}</p>
              <p className="text-muted mb-0">{description}</p>

            </div>
          </div>
        </div>
      </li>
    </>
  )
}

// Breadcrumb.propTypes = {
//   breadcrumbItem: PropTypes.string,
//   title: PropTypes.string
// }

export default Timeline
