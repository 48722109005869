import { get, post, put, patch } from "../Config"

export function SearchBank({ params = {} }) {
  return get({ url: `/v1/admin/bank`, params })
}
export function GetBankById({ id }) {
  return get({ url: `/v1/admin/bank/${id}` })
}
export function CreateBank({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bank`, params, data })
}
export function UpdateBank({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/bank/${id}`, params, data })
}
export function PatchBank({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/bank/${id}`, params, data })
}
export function DeleteBank({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bank/mutiDelete`, params, data })
}
