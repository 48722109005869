import React, { useEffect, useState, useContext, useRef  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"


//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateBannerMain, GetBannerMainById, UpdateBannerMain } from "services/api/module/BannerMain"
import ModalContext from "../../contexts/ModalContext";
import { DropdownBrand, DropdownTemplate } from "services/api/module/Dropdown"
import Flatpickr from "react-flatpickr";
import moment from "moment"
import UploadFileAPI from "components/UploadFileAPI"
import InputRadio from "components/Input/InputRadio"
import InputSwitch from "components/Input/InputSwitch"
import TemplateView from "pages/BannerImageProduct/BannerBox/TemplateView"


const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const VIDEO_TYPE_OPTION = [
  { id: 'youtube', name: 'Youtube' },
  { id: 'upload', name: 'Upload' },
]

const HIGHT_LIGHT_OPTION = [
  { id: '0', name: 'No' },
  { id: '1', name: 'Yes' },
]

const BannerMainTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qBannerMainId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    name: Yup.string().max(100).required("Please Enter Banner Name"),
    // description: Yup.string().max(100).required("Please Enter Description"),
    brandId: Yup.string().max(100).required("Please Select Banner Main Category"),
    bannerSupplierTemplateId: Yup.string().max(100).required("Please Select Template"),
    startDate: Yup.string().max(100).required("Please Enter Start Date"),
    endDate: Yup.string().max(100).required("Please Enter End Date"),

    status: Yup.string().max(100).required("Please Select Status"),

    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string().max(100).required("Please Enter Meta Description"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddBrand, setDDBrand] = useState([])
  const [ddTemplate, setDDTemplate] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)
  const [ddLoaded1, setDDLoaded1] = useState(DEF_LOADED)
  const prevSelectDDTemplateRef = useRef();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      brandId: "",
      bannerSupplierTemplateId: "",
      name: "",
      description: "",
      status: "",

      startDate: "",
      endDate: "",

      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ BannerMainCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        brandId: Number(values.brandId),
        bannerSupplierTemplateId: Number(values.bannerSupplierTemplateId),
        name: values.name,
        description: values.description,
        status: Number(values.status),
        startDate: moment(values.startDate, 'YYYY-MM-DDTHH:mm').toISOString(),
        endDate: moment(values.endDate, 'YYYY-MM-DDTHH:mm').toISOString(),

        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateBannerMain(payload)
      } else {
        API.fetchCreateBannerMain(payload)
      }
    },
  })

  // SELECT DATA
  const selectedDDTemplate = ddTemplate.find(item => item.id == validation.values.bannerSupplierTemplateId)

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("brandId", resData.brandId)
    validation.setFieldValue("name", resData.name)
    validation.setFieldValue("description", resData.description)

    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)

    validation.setFieldValue("startDate", moment(resData.startDate).format('YYYY-MM-DDTHH:mm'))
    validation.setFieldValue("endDate", moment(resData.endDate).format('YYYY-MM-DDTHH:mm'))

    // ติด Effect Clear State
    setTimeout(() => validation.setFieldValue("bannerSupplierTemplateId", resData.bannerSupplierTemplateId), 100)
  }

  const API = {
    fetchDDBrand: async () => {
      try {
          const response = await DropdownBrand({})
          const resData = response?.data ?? []
          const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          console.log("🚀 ~ fetchDDBrand: ~ tmpDD:", tmpDD)
          setDDBrand(tmpDD);
          setDDLoaded0(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchDDTemplate: async () => {
      try {
          const response = await DropdownTemplate({})
          const resData = response?.data ?? []
          const tmpDD = resData.data
          setDDTemplate(tmpDD);
          setDDLoaded1(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchGetBannerMainById: async (payload) => {
      try {
          const response = await GetBannerMainById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateBannerMain: async (payload) => {
      try {
          const response = await CreateBannerMain({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/banner-supplier/banner-main')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateBannerMain: async (payload) => {
      try {
          const response = await UpdateBannerMain({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/banner-supplier/banner-main')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    if (ddLoaded0 && ddLoaded1) {
      if (["update", "view"].includes(pageView)) {
        const payload = { id : qBannerMainId }
        API.fetchGetBannerMainById(payload)
      }
    }
  }, [ddLoaded0, ddLoaded1])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDBrand();
    API.fetchDDTemplate();
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Banner Main | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Banner Main`}
            breadcrumbItems={[
              { title: "Banner Main", link: "/banner-supplier/banner-main" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Banner Main Infomation</h5>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Banner Name
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="name"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name}
                            invalid={validation.touched.name && validation.errors.name ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.name && validation.errors.name && (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="description"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description}
                            invalid={validation.touched.description && validation.errors.description ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.description && validation.errors.description && (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Brand
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Brand"
                            value={fn_helper.FNFORM.getObjectValue(ddBrand, `${validation.values.brandId}`)}
                            onChange={(value) => validation.setFieldValue("brandId", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddBrand}
                            className={`select2-selection ${validation.touched.brandId && validation.errors.brandId ? "input-err" : ""}` }
                            isDisabled={["update", "view"].includes(pageView)}
                          />
                          {
                            (validation.touched.brandId && validation.errors.brandId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.brandId}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                            onChange={(value) => validation.setFieldValue("status", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                            isDisabled={["view"].includes(pageView)}
                          />
                          {
                            (validation.touched.status && validation.errors.status) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="startDate"
                          className="col-md-12 col-form-label"
                        >
                          Start Date
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="startDate"
                            className="form-control"
                            type="datetime-local"
                            placeholder=""
                            name="startDate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.startDate}
                            invalid={
                              validation.touched.startDate &&
                              validation.errors.startDate
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.startDate &&
                            validation.errors.startDate && (
                              <FormFeedback type="invalid">
                                {validation.errors.startDate}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="endDate"
                          className="col-md-12 col-form-label"
                        >
                          End Date
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="endDate"
                            className="form-control"
                            type="datetime-local"
                            placeholder=""
                            name="endDate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.endDate}
                            invalid={
                              validation.touched.endDate &&
                              validation.errors.endDate
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.endDate &&
                            validation.errors.endDate && (
                              <FormFeedback type="invalid">
                                {validation.errors.endDate}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="col-6">
                        <Col className="col-12">
                          <h5 className="mb-3">SEO Setting</h5>
                        </Col>
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              URL Key
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoUrlKey"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoUrlKey"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoUrlKey}
                                invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoUrlKey}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Meta Title
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaTitle"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaTitle"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaTitle}
                                invalid={validation.touched.seoMetaTitle && validation.errors.seoMetaTitle ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.seoMetaTitle && validation.errors.seoMetaTitle && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaTitle}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Meta keyword
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaKeyword"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaKeyword"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaKeyword}
                                invalid={validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaKeyword}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Meta Description
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="seoMetaDescription"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="seoMetaDescription"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.seoMetaDescription}
                                invalid={validation.touched.seoMetaDescription && validation.errors.seoMetaDescription ? true : false}
                                disabled={pageView === "view"}
                              />
                              { 
                                validation.touched.seoMetaDescription && validation.errors.seoMetaDescription && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.seoMetaDescription}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12 mb-3" >
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label mb-2"
                        >
                          Choose template
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Row>
                            {
                              ddTemplate.map((item,i) => {
                                return (
                                  <Col className="col-6 mb-4" key={i}>
                                    <div className="radio-template">
                                      <input
                                        id={'template' + i}
                                        className="form-check-input"
                                        type="radio"
                                        name="bannerSupplierTemplateId"
                                        // value={item.id}
                                        checked={item.id == validation.values.bannerSupplierTemplateId}
                                        onClick={() => {
                                          // console.log("🚀 ~ ddTemplate.map ~ item.id:", item.id)
                                          // console.log("🚀 ~ ddTemplate.map ~ item.id:", validation.values.bannerSupplierTemplateId)
                                          validation.setFieldValue('bannerSupplierTemplateId', item.id)
                                        }}
                                        disabled={['update','view'].includes(pageView)}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={'template' + i}
                                      >
                                        {/* <img className="rs1-1" src={item.img}/> */}
                                        <h6>{item.name}</h6>
                                        <TemplateView useTemplateName={item.name} mini={true} />
                                      </label>
                                    </div>
                                  </Col>
                                )
                              })
                            }
                            
                          </Row>
                          {
                            (validation.touched.bannerSupplierTemplateId && validation.errors.bannerSupplierTemplateId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.bannerSupplierTemplateId}</div>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/banner-supplier/banner-main')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/banner-supplier/banner-main')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(BannerMainTemplate)
