import { get, post, put, patch } from "../Config"

export function SearchPaymentCreditDebit({ params = {} }) {
  return get({ url: `/v1/admin/paymentCreditDebit`, params })
}
export function GetPaymentCreditDebitById({ id }) {
  return get({ url: `/v1/admin/paymentCreditDebit/${id}` })
}
export function CreatePaymentCreditDebit({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/paymentCreditDebit`, params, data })
}
export function UpdatePaymentCreditDebit({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/paymentCreditDebit/${id}`, params, data })
}
export function PatchPaymentCreditDebit({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/paymentCreditDebit/${id}`, params, data })
}
export function DeletePaymentCreditDebit({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/paymentCreditDebit/mutiDelete`, params, data })
}
