import { get, post, put, patch } from "../Config"

export function SearchArticleCategory({ params = {} }) {
  return get({ url: `/v1/admin/artlcleCategory`, params })
}
export function GetArticleCategoryById({ id }) {
  return get({ url: `/v1/admin/artlcleCategory/${id}` })
}
export function CreateArticleCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/artlcleCategory`, params, data })
}
export function UpdateArticleCategory({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/artlcleCategory/${id}`, params, data })
}
export function PatchArticleCategory({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/artlcleCategory/${id}`, params, data })
}
export function DeleteArticleCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/artlcleCategory/mutiDelete`, params, data })
}
export function CheckDuplicateArticleCategory({ params = {}, data = {}}) {
  return post({ url: `/v1/admin/artlcleCategory/duplicate`, params, data })
}
