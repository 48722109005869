import moment from "moment";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

class CustomUploadAdapter {
  constructor(loader) {
      const typeFile = 'jpg'
      const timestamp = moment().unix();
      const fileName = `editor-${timestamp}.${typeFile}`
      
      this.loader = loader;
      this.url = baseUrl + '/upload/editor/' + fileName; // เปลี่ยนเป็น URL ของ API ที่คุณต้องการอัปโหลดไฟล์
  }

  upload() {
      return this.loader.file
          .then(file => new Promise((resolve, reject) => {
              const formData = new FormData();
              formData.append('file', file);

              fetch(this.url, {
                  method: 'POST',
                  body: formData
              })
              .then(response => response.json())
              .then(result => {
                  resolve({
                      default: result.data.url // ใช้ URL ที่ส่งกลับจากเซิร์ฟเวอร์หลังอัปโหลดสำเร็จ
                  });
              })
              .catch(error => {
                  reject(error);
              });
          }));
  }

  abort() {
      // handle aborting file upload here if needed
  }
}

export default function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader);
  };
}
