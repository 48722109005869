import { get, post, put } from "../Config"

export function DropdownBannerPlatform({ params = {} }) {
  return new Promise((resolve, reject) => {
    resolve(get({ url: `/v1/admin/dropdown/bannerPlatform`, params }))
  })
}
export function DropdownBannerPage({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/bannerPage`, params })
}
export function DropdownArticleCategory({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/articleCategory`, params })
}
export function DropdownArticleKeyword({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/articleKeyword`, params })
}
export function DropdownBrand({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/brand`, params })
}
export function DropdownTemplate({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/template`, params })
}
export function DropdownProductGroup({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/productGroup`, params })
}
export function DropdownBannerMain({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/bannerSupplierMain`, params })
}
export function DropdownPromotionCategoryParent({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/promotionCategoryParent`, params })
}
export function DropdownPromotionCategoryAll({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/promotionCategoryAll`, params })
}
export function DropdownPromotionCategoryByType({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/promotionCategoryByType`, params })
}
export function DropdownJobType({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/jobType`, params })
}
export function DropdownJobLocation({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/jobLocation`, params })
}
export function DropdownJobPosition({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/jobPosition`, params })
}
export function DropdownHelpCenterCategory({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/helpCenterCategory`, params })
}
export function DropdownBranchType({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/branchType`, params })
}
export function DropdownRegionAll({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/regionAll`, params })
}
export function DropdownProvinceAll({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/provinceAll`, params })
}
export function DropdownAmphureAll({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/amphureAll`, params })
}
export function DropdownTombonAll({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/tombonAll`, params })
}
export function DropdownAddressAll({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/addressAll`, params })
}
export function DropdownPromotionCategoryChild({ id, params = {} }) {
  return get({ url: `/v1/admin/dropdown/promotionCategoryChild/${id}`, params })
}
export function DropdownRewardCategory({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/rewardCategory`, params })
}
export function DropdownBank({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/bank`, params })
}
export function DropdownPaymentMethodGroup({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/paymentMethodGroup`, params })
}
