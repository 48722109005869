import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { UPDATE_QUESTIONAIRE_QUESTION } from "./actionsType"
import { updateQuestionaireQuestionSuccessful, updateQuestionaireQuestionFailed } from "./actions"
import { apiError } from "store/actions"

import * as backend from "helpers/backend"

function* updateQuestionaireQuestion({ payload: { questionData } }) {
    console.log('questionData', questionData)
    try {
        const response = yield call(backend.updateQQuestion, questionData);
        console.log(response);
        const insertData = response.data
        yield put(updateQuestionaireQuestionSuccessful(insertData))
    } catch (error) {
        console.log('error',error)
        if(typeof error.response.data !== "undefined") {
            yield put(updateQuestionaireQuestionFailed(error.response.data.message))
            yield put(apiError(error.response.data.message));
        }
        else {
            yield put(updateQuestionaireQuestionFailed(error.response))
            yield put(apiError(error.response));
        }
    }
}

export function* watchCreateQuestionaireQuestion() {
    yield takeEvery(UPDATE_QUESTIONAIRE_QUESTION, updateQuestionaireQuestion)
}

function* questionaireCateSaga() {
    yield all([fork(watchCreateQuestionaireQuestion)])
}

export default questionaireCateSaga