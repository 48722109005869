import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { CREATE_QUESTIONAIRE_QUESTION } from "./actionsType"
import { createQuestionaireQuestionSuccessful, createQuestionaireQuestionFailed } from "./actions"
import { apiError } from "store/actions"

import * as backend from "helpers/backend"

function* createQuestionaireQuestion({ payload: { questionData } }) {
    try {
        const response = yield call(backend.createQQuestion, questionData);
        console.log(response);
        const insertData = response.data
        yield put(createQuestionaireQuestionSuccessful(insertData))
    } catch (error) {
        if(typeof error.response.data !== "undefined") {
            yield put(createQuestionaireQuestionFailed(error.response.data.message))
            yield put(apiError(error.response.data.message));
        }
        else {
            yield put(createQuestionaireQuestionFailed(error.response))
            yield put(apiError(error.response));
        }
    }
}

export function* watchCreateQuestionaireQuestion() {
    yield takeEvery(CREATE_QUESTIONAIRE_QUESTION, createQuestionaireQuestion)
}

function* questionaireCateSaga() {
    yield all([fork(watchCreateQuestionaireQuestion)])
}

export default questionaireCateSaga