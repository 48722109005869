import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback, useRef } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    // CardHeader,
    Input,
    // Modal,
    // ModalHeader,
    // ModalBody,
    // ModalFooter,
    // Table,
    // Badge,
    Form,
    Alert,
    FormFeedback,
    Label,
    CardFooter,
    InputGroup
} from "reactstrap"

import { Link, useLocation, useNavigate } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'

import { get } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, registerUserReset, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import Select from "react-select"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import moment from "moment";

const UserAdd = props => {
    const location = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);

    // get Menu from path
    const [, rootMenu, subMenu, action] = location.pathname.split('/')

    //meta title
    document.title = "Create User | " + process.env.REACT_APP_CMS_TITLE;

    // ========== Set Fropdown Option from Server ========== //
    const [dropDownOptions, setDropDownOptions] = useState([]);
    const [dropDownLoading, setDropDownLoading] = useState(true);
    const [userGroupSelected, setUserGroupSelected] = useState(null);

    useEffect(() => {
        const fetchMenu = async () => {
            const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/systemsettings/userGroups')

            const dropDownOptions = result.map(x => ({
                label: x.group_name,
                value: x.group_id
            }));

            setDropDownOptions(dropDownOptions);
            setDropDownLoading(false)
        };
    
        fetchMenu();
    }, []);
    // ========== Set Fropdown Option from Server ========== //

    // ========== Form Validation ========== //
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            user_fullname: '',
            user_jobtitle: '',
            user_email: '',
            user_mobileno: '',
            user_group: '',
            username: '',
            password: '',
            user_joindate: moment().format('YYYY-MM-DD'),
            user_status: '',
        },
        validationSchema: Yup.object({
            user_fullname: Yup.string().required("Please Enter User's Name"),
            user_jobtitle: Yup.string().required("Please Enter User's Job Title"),
            user_email: Yup.string().required("Please Enter User's Email")
                .email('Invalid email')
                .max(100, 'Please enter a value less than or equal to 100'),
            user_mobileno: Yup.string(),
            user_group: Yup.string().required('Please Select User Group'),
            username: Yup.string().required("Please Enter Username")
                .min(8, 'Please enter a value greater than or equal to 8')
                .max(16, 'Please enter a value less than or equal to 16'),
            password: Yup.string().required("Please Enter Password"),
            user_joindate: Yup.string().required("Please Enter Join date"),
            user_status: Yup.string().required("Please Select User Status")
        }),
        onSubmit: (values) => {
            console.log(values);
            dispatch(registerUser(values));
        }
    })

    // ========== Form Validation ========== //

    // ========== User Status ========== //
    const [userStatusSelected, setUserStatusSelected] = useState(null);
    const optionsUserStatus = [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
    ]

    if(checkScope(rootMenu + ':' + subMenu + ':delete')) {
        optionsUserStatus.push({ value: 'deleted', label: 'Deleted' })
    }
    // ========== User Status ========== //

    const AccountProperties = createSelector(
        (state) => state.Account,
        (account) => ({
            user: account.user,
            registrationError: account.registrationError,
            success: account.success,
            loading: account.loading,
        })
    );

    const {
        user, registrationError, success, loading
    } = useSelector(AccountProperties);
    
    useEffect(() => {
        dispatch(apiError(""));
    }, []);

    useEffect(() => {
        success && dispatch(registerUserReset()) && setTimeout(() => navigate(`/${rootMenu}/${subMenu}`), 2000)
    }, [success])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs 
                        title={props.t("Create User")} 
                        breadcrumbItems={[
                            {title: props.t("System Setting"), link: props.t("systemsettings")}, 
                            {title: props.t("User Setting"), link: props.t("usersetting")},
                            {title: props.t("Create User"), link: props.t("useradd")}
                        ]} />
        
                    {/* Render Form */}
                    <Row>
                        <Col className="col-12">
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                {user && user ? (
                                    <Alert color="success">
                                        Create User Successfully
                                    </Alert>
                                ) : null}

                                {registrationError && registrationError ? (
                                    <Alert color="danger">{registrationError}</Alert>
                                ) : null}

                                <Row>
                                    <Col className="col-8">
                                        <Card>
                                            <h5 className="card-header bg-transparent border-bottom text-uppercase">
                                                <i className='bx bx-cube-alt'></i> Information
                                            </h5>
                                            <CardBody>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="user_fullname">Full Name <span style={{color:"#F00"}}>*</span></Label>
                                                    <Input
                                                        id="user_fullname"
                                                        name="user_fullname"
                                                        className="form-control"
                                                        placeholder="Enter Full Name"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.user_fullname || ""}
                                                        invalid={
                                                            validation.touched.user_fullname && validation.errors.user_fullname ? true : false
                                                        }
                                                    />
                                                    {validation.touched.user_fullname && validation.errors.user_fullname ? (
                                                        <FormFeedback type="invalid">{validation.errors.user_fullname}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="user_jobtitle">Job Title <span style={{color:"#F00"}}>*</span></Label>
                                                    <Input
                                                        id="user_jobtitle"
                                                        name="user_jobtitle"
                                                        className="form-control"
                                                        placeholder="Job Title"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.user_jobtitle || ""}
                                                        invalid={
                                                            validation.touched.user_jobtitle && validation.errors.user_jobtitle ? true : false
                                                        }
                                                    />
                                                    {validation.touched.user_jobtitle && validation.errors.user_jobtitle ? (
                                                        <FormFeedback type="invalid">{validation.errors.user_jobtitle}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="user_email">Email <span style={{color:"#F00"}}>*</span></Label>
                                                    <Input
                                                        id="user_email"
                                                        name="user_email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.user_email || ""}
                                                        invalid={
                                                            validation.touched.user_email && validation.errors.user_email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.user_email && validation.errors.user_email ? (
                                                        <FormFeedback type="invalid">{validation.errors.user_email}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="user_mobileno">Mobile Phone</Label>
                                                    <Input
                                                        id="user_mobileno"
                                                        name="user_mobileno"
                                                        className="form-control"
                                                        placeholder="Enter mobile phone number"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.user_mobileno || ""}
                                                        invalid={
                                                            validation.touched.user_mobileno && validation.errors.user_mobileno ? true : false
                                                        }
                                                    />
                                                    {validation.touched.user_mobileno && validation.errors.user_mobileno ? (
                                                        <FormFeedback type="invalid">{validation.errors.user_mobileno}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="user_group">User Group <span style={{color:"#F00"}}>*</span></Label>
                                                    <Select
                                                        id="user_group"
                                                        name="user_group"
                                                        value={userGroupSelected}
                                                        onChange={(selectedOption) => {
                                                            setUserGroupSelected(selectedOption)
                                                            validation.setFieldValue("user_group", selectedOption.value)
                                                        }}
                                                        options={dropDownOptions}
                                                        className="select2-selection"
                                                        isLoading={dropDownLoading}
                                                        invalid={
                                                            validation.touched.user_group && validation.errors.user_group ? true : false
                                                        }
                                                    />
                                                    {validation.touched.user_group && validation.errors.user_group ? (
                                                        <FormFeedback type="invalid">{validation.errors.user_group}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="username">Username <span style={{color:"#F00"}}>*</span></Label>
                                                    <Input
                                                        id="username"
                                                        name="username"
                                                        className="form-control"
                                                        placeholder="Enter username (English Only)"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.username || ""}
                                                        invalid={
                                                            validation.touched.username && validation.errors.username ? true : false
                                                        }
                                                    />
                                                    {validation.touched.username && validation.errors.username ? (
                                                        <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="password">Password <span style={{color:"#F00"}}>*</span></Label>
                                                    <Input
                                                        id="password"
                                                        name="password"
                                                        className="form-control"
                                                        placeholder="Enter password"
                                                        type="password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col className="col-4">
                                        <Card outline color="primary" className="border">
                                            <h5 className="card-header bg-transparent border-bottom text-primary text-uppercase">
                                                <i className='bx bx-check'></i> Tools Box
                                            </h5>
                                            <CardBody>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="user_joindate">Join Date <span style={{color:"#F00"}}>*</span></Label>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            id="user_joindate"
                                                            // name="user_joindate"
                                                            className="form-control d-block"
                                                            placeholder="Pick a date"
                                                            options={{
                                                                altInput: true,
                                                                dateFormat: "Y-m-d",
                                                                // defaultDate: [new Date(), moment().format('YYYY-MM-DD')]
                                                            }}
                                                            // onChange={val => {
                                                            //     validation.setFieldValue("user_joindate", moment().format('YYYY-MM-DD'));
                                                            // }}
                                                            // onBlur={validation.handleBlur}
                                                            value={validation.values.user_joindate}
                                                            disabled="disabled"
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                disabled
                                                            >
                                                                <i
                                                                    className="fa fa-calendar"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </div>
                                                    </InputGroup>
                                                    <Input
                                                        type="hidden"
                                                        name="user_joindate"
                                                        value={validation.values.user_joindate}
                                                    />
                                                    {validation.touched.user_joindate && validation.errors.user_joindate ? (
                                                        <FormFeedback type="invalid">{validation.errors.user_joindate}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="user_status">Status <span style={{color:"#F00"}}>*</span></Label>
                                                    <Select
                                                        id="user_status"
                                                        name="user_status"
                                                        className="select2-selection"
                                                        value={userStatusSelected}
                                                        onChange={(selectedOption) => {
                                                            setUserStatusSelected(selectedOption)
                                                            validation.setFieldValue("user_status", selectedOption.value)
                                                        }}
                                                        options={optionsUserStatus}
                                                    />
                                                    {validation.touched.user_status && validation.errors.user_status ? (
                                                        <FormFeedback type="invalid">{validation.errors.user_status}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <Row>
                                                    <Col className="text-start">
                                                        <Button color="danger" className="btn btn-danger" onClick={() => navigate(-1)}>Back</Button> 
                                                    </Col>
                                                    <Col className="text-end">
                                                        <Button type="submit" color="primary" className="btn btn-primary">Submit</Button>
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    {/* <Col className="col-12">
                                        <Card>
                                            <h5 className="card-header bg-transparent border-bottom text-uppercase">
                                                <i className='bx bx-trending-up'></i> SEO
                                            </h5>
                                            <CardBody>
                                                SEO form
                                            </CardBody>
                                        </Card>
                                    </Col> */}
                                </Row>
                            </Form>
                        </Col>
                        
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

UserAdd.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(UserAdd);