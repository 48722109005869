import {
    CREATE_QUESTIONAIRE_CATEGORY,
    CREATE_QUESTIONAIRE_CATEGORY_SUCCESSFUL,
    CREATE_QUESTIONAIRE_CATEGORY_FAILED,
    CREATE_QUESTIONAIRE_CATEGORY_RESET,
} from "./actionsType"

const initialState = {
    message: null,
    loading: false,
    categoryData: null,
    createError: null,
    createSuccess: false
}

const questionaireCategory = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_QUESTIONAIRE_CATEGORY:
            state = {
                ...state,
                loading: true,
                createSuccess: false,
                createError: null,
            }
            break
        case CREATE_QUESTIONAIRE_CATEGORY_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                categoryData: action.payload,
                createSuccess: true,
                createError: null,
            }
            break
        case CREATE_QUESTIONAIRE_CATEGORY_FAILED:
            state = {
                ...state,
                categoryData: null,
                loading: false,
                createSuccess: false,
                createError: action.payload,
            }
            break
        case CREATE_QUESTIONAIRE_CATEGORY_RESET:
            state = {
                ...state,
                message: null,
                loading: false,
                createError: null,
                createSuccess: false
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export default questionaireCategory