import React, { useEffect, useState, useContext  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../../store/actions"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateJobType, GetJobTypeById, UpdateJobType } from "services/api/module/JobType"
import ModalContext from "../../../contexts/ModalContext";
import MyEditor from "components/MyEditor"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const JobTypeTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qJobTypeId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    nameTh: Yup.string().max(100).required("Please Enter Type Name Thai"),
    nameEn: Yup.string().max(100).required("Please Enter Type Name Eng"),
    descriptionTh: Yup.string().max(2048).required("Please Enter Description Thai"),
    descriptionEn: Yup.string().max(2048).required("Please Enter Description Eng"),
    status: Yup.string().max(100).required("Please Enter Status"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      nameTh: "",
      nameEn: "",
      descriptionTh: "",
      descriptionEn: "",
      status: ""
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ JobTypeCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        descriptionTh: values.descriptionTh,
        descriptionEn: values.descriptionEn,
        status: Number(values.status)
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateJobType(payload)
      } else {
        API.fetchCreateJobType(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("descriptionTh", resData.descriptionTh)
    validation.setFieldValue("descriptionEn", resData.descriptionEn)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetJobTypeById: async (payload) => {
      try {
          const response = await GetJobTypeById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateJobType: async (payload) => {
      try {
          const response = await CreateJobType({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/jobs-managment/job-type')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateJobType: async (payload) => {
      try {
          const response = await UpdateJobType({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/jobs-managment/job-type')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id : qJobTypeId }
      API.fetchGetJobTypeById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Job Type | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Job Type`}
            breadcrumbItems={[
              { title: "Job Type", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Job Type Infomation</h5>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Type Name Thai
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="nameTh"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="nameTh"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.nameTh}
                            invalid={validation.touched.nameTh && validation.errors.nameTh ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.nameTh && validation.errors.nameTh && (
                              <FormFeedback type="invalid">
                                {validation.errors.nameTh}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Type Name Eng
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="nameEn"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="nameEn"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.nameEn}
                            invalid={validation.touched.nameEn && validation.errors.nameEn ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.nameEn && validation.errors.nameEn && (
                              <FormFeedback type="invalid">
                                {validation.errors.nameEn}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Description Thai
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <MyEditor
                            value={validation.values.descriptionTh}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              validation.setFieldValue("descriptionTh", data)
                              console.log({ event, editor, data });
                            }}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.descriptionTh && validation.errors.descriptionTh && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.descriptionTh}</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Description Eng
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <MyEditor
                            value={validation.values.descriptionEn}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              validation.setFieldValue("descriptionEn", data)
                              console.log({ event, editor, data });
                            }}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.descriptionEn && validation.errors.descriptionEn && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.descriptionEn}</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                            onChange={(value) => validation.setFieldValue("status", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.status && validation.errors.status) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                            )
                          }
                          { 
                            validation.touched.status && validation.errors.status && (
                              <FormFeedback type="invalid">
                                {validation.errors.status}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/jobs-managment/job-type')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/jobs-managment/job-type')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(JobTypeTemplate)
