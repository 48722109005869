import { get, post, put, patch } from "../Config"

export function SearchRewardTermsConditions({ params = {} }) {
  return get({ url: `/v1/admin/rewardTermsConditions`, params })
}
export function GetRewardTermsConditionsById({ id }) {
  return get({ url: `/v1/admin/rewardTermsConditions/${id}` })
}
export function CreateRewardTermsConditions({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/rewardTermsConditions`, params, data })
}
export function UpdateRewardTermsConditions({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/rewardTermsConditions/${id}`, params, data })
}
export function PatchRewardTermsConditions({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/rewardTermsConditions/${id}`, params, data })
}
export function DeleteRewardTermsConditions({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/rewardTermsConditions/mutiDelete`, params, data })
}
