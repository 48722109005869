import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

const PrintComponent = ({
  prefixName = 'document',
}) => {
  const generatePdfWithJsPdf = async (callback) => {
    const pdf = new jsPDF("p", "mm", "a4");
    
    // alert('START !')
    // หน้าที่ 1
    const inputPageList = document.querySelectorAll('[id^="pdf-content-page-"]')
    // alert(inputPageList.length)
    let maxloop = inputPageList.length
    let i = 0;
    while (  i <  maxloop) {
      const inputPage = document.getElementById(`pdf-content-page-${i+1}`);
      // console.log("🚀 ~ generatePdfWithJsPdf ~ `pdf-content-page-${i+1}`:", `pdf-content-page-${i+1}`)
      // console.log("🚀 ~ generatePdfWithJsPdf ~ inputPage:", inputPage)
      const options = {
        scale: 2.5, // เพิ่มความชัดเจน
        useCORS: true,
      };
      const canvasPage = await html2canvas(inputPage, options);
      const imgDataPage = canvasPage.toDataURL("image/jpeg", 1.0); // 100% คุณภาพ
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeightPage = (canvasPage.height * imgWidth) / canvasPage.width;
  
      // เพิ่มหน้าที่ N
      pdf.addImage(imgDataPage, "JPEG", 0, 0, imgWidth, imgHeightPage);
      if (i < inputPageList.length - 1) {
        pdf.addPage();
      }
      i++;
    }

    // บันทึกไฟล์ PDF
    const timestamp = moment().unix();
    const fileName = `${prefixName}-${timestamp}.pdf`
    pdf.save(fileName);
    // alert('END !')
    if(callback) {
      callback()
    }
    // pdf.save(`doc.pdf`);
  };

  const genPdf = (callBackDone) => {
    generatePdfWithJsPdf(callBackDone)
  }
  

  const RenderDoc = ({
    isShow = false,
    children,
  }) => {
    return (
      <section className={`temp-print ${ isShow ? 'show' : '' }`}> 
        <div id="printDemo">
            <div>
              <button style={{ position:'absolute', marginTop: '20px'}} onClick={() => generatePdfWithJsPdf()}>ดาวน์โหลด PDF</button>
            </div>
            {/* <div id="render-doc-page" className="outer-container doc-jib" > */}
              { children }
            {/* </div> */}
        </div>
      </section>
    );
  }

  return { genPdf: genPdf , PrintDoc: RenderDoc,}
  
};

export default PrintComponent;
