import React, { useState, useEffect } from "react";
import DialogCustom from "./DialogCustom";

function ModalAlertConfrim({ 
  open = false,
  title = '',
  subTitle = '',
  content = `มีบางอย่างผิดพลาด`,
  mode = "blue",
  onClose = () => console.log('onClose'),
  onYes = () => console.log('onYes'),
  onNo = () => console.log('onNo')
}) {
  let labelCancel = 'ยกเลิก'
  let colorMode = `blue`
  if (mode === "red") {
    colorMode = mode
    labelCancel = 'ไม่ต้องการยกเลิก'
  }
  return (
    <DialogCustom visible={open} style={{ width: '342px' }} onHide={() => onClose()} onClickOut = {() => onClose()}>
      <div className="modal-header">
        { title ? <h5 className="modal-title" id="staticBackdropLabel" dangerouslySetInnerHTML={{ __html: title }}></h5> : <></> }
      </div>
      <div className="modal-body">
        { subTitle ? <p>{ subTitle }</p> : <></> }
        { content ? <p>{ content }</p> : <></> }
      </div>
      <div className="modal-footer">
        
        <button 
          type="button" 
          className="btn btn-primary" 
          onClick={() => onYes()}
        >Yes</button>

        <button 
          className="btn btn-light"
          type="button" 
          onClick={() => { onNo()}}
        >No</button>

      </div>
    </DialogCustom>
  )
}

export default ModalAlertConfrim;
