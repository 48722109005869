import {
    UPDATE_QUESTIONAIRE_QUESTION,
    UPDATE_QUESTIONAIRE_QUESTION_SUCCESSFUL,
    UPDATE_QUESTIONAIRE_QUESTION_FAILED,
    UPDATE_QUESTIONAIRE_QUESTION_RESET,
} from "./actionsType"

export const updateQuestionaireQuestion = questionData => {
    return {
        type: UPDATE_QUESTIONAIRE_QUESTION,
        payload: { questionData },
    }
}

export const updateQuestionaireQuestionSuccessful = questionData => {
    return {
        type: UPDATE_QUESTIONAIRE_QUESTION_SUCCESSFUL,
        payload: questionData,
    }
}

export const updateQuestionaireQuestionFailed = questionData => {
    return {
        type: UPDATE_QUESTIONAIRE_QUESTION_FAILED,
        payload: questionData,
    }
}

export const updateQuestionaireQuestionReset = questionData => {
    return {
        type: UPDATE_QUESTIONAIRE_QUESTION_RESET,
        payload: { questionData },
    }
}
