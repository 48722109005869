import axios from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const headers = {
  'Content-Type': 'multipart/form-data',
}

export function UploadFile({ formData = {}, bucketName = '', fileName = '' }) {
  return axios.post(baseUrl + `/upload/${bucketName}/${fileName}`, formData, { headers: headers, timeout: 20000, },)
}
