import React, { useEffect, useState, useContext, useRef  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"


//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateBannerList, GetBannerListById, UpdateBannerList } from "services/api/module/BannerList"
import ModalContext from "../../contexts/ModalContext";
import { DropdownBannerPage, DropdownBannerPlatform } from "services/api/module/Dropdown"
import Flatpickr from "react-flatpickr";
import moment from "moment"
import UploadFileAPI from "components/UploadFileAPI"


const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const BannerListTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qBannerListId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    bannerPlatformId: Yup.string().max(100).required("Please Select Banner Platform"),
    bannerPageId: Yup.string().max(100).required("Please Select Banner Page"),
    bannerName: Yup.string().max(100).required("Please Enter Banner Name"),
    order: Yup.string().max(100).required("Please Enter Order"),
    startDate: Yup.string().max(100).required("Please Enter Start Date"),
    endDate: Yup.string().max(100).required("Please Enter End Date"),
    // embedLink: Yup.string().max(200).required("Please Enter Embed Link"),
    imageDesktop: Yup.string().max(200).required("Please Enter Image Desktop"),
    imageMobile: Yup.string().max(200).required("Please Enter Image Mobile"),
    status: Yup.string().max(100).required("Please Select Status"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddBannerPlatform, setDDBannerPlatform] = useState([])
  const [ddBannerPage, setDDBannerPage] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)
  const [ddLoaded1, setDDLoaded1] = useState(DEF_LOADED)
  const prevSelectDDBannerPageRef = useRef();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      bannerPlatformId: "",
      bannerPageId: "",
      bannerName: "",
      order: "",
      startDate: "",
      endDate: "",
      embedLink: "",
      imageDesktop: "",
      imageMobile: "",
      status: ""
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ BannerListCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        bannerPlatformId: Number(values.bannerPlatformId),
        bannerPageId: Number(values.bannerPageId),
        bannerName: values.bannerName,
        order: values.order,
        startDate: moment(values.startDate, 'YYYY-MM-DDTHH:mm').toISOString(),
        endDate: moment(values.endDate, 'YYYY-MM-DDTHH:mm').toISOString(),
        embedLink: values.embedLink,
        imageDesktop: values.imageDesktop,
        imageMobile: values.imageMobile,
        status: Number(values.status)
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateBannerList(payload)
      } else {
        API.fetchCreateBannerList(payload)
      }
    },
  })

  // SELECT DATA
  const selectedDDBannerPage = ddBannerPage.find(item => item.id == validation.values.bannerPageId)

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("bannerPlatformId", resData.bannerPlatformId)
    validation.setFieldValue("bannerName", resData.bannerName)
    validation.setFieldValue("order", resData.order)

    validation.setFieldValue("embedLink", resData.embedLink)
    validation.setFieldValue("imageDesktop", resData.imageDesktop)
    validation.setFieldValue("imageMobile", resData.imageMobile)
    validation.setFieldValue("status", resData.status)

    validation.setFieldValue("startDate", moment(resData.startDate).format('YYYY-MM-DDTHH:mm'))
    validation.setFieldValue("endDate", moment(resData.endDate).format('YYYY-MM-DDTHH:mm'))

    // ติด Effect Clear State
    setTimeout(() => validation.setFieldValue("bannerPageId", resData.bannerPageId), 100)
  }

  const API = {
    fetchDDBannerPlatform: async () => {
      try {
          const response = await DropdownBannerPlatform({})
          const resData = response?.data ?? []
          const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          console.log("🚀 ~ fetchDDBannerPlatform: ~ tmpDD:", tmpDD)
          setDDBannerPlatform(tmpDD);
          setDDLoaded0(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchDDBannerPage: async () => {
      try {
          const response = await DropdownBannerPage({})
          const resData = response?.data ?? []
          const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          setDDBannerPage(tmpDD);
          setDDLoaded1(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchGetBannerListById: async (payload) => {
      try {
          const response = await GetBannerListById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateBannerList: async (payload) => {
      try {
          const response = await CreateBannerList({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/banner/banner-list')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateBannerList: async (payload) => {
      try {
          const response = await UpdateBannerList({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/banner/banner-list')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  const clearImageFill = (prevState) => {
    if (validation.values.imageDesktop || validation.values.imageMobile) {
      mAlert.info({
        type: "confrim",
        title: "Alert Confrim",
        subTitle: `Are you sure Change Banner Page ?`,
        mode: "red",
        content: "",
        onClose: () => {},
        onYes: () => { 
          validation.setFieldValue("imageDesktop", "")
          validation.setFieldValue("imageMobile", "")
        },
        onNo: () => {
          validation.setFieldValue("bannerPageId", `${prevState}`)
        },
      })
    }
  }


  useEffect(() => {
    console.log("🚀 ~ useEffect :", validation.values.bannerPlatformId)
    validation.setFieldValue("bannerPageId", "")

  }, [validation.values.bannerPlatformId])

  // กรณี Edit
  useEffect(() => {
    if (ddLoaded0 && ddLoaded1) {
      if (["update", "view"].includes(pageView)) {
        const payload = { id : qBannerListId }
        API.fetchGetBannerListById(payload)
      }
    }
  }, [ddLoaded0, ddLoaded1])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDBannerPlatform();
    API.fetchDDBannerPage();
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Banner List | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE


  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Banner List`}
            breadcrumbItems={[
              { title: "Banner List", link: "banner/banner-list" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Banner List Infomation</h5>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Banner Platform
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Banner Platform"
                            value={fn_helper.FNFORM.getObjectValue(ddBannerPlatform, `${validation.values.bannerPlatformId}`)}
                            onChange={(value) => validation.setFieldValue("bannerPlatformId", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddBannerPlatform}
                            className={`select2-selection ${validation.touched.bannerPlatformId && validation.errors.bannerPlatformId ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.bannerPlatformId && validation.errors.bannerPlatformId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.bannerPlatformId}</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Banner Page
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Banner Page"
                            value={fn_helper.FNFORM.getObjectValue(ddBannerPage, `${validation.values.bannerPageId}`)}
                            onChange={(value) => {
                              const _bannerPageId = validation.values.bannerPageId
                              if (_bannerPageId) {
                                mAlert.info({
                                  type: "confrim",
                                  title: "Alert Confrim",
                                  subTitle: `Are you sure Change Banner Page ?`,
                                  mode: "red",
                                  content: "",
                                  onClose: () => {},
                                  onYes: () => { 
                                    validation.setFieldValue("imageDesktop", "")
                                    validation.setFieldValue("imageMobile", "")
                                    validation.setFieldValue("bannerPageId", value.id)
                                  },
                                  onNo: () => {
                                  },
                                })
                              } else {
                                validation.setFieldValue("bannerPageId", value.id)
                              }

                            }}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddBannerPage.filter(item => {
                              if (item.id === "") {
                                return true
                              }
                              return !validation.values?.bannerPlatformId || item.bannerPlatformId === validation.values?.bannerPlatformId
                            })}
                            className={`select2-selection ${validation.touched.bannerPageId && validation.errors.bannerPageId ? "input-err" : ""}` }
                            isDisabled={pageView === "view" || validation.values.bannerPlatformId === ""}
                          />
                          {
                            (validation.touched.bannerPageId && validation.errors.bannerPageId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.bannerPageId}</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Banner Name
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="bannerName"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="bannerName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.bannerName}
                            invalid={validation.touched.bannerName && validation.errors.bannerName ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.bannerName && validation.errors.bannerName && (
                              <FormFeedback type="invalid">
                                {validation.errors.bannerName}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Row>
                        <Col className="col-6 mb-3">
                          <label
                            htmlFor="order"
                            className="col-md-12 col-form-label"
                          >
                            Sort Order
                            <span className="t-require">*</span>
                          </label>
                          <div className="col-md-12">
                            <Input
                              id="order"
                              className="form-control"
                              type="number"
                              name="order"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.order}
                              invalid={
                                validation.touched.order &&
                                validation.errors.order
                                  ? true
                                  : false
                              }
                              disabled={pageView === "view"}
                              min="1"
                            />
                            {validation.touched.order &&
                              validation.errors.order && (
                                <FormFeedback type="invalid">
                                  {validation.errors.order}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      </Row>
                      
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="startDate"
                          className="col-md-12 col-form-label"
                        >
                          Start Date
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="startDate"
                            className="form-control"
                            type="datetime-local"
                            placeholder=""
                            name="startDate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.startDate}
                            invalid={
                              validation.touched.startDate &&
                              validation.errors.startDate
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.startDate &&
                            validation.errors.startDate && (
                              <FormFeedback type="invalid">
                                {validation.errors.startDate}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="endDate"
                          className="col-md-12 col-form-label"
                        >
                          End Date
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="endDate"
                            className="form-control"
                            type="datetime-local"
                            placeholder=""
                            name="endDate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.endDate}
                            invalid={
                              validation.touched.endDate &&
                              validation.errors.endDate
                                ? true
                                : false
                            }
                            disabled={pageView === "view"}
                          />
                          {validation.touched.endDate &&
                            validation.errors.endDate && (
                              <FormFeedback type="invalid">
                                {validation.errors.endDate}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Banner embed Link
                          {/* <span className="t-require">*</span> */}
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="embedLink"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="embedLink"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.embedLink}
                            invalid={validation.touched.embedLink && validation.errors.embedLink ? true : false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.embedLink && validation.errors.embedLink && (
                              <FormFeedback type="invalid">
                                {validation.errors.embedLink}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <div className="col-md-12">
                          <UploadFileAPI 
                            bucketName="banner"
                            upload="Image"
                            typeUpload="ImageMap"
                            prefixName="banner-desktop"
                            label="Upload Desktop Image"
                            required={true}
                            widthSize={selectedDDBannerPage?.bannerWidth ?? 0}
                            heightSize={selectedDDBannerPage?.bannerHeight ?? 0}
                            description="description"
                            value={validation.values.imageDesktop}
                            onChange={([ imageURL = '', imageSize = '']) => {
                                validation.setFieldValue("imageDesktop", imageURL)
                            }}
                          />
                          <Input
                            id="imageDesktop"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="imageDesktop"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.imageDesktop}
                            invalid={validation.touched.imageDesktop && validation.errors.imageDesktop ? true : false}
                            readOnly={true}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.imageDesktop && validation.errors.imageDesktop && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.imageDesktop}</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <div className="col-md-12">
                          <UploadFileAPI
                            bucketName="banner"
                            upload="Image"
                            typeUpload="ImageMap"
                            prefixName="banner-mobile"
                            label="Upload Mobile Image"
                            required={true}
                            widthSize={selectedDDBannerPage?.bannerMobileWidth ?? 0}
                            heightSize={selectedDDBannerPage?.bannerMobileHeight ?? 0}
                            description="description"
                            value={validation.values.imageMobile}
                            onChange={([ imageURL = '', imageSize = '']) => {
                                validation.setFieldValue("imageMobile", imageURL)
                            }}
                          />
                          <Input
                            id="imageMobile"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="imageMobile"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.imageMobile}
                            invalid={validation.touched.imageMobile && validation.errors.imageMobile ? true : false}
                            readOnly={true}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.imageMobile && validation.errors.imageMobile && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.imageMobile}</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                            onChange={(value) => validation.setFieldValue("status", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.status && validation.errors.status) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/banner/banner-list')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/banner/banner-list')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(BannerListTemplate)
