import { get, post, put, patch } from "../Config"

export function SearchAboutUsDetail({ params = {} }) {
  return get({ url: `/v1/admin/aboutUsDetail`, params })
}
export function GetAboutUsDetailById({ id }) {
  return get({ url: `/v1/admin/aboutUsDetail/${id}` })
}
export function CreateAboutUsDetail({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/aboutUsDetail`, params, data })
}
export function UpdateAboutUsDetail({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/aboutUsDetail/${id}`, params, data })
}
export function PatchAboutUsDetail({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/aboutUsDetail/${id}`, params, data })
}
export function DeleteAboutUsDetail({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/aboutUsDetail/mutiDelete`, params, data })
}
