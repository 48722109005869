import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Badge,
} from "reactstrap"

import { Link } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { withTranslation } from "react-i18next";

import { get } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";
import "../../components/Common/datatables.scss"
import Spinners from "components/Common/Spinner";

const RowStatus = ({ status }) => {
    switch (status) {
        case "inactive":
            return <Badge className="bg-warning"> Inactive </Badge>;
    
        case "deleted":
            return <Badge className="bg-danger"> Deleted </Badge>;
    
        case "active":
            return <Badge className="bg-success"> Active </Badge>;
    
        default:
            return <Badge className="bg-success"> {status} </Badge>;
    }
};

const UserList = props => {
    const deleteRow = useCallback(
        (id) => () => {
            if (confirm("Are you sure?") == true) {
                console.log("ID: ", id)
            } else {
                console.log("Cancled!")
            }
        },
        []
    )

    const columns = useMemo(
        () => [
            {
                id: "colcheckbox",
                header: () => {
                    return (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Input type={`checkbox`} />
                        </div>
                )},
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return (
                        <>
                            <div id={cellProps.row.id} style={{ display: "flex", justifyContent: "center" }}>
                                <Input type={`checkbox`} value={cellProps.row.original.user_id} />
                            </div>
                        </>
                    )
                }
            },
            {
                header: '#',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                { (parseInt(cellProps.row.id) + 1) }
                            </div>
                        </>
                    )
                }
            },
            {
                header: 'Username',
                accessorKey: 'username',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Fullname',
                accessorKey: 'user_fullname',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Email',
                accessorKey: 'user_email',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Join date',
                accessorKey: 'user_joindate',
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: 'User Status',
                accessorKey: 'user_status',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => <RowStatus status={cellProps.row.original.user_status} />
            },
            {
                header: 'Tools',
                accessorKey: 'user_id',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    let _userID = cellProps.row.original.user_id;
                    return (
                        <>
                            {checkScope('systemsettings:usersetting:update') && <Link className={`btn btn-warning`} to={`/systemsettings/usersetting/useredit/${_userID}`}><i className={`bx bx-edit-alt`}></i></Link>}
                            &nbsp;
                            {checkScope('systemsettings:usersetting:delete') && <Link className={`btn btn-danger`} onClick={deleteRow(_userID)}><i className={`bx bx-trash`}></i></Link>}
                        </>
                    )
                }
            },
        ],
        []
    );

    const [userList, setUserList] = useState([])
    const [userListLoading, setUserListLoading] = useState(false)

    useEffect(() => {
        setUserListLoading(true)
        const fetchUserList = async () => {
            const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/systemsettings/userList')
            setUserList(result);
        };
    
        fetchUserList();
    }, []);

    useEffect(() => {
        setUserListLoading(false)
    }, [userList])

    //meta title
    document.title = "User Setting | " + process.env.REACT_APP_CMS_TITLE;

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="System Setting" breadcrumbItem="User Setting" />

                {
                    userListLoading ? <Spinners setLoading={setUserListLoading} />
                    : 
                        <TableContainer
                            isAddButton={true}
                            addRowLink={`/useradd`}
                            isExportButton={true}
                            exportLink={`/userexport`}
                            columns={columns}
                            data={userList || []}
                            isGlobalFilter={true}
                            isPagination={true}
                            SearchPlaceholder="Search"
                            pagination="pagination"
                            paginationWrapper='dataTables_paginate paging_simple_numbers'
                            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                            rootMenu="systemsettings"
                            subMenu="usersetting"
                            isCustomPageSize={true}
                        />
                }
            </div>
        </div>
    );
}

UserList.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(UserList)
