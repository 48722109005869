import { get, post, put, patch } from "../Config"

export function SearchTemplate({ params = {} }) {
  return get({ url: `/v1/admin/bannerSupplierTemplate`, params })
}
export function GetTemplateById({ id }) {
  return get({ url: `/v1/admin/bannerSupplierTemplate/${id}` })
}
export function CreateTemplate({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerSupplierTemplate`, params, data })
}
export function UpdateTemplate({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/bannerSupplierTemplate/${id}`, params, data })
}
export function PatchTemplate({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/bannerSupplierTemplate/${id}`, params, data })
}
export function DeleteTemplate({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/bannerSupplierTemplate/mutiDelete`, params, data })
}
