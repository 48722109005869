import React, { useEffect, useState, useContext, useRef  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select, { components } from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"


//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateBannerMain, GetBannerMainById, UpdateBannerMain } from "services/api/module/BannerMain"
import { CreateBannerImageProduct, GetBannerImageProductById, UpdateBannerImageProduct } from "services/api/module/BannerImageProduct"
import ModalContext from "../../contexts/ModalContext";
import { DropdownBannerMain, DropdownProductGroup } from "services/api/module/Dropdown"
import Flatpickr from "react-flatpickr";
import moment from "moment"
import UploadFileAPI from "components/UploadFileAPI"
import InputRadio from "components/Input/InputRadio"
import InputSwitch from "components/Input/InputSwitch"
import Stepper from "./Stepper"
import PopupForm from "./PopupForm"

import Template1 from "./BannerBox/Template1"
import Template2 from "./BannerBox/Template2"
import Template3 from "./BannerBox/Template3"
import Template4 from "./BannerBox/Template4"
import Template5 from "./BannerBox/Template5"
import Template6 from "./BannerBox/Template6"
import Template7 from "./BannerBox/Template7"
import Template8 from "./BannerBox/Template8"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const VIDEO_TYPE_OPTION = [
  { id: 'youtube', name: 'Youtube' },
  { id: 'upload', name: 'Upload' },
]

const HIGHT_LIGHT_OPTION = [
  { id: '0', name: 'No' },
  { id: '1', name: 'Yes' },
]

const BannerImageProductTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qBannerImageProductId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    bannerSupplierMainId: Yup.string().max(100).required("Please Select Banner Main"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddProductGroup, setDDProductGroup] = useState([])
  const [ddBannerImageProduct, setDDBannerImageProduct] = useState([])
  const [bannerMainName, setBannerMainName] = useState("")
  const [templateName, setTemplateName] = useState("")
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)
  const [ddLoaded1, setDDLoaded1] = useState(DEF_LOADED)
  const prevSelectDDTemplateRef = useRef();

  const [bannerImageList, setBannerImageList] = useState([])
  const [lenTemplate, setLenTemplate] = useState(0)

  // STEPPER STATE
  const [activeTab, setActiveTab] = useState(0)
  const [passedSteps, setPassedSteps] = useState([0])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      bannerSupplierMainId: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ BannerImageProductCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        bannerSupplierMainId: Number(values.bannerSupplierMainId),
      };
      console.log("🚀 ~ onSave ~ payload:", payload);
      setActiveTab(activeTab + 1)
    },
  })

  // SELECT DATA
  const selectedDDTemplate = ddBannerImageProduct.find(item => item.id == validation.values.templateId)

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("bannerSupplierMainId", resData.bannerSupplierMainId)
    setBannerMainName(resData.bannerSupplierMainName)
    setTemplateName(resData.bannerSupplierTemplate?.name ?? '')
    setBannerImageList(resData.bannerImageList)
    // ติด Effect Clear State
    setTimeout(() => validation.setFieldValue("templateId", resData.templateId), 100)
  }

  const onFinish = () => {
    let isBannerFull = bannerImageList.length >= lenTemplate
    console.log("🚀 ~ onFinish ~ bannerImageList:", bannerImageList)
    console.log("🚀 ~ onFinish ~ bannerImageList.length:", bannerImageList.length)
    console.log("🚀 ~ onFinish ~ lenTemplate:", lenTemplate)

    if (isBannerFull) {
      const payload = {
        bannerSupplierMainId: validation.values.bannerSupplierMainId,
        bannerImageList: bannerImageList.map(item => {
          item.target = parseInt(item.target)
          return item
        })
      }
      console.log("🚀 ~ onFinish ~ payload:", payload)
      if (pageView === "update") {
        API.fetchUpdateBannerImageProduct(payload)
      } else {
        API.fetchCreateBannerImageProduct(payload)
      }
    } else {
      mAlert.info({
        title: "Alert",
        subTitle: "Please push Image",
        content: "",
        onClose: () => {
        //  alert("onClose")
        },
      })

    }

  }

  const API = {
    fetchDDProductGroup: async () => {
      try {
          const response = await DropdownProductGroup({})
          const resData = response?.data ?? []
          // const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          const tmpDD = resData.data
          console.log("🚀 ~ fetchDDProductGroup: ~ tmpDD:", tmpDD)
          setDDProductGroup(tmpDD);
          setDDLoaded0(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
          })
      }
    },
    fetchDDBannerImageProduct: async () => {
      try {
          const response = await DropdownBannerMain({})
          const resData = response?.data ?? []
          const tmpDD = resData.data
          setDDBannerImageProduct(tmpDD);
          setDDLoaded1(true)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchGetBannerImageProductById: async (payload) => {
      try {
          const response = await GetBannerImageProductById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateBannerImageProduct: async (payload) => {
      try {
          const response = await CreateBannerImageProduct({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/banner-supplier/banner-image-product')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateBannerImageProduct: async (payload) => {
      try {
          const response = await UpdateBannerImageProduct({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/banner-supplier/banner-image-product')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    if (ddLoaded0 && ddLoaded1) {
      if (["update", "view"].includes(pageView)) {
        const payload = { id : qBannerImageProductId }
        API.fetchGetBannerImageProductById(payload)
      }
    }
  }, [ddLoaded0, ddLoaded1])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDProductGroup();
    API.fetchDDBannerImageProduct();
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Banner Image Product | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  // RENDER
  const STEP_CONTENT = [
    {
      stepLabel: 'Select Banner Main',
      components: <>
        <Col className="col-6 mb-3">
          <label
            htmlFor="category_name"
            className="col-md-12 col-form-label"
          >
            Banner Main
            <span className="t-require">*</span>
          </label>
          <div className="col-md-12">
            {
              pageView === 'create'
              ? (
                <>
                  <Select
                    placeholder="Select Banner Main"
                    value={fn_helper.FNFORM.getObjectValue(ddBannerImageProduct, `${validation.values.bannerSupplierMainId}`)}
                    onChange={(value) => {
                      validation.setFieldValue("bannerSupplierMainId", value.id)
                      setTemplateName(value.templateName)
                    }}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    options={ddBannerImageProduct}
                    className={`select2-selection ${validation.touched.bannerSupplierMainId && validation.errors.bannerSupplierMainId ? "input-err" : ""}` }
                    isDisabled={pageView === "view"}
                  />
                  {
                    (validation.touched.bannerSupplierMainId && validation.errors.bannerSupplierMainId) && (
                      <div type="invalid" className="invalid-feedback d-block">{validation.errors.bannerSupplierMainId}</div>
                    )
                  }
                </>
              )
              : (
                <>
                {/* {JSON.stringify(ddBannerImageProduct)} */}
                  <Input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={bannerMainName}
                    disabled={true}
                  />
                  
                </>
              )
            }
            
          </div>
        </Col>
      </>,
    },
    {
      stepLabel: 'Upload Image & Select Products',
      components: <>
        <Col className="col-12 mb-3">
        <section id="pPartnerTemplate">
          {templateName}[{lenTemplate}]

          <Template1
            useTemplateName={templateName}
            values={bannerImageList}
            productGroupOption={ddProductGroup}
            onChange={(value = {}, config = {}, index) => {
              console.log("🚀 ~ PopupForm ~ value, config, index:", value, config, index)
              let tmpUpdate = _.cloneDeep(bannerImageList)
              console.log("🚀 ~ PopupForm ~ tmpUpdate0:", tmpUpdate)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              tmpUpdate.push(value)
              console.log("🚀 ~ PopupForm ~ tmpUpdate1:", tmpUpdate)
              
              setBannerImageList(tmpUpdate)
            }}
            onRemove={(config = {}, order) => {
              let tmpUpdate = _.cloneDeep(bannerImageList)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              setBannerImageList(tmpUpdate)
            }}
            pageView={pageView}
            initMaxTemplate={(_lenTemplate = 0)=> {
              console.log('initMaxTemplate _lenTemplate >>', _lenTemplate)
              setLenTemplate(_lenTemplate)
            }}
            viewmode={false}
          />
          <Template2
            useTemplateName={templateName}
            values={bannerImageList}
            productGroupOption={ddProductGroup}
            onChange={(value = {}, config = {}, index) => {
              console.log("🚀 ~ PopupForm ~ value, config, index:", value, config, index)
              let tmpUpdate = _.cloneDeep(bannerImageList)
              console.log("🚀 ~ PopupForm ~ tmpUpdate0:", tmpUpdate)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              tmpUpdate.push(value)
              console.log("🚀 ~ PopupForm ~ tmpUpdate1:", tmpUpdate)
              
              setBannerImageList(tmpUpdate)
            }}
            onRemove={(config = {}, order) => {
              let tmpUpdate = _.cloneDeep(bannerImageList)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              setBannerImageList(tmpUpdate)
            }}
            pageView={pageView}
            initMaxTemplate={(_lenTemplate = 0)=> {
              console.log('initMaxTemplate _lenTemplate >>', _lenTemplate)
              setLenTemplate(_lenTemplate)
            }}
            viewmode={false}
          />
          <Template3
            useTemplateName={templateName}
            values={bannerImageList}
            productGroupOption={ddProductGroup}
            onChange={(value = {}, config = {}, index) => {
              console.log("🚀 ~ PopupForm ~ value, config, index:", value, config, index)
              let tmpUpdate = _.cloneDeep(bannerImageList)
              console.log("🚀 ~ PopupForm ~ tmpUpdate0:", tmpUpdate)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              tmpUpdate.push(value)
              console.log("🚀 ~ PopupForm ~ tmpUpdate1:", tmpUpdate)
              
              setBannerImageList(tmpUpdate)
            }}
            onRemove={(config = {}, order) => {
              let tmpUpdate = _.cloneDeep(bannerImageList)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              setBannerImageList(tmpUpdate)
            }}
            pageView={pageView}
            initMaxTemplate={(_lenTemplate = 0)=> {
              console.log('initMaxTemplate _lenTemplate >>', _lenTemplate)
              setLenTemplate(_lenTemplate)
            }}
            viewmode={false}
          />
          <Template4
            useTemplateName={templateName}
            values={bannerImageList}
            productGroupOption={ddProductGroup}
            onChange={(value = {}, config = {}, index) => {
              console.log("🚀 ~ PopupForm ~ value, config, index:", value, config, index)
              let tmpUpdate = _.cloneDeep(bannerImageList)
              console.log("🚀 ~ PopupForm ~ tmpUpdate0:", tmpUpdate)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              tmpUpdate.push(value)
              console.log("🚀 ~ PopupForm ~ tmpUpdate1:", tmpUpdate)
              
              setBannerImageList(tmpUpdate)
            }}
            onRemove={(config = {}, order) => {
              let tmpUpdate = _.cloneDeep(bannerImageList)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              setBannerImageList(tmpUpdate)
            }}
            pageView={pageView}
            initMaxTemplate={(_lenTemplate = 0)=> {
              console.log('initMaxTemplate _lenTemplate >>', _lenTemplate)
              setLenTemplate(_lenTemplate)
            }}
            viewmode={false}
          />
          <Template5
            useTemplateName={templateName}
            values={bannerImageList}
            productGroupOption={ddProductGroup}
            onChange={(value = {}, config = {}, index) => {
              console.log("🚀 ~ PopupForm ~ value, config, index:", value, config, index)
              let tmpUpdate = _.cloneDeep(bannerImageList)
              console.log("🚀 ~ PopupForm ~ tmpUpdate0:", tmpUpdate)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              tmpUpdate.push(value)
              console.log("🚀 ~ PopupForm ~ tmpUpdate1:", tmpUpdate)
              
              setBannerImageList(tmpUpdate)
            }}
            onRemove={(config = {}, order) => {
              let tmpUpdate = _.cloneDeep(bannerImageList)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              setBannerImageList(tmpUpdate)
            }}
            pageView={pageView}
            initMaxTemplate={(_lenTemplate = 0)=> {
              console.log('initMaxTemplate _lenTemplate >>', _lenTemplate)
              setLenTemplate(_lenTemplate)
            }}
            viewmode={false}
          />
          <Template6
            useTemplateName={templateName}
            values={bannerImageList}
            productGroupOption={ddProductGroup}
            onChange={(value = {}, config = {}, index) => {
              console.log("🚀 ~ PopupForm ~ value, config, index:", value, config, index)
              let tmpUpdate = _.cloneDeep(bannerImageList)
              console.log("🚀 ~ PopupForm ~ tmpUpdate0:", tmpUpdate)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              tmpUpdate.push(value)
              console.log("🚀 ~ PopupForm ~ tmpUpdate1:", tmpUpdate)
              
              setBannerImageList(tmpUpdate)
            }}
            onRemove={(config = {}, order) => {
              let tmpUpdate = _.cloneDeep(bannerImageList)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              setBannerImageList(tmpUpdate)
            }}
            pageView={pageView}
            initMaxTemplate={(_lenTemplate = 0)=> {
              console.log('initMaxTemplate _lenTemplate >>', _lenTemplate)
              setLenTemplate(_lenTemplate)
            }}
            viewmode={false}
          />
          <Template7
            useTemplateName={templateName}
            values={bannerImageList}
            productGroupOption={ddProductGroup}
            onChange={(value = {}, config = {}, index) => {
              console.log("🚀 ~ PopupForm ~ value, config, index:", value, config, index)
              let tmpUpdate = _.cloneDeep(bannerImageList)
              console.log("🚀 ~ PopupForm ~ tmpUpdate0:", tmpUpdate)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              tmpUpdate.push(value)
              console.log("🚀 ~ PopupForm ~ tmpUpdate1:", tmpUpdate)
              
              setBannerImageList(tmpUpdate)
            }}
            onRemove={(config = {}, order) => {
              let tmpUpdate = _.cloneDeep(bannerImageList)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              setBannerImageList(tmpUpdate)
            }}
            pageView={pageView}
            initMaxTemplate={(_lenTemplate = 0)=> {
              console.log('initMaxTemplate _lenTemplate >>', _lenTemplate)
              setLenTemplate(_lenTemplate)
            }}
            viewmode={false}
          />
          <Template8
            useTemplateName={templateName}
            values={bannerImageList}
            productGroupOption={ddProductGroup}
            onChange={(value = {}, config = {}, index) => {
              console.log("🚀 ~ PopupForm ~ value, config, index:", value, config, index)
              let tmpUpdate = _.cloneDeep(bannerImageList)
              console.log("🚀 ~ PopupForm ~ tmpUpdate0:", tmpUpdate)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              tmpUpdate.push(value)
              console.log("🚀 ~ PopupForm ~ tmpUpdate1:", tmpUpdate)
              
              setBannerImageList(tmpUpdate)
            }}
            onRemove={(config = {}, order) => {
              let tmpUpdate = _.cloneDeep(bannerImageList)
              tmpUpdate = tmpUpdate.filter(item => item.order !== config.order)
              setBannerImageList(tmpUpdate)
            }}
            pageView={pageView}
            initMaxTemplate={(_lenTemplate = 0)=> {
              console.log('initMaxTemplate _lenTemplate >>', _lenTemplate)
              setLenTemplate(_lenTemplate)
            }}
            viewmode={false}
          />
        </section>
          
        </Col>
      </>
    }
  ]

  const SelectedBannerSupplierMain = ddBannerImageProduct.find(item => item.id == validation.values.bannerSupplierMainId) ?? {}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Banner Image Product`}
            breadcrumbItems={[
              { title: "Banner Image Product", link: "banner/banner-list" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  {/* ddProductGroup: {JSON.stringify(ddProductGroup)}<br/> */}
                  {/* bannerSupplierMainId: {JSON.stringify(validation.values.bannerSupplierMainId)}<br/> */}
                  {/* SelectedBannerSupplierMain : {JSON.stringify(SelectedBannerSupplierMain)} */}

                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Banner Image Product Infomation</h5>
                      <Stepper
                        value={activeTab}
                        componentSteps={STEP_CONTENT}
                      />
                    </Row>

                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                    <button
                      className="btn btn-primary w-md" 
                      onClick={() => {
                        setActiveTab(activeTab - 1)
                      }}
                      disabled={activeTab === 0}
                    >
                      Previous
                    </button>
                    {
                      activeTab === 0 && (
                        <>
                          {
                            pageView == "view"
                            ? <><button className="btn btn-primary w-md" onClick={() => navigate('/banner-supplier/banner-image-product')}>BACK</button></> 
                            : <>
                              <button type="submit" className="btn btn-primary w-md" >Next</button>
                              <button className="btn btn-secondary w-md" onClick={() => navigate('/banner-supplier/banner-image-product')}>CANCEL</button>
                            </>
                          }
                        </>
                        
                      )
                    }
                    {
                      activeTab === 1 && (
                        <>
                          {
                            pageView == "view"
                            ? <><button className="btn btn-primary w-md" onClick={() => navigate('/banner-supplier/banner-image-product')}>BACK</button></> 
                            : <>
                              <button type="button" className="btn btn-primary w-md" onClick={() => onFinish()}>SAVE</button>
                              <button className="btn btn-secondary w-md" onClick={() => navigate('/banner-supplier/banner-image-product')}>CANCEL</button>
                            </>
                          }
                        </>
                      )
                    }
                    
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(BannerImageProductTemplate)
