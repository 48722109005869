import { get, post, put, patch } from "../Config"

export function SearchBranch({ params = {} }) {
  return get({ url: `/v1/admin/branch`, params })
}
export function GetBranchById({ id }) {
  return get({ url: `/v1/admin/branch/${id}` })
}
export function CreateBranch({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/branch`, params, data })
}
export function UpdateBranch({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/branch/${id}`, params, data })
}
export function PatchBranch({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/branch/${id}`, params, data })
}
export function DeleteBranch({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/branch/mutiDelete`, params, data })
}
