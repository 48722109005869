import React, { useEffect, useState, useContext  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateHelpCenterCategory, GetHelpCenterCategoryById, UpdateHelpCenterCategory } from "services/api/module/HelpCenterCategory"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const HelpCenterCategoryTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qHelpCenterCategoryId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    categoryTh: Yup.string().max(100).required("Please Enter Category TH"),
    categoryEn: Yup.string().max(100).required("Please Enter Category EN"),
    sortOrder: Yup.string().max(100).required("Please Enter sort Order"),
    status: Yup.string().max(100).required("Please Enter Status"),

  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryTh: "",
      categoryEn: "",
      sortOrder: "",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ HelpCenterCategoryCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        categoryTh: values.categoryTh,
        categoryEn: values.categoryEn,
        sortOrder: values.sortOrder,
        status: Number(values.status),
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateHelpCenterCategory(payload)
      } else {
        API.fetchCreateHelpCenterCategory(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("categoryTh", resData.categoryTh)
    validation.setFieldValue("categoryEn", resData.categoryEn)
    validation.setFieldValue("sortOrder", resData.sortOrder)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetHelpCenterCategoryById: async (payload) => {
      try {
          const response = await GetHelpCenterCategoryById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateHelpCenterCategory: async (payload) => {
      try {
          const response = await CreateHelpCenterCategory({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/help-center/category')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateHelpCenterCategory: async (payload) => {
      try {
          const response = await UpdateHelpCenterCategory({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/help-center/category')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id : qHelpCenterCategoryId }
      API.fetchGetHelpCenterCategoryById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `HelpCenterCategory | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} HelpCenterCategory`}
            breadcrumbItems={[
              { title: "HelpCenterCategory", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      
                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12">
                                <h5 className="mb-3">Category Infomation</h5>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Category TH
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="categoryTh"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="categoryTh"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.categoryTh}
                                    invalid={validation.touched.categoryTh && validation.errors.categoryTh ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  { 
                                    validation.touched.categoryTh && validation.errors.categoryTh && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.categoryTh}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_en"
                                  className="col-md-12 col-form-label"
                                >
                                  Category EN
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="categoryEn"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="categoryEn"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.categoryEn}
                                    invalid={validation.touched.categoryEn && validation.errors.categoryEn ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  { 
                                    validation.touched.categoryEn && validation.errors.categoryEn && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.categoryEn}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="sort_order"
                                  className="col-md-12 col-form-label"
                                >
                                  Sort Order
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="sortOrder"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="sortOrder"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.sortOrder}
                                    invalid={validation.touched.sortOrder && validation.errors.sortOrder ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  { 
                                    validation.touched.sortOrder && validation.errors.sortOrder && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sortOrder}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Status (Active/Inactive)
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Status"
                                    value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                                    onChange={(value) => validation.setFieldValue("status", value.id)}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    options={STATUS_OPTION}
                                    className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                                    isDisabled={pageView === "view"}
                                  />
                                  {
                                    (validation.touched.status && validation.errors.status) && (
                                      <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                                    )
                                  }
                                  { 
                                    validation.touched.status && validation.errors.status && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.status}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/help-center/category')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/help-center/category')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(HelpCenterCategoryTemplate)
