import { get, post, put, patch } from "../Config"

export function SearchPromotionArticleDetail({ params = {} }) {
  return get({ url: `/v1/admin/promotionArticleDetail`, params })
}
export function GetPromotionArticleDetailById({ id }) {
  return get({ url: `/v1/admin/promotionArticleDetail/${id}` })
}
export function CreatePromotionArticleDetail({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/promotionArticleDetail`, params, data })
}
export function UpdatePromotionArticleDetail({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/promotionArticleDetail/${id}`, params, data })
}
export function PatchPromotionArticleDetail({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/promotionArticleDetail/${id}`, params, data })
}
export function DeletePromotionArticleDetail({ params = {}, data = {} }) {
  return post({
    url: `/v1/admin/promotionArticleDetail/mutiDelete`,
    params,
    data,
  })
}
export function CheckDuplicatePromotionArticleDetail({ params = {}, data = {}}) {
  return post({ url: `/v1/admin/promotionArticleDetail/duplicate`, params, data })
}
