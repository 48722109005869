import { get, post, put, patch } from "../Config"

export function SearchShippingMethod({ params = {} }) {
  return get({ url: `/v1/admin/shippingMethod`, params })
}
export function GetShippingMethodById({ id }) {
  return get({ url: `/v1/admin/shippingMethod/${id}` })
}
export function CreateShippingMethod({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/shippingMethod`, params, data })
}
export function UpdateShippingMethod({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/shippingMethod/${id}`, params, data })
}
export function PatchShippingMethod({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/shippingMethod/${id}`, params, data })
}
export function DeleteShippingMethod({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/shippingMethod/mutiDelete`, params, data })
}
