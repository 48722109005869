import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// TS Route
import UserList from "../pages/Systemsettings/UserList"
import UserAdd from "../pages/Systemsettings/UserAdd"
import UserEdit from "../pages/Systemsettings/UserEdit"

// Questionaire Page
import QuestionaireCategoryList from "pages/Questionaire/CategoryList";
import QuestionaireCategoryCreate from "pages/Questionaire/QuestionaireCategory";
import QuestionaireCategoryEdit from "pages/Questionaire/QuestionaireCategoryEdit";

import QuestionaireList from "pages/Questionaire/QuestionaireList";
import QuestionaireCreate from "pages/Questionaire/QuestionaireCreate";
import QuestionaireEdit from "pages/Questionaire/QuestionaireEdit";


// Frontweb
import QuestionnaireFrontweb from "pages/Frontweb/QuestionnaireFrontweb";
import BannerPlatformList from "pages/BannerPlatform/BannerPlatformList";
import BannerPlatformTemplate from "pages/BannerPlatform/BannerPlatformTemplate";
import BannerPageList from "pages/BannerPage/BannerPageList";
import BannerPageTemplate from "pages/BannerPage/BannerPageTemplate";
import BannerList from "pages/BannerList/BannerList";
import BannerListTemplate from "pages/BannerList/BannerListTemplate";

// Survey page
import SurveyListPage from "pages/Survey/List";
import SurveyFormTemplatePage from "pages/Survey/FormTemplate";

// Customer page
import CustomerListPage from "pages/Customer/List";
import CustomerFormTemplatePage from "pages/Customer/FormTemplate";

// Point page
import PointTypeListPage from "pages/Point/Type/List";
import PointTypeFormTemplatePage from "pages/Point/Type/FormTemplate";
import PointConfigListPage from "pages/Point/Config/List";
import PointConfigFormTemplatePage from "pages/Point/Config/FormTemplate";
import PointSpecialEventConfigListPage from "pages/Point/SpecialEventConfig/List";
import PointSpecialEventConfigFormTemplatePage from "pages/Point/SpecialEventConfig/FormTemplate";
import BrandList from "pages/Brand/BrandList";
import BrandTemplate from "pages/Brand/BrandTemplate";
import EMagazineList from "pages/EMagazine/EMagazineList";
import EMagazineTemplate from "pages/EMagazine/EMagazineTemplate";
import ArticleCategoryList from "pages/ArticleCategory/ArticleCategoryList";
import ArticleCategoryTemplate from "pages/ArticleCategory/ArticleCategoryTemplate";
import ArticleKeywordList from "pages/ArticleKeyword/ArticleKeywordList";
import ArticleKeywordTemplate from "pages/ArticleKeyword/ArticleKeywordTemplate";
import ArticleList from "pages/Article/ArticleList";
import ArticleTemplate from "pages/Article/ArticleTemplate";
import TemplateList from "pages/Template/TemplateList";
import TemplateTemplate from "pages/Template/TemplateTemplate";
import BannerMainList from "pages/BannerMain/BannerMainList";
import BannerMainTemplate from "pages/BannerMain/BannerMainTemplate";
import BannerImageProductList from "pages/BannerImageProduct/BannerImageProductList";
import BannerImageProductTemplate from "pages/BannerImageProduct/BannerImageProductTemplate";
import BankList from "pages/Bank/BankList";
import BankTemplate from "pages/Bank/BankTemplate";
import PromotionCategoryList from "pages/PromotionCategory/PromotionCategoryList";
import PromotionCategoryTemplate from "pages/PromotionCategory/PromotionCategoryTemplate";
import JobTypeList from "pages/JobsManagment/JobType/JobTypeList";
import JobTypeTemplate from "pages/JobsManagment/JobType/JobTypeTemplate";
import JobPositionList from "pages/JobsManagment/JobPosition/JobPositionList";
import JobPositionTemplate from "pages/JobsManagment/JobPosition/JobPositionTemplate";
import JobApplicationList from "pages/JobsManagment/JobApplication/JobApplicationList";
import JobApplicationTemplate from "pages/JobsManagment/JobApplication/JobApplicationTemplate";
import JobApplicationPrint from "pages/JobsManagment/JobApplication/JobApplicationPrint";
import PrintComponent from "pages/JobsManagment/JobApplication/PrintComponent";
import BranchTypeList from "pages/BranchType/BranchTypeList";
import BranchTypeTemplate from "pages/BranchType/BranchTypeTemplate";

//AboutUs Page
import AboutUsDetailList from "pages/AboutUsDetail/AboutUsDetailList";
import AboutUsDetailTemplate from "pages/AboutUsDetail/AboutUsDetailTemplate";
import AboutUsRewardList from "pages/AboutUsReward/AboutUsRewardList";
import AboutUsRewardTemplate from "pages/AboutUsReward/AboutUsRewardTemplate";
import BranchList from "pages/Branch/BranchList";
import BranchTemplate from "pages/Branch/BranchTemplate";
import PartnersList from "pages/Partners/PartnersList";
import PartnersTemplate from "pages/Partners/PartnersTemplate";
import HelpCenterCategoryList from "pages/HelpCenterCategory/HelpCenterCategoryList";
import HelpCenterCategoryTemplate from "pages/HelpCenterCategory/HelpCenterCategoryTemplate";
import HelpCenterListList from "pages/HelpCenterList/HelpCenterListList";
import HelpCenterListTemplate from "pages/HelpCenterList/HelpCenterListTemplate";
import PromotionArticleDetailList from "pages/PromotionArticleDetail/PromotionArticleDetailList";
import PromotionArticleDetailTemplate from "pages/PromotionArticleDetail/PromotionArticleDetailTemplate";
import RewardCategoryList from "pages/RewardCategory/RewardCategoryList";
import RewardCategoryTemplate from "pages/RewardCategory/RewardCategoryTemplate";
import RewardList from "pages/Reward/RewardList";
import RewardTemplate from "pages/Reward/RewardTemplate";
import RewardTermsConditionsList from "pages/RewardTermsConditions/RewardTermsConditionsList";
import RewardTermsConditionsTemplate from "pages/RewardTermsConditions/RewardTermsConditionsTemplate";
import OrderRewardList from "pages/OrderReward/OrderRewardList";
import OrderRewardTemplate from "pages/OrderReward/OrderRewardTemplate";
import ShippingMethodList from "pages/ShippingMethod/ShippingMethodList";
import ShippingMethodTemplate from "pages/ShippingMethod/ShippingMethodTemplate";
import ProductsList from "pages/Products/ProductsList";
import ProductsTemplate from "pages/Products/ProductsTemplate";
import AttributesList from "pages/Attributes/AttributesList";
import AttributesTemplate from "pages/Attributes/AttributesTemplate";
import PaymentMethodGroupList from "pages/PaymentMethodGroup/PaymentMethodGroupList";
import PaymentMethodGroupTemplate from "pages/PaymentMethodGroup/PaymentMethodGroupTemplate";
import PaymentCreditDebitList from "pages/PaymentCreditDebit/PaymentCreditDebitList";
import PaymentCreditDebitTemplate from "pages/PaymentCreditDebit/PaymentCreditDebitTemplate";
import BankPaymentAccountList from "pages/BankPaymentAccount/BankPaymentAccountList";
import BankPaymentAccountTemplate from "pages/BankPaymentAccount/BankPaymentAccountTemplate";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/systemsettings/usersetting", component: <UserList /> },
  { path: "/systemsettings/usersetting/useradd", component: <UserAdd /> },
  { path: "/systemsettings/usersetting/useredit/:userId", component: <UserEdit /> },

  // for AI Shopping
  { path: "/questionnaire", component: <QuestionaireCategoryList /> },
  { path: "/questionnaire/category", component: <QuestionaireCategoryList /> },
  { path: "/questionnaire/category/create", component: <QuestionaireCategoryCreate /> },
  { path: "/questionnaire/category/edit/:categoryId", component: <QuestionaireCategoryEdit /> },

  { path: "/questionnaire/list/:categoryName", component: <QuestionaireList /> },
  // { path: "/questionnaire/create/:categoryId", component: <QuestionaireCreate /> },
  { path: "/questionnaire/create", component: <QuestionaireCreate /> },
  // { path: "/questionnaire/edit/:oldCategoryId/:newCategoryId", component: <QuestionaireEdit /> },
  { path: "/questionnaire/edit/:categoryId", component: <QuestionaireEdit /> },



  /* Banner route */
  { path: "/banner/banner-platform", component: <BannerPlatformList /> },
  { path: "/banner/banner-platform/create", component: <BannerPlatformTemplate pageView="create" /> },
  { path: "/banner/banner-platform/edit/:id", component: <BannerPlatformTemplate pageView="update" /> },
  { path: "/banner/banner-platform/view/:id", component: <BannerPlatformTemplate pageView="view" /> },

  { path: "/banner/banner-page", component: <BannerPageList /> },
  { path: "/banner/banner-page/create", component: <BannerPageTemplate pageView="create" /> },
  { path: "/banner/banner-page/edit/:id", component: <BannerPageTemplate pageView="update" /> },
  { path: "/banner/banner-page/view/:id", component: <BannerPageTemplate pageView="view" /> },

  { path: "/banner/banner-list", component: <BannerList /> },
  { path: "/banner/banner-list/create", component: <BannerListTemplate pageView="create" /> },
  { path: "/banner/banner-list/edit/:id", component: <BannerListTemplate pageView="update" /> },
  { path: "/banner/banner-list/view/:id", component: <BannerListTemplate pageView="view" /> },

  /* Brand route */
  { path: "/brand", component: <BrandList /> },
  { path: "/brand/create", component: <BrandTemplate pageView="create" /> },
  { path: "/brand/edit/:id", component: <BrandTemplate pageView="update" /> },
  { path: "/brand/view/:id", component: <BrandTemplate pageView="view" /> },
  /* End Brand route */

  /* E-Magazine route */
  { path: "/e-magazine", component: <EMagazineList /> },
  { path: "/e-magazine/create", component: <EMagazineTemplate pageView="create" /> },
  { path: "/e-magazine/edit/:id", component: <EMagazineTemplate pageView="update" /> },
  { path: "/e-magazine/view/:id", component: <EMagazineTemplate pageView="view" /> },
  /* End E-Magazine route */

  /* Article route */
  { path: "/article/category", component: <ArticleCategoryList /> },
  { path: "/article/category/create", component: <ArticleCategoryTemplate pageView="create" /> },
  { path: "/article/category/edit/:id", component: <ArticleCategoryTemplate pageView="update" /> },
  { path: "/article/category/view/:id", component: <ArticleCategoryTemplate pageView="view" /> },

  { path: "/article/keyword", component: <ArticleKeywordList /> },
  { path: "/article/keyword/create", component: <ArticleKeywordTemplate pageView="create" /> },
  { path: "/article/keyword/edit/:id", component: <ArticleKeywordTemplate pageView="update" /> },
  { path: "/article/keyword/view/:id", component: <ArticleKeywordTemplate pageView="view" /> },

  { path: "/article/article", component: <ArticleList /> },
  { path: "/article/article/create", component: <ArticleTemplate pageView="create" /> },
  { path: "/article/article/edit/:id", component: <ArticleTemplate pageView="update" /> },
  { path: "/article/article/view/:id", component: <ArticleTemplate pageView="view" /> },

  /* End Brand route */


  /* Banner Supplier route */
  { path: "/banner-supplier/template", component: <TemplateList /> },
  { path: "/banner-supplier/template/create", component: <TemplateTemplate pageView="create" /> },
  { path: "/banner-supplier/template/edit/:id", component: <TemplateTemplate pageView="update" /> },
  { path: "/banner-supplier/template/view/:id", component: <TemplateTemplate pageView="view" /> },

  { path: "/banner-supplier/banner-main", component: <BannerMainList /> },
  { path: "/banner-supplier/banner-main/create", component: <BannerMainTemplate pageView="create" /> },
  { path: "/banner-supplier/banner-main/edit/:id", component: <BannerMainTemplate pageView="update" /> },
  { path: "/banner-supplier/banner-main/view/:id", component: <BannerMainTemplate pageView="view" /> },

  { path: "/banner-supplier/banner-image-product", component: <BannerImageProductList /> },
  { path: "/banner-supplier/banner-image-product/create", component: <BannerImageProductTemplate pageView="create" /> },
  { path: "/banner-supplier/banner-image-product/edit/:id", component: <BannerImageProductTemplate pageView="update" /> },
  { path: "/banner-supplier/banner-image-product/view/:id", component: <BannerImageProductTemplate pageView="view" /> },

  /* End Banner Supplier route */

  /* Banner Supplier route */
  { path: "/jobs-managment/job-type", component: <JobTypeList /> },
  { path: "/jobs-managment/job-type/create", component: <JobTypeTemplate pageView="create" /> },
  { path: "/jobs-managment/job-type/edit/:id", component: <JobTypeTemplate pageView="update" /> },
  { path: "/jobs-managment/job-type/view/:id", component: <JobTypeTemplate pageView="view" /> },

  { path: "/jobs-managment/job-position", component: <JobPositionList /> },
  { path: "/jobs-managment/job-position/create", component: <JobPositionTemplate pageView="create" /> },
  { path: "/jobs-managment/job-position/edit/:id", component: <JobPositionTemplate pageView="update" /> },
  { path: "/jobs-managment/job-position/view/:id", component: <JobPositionTemplate pageView="view" /> },

  { path: "/jobs-managment/job-application", component: <JobApplicationList /> },
  { path: "/jobs-managment/job-application/edit/:id", component: <JobApplicationTemplate pageView="update" /> },
  { path: "/jobs-managment/job-application/print/:id", component: <JobApplicationPrint /> },
  /* End Banner Supplier route */

  /* Bank route */
  { path: "/bank/bank", component: <BankList /> },
  { path: "/bank/bank/create", component: <BankTemplate pageView="create" /> },
  { path: "/bank/bank/edit/:id", component: <BankTemplate pageView="update" /> },
  { path: "/bank/bank/view/:id", component: <BankTemplate pageView="update" /> },
  /* Bank END route */

  /* Bank Payment Account route */
  { path: "/bank/bank-payment-account", component: <BankPaymentAccountList /> },
  { path: "/bank/bank-payment-account/create", component: <BankPaymentAccountTemplate pageView="create" /> },
  { path: "/bank/bank-payment-account/edit/:id", component: <BankPaymentAccountTemplate pageView="update" /> },
  { path: "/bank/bank-payment-account/view/:id", component: <BankPaymentAccountTemplate pageView="view" /> },
  /* End Bank Payment Account route */


  /* Survey route */
  { path: "/survey", component: <SurveyListPage /> },
  { path: "/survey/create", component: <SurveyFormTemplatePage pageView="create" /> },
  { path: "/survey/edit/:id", component: <SurveyFormTemplatePage pageView="update" /> },
  /* End Survey route */

  /* Customer route */
  { path: "/customer", component: <CustomerListPage /> },
  { path: "/customer/edit/:id", component: <CustomerFormTemplatePage pageView="update" /> },
  { path: "/customer/view/:id", component: <CustomerFormTemplatePage pageView="view" /> },
  /* End Customer route */

  /* Point route */
  { path: "/point/type", component: <PointTypeListPage /> },
  { path: "/point/type/create", component: <PointTypeFormTemplatePage pageView="create" /> },
  { path: "/point/type/edit/:id", component: <PointTypeFormTemplatePage pageView="update" /> },
  { path: "/point/config", component: <PointConfigListPage /> },
  { path: "/point/config/create", component: <PointConfigFormTemplatePage pageView="create" /> },
  { path: "/point/config/edit/:id", component: <PointConfigFormTemplatePage pageView="update" /> },
  { path: "/point/special-event-config", component: <PointSpecialEventConfigListPage /> },
  { path: "/point/special-event-config/create", component: <PointSpecialEventConfigFormTemplatePage pageView="create" /> },
  { path: "/point/special-event-config/edit/:id", component: <PointSpecialEventConfigFormTemplatePage pageView="update" /> },
  /* End Point route */

  /* About Us route */
  { path: "/about-us/about-us-detail", component: <AboutUsDetailList /> },
  { path: "/about-us/about-us-detail/create", component: <AboutUsDetailTemplate pageView="create" /> },
  { path: "/about-us/about-us-detail/edit/:id", component: <AboutUsDetailTemplate pageView="update" /> },
  { path: "/about-us/about-us-detail/view/:id", component: <AboutUsDetailTemplate pageView="view" /> },

  { path: "/about-us/about-us-reward", component: <AboutUsRewardList /> },
  { path: "/about-us/about-us-reward/create", component: <AboutUsRewardTemplate pageView="create" /> },
  { path: "/about-us/about-us-reward/edit/:id", component: <AboutUsRewardTemplate pageView="update" /> },
  { path: "/about-us/about-us-reward/view/:id", component: <AboutUsRewardTemplate pageView="view" /> },
  /* End About Us route */

  /* Partners route */
  { path: "/partners", component: <PartnersList /> },
  { path: "/partners/create", component: <PartnersTemplate pageView="create" /> },
  { path: "/partners/edit/:id", component: <PartnersTemplate pageView="update" /> },
  { path: "/partners/view/:id", component: <PartnersTemplate pageView="view" /> },
  /* End Partners route */

  /* HelpCenter route */
  { path: "/help-center/category", component: <HelpCenterCategoryList /> },
  { path: "/help-center/category/create", component: <HelpCenterCategoryTemplate pageView="create" /> },
  { path: "/help-center/category/edit/:id", component: <HelpCenterCategoryTemplate pageView="update" /> },
  { path: "/help-center/category/view/:id", component: <HelpCenterCategoryTemplate pageView="view" /> },

  { path: "/help-center/list", component: <HelpCenterListList /> },
  { path: "/help-center/list/create", component: <HelpCenterListTemplate pageView="create" /> },
  { path: "/help-center/list/edit/:id", component: <HelpCenterListTemplate pageView="update" /> },
  { path: "/help-center/list/view/:id", component: <HelpCenterListTemplate pageView="view" /> },
  /* End HelpCenter route */

  /* Branch route */
  { path: "/branch/branch-type", component: <BranchTypeList /> },
  { path: "/branch/branch-type/create", component: <BranchTypeTemplate pageView="create" /> },
  { path: "/branch/branch-type/edit/:id", component: <BranchTypeTemplate pageView="update" /> },
  { path: "/branch/branch-type/view/:id", component: <BranchTypeTemplate pageView="view" /> },

  { path: "/branch/branch", component: <BranchList /> },
  { path: "/branch/branch/create", component: <BranchTemplate pageView="create" /> },
  { path: "/branch/branch/edit/:id", component: <BranchTemplate pageView="update" /> },
  { path: "/branch/branch/view/:id", component: <BranchTemplate pageView="view" /> },

  /* Promotion Category route */
  { path: "/promotion/category", component: <PromotionCategoryList /> },
  { path: "/promotion/category/create", component: <PromotionCategoryTemplate pageView="create" /> },
  { path: "/promotion/category/edit/:id", component: <PromotionCategoryTemplate pageView="update" /> },
  { path: "/promotion/category/view/:id", component: <PromotionCategoryTemplate pageView="view" /> },
  /* End Promotion Category route */

  /* Promotion Article Detail route */
  { path: "/promotion/promotion-article-detail", component: <PromotionArticleDetailList /> },
  { path: "/promotion/promotion-article-detail/create", component: <PromotionArticleDetailTemplate pageView="create" /> },
  { path: "/promotion/promotion-article-detail/edit/:id", component: <PromotionArticleDetailTemplate pageView="update" /> },
  { path: "/promotion/promotion-article-detail/view/:id", component: <PromotionArticleDetailTemplate pageView="view" /> },
  /* End Promotion Article Detail route */


  /* Reward Category route */
  { path: "/reward/category", component: <RewardCategoryList /> },
  { path: "/reward/category/create", component: <RewardCategoryTemplate pageView="create" /> },
  { path: "/reward/category/edit/:id", component: <RewardCategoryTemplate pageView="update" /> },
  { path: "/reward/category/view/:id", component: <RewardCategoryTemplate pageView="view" /> },
  /* End Reward Category route */

  /* Reward route */
  { path: "/reward/reward-detail", component: <RewardList /> },
  { path: "/reward/reward-detail/create", component: <RewardTemplate pageView="create" /> },
  { path: "/reward/reward-detail/edit/:id", component: <RewardTemplate pageView="update" /> },
  { path: "/reward/reward-detail/view/:id", component: <RewardTemplate pageView="view" /> },
  /* End Reward route */


  /* Reward Terms Conditions route */
  { path: "/reward/reward-terms-conditions", component: <RewardTermsConditionsList /> },
  { path: "/reward/reward-terms-conditions/create", component: <RewardTermsConditionsTemplate pageView="create" /> },
  { path: "/reward/reward-terms-conditions/edit/:id", component: <RewardTermsConditionsTemplate pageView="update" /> },
  { path: "/reward/reward-terms-conditions/view/:id", component: <RewardTermsConditionsTemplate pageView="view" /> },
  /* End Reward Terms Conditions route */

  /* Brand route */
  { path: "/reward/order-reward", component: <OrderRewardList /> },
  { path: "/reward/order-reward/view/:id", component: <OrderRewardTemplate pageView="view" /> },

  /* Shipping Method route */
  { path: "/shipping/shipping-method", component: <ShippingMethodList /> },
  { path: "/shipping/shipping-method/create", component: <ShippingMethodTemplate pageView="create" /> },
  { path: "/shipping/shipping-method/edit/:id", component: <ShippingMethodTemplate pageView="update" /> },
  { path: "/shipping/shipping-method/view/:id", component: <ShippingMethodTemplate pageView="view" /> },
  /* End Shipping Method  route */

  /* Payment Method Group route */
  { path: "/payment/payment-method-group", component: <PaymentMethodGroupList /> },
  { path: "/payment/payment-method-group/create", component: <PaymentMethodGroupTemplate pageView="create" /> },
  { path: "/payment/payment-method-group/edit/:id", component: <PaymentMethodGroupTemplate pageView="update" /> },
  { path: "/payment/payment-method-group/view/:id", component: <PaymentMethodGroupTemplate pageView="view" /> },
  /* End Payment Method Group route */

  /* Payment Credit Debit route */
  { path: "/payment/payment-credit-debit", component: <PaymentCreditDebitList /> },
  { path: "/payment/payment-credit-debit/create", component: <PaymentCreditDebitTemplate pageView="create" /> },
  { path: "/payment/payment-credit-debit/edit/:id", component: <PaymentCreditDebitTemplate pageView="update" /> },
  { path: "/payment/payment-credit-debit/view/:id", component: <PaymentCreditDebitTemplate pageView="view" /> },
  /* End Payment Credit Debit route */


  /* Products-Management products route */
  { path: "/product-management/products", component: <ProductsList /> },
  { path: "/product-management/products/create", component: <ProductsTemplate pageView="create" /> },
  { path: "/product-management/products/edit/:id", component: <ProductsTemplate pageView="update" /> },
  { path: "/product-management/products/view/:id", component: <ProductsTemplate pageView="view" /> },

  { path: "/product-management/attributes", component: <AttributesList /> },
  { path: "/product-management/attributes/create", component: <AttributesTemplate pageView="create" /> },
  { path: "/product-management/attributes/edit/:id", component: <AttributesTemplate pageView="update" /> },
  { path: "/product-management/attributes/view/:id", component: <AttributesTemplate pageView="view" /> },
  /* End Reward Terms Conditions route */

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
  { path: "/print", component: <PrintComponent /> },

  { path: "/make/questionnaire/:questionnaireUUID", component: <QuestionnaireFrontweb /> }
];

export { authProtectedRoutes, publicRoutes };
