import React, { useEffect, useState, useContext, useRef } from "react"
import { checkScope } from "helpers/utility_helper"
import _ from "lodash"
import PopupForm from "../PopupForm"

const TEMPLATE_NAME = 'Template6'
const TEMPLATE_6_CONF = {
  1: { order: 1, width: 600, height: 666 },

  2: { order: 2, width: 600, height: 321 },
  3: { order: 3, width: 600, height: 321 },

  4: { order: 4, width: 184, height: 184 },
  5: { order: 5, width: 184, height: 184 },
  6: { order: 6, width: 184, height: 184 },
  7: { order: 7, width: 184, height: 184 },
  8: { order: 8, width: 184, height: 184 },
  9: { order: 9, width: 184, height: 184 },

  10: { order: 10, width: 1224, height: 126 },
}

const Template6 = ({
  useTemplateName = '',
  values = [],
  productGroupOption = [],
  onChange = (value = {}, config = {}, index) => console.log("onChange!"),
  onRemove = (config = {}, order) => console.log("onRemove!"),
  initMaxTemplate = (len) => console.log("initMaxTemplate!"),
  pageView = "create",
  viewmode = false
}) => {

  


  // ถ้าชื่อ Template ที่เลือกไม่ตรงกัน ไม่ต้องแสดง
  if (useTemplateName != TEMPLATE_NAME) {
    return <></>
  }

  useEffect(() => {
    if (useTemplateName == TEMPLATE_NAME) {
      initMaxTemplate(Object.keys(TEMPLATE_6_CONF).length)
    }
    
  }, [useTemplateName])
  console.log("🚀 ~ useTemplateName:", useTemplateName)

  const genTemplateNo = (no = 1) => {
    return <>
      {
        viewmode 
        ? <img src={`https://dummyimage.com/${TEMPLATE_6_CONF[`${no}`].width}x${TEMPLATE_6_CONF[`${no}`].height}/2C3D92/FFF`} alt=""/>
        : (
          <PopupForm
            // index={0}
            config={TEMPLATE_6_CONF[`${no}`]}
            value={values.find(item1 => item1.order == `${no}`) ?? null}
            productGroupOption={productGroupOption}
            onChange={(value = {}, config = {}, index) => {
              onChange(value, config, index)
            }}
            onRemove={(config = {}, order) => {
              onRemove(config, order)
            }}
            pageView={pageView}
          />
        )
      }
    </>

  }

  return (
    <>
    <div className="bTemplateGroup" data-template="6">
        <div className="bRow" data-item="2">
          <div className="bRow" data-item="1">
            <div className="bPic">
              {genTemplateNo(1)}
            </div>
          </div>
          <div className="bRow" data-item="1">
            <div className="bPic">
            {genTemplateNo(2)}
            </div>
            <div className="bPic">
            {genTemplateNo(3)}
            </div>
          </div>
    
        </div>
        <div className="bRow" data-item="6">
          <div className="bPic">
          {genTemplateNo(4)}
          </div>
          <div className="bPic">
          {genTemplateNo(5)}
          </div>
          <div className="bPic">
            {genTemplateNo(6)}
          </div>
          <div className="bPic">
            {genTemplateNo(7)}
          </div>
          <div className="bPic">
            {genTemplateNo(8)}
          </div>
          <div className="bPic">
            {genTemplateNo(9)}
          </div>
        </div>
        <div className="bRow" data-item="1">
          <div className="bPic">
          {genTemplateNo(10)}
          </div>
          </div>
      </div>
    </>
  )
}

export default Template6
