import React, { useState, useEffect } from 'react';

const TextSwatchForm = ({ disabled, values, onChange }) => {
  const [formRows, setFormRows] = useState([
    { 
      id: 1,
      swatchValue: '',
      labelAdmin: '',
      labelTh: '',
      labelEn: ''
    }
  ]);

  const onAddFormRow = () => {
    const newRow = {
      id: Math.floor(Math.random() * (30 - 20)) + 20,
      swatchValue: '',
      labelAdmin: '',
      labelTh: '',
      labelEn: ''
    };
    const newRows = [...formRows, newRow];
    setFormRows(newRows);
    if (onChange) {
      onChange(newRows);
    }
  };

  const onDeleteFormRow = (id) => {
    const updatedRows = formRows.filter(row => row.id !== id);
    setFormRows(updatedRows);
    if (onChange) {
      onChange(updatedRows);
    }
  };

  const handleInputChange = (id, field, value) => {
    const updatedRows = formRows.map(row => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    
    setFormRows(updatedRows);
    if (onChange) {
      onChange(updatedRows);
    }
  };

  useEffect(() => {
    if (values.length > 0) {
      setFormRows(values);
    }
  }, [values]);

  return (
    <div className="text-swatch-form mt-4">
      <h6 className="mb-3">Text Swatch Options</h6>
      <div className="option-rows">
        {formRows.map((row) => (
          <div key={row.id} className="mb-4 p-3 border rounded">
            <div className="row">
              <div className="col-md-2 mb-3">
                <label className="form-label">Value</label>
                <input
                  type="text"
                  className="form-control"
                  value={row.swatchValue}
                  onChange={(e) => handleInputChange(row.id, 'swatchValue', e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="col-md-3 mb-3">
                <label className="form-label">Admin Label</label>
                <input
                  type="text"
                  className="form-control"
                  value={row.labelAdmin}
                  onChange={(e) => handleInputChange(row.id, 'labelAdmin', e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="col-md-3 mb-3">
                <label className="form-label">Thai Label</label>
                <input
                  type="text"
                  className="form-control"
                  value={row.labelTh}
                  onChange={(e) => handleInputChange(row.id, 'labelTh', e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="col-md-3 mb-3">
                <label className="form-label">English Label</label>
                <input
                  type="text"
                  className="form-control"
                  value={row.labelEn}
                  onChange={(e) => handleInputChange(row.id, 'labelEn', e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="col-md-1 mb-3 d-flex align-items-end">
                <button
                  type="button"
                  className="btn btn-danger w-100"
                  onClick={() => onDeleteFormRow(row.id)}
                  disabled={disabled}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <button
        type="button"
        className="btn btn-success mt-2"
        onClick={onAddFormRow}
        disabled={disabled}
      >
        Add Option
      </button>
    </div>
  );
};

export default TextSwatchForm;
