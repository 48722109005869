import { get, post, put, patch } from "../Config"

export function SearchPartners({ params = {} }) {
  return get({ url: `/v1/admin/partners`, params })
}4
export function GetPartnersById({ id }) {
  return get({ url: `/v1/admin/partners/${id}` })
}
export function CreatePartners({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/partners`, params, data })
}
export function UpdatePartners({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/partners/${id}`, params, data })
}
export function PatchPartners({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/partners/${id}`, params, data })
}
export function DeletePartners({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/partners/mutiDelete`, params, data })
}
