import { get, post, put } from "../Config"

export function listPointType({ params = {} }) {
  return get({ url: `/v1/admin/point/type/`, params })
}
export function detailPointType({ params = {}, id }) {
  return get({ url: `/v1/admin/point/type/detail/${id}`, params })
}
export function createPointType({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/point/type/create`, params, data })
}
export function updatePointType({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/point/type/update/${id}`, params, data })
}
export function deletePointType({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/point/type/delete`, params, data })
}

export function listPointConfig({ params = {} }) {
  return get({ url: `/v1/admin/point/config/`, params })
}
export function detailPointConfig({ params = {}, id }) {
  return get({ url: `/v1/admin/point/config/detail/${id}`, params })
}
export function createPointConfig({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/point/config/create`, params, data })
}
export function updatePointConfig({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/point/config/update/${id}`, params, data })
}
export function deletePointConfig({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/point/config/delete`, params, data })
}

export function listPointSpecialEventConfig({ params = {} }) {
  return get({ url: `/v1/admin/point/special-event-config/`, params })
}
export function detailPointSpecialEventConfig({ params = {}, id }) {
  return get({
    url: `/v1/admin/point/special-event-config/detail/${id}`,
    params,
  })
}
export function createPointSpecialEventConfig({ params = {}, data = {} }) {
  return post({
    url: `/v1/admin/point/special-event-config/create`,
    params,
    data,
  })
}
export function updatePointSpecialEventConfig({ params = {}, data = {}, id }) {
  return put({
    url: `/v1/admin/point/special-event-config/update/${id}`,
    params,
    data,
  })
}
export function deletePointSpecialEventConfig({ params = {}, data = {} }) {
  return post({
    url: `/v1/admin/point/special-event-config/delete`,
    params,
    data,
  })
}
