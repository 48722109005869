import moment from "moment";
import helper from "./fn_helper";
import Cookies from 'js-cookie';
import _ from "lodash";

const MONTH = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];
/* Check ID card format */



export const checkIdCardFormat = (idCard) => {
  if (idCard.length !== 13) return false;

  let i,
    sum = 0;
  for (i = 0; i < 12; i++) {
    sum += parseFloat(idCard.charAt(i)) * (13 - i);
  }

  if ((11 - (sum % 11)) % 10 != parseFloat(idCard.charAt(12))) {
    return false;
  } else {
    return true;
  }
};
/* End Check ID card format */

/* Convert date format */
export const convertDateFormat = (date = "", format = "") => {
  if (date !== "") {
    date = new Date(date);

    switch (format) {
      case "d-m-y":
        date = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        break;

      case "d / m / y":
        date = `${date.getDate()} / ${
          date.getMonth() + 1
        } / ${date.getFullYear()}`;
        break;

      default:
        date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        break;
    }
  }
  return date;
};
/* End Convert date format */

/* Convert mobile number format */
export const convertMobileNoFormat = (mobileNo = "", format = "") => {
  if (mobileNo !== "") {
    switch (format) {
      case "-":
        mobileNo = mobileNo.replace(
          /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
          "$1$2$3-$4$5$6-$7$8$9$10"
        );
        break;

      default:
        mobileNo = mobileNo.replaceAll("-", "");
        break;
    }
  }
  return mobileNo;
};
/* End Convert mobile number format */

/* Break form submit with enter key */
export const breakKeyDownEnter = (e) => {
  if (e.key === "Enter") e.preventDefault();
};
/* End Break form submit with enter key */

export default {
  ISDEV : () => localStorage.getItem('devmode') === "dev",
  CONFIG: {
    //  CONFIG API
    MAX_SIZE_FILE: 5120000,
    IMG_TYPE_FILE: ["image/png", "image/jpeg", "image/jpg"],
  },
  GEN: {
    YEAR_OPTION: (startYear = 2000, yearsAhead) => {
      const currentYear = moment().year();
      const years = [];
      
      for (let year = startYear; year <= currentYear + yearsAhead; year++) {
        years.push({ "id": year.toString(), "name": year.toString() });
      }
      
      return years;
    }
  },
  OPTIONS: {
    ADD_SELECT_DEF: (arrData = []) => {
      const SELECT_DEF = { id: "", name: "NO SELECT" }
      let tmpData = _.cloneDeep(arrData)
      tmpData.unshift(SELECT_DEF)
      return tmpData
    },
    STATUS: [
      { id: "0", name: "Inactive" },
      { id: "1", name: "Active" },
    ],
    GENDER: [
      { value: 1, label: "Male" },
      { value: 2, label: "Female" },
      { value: 3, label: "Non-Specified" },
    ],
    WORK_PATTERN_OPTION: [
      { id: "FULL_TIME", name: "งานเต็มเวลา" },
      { id: "PART_TIME", name: "งานพาร์ทไทม์" },
      { id: "CONTRACT", name: "สัญญาจ้าง" },
      { id: "INTERNSHIP", name: "ฝึกงาน" },
    ]
  },
  STYLE: {
    aDisable: (isDisable = false) => {
      return { pointerEvents: isDisable ? "none" : "all" };
    },
  },
  FN: {
    delayTime: (ms) =>{
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    calPercen(value = 0, total = 1) {
      if (!total) {
        total = 1;
      }
      return (value / total) * 100;
    },
    removeUndefined: (obj) => {
      Object.keys(obj).forEach((key) =>
        obj[key] === undefined || obj[key] === "" || obj[key] === "[]"
          ? delete obj[key]
          : {}
      );
      return obj;
    },
    range: (start, end) => {
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
    objectToQueryString: (obj) => {
      var queryString = "";
      let i = 0;
      const maxLen = Object.keys(obj).length;
      for (var key in obj) {
        if (obj[key].length) {
          // queryString += encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
          queryString += encodeURIComponent(key) + "=" + obj[key].join(",");
        }

        if (obj.hasOwnProperty(key)) {
          if (obj[key].length > 0) {
            queryString += "&";
          }
        }

        i++;
      }
      queryString = queryString.substring(0, queryString.length - 1);

      return queryString ? "?" + queryString : "";
    },

    toNumberWithCommas: (x = "") => {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return "0";
    },
    removeCommasToNumber(formattedValue) {
      let rawValue = `${formattedValue}`.replace(/,/g, '');
      return Number(rawValue); // หรือใช้ parseInt(rawValue, 10) ถ้าคุณไม่ต้องการทศนิยม
    },
    toArrayNumber: (arr = []) => {
      return arr.map((item) => Number(item)).filter((item) => item);
    },
    numberWithZero: (num = "", pad = 4) => {
      var str = "" + num;
      var padStr = "";
      for (let i = 0; i < pad; i++) {
        padStr += "0";
      }
      return padStr.substring(0, padStr.length - str.length) + str;
    },
    gotoPage: (url) => {
      if (url) window.open(url, "_blank");
    },
    splitText: (str = "", len = 255) => {
      // if (url) window.open(url, "_blank");
      if (str.length <= len) {
        return str;
      }
      return str.substring(0, len) + "...";
    },
    formatIdcard: (number, useStar = false) => {
      const asteriskIndices = [10, 11, 12, 13, 14]; // Indices to replace with asterisks
      const indexSpace = ["1", "5", "10", "12"]; // Indices to replace with asterisks
      const digits = number.split("");
      // Replace the specified indices with asterisks
      let formattedNumber = "";
      for (let i in number) {
        const n = number[i];
        // formattedNumber += i + ' '
        if (indexSpace.includes(i)) {
          formattedNumber += " ";
        }
        if (useStar && i >= 8) {
          formattedNumber += "*";
        } else {
          formattedNumber += n;
        }
      }
      return formattedNumber;
    },

    getQueryStringParams: (url) => {
      const queryString = url.split("?")[1];
      if (!queryString) return {};

      const paramsArray = queryString.split("&");
      const params = {};

      paramsArray.forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = decodeURIComponent(value);
      });

      return params;
    },
    detectBoxContent: (selectorCSS, callBack, realTime = false) => {
      if (!!window.IntersectionObserver) {
        let observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                callBack();
                if (realTime === false) {
                  observer.unobserve(entry.target);
                }
              }
            });
          },
          { rootMargin: "0px 0px -200px 0px" }
        );
        // Add the observer to you swiper
        if (document.querySelector(selectorCSS)) {
          observer.observe(document.querySelector(selectorCSS));
        }
      } else {
        // You can use the polyfill or just start the autoplay
      }
    },
    generateRandomNumber: (n = 10) => {
      return Math.floor(Math.random() * n);
    },
    zeroToDash(value) {
      if (value == 0 || !value) {
        return "-";
      }
      return value;
    },
  },
  TIME: {
    toShortAllDateTH: (dateshow) => {
      if (dateshow) {
        const YearTHShort = `${
          Number(moment(dateshow).format("YYYY")) + 543
        }`.slice(2, 4);
        return moment(dateshow).format("DD MMM ") + YearTHShort;
      }
      return "";
    },
    toShortAllDateNormalTH: (dateshow) => {
      if (dateshow) {
        const YearTHShort = `${
          Number(moment(dateshow).format("YYYY")) + 543
        }`.slice(2, 4);
        return moment(dateshow).format("DD/MM/") + YearTHShort;
      }
      return "";
    },
    toShortDateNormal: (dateshow) => {
      if (dateshow) {
        return moment(dateshow).format("DD/MM/YYYY");
      }
      return "";
    },
    toShortDateNormalTH: (dateshow) => {
      const YearTH = `${Number(moment(dateshow).format("YYYY")) + 543}`;
      if (dateshow) {
        return moment(dateshow).format("DD/MM/") + YearTH;
      }
      return "";
    },
    toShortDateTimeNormalTH: (dateshow) => {
      const YearTH = `${Number(moment(dateshow).format("YYYY")) + 543}`;
      if (dateshow) {
        return (
          moment(dateshow).format("DD/MM/") +
          YearTH +
          " " +
          moment(dateshow).format("HH:mm:ss") +
          " น."
        );
      }
      return "";
    },

    toShortTime: (dateshow) => {
      if (dateshow) {
        return moment(dateshow, "HH:mm:ss").format("HH.mm");
      }
      return "";
    },
    toShortTimeAmPm: (dateshow) => {
      if (dateshow) {
        return moment(dateshow, "HH:mm:ss").format("h.mm A");
      }
      return "";
    },
    toDateAdd7: (dateTimeStr = "") => {
      return moment(dateTimeStr).add(7, "hour").format("YYYY-MM-DD");
    },
    toFullDateTH: (dateshow) => {
      const YearTH = `${Number(moment(dateshow).format("YYYY")) + 543}`;
      if (dateshow) {
        return (
          moment(dateshow).format("DD") +
          ` ${MONTH[Number(moment(dateshow).format("M")) - 1]} ` +
          YearTH
        );
      }
      return "";
    },
    generateTimes:(startTimeStr = '00:00', endTimeStr = '23:30', intervalMinutes = 30) => {
      const startTime = moment(startTimeStr, 'HH:mm');
      const endTime = moment(endTimeStr, 'HH:mm');
      const interval = intervalMinutes; // minutes
  
      let times = [];
      let currentTime = startTime.clone();
  
      while (currentTime <= endTime) {
          times.push(currentTime.format('HH:mm'));
          currentTime.add(interval, 'minutes');
      }
      return times;
  }
  },
  FORMAT: {
    TEL: (txt = "") => {
      return txt.replace(/^(\d{3})(\d{3})(\d{4})$/, "$1-$2-$3");
    },
    TelToNumber: (txt = "") => {
      return txt.replace(/-/g, "");
    },
  },
  SORT: {
    compare: (a, b, key) => {
      if (_.get(a, key) < _.get(b, key)) {
        return -1;
      }
      if (_.get(a, key) > _.get(b, key)) {
        return 1;
      }
      return 0;
    },
  },
  IMAGE: {
    async toImageSmall(fileBase64) {
      async function reduce_image_file_size(
        base64Str,
        MAX_WIDTH = 900,
        MAX_HEIGHT = 900
      ) {
        let resized_base64 = await new Promise((resolve) => {
          let img = new Image();
          img.src = base64Str;
          img.onload = () => {
            let canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas.toDataURL()); // this will return base64 image results after resize
          };
        });
        return resized_base64;
      }

      async function image_to_base64(file) {
        let result_base64 = await new Promise((resolve) => {
          let fileReader = new FileReader();
          fileReader.onload = (e) => resolve(fileReader.result);
          fileReader.onerror = (error) => {
            console.log(error);
            alert("An Error occurred please try again, File might be corrupt");
          };
          fileReader.readAsDataURL(file);
        });
        return result_base64;
      }

      async function process_image(file, min_image_size = 300) {
        // const res = await image_to_base64(file);
        const res = file;
        if (res) {
          const old_size = calc_image_size(res);
          if (old_size > min_image_size) {
            const resized = await reduce_image_file_size(res);
            const new_size = calc_image_size(resized);
            console.log("new_size=> ", new_size, "KB");
            console.log("old_size=> ", old_size, "KB");
            return resized;
          } else {
            console.log("image already small enough");
            return res;
          }
        } else {
          console.log("return err");
          return null;
        }
      }

      /*- NOTE: USE THIS JUST TO GET PROCESSED RESULTS -*/
      // async function preview_image() {
      //     console.log('00')
      //     const file = document.getElementById('file');
      //     const image = await process_image(file.files[0]);
      //     console.log('image >>', image)
      // }

      function calc_image_size(image) {
        let y = 1;
        if (image.endsWith("==")) {
          y = 2;
        }
        const x_size = image.length * (3 / 4) - y;
        return Math.round(x_size / 1024);
      }

      return new Promise(async (resolve) => {
        // const file = document.getElementById('file');
        // const image = await process_image(file.files[0]);
        const tmpBase64 = `data:image/jpeg;base64,${fileBase64}`;
        let imageResize = await process_image(tmpBase64);
        console.log("imageResize >>", imageResize);
        // imageResize = `${imageResize}`.replace('data:image/png;base64,','')
        resolve(imageResize);
      });
    },
  },
  Auth: {
    checkCookie : (cookieName) => {
      const cookieValue = Cookies.get(cookieName);
      console.log("🚀 ~ cookieValue:", cookieValue)
      // const cookies = document.cookie.split(';');
      // console.log("🚀 ~ cookies:", cookies)
      // for (let cookie of cookies) {
      //   let [name, value] = cookie.split('=');
      //   name = name.trim();
      //   if (name === cookieName) {
      //     return value; // คืนค่าถ้าพบ cookie
      //   }
      // }
      if (cookieValue) {
        return cookieValue
      }
      return null; // ถ้าไม่พบ cookie
    },
    deleteCookie: (cookieName) => {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
    isLogin: () => {
      return !!localStorage.getItem("token");
    },
    Logout: ( callBack = () => { console.log('callback') }) => {
      console.log('Logout !!!!')
      localStorage.removeItem("token");
      callBack()

    },
    setToken: (token) => {
      localStorage.setItem("token", token)
    },
    getToken: () => {
      let mytoken = localStorage.getItem("cmsMenu");
      if (mytoken) {
        return `Bearer ${mytoken}`;
      }
      return null;
    },
    getBearerToken: () => {
      let mytoken = localStorage.getItem("cmsMenu");
      if (mytoken) {
        return `Bearer ${mytoken}`;
      }
      return null;
    },
    getLang: () => {
      let lang = localStorage.getItem("lang");
      if (lang) {
        return `${lang}`;
      }
      return null;
    },
    getTokenData: () => {
      try {
        let mytoken = localStorage.getItem("token");
        const decode = jwt_decode(mytoken);
        return decode;
      } catch (e) {
        return {};
      }
    },
  },
  FNFORM: {
    getObjectValue: (options = [], keyId) => {
      return options.find(item => item.id == keyId)
    },
    getArrObjectValue: (_option =[], _values = [], keyId = 'id') => {
      if (_values && _values.length) {
        return _option.filter(item => _values.includes(item[keyId]))
      }
      return []
    },
    arrObjToId: (values = []) => {
      const newValues = _.cloneDeep(values)
      let updateState = newValues.map(item => item.id)
      return updateState
    },
    checkDupField: (fieldName, value, dataList = [], id = 0) => {
      let dataFind = dataList.filter(item =>  item.id != id && item[_.snakeCase(fieldName)] === value)
      return dataFind.length > 0
    },
    handleNumberOnly: (e) => {
      const numericValue = e.target.value.replace(/[^0-9]/g, "");
      e.target.value = numericValue;
    },
    handleNumberDotOnly: (e) => {
      let numericValue = e.target.value.replace(/[^.0-9]/g, "");
      console.log('handleNumberDotOnly e.target.value >>', e.target.value)
      let dotCount = 0;
      for (let ct of numericValue) {
        if (ct === ".") {
          dotCount = dotCount + 1
        }

        if (dotCount >= 2) {
          numericValue = numericValue.slice(0, -1)
        }
      }

      console.log('numericValue.indexOf(".") >>', numericValue.indexOf("."))
      const indexDot = numericValue.indexOf(".")
      if (indexDot != -1) {
        let afterDot = 2
        numericValue = numericValue.substring(0, indexDot + (afterDot + 1));
      }

      e.target.value = numericValue;
    },
    handleCheckBox: (mainId, listId, state) => {
      let _state = _.cloneDeep(state);
      if (_state[mainId]) {
        if (_state[mainId].includes(listId)) {
          _state[mainId] = _state[mainId].filter((item) => item !== listId);
          if (_state[mainId].length === 0) {
            delete _state[mainId];
          }
        } else {
          _state[mainId].push(listId);
        }
      } else {
        _state[mainId] = [listId];
      }
      return _state;
    },
    handleCharOnly: (e) => {
      // const charValue = e.target.value.replace(/[^@_.0-9a-zA-Zก-์]/g, "");
      const charValue = e.target.value.replace(/[^\s@_.a-zA-Z0-9ก-์]/g, "");
      e.target.value = charValue;
    },
    handleCharEngOnly: (e) => {
      const charValue = e.target.value.replace(/[^\s@_.a-zA-Z0-9]/g, "");
      e.target.value = charValue;
    },
    handleEngCharOnly: (e) => {
      // const charValue = e.target.value.replace(/[^@_.0-9a-zA-Zก-์]/g, "");
      const charValue = e.target.value.replace(/[a-zA-Z\s]/g, "");
      e.target.value = charValue;
    },
    isUpperCase: (e) => {
      const charEng = e.target.value.replace(/[^\s@_.a-zA-Z0-9]/g, "");
      const charValue = charEng.toUpperCase();
      e.target.value = charValue;
    },

    idCardCheck: (idCardNumber) => {
      let sum = 0;
      for (let i = 0; i < idCardNumber.length - 1; i++) {
        sum += parseInt(idCardNumber[i]) * (13 - i);
      }

      let checkDigit = (11 - (sum % 11)) % 10;

      if (checkDigit == idCardNumber[12]) {
        return true;
      } else {
        return false;
      }
    },
    handleNumberMoneyOnly: (e) => {
      let rawValue = e.target.value.replace(/,/g, '');
      if (/^\d*$/.test(rawValue)) {
        e.target.value = new Intl.NumberFormat().format(rawValue);
      } else {
        e.target.value = e.target.value.slice(0, -1);
      }
      return e;
    },
  },
  FNFILE: {
    getImageDimensions : async (file) => {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
              const img = new Image();
              img.onload = () => {
                  resolve({ width: img.width, height: img.height });
              };
              img.onerror = reject;
              img.src = e.target.result;
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
      });
    }
  }
};
