import { get, post, put, patch } from "../Config"

export function SearchRewardCategory({ params = {} }) {
  return get({ url: `/v1/admin/rewardCategory`, params })
}
export function GetRewardCategoryById({ id }) {
  return get({ url: `/v1/admin/rewardCategory/${id}` })
}
export function CreateRewardCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/rewardCategory`, params, data })
}
export function UpdateRewardCategory({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/rewardCategory/${id}`, params, data })
}
export function PatchRewardCategory({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/rewardCategory/${id}`, params, data })
}
export function DeleteRewardCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/rewardCategory/mutiDelete`, params, data })
}
